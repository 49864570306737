.NieuwsPage {    
    .textblocks {
        position: relative;

        .content {
            &-block {
                &--container {
                    margin-top: 40px;

                    > * {
                        &:not(:first-of-type) {
                            margin-top: 60px;
                        }
                    }
                }

                &.textBlock {
                    margin-right: 200px;

                    .title {
                        margin-bottom: 10px;
                        font-weight: 600;
                    }                    
                }    
            }
        }               
        
        .share {  
            display: flex;
            align-items: flex-start;
            margin-bottom: 40px;
            
            p {
                margin-right: 10px;
            }

            &-buttons {
                display: flex;
                flex-direction: column;
                align-items: center;

                a {
                    text-decoration: none;
                    height: 20px;

                    svg {
                        height: 100%;

                        path {
                            fill: var(--brandingColor);
                        }
                    }
                }

                a + a {
                    margin-top: 5px;
                }
            }
            
            &-press {
                &--container {
                    position: sticky;
                    top: 200px;
                }            
    
                position: absolute;
                top: 0;
                right: $space-base;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
    
                li {
                    list-style: none;
                    display: flex;
                    justify-content: flex-end;
    
                    &:not(:first-of-type) {
                        margin-top: 10px;
                    }
                }
            }
        }
        
        @include mq($until: $resp-nav) {
            .content {
                &-block {
                    &.textBlock {
                        margin-right: 0;
                    }
                }
            }

            .share {    
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                left: 0;  
                
                p {
                    margin-top: 5px;
                    margin-bottom: 0px;
                }

                &-buttons {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    margin-left: 10px;

                    a + a {
                        margin-top: 0;
                        margin-left: 10px;
                    }
                }

                &-press {
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
                    position: relative;                    
                    right: unset;
                    left: unset;

                    &--container {
                        position: relative;
                        justify-content: flex-start;
                        top: 0;
                        left: 0;
                        display: flex;
                        padding: 0 50px;
                        margin-bottom: 50px;
                    }

                    .press {
                        padding-left: 0;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                    }
                }
            }       
        }    
        
        @include mq($until: $viewport--sm) {
            .share {
                &-press {
                    &--container {
                        padding: 0 30px;
                    }
                }
            }
        }
    }
}

.NieuwsOverviewPage {
    .listing {
        margin-top: 180px;

        &-item {
            display: grid;
            grid-template-columns: 540px auto;

            &--image {
                width: 100%;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;                    
                    transform-origin: center;
                    transition: transform .2s ease;
                }
            }

            &.hovering {
                .listing-item--image {
                    img {
                        transform: scale(1.05);
                    }
                }
            }

            &--info {
                background-color: var(--brandingShade);
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 100px 140px;

                h2 {
                    max-width: 400px;
                }

                .subtitle {
                    color: var(--brandingColor);
                    font-weight: 400;
                    text-transform: uppercase;
                    font-size: 14px;
                    margin-top: 10px;
                }

                .text {
                    margin-bottom: 30px;
                }

                .ctas {
                    display: flex;
                    align-items: flex-end;                    

                    .cta {
                        &:not(.simple) {
                            transition: background-color .2s ease, border-color .2s ease;
                        
                            span {
                                color: var(--brandingColor);
                                transition: color .2s ease;
                            }
                            
                            &:hover {
                                background-color: var(--primaryDark) !important;
                                border-color: var(--primaryDark) !important;
    
                                span {
                                    color: $cc-white !important;
                                }
                            }
                        }
                        

                        &:not(:last-of-type) {
                            margin-right: 20px;
                        }
                    }
                }
            }
        }

        .listing-item + .listing-item {
            margin-top: 40px;
        }

        @include mq($until: 1500px) {
            margin-left: 80px;
            margin-right: 80px;
        }
        
        @include mq($until: 1350px) {
            &-item {
                &--info {
                    padding: 60px;
                }
            }
        }

        @include mq($until: 1250px) {
            &-item {
                grid-template-columns: 400px auto;
            }
        }
       
        @include mq($until: 1000px) {
            margin-left: 30px;
            margin-right: 30px;

            &-item {
                grid-template-columns: 320px auto;

                &--info {
                    padding: 40px;
                }
            }
        }

        @include mq($until: $viewport--md) {
            margin-top: 80px;

            &-item {
                grid-template-columns: auto;

                &--image {
                    max-height: 400px;
                }

                &--info {
                    padding: 40px 30px;

                    .text {
                        * {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        @include mq($until: $viewport--sm) {
            &-item {
                &--image {
                    max-height: 280px;
                }
            }
        }
    } 
}