.media {
    &-items {
        margin-top: 180px;

        &--invisible {
            display: none;
        }
    }

    &-item {
        display: flex;
        flex-direction: column;

        > a {
            position: relative;
    
            .media-item--figure {
                overflow: hidden;
                height: 300px;
    
                img {
                    transition: transform .2s ease;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
    
            .media-item--button {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: rgba(var(--primary), .75);
                opacity: 0;
                transition: opacity .2s ease;
                z-index: 1;
    
                .cta {
                    background-color: var(--brandingColor);
                    pointer-events: none;
                }
    
                &:hover {
                    opacity: 1;
    
                    & ~ .media-item--figure {
                        img {
                            transform: scale(1.02);
                        }
                    }
                }
            }
        }

        > h3 {
            margin-top: 20px;
            margin-bottom: 0;
        }
    }    

    @include mq($until: $viewport--md) {
        &-items {
            margin-top: 80px;
        } 
    }
}