.textblock {
    background-color: var(--brandingShade);
    padding: 250px 0 200px 0;
    position: relative;
    z-index: 1;
    overflow-x: hidden;

    > div {
        display: grid;
        grid-template-columns: 480px 830px;
        grid-column-gap: 70px;
        margin-top: -50px;
    
        .text {
            max-width: 480px;
            min-width: 480px;
            width: 100%;
    
            h1 {
                padding-bottom: 20px;
                margin-bottom: 20px;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                flex-direction: column;
        
                .element {
                    letter-spacing: 10px;
                    color: var(--brandingColor);
                }
            }
    
            .quote {
                margin-top: 100px;
            }

            .ctas {
                display: flex;
                flex-wrap: wrap;

                .cta {
                    margin-right: 10px;
                    margin-bottom: 10px;
                }
            }
        }
    
        .image {       
            position: relative;
            // overflow: hidden;
    
            &.amount {
                &-1 {
                    padding-left: 70px;
    
                    img {
                        &:nth-child(1) {
                            max-width: 660px;
                            position: absolute;
                            right: 0;
                        }
                    }
                }
    
                &-2 {
                    img {
                        &:nth-child(1),
                        &:nth-child(2) {
                            position: absolute;
                        }
    
                        &:nth-child(1) {
                            width: 700px !important;
                            height: 500px !important;
                            top: 0;
                            right: 0;
                        }
    
                        &:nth-child(2) {
                            width: 480px !important;
                            height: 300px !important;
                            bottom: 0;
                            left: 300px;
                        }
    
                    }
                }
                
                &-3 {
                    img {                    
                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(3) {
                            position: absolute;
                        }
                        
                        &:nth-child(1) {
                            width: 700px !important;
                            height: 500px;
                            top: 0;
                            right: 0;
                        }
                        
                        &:nth-child(2) {
                            width: 480px !important;
                            height: 300px !important;
                            bottom: -20px;
                            left: 300px;
                        }
                        
                        &:nth-child(3) {
                            width: 350px !important;
                            height: 530px !important;
                            bottom: 20px;
                            left: 20px;
                        }
                    }
                }
            }
            
            &.parallax {
                img {
                    &:nth-child(1) {
                        top: -100px;
                    }
                    
                    &:nth-child(2) {
                        bottom: 100px;
                    }
                    
                    &:nth-child(3) {
                        bottom: 200px;
                    }
                }
            }
        }        
    
        @include mq($from: $desktop-size) {
            grid-template-columns: 480px 800px;
            justify-content: space-between;
    
            .image {
                &.amount {
                    &-2 {
                        img {
                            &:nth-child(1) {
                                width: 80% !important;                    
                            }
                            
                            &:nth-child(2) {
                                width: 440px !important;                    
                            }
                        }
                    }
    
                    &-3 {
                        width: 100% !important;
                        
                        img {
                            &:nth-child(2) {
                                bottom: -20px;
                            }
                        }
                    }
                }
            }
        }
    
        @include mq($until: 2000px) {
            .image {
                max-width: 1000px;
            }
        }
        
        @include mq($until: 1800px) {
            .image {
                max-width: 800px;
            }
        }
    
        @include mq($until: 1700px) {
            padding: 140px 0 140px 0;
            grid-column-gap: 100px;
        }
       
        @include mq($until: 1450px) {
            grid-template-columns: 480px 1fr;
            padding-right: 0;
    
            .text {           
                .quote {
                    margin-top: 60px;
                }
            }
    
            .image {              
                &.amount {
                    &-2 {
                        img {
                            &:nth-child(1) {
                                width: 100% !important;
                                height: 440px;
                            }
        
                            &:nth-child(2) {
                                left: -20px;
                            }
                        }
                    }
    
                    &-3 {
                        img {
                            &:nth-child(1) {
                                top: -40px;
                                right: -110px;
                            }
                        }
                    }
                }
            }
        }
    
        @include mq($until: 1350px) {
            .image {
                min-height: 700px;
                max-height: calc(100vh - 600px);
                max-width: 590px
            }
        }
    
        @include mq($until: 1250px) {
            padding: 80px 0;
        }
    
        @include mq($until: 1100px) {
            padding: 70px 0 70px 0;
        }
    
        @include mq($until: 1000px) {
            padding: 70px 0;
            grid-template-columns: 100%;
            grid-row-gap: 40px;
            margin-bottom: 0;
    
            .text {
                min-width: unset;
                max-width: 480px;
    
                .quote {
                    margin-top: 30px;
                }
            }
    
            .image {
                max-width: unset;
                min-height: 500px;
    
                &.amount {
                    &-1 {
                        padding-left: 0;
                        max-width: 600px;
    
                        img {
                            &:nth-child(1) {
                                max-width: unset;
                                position: relative;
                            }
                        }
                    }
    
                    &-3 {
                        img {
                            &:nth-child(1) {
                                position: relative;
                                max-height: 500px;
                                top: 0;
                                right: 0;
                            }
                        }
                    }
                }
                
                img {
                    &:nth-child(1) {
                        height: 100%;
                        transform: translateY(0) !important;
                    }
    
                    &:nth-child(2), &:nth-child(3) {
                        display: none;
                    }
                }
            }
        }
    
        @include mq($until: $viewport--sm) {
            .text {
                h1 {
                    align-items: center;
                }
            }
            
            .image {
                min-height: 300px;
                max-height: 300px;
                height: 100%;
                overflow: hidden;
                            
                img {
                    &:nth-child(1) {
                        height: 300px !important;
                    }
                }
            }
        }
    }

    @include mq($until: 1450px) {
        padding: 100px 0 60px 0;
        padding-bottom: 300px !important;
    }
   
    @include mq($until: 1000px) {
        padding: 40px 0 0 0;
        padding-bottom: 0 !important;
    }

    > .container + .container {
        padding-top: 200px;
        padding-bottom: 200px;        
    }
}