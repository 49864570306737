.Show {
    &-list {
        margin-top: 80px;

        > .show + .show {
            margin-top: 40px;
        }

        @include mq($until: $viewport--sm) {
            margin-top: 100px;
            margin-bottom: 40px;
        }

        .show {
            &.Filter-tile {
                > h2 {
                    margin-bottom: 30px;
                    text-align: center;
                    margin-top: 120px;
                }
            }
        }
    }

    &-tile {
        display: grid;
        grid-template-columns: 340px auto;
        text-decoration: none;
        color: $cc-black;    
        
        &:focus, &:active {            
            color: $cc-black;
        }
        
        &.arrangement {
            &:hover {
                .ctas {
                    .no-cta {
                        span {
                            color: var(--brandingColor) !important;
                        }

                        &:after {
                            background-color: rgba(var(--brandingColor), 0);
                        }
                    }
                }
            }
        }

        .categories {
            > * {
                margin-left: 20px;
            }
        }

        &--image {
            width: 100%; 
            overflow: hidden;          

            img {
                width: 100%;
                height: 100%;
                transition: transform .2s ease;
            }

            &.resizable {
                img {
                    object-fit: cover;
                }
            }
        }

        &:hover {
            color: $cc-black;
            cursor: pointer;

            .Show-tile--image {
                img {
                    transform: scale(1.05);
                }
            }

            .cta {
                &.fake {
                    &:before {
                        transform: scaleX(0);
                    }
                }
            }
        }

        &--info {
            background-color: var(--brandingShade);
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 100px 140px;
            position: relative;

            .highlight {
                background-color: var(--primaryDark);
                color: $cc-white;
                font-size: 13px;
                letter-spacing: 1px;
                padding: 10px 15px;
                position: absolute;
                right: 0;
                top: 0;

                img {
                    height: 35px;
                }
            }

            .subtitle {
                color: var(--brandingColor);
                font-weight: 400;
                text-transform: uppercase;
                font-size: 14px;
                margin-top: 10px;
            }

            .intro {
                max-width: 500px;

                p {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3; /* number of lines to show */
                            line-clamp: 3; 
                    -webkit-box-orient: vertical;
                }
                
                &-list {
                    ul {
                        padding-left: 0;
                        margin-bottom: 0;

                        li {
                            list-style: none;
                            
                            svg {
                                width: 15px;
                                height: auto;
                            }

                            span {
                                margin-left: 10px;
                            }
                        }
                    }
                }
            }
            
            .bottom {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                
                .ctas {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-end;
                    margin-top: 30px;
                    
                    > * {
                        margin-top: 20px;
                    }

                    &.full {
                        width: 100%;

                        .cta {
                            width: inherit;
                            padding: 15px !important;
                        }
                    }
    
                    .cta {
                        z-index: 1;                        

                        &:not(.simple) {
                            transition: background-color .2s ease, border-color .2s ease;
                        
                            span {
                                color: $cc-white;
                                transition: color .2s ease;
                                z-index: 1;
                            }

                            &:after {
                                transition: background-color .2s ease;
                            }
                            
                            &:hover {                                
                                border-color: var(--primaryDark) !important;    

                                &:after {
                                    opacity: 1 !important;
                                    background-color: var(--primaryDark) !important;
                                }
                            }
                        }

                        &:nth-of-type(2) {
                            border-color: var(--primary);
                            
                            &:after {
                                background-color: var(--primary);
                            }

                            &:hover {
                                span {
                                    color: var(--primary);
                                }

                                &:after {
                                    background-color: var(--brandingShade) !important;
                                }
                            }
                        }

                        &:not(:last-of-type) {
                            margin-right: 20px;
                        }
                    }
                }

                .price {
                    margin-bottom: 0;
                    font-size: 35px;
                    color: var(--brandingColor);
                    line-height: 1;
                    font-family: $font-secondary;

                    .person, .small {
                        font-family: $font-base;
                        font-size: 14px;
                        font-weight: 600;
                        text-transform: uppercase;
                    }
                }
            }
        }

        @include mq($until: 1500px) {
            &--info {
                padding: 70px 80px;

                .bottom {
                    flex-direction: column;
                    align-items: flex-start;
                    
                    .categories {
                        margin-top: 40px;

                        > svg {
                            margin-left: 0;
                            margin-right: 20px;
                        }
                    }
                }
            }
        }

        @include mq($until: 1000px) {
            &--info {
                .bottom {
                    .ctas {
                        flex-direction: column;
                        align-items: flex-start !important;

                        > .price {
                            margin-top: 20px;
                        }
                    }
                }
            }
        }

        @include mq($until: 900px) {
            grid-template-columns: auto;

            &--image {
                // max-height: 420px;
                height: 100%;

                img {
                    object-fit: cover;
                }
            }

            &--info {
                .bottom {
                    .ctas {
                        flex-direction: column;
                        align-items: flex-start;

                        > a {
                            &:last-of-type {
                                margin-top: 10px;
                            }
                        }
                    }

                    .price {
                        font-size: 26px;
                    }
                }
            }
        }

        @include mq($until: 700px) {
            max-width: 300px;
            margin-left: auto;
            margin-right: auto;           
        }

        @include mq($until: $viewport--sm) {
            &--image {
                // max-height: 300px;
            }

            &--info {
                padding: 60px 40px 40px;
            }
        }
    }    

    &-tile + &-tile {        
        margin-top: 40px;        
    }
}

.ShowsOverviewPage, .ComedyPage {
    .Show {
        &-tile {
            .ctas {
                display: flex;
                align-items: center;
                
                .cta {
                    &:last-of-type {
                        margin-right: 30px;
                    }
                }
            }
        }
    }
}