.header-slider {
    margin-top: 120px;
    display: grid;
    grid-template-columns: 65% 35%;

    .left {
        .slick-slide {
            > div {
                max-height: 1000px;
                
                .figure {
                    height: 100%;
                    max-height: inherit;
        
                    .square-size {
                        display: none;
                    }
        
                    @include mq($from: 1300px, $until: 1800px) {
                        .normal-size {
                            display: none;
                        }
                        
                        .square-size {
                            display: flex;
                        }
                    }
        
                    @include mq($until: $viewport--sm) {
                        max-height: 350px;
                    }
                }
            }
        }


        * {
            width: 100%;
            height: 100%;
        }

        img {
            object-fit: contain !important;
            object-position: top left !important;
        }        

        video {
            object-fit: contain;
            object-position: top;
        }
        
        .video-container {
            position: relative;
            width: 100%;
            height: 0;
            padding-top: 56.25%; /* 16:9 aspect ratio (360/640) */

            #vimeo-player {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: var(--brandingColor);
                pointer-events: none;
                
                iframe {
                    width: 100%;
                    height: 100%;
                    pointer-events: none;
                }
            }
        }
    }

    .right {
        background-color: var(--brandingShade);        
        position: relative;

        &:before {
            content: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='71.888' height='71.888' viewBox='0 0 71.888 71.888'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Cpath id='Path_146' data-name='Path 146' d='M0,60.776H71.888V-11.112H0Z' transform='translate(0 11.112)' fill='%23fcf9f6'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Group_112' data-name='Group 112' transform='translate(0 11.112)'%3E%3Cg id='Group_110' data-name='Group 110' transform='translate(0 -11.112)' clip-path='url(%23clip-path)'%3E%3Cg id='Group_109' data-name='Group 109' transform='translate(-0.001 0.001)'%3E%3Cpath id='Path_145' data-name='Path 145' d='M60.776,30.388A35.944,35.944,0,0,1,24.832-5.556,35.94,35.94,0,0,1-11.112,30.388,35.94,35.94,0,0,1,24.832,66.332,35.944,35.944,0,0,1,60.776,30.388' transform='translate(11.112 5.556)' fill='%23fcf9f6'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            position: absolute;      
            width: 70px;
            height: 70px;
            top: calc(50% - 35px);
            left: -35px;
        }
        
        .slick {
            &-list, &-track, &-slide, &-slide > div {
                height: 100%;
            }        
            
            &-slide {
                padding: 80px 135px; 

                > div {
                    max-width: 400px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            &-arrow {
                width: 40px;
                height: 40px;
                z-index: 11;
                bottom: 0;
                position: absolute;
                background-color: var(--brandingColor);
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                transition: opacity .3s ease;

                &:hover {
                    opacity: .7;
                }

                &:last-of-type {
                    right: 0;
                }

                svg {
                    height: 20px;
                }
            }
        }

        .calendar {            
            &-container {
                height: 100%;
            }

            &-content {
                display: flex !important;
                flex-direction: column;
                justify-content: center;
                height: 100%;

                .intro {
                    margin-top: 20px;
                }

                .buttons {
                    display: flex;
                    margin-bottom: 40px;

                    > * {
                        margin-right: 10px;
                        margin-bottom: 10px;
                    }
                }

                .ticket-options {
                    padding-left: 0;
                    margin-bottom: 0;

                    li {
                        list-style: none;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border-top: 1px solid var(--brandingColor);
                        margin-bottom: 0;
                        padding: 20px 0;

                        > div {
                            display: flex;
                            flex-direction: column;
                        }

                        p {
                            margin-bottom: 0;
                        }


                        &:last-of-type {
                            border-bottom: 1px solid var(--brandingColor);
                        }
                    }
                }

                .kalender {
                    &-legend {
                        display: flex;
                        align-items: center;

                        &--option {
                            display: flex;
                            align-items: center;
                            margin-right: 20px;
                            margin-bottom: 10px;

                            .square {
                                width: 12px;
                                height: 12px;
                                background-color: var(--primary);
                                margin-right: 10px;

                                &.red {
                                    background-color: var(--red);
                                }
                            }

                            p {
                                margin-bottom: 0;
                                font-size: 13px;
                            }
                        }

                        @include mq($until: $viewport--sm) {
                            flex-direction: column;
                            align-items: flex-start;
                        }
                    }
                }
            }
        }
    }

    @include mq($until: 1800px) {
        grid-template-columns: 55% 45%;
    }
    
    @include mq($until: 1400px) {
        grid-template-columns: 50% 50%;
        
        .right {
            .slick {
                &-slide {
                    padding: 60px 70px; 
                }
            }
        }
    }
    
    @include mq($until: 1100px) {
        grid-template-columns: 100%;        

        .right {
            .slick {
                &-slide {
                    padding: 40px;

                    > div {
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
            }
        }
    }
   
    @include mq($from: $viewport--sm) {    
        .right {
            .slick {
                &-slide {
                    .buttons {
                        &-mobile {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    
    @include mq($until: $viewport--sm) {    
        .right {
            .slick {
                &-slide {                    
                    .buttons {
                        margin-right: 0;
                        margin-bottom: 30px;
                        width: 100%;

                        &-mobile {
                            display: flex;
                            margin-top: 30px;
                            
                            > a {
                                width: 100%;
                                margin-bottom: 0;
                                margin-right: 0;
                            }
                        }

                        > a {
                            width: 100%;
                            margin-bottom: 0;
                            margin-right: 0;

                            &:nth-of-type(2) {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

    @include mq($until: 500px) {
        .right {
            .slick {
                &-slide {    
                    padding: 40px 20px;

                    > div {
                        max-width: 280px;
                    }
                }
            }
        }
    }
    
    @include mq($until: 400px) {
        margin-top: 90px;
    }
}