.cta-block {
    background-color: var(--brandingShade);
    position: relative;
    display: flex;
    justify-content: center;    
    z-index: -1;  

    &--content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        padding: 50px 360px;

        figure {
            position: absolute;
            max-width: 360px;
            right: 0;
            bottom: 0;
        }

        h2 {
            margin-bottom: 20px;
            color: var(--primaryDark);
            text-align: center;
        }

        * {
            color: var(--primary);
            text-align: center;
        }

        .cta {
            color: var(--primaryDark) !important;
            transition: background-color .2s ease;
            margin-top: 20px;

            * {
                color: var(--primaryDark) !important;
            }

            &:hover {
                background-color: var(--primaryDark) !important;
                border-color: var(--primaryDark) !important;
                color: $cc-white !important;

                * {
                    color: $cc-white !important;
                }
            }
        }

        @include mq($until: 1100px) {
            padding: 50px 160px;

            figure {
                max-width: 300px;
                right: -180px;
            }
        }
        
        @include mq($until: 900px) {
            padding: 50px 160px;

            > * {
                &:not(.image) {
                    z-index: 1;
                }
            }

            figure {
                max-width: 300px;
                right: -80px;
                z-index: 0;
            }
        }

        @include mq($until: $viewport--md) {
            padding: 50px 40px;

            figure {
                right: -140px;
            }
        }
    }

    @include mq($until: $viewport--sm) {
        overflow-x: hidden;  
    }
}