html, body {
    font-family: $font-base;
    font-weight: 400;
    color: var(--primary);
}

h1, h2, h3, h4 {
    font-family: $font-secondary;
    line-height: 1;
    font-weight: 400;
}

h1 {
    font-size: 48px;

    @include mq($until: $viewport--md) {
        font-size: 40px;
    }

    @include mq($until: $viewport--sm) {
        font-size: 30px;
    }
}

h2 {
    font-size: 32px;

    @include mq($until: $viewport--md) {
        font-size: 28px;
    }

    @include mq($until: $viewport--sm) {
        font-size: 22px;
    }
}

h3 {
    font-size: 24px;

    @include mq($until: $viewport--md) {
        font-size: 22px;
    }

    @include mq($until: $viewport--sm) {
        font-size: 18px;
    }
}

p {
    strong, a {
        font-weight: 500;
        color: $cc-black;
    }

    a {
        &:hover {
            color: $cc-black;
        }
    }
}

.triple-grid {
    display: grid;
    grid-template-columns: repeat(3, 400px);   
    grid-column-gap: 85px; 
    grid-row-gap: 70px; 
    justify-content: center;

    @include mq($until: 1500px) {
        grid-column-gap: 40px;
        grid-row-gap: 35px;
    }
    
    @include mq($until: 1400px) {
        grid-template-columns: repeat(2, 500px);
    }

    @include mq($until: 1100px) {
        grid-template-columns: repeat(2, 350px);
    }
   
    @include mq($until: 900px) {
        grid-template-columns: 460px;
    }

    @include mq($until: $viewport--sm) {
        grid-template-columns: 100%;
    }
}

.Main {
    margin-bottom: 100px;

    @include mq($until: $viewport--md) {
        margin-bottom: 0;
    }
}

.u-hidden {
    display: none !important;
}

.quote {
    display: flex;
    align-items: center;
    height: fit-content;
    max-width: 670px;
    border-left: 1px solid var(--brandingColor);    
    padding-left: 30px;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 40px;
    padding-bottom: 40px;
    
    > div {
        font-size: 40px;
        font-family: $font-secondary;
        line-height: 1;        
    }

    span {
        margin-top: 20px;
        text-transform: uppercase;
        color: var(--brandingColor);
    }

    @include mq($until: $viewport--md) {
        padding-top: 20px;
        padding-bottom: 20px;

        > div {
            font-size: 28px;
        }

        span {
            margin-top: 10px;
            font-size: 14px;
        }
    }
}

.mobile-book {
    display: none;

    @include mq($until: $resp-nav) {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 20px;
        background-color: var(--red);
        text-decoration: none;
        z-index: 2;
        transition: opacity .2s ease;

        &:hover {
            opacity: .8;
        }

        span {
            font-size: 12px;
            color: $cc-white;
            font-family: $font-base;
            letter-spacing: 2px;
            text-transform: uppercase;
            font-weight: 500;
        }
    }
}