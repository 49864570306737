.breadcrumbs {
    padding: 10px 0;

    &-container {
        width: 100%;
        border-bottom: 1px solid var(--primary);
    }

    ul {
        margin-bottom: 0;
        padding-left: 0;
        display: flex;
        flex-wrap: wrap;

        li {
            list-style: none;
            margin-bottom: 0;
            padding-left: 0;
            font-size: 14px;
            font-family: $font-base;
            text-transform: lowercase;

            * {
                font-size: 14px;
                font-family: $font-base;
                text-transform: lowercase;                    
            }

            a {                
                text-decoration: none;

                > .title {                    
                    transition: color .2s ease;
                    
                    &:hover {
                        color: $cc-white !important;
                    }
                }       
                
                > .arrow {
                    width: 4px;
                    height: 6.7px;
                }
                
                > * {
                    color: $cc-darkGrey;
                    margin-right: 5px;
                }
            }
        }
    }
}