.HomePage {
    .full-page-header {       
        h1 {
            margin-bottom: 50px;
        }

        .cta {
            color: $cc-white !important;
            border-color: $cc-white !important;
            pointer-events: all;
            transition: background-color .2s ease, border-color .2s ease;

            span {
                transition: color .2s ease;
            }
            
            &:hover {                
                color: var(--primaryDark) !important;
                background-color: $cc-white !important;

                span {
                    color: var(--primaryDark) !important;
                }
            }
        }

        &-whiteblock {
            &--content {
                background-color: $cc-white;
            }
        }        
    }

    .js-carousel-shows {
        .slick {
            &-slide {
                .full-page-header--background {
                    max-height: 940px;
                    overflow: hidden;
                }
            }

            @include mq($until: $viewport--sm) {
                &-arrow {
                    left: 30px;

                    &:last-of-type {
                        left: 50px;
                    }
                }
            }
        }
    }
}

.full-page-header {
    padding-top: 240px;
    position: relative;
    padding-bottom: 240px;

    &.has-video {
        padding-top: 350px;
        padding-bottom: 350px;

        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &--container {
        margin-bottom: 120px;
        margin-top: $navHeight;

        @include mq($until: $resp-nav) {
            margin-top: 130px;
        }

        &.has-map {
            pointer-events: none;
    
            .full-page-header--background, .full-page-header-whiteblock--content {
                pointer-events: all;
            }
        }

        @include mq($until: $viewport--sm) {
            margin-bottom: 80px;
        }
    }

    &.has-block {
        padding-bottom: 400px;
        pointer-events: none;
    }

    &-whiteblock {
        margin-top: -250px;        
        margin-left: 140px !important;

        .parameters {
            display: flex;
            flex-direction: column;
            padding-left: 0;
            margin-bottom: 0;

            li {
                list-style: none;
                display: flex;
                margin-bottom: 0;

                figure {
                    width: 80px;
                    height: 80px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid var(--primaryDark);
                    border-radius: 100%;

                    img {
                        width: 40px;
                        height: 40px;
                    }
                }

                .text {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-left: 30px;

                    > p {
                        font-weight: 600;
                        text-transform: uppercase;
                    }

                    p {
                        margin-bottom: 5px;
                    }

                    a {
                        position: relative;
                        cursor: pointer;
                        color: var(--primaryDark);
                        text-decoration: none;                        
                        padding-bottom: 2px;

                        &:before {
                            content: '';
                            width: 100%;
                            height: 1px;
                            background-color: $cc-black;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            transition: transform .2s ease, background-color .2s ease;
                            transform-origin: center;
                        }

                        &:hover {
                            &:before {
                                transform: scaleX(0);
                            }
                        }
                    }
                }

                &:not(:last-of-type) {
                    margin-bottom: 50px;
                }
            }
        }   
        
        .arrangement-options {
            padding-left: 0;
            margin-bottom: 50px;
            width: 100%;
            display: flex;
            flex-direction: column;
    
            li {
                list-style: none;
                width: 100%;
                font-weight: 500;
                border-bottom: 1px solid var(--brandingColor);
                display: flex;
                justify-content: space-between;
    
                a {
                    color: var(--brandingColor);
                    text-decoration: none;
                    font-size: 16px;
                    font-weight: 400;
                    text-transform: uppercase;
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                                    
                    span {
                        padding-bottom: 2px;
                        color: $cc-black;
                        font-weight: 500;
                        font-size: 18px;
                    }
                }    
    
                &:not(:first-of-type) {
                    margin-top: 20px;
                }

                @include mq($until: 500px) {
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                }
            }
        }
    
        .custom-cta {
            z-index: 1;
            position: relative;

            .cta {
                padding: 20px 25px !important;
                color: var(--brandingColor) !important;    

                &:after {
                    z-index: 0;
                    opacity: 0;
                }
                
                &:hover {
                    color: $cc-white !important;    

                    &:after {
                        opacity: 1;
                        z-index: -1;
                    }
                }
            }
        }

        &--content {
            background-color: var(--whiteShade);  
            padding: 80px 140px !important;
            max-width: 660px;            
        }
        
        @include mq($from: $desktop-size) {
            max-width: 800px;
            margin-left: auto !important;
            margin-right: auto !important;
            transform: translateX(-140px);
        }
    }
    
    * {
        color: $cc-white;
    }

    &--content {
        &-title {
            max-width: 670px;
        }

        &-text {
            margin-top: 20px;

            p {
                margin-bottom: 0;
            }
        }
    }

    &--background {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        width: 100%;
        height: 100%;
        z-index: -1;

        > * {
            height: 100%;
            width: 100%;
        }

        .video-bg {
            background-color: rgba(0,3,16,1);
            position: absolute;
            z-index: -3;
        }
        
        .gradient {
            width: 100%;
            max-width: 80vw;
            background: rgb(0,3,16);
            background: linear-gradient(90deg, rgba(0,3,16,1) 0vw, rgba(0,3,16,1) 30vw, rgba(0,3,16,0) 100%);
            position: absolute;
            z-index: 1;
            pointer-events: none;

            &:not(.image-full-visible-desktop) {
                @include mq($from: $desktop-size) {
                    max-width: unset;
                }
            }

            @include mq($until: $viewport--md) {
                background: linear-gradient(90deg, rgba(0,3,16,1) 0vw, rgba(0,3,16,1) 10vw, rgba(0,3,16,0) 100%);
            }
        }

        .content {
            width: 70%;
            margin-left: auto;
            height: 100%;
            background-color: var(--primaryDark);     
            z-index: -2;  
            position: relative;

            &.video {
                width: auto;
                background-color: #000310;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0%;
                    left: 0%;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(90deg, #000310 0, #000310 140px, rgba(0, 3, 16, 0) 200px);
                }

                video {
                    object-fit: contain;
                    object-position: right;
                }

                @include mq($until: $viewport--sm) {
                    &:before {
                        background: unset;
                    }

                    video {
                        object-fit: cover;
                        object-position: center;
                    }
                }
            }

            &.image {
                figure {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    height: 100%;
                    width: 100%;

                    img {                        
                        height: 100%;
                        object-fit: cover;
                        object-position: right;
                        width: 70vw;
                        margin-left: auto;

                        @include mq($until: $desktop-size) {
                            width: 100%;
                        }

                        @include mq($until: $viewport--sm) {
                            object-position: center;
                        }
                    }
                }
            }
            
            &.map {
                pointer-events: all;

                .Maps {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;

                    @include mq($until: $viewport--md) {
                        width: 160%;
    
                        > div {
                            margin-left: -50%;
                        }
                    }

                    &-container {
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                    }
                }
            }

            @include mq($until: $viewport--md) {
                width: 90%;
            }
        }
    }

    @include mq($until: $resp-nav) {
        &.has-block {
            padding-top: calc(180px + 90px);
            padding-bottom: 350px;
        }

        &.without-block {
            padding-top: calc(180px + 80px);
            padding-bottom: 140px;
        }

        &-whiteblock {
            margin-top: -290px;
            margin-left: 30px !important;
            margin-right: 30px;

            &--content {
                padding: 50px !important;

                .arrangement-options {
                    margin-bottom: 40px;
                    
                    li {
                        a {
                            font-size: 14px;
                            display: flex;
                            align-items: flex-end;

                            span {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }   
        
        &--background {
            max-height: 550px !important;
        }
    }



    @include mq($until: 800px) {
        &-whiteblock {
            .parameters {
                li {
                    &:not(:last-of-type) {
                        margin-bottom: 30px;
                    }

                    figure {
                        min-width: 60px;
                        max-width: 60px;
                        min-height: 60px;
                        max-height: 60px;

                        img {
                            width: 30px;
                            height: 30px;
                        }
                    }
                }
            }
        }
    }

    @include mq($until: $viewport--sm) {
        &-whiteblock {
            &--content {
                padding: 30px !important;
            }

            .parameters {
                li {
                    &:not(:last-of-type) {
                        margin-bottom: 20px;                        
                    }
                }
            }
        }
    }

    @include mq($until: 350px) {
        &-whiteblock {
            &--content {
                .arrangement-options {
                    li {
                        a {
                            flex-direction: column;
                            align-items: flex-start;
                        }
                    }
                }
            }
        }
    }
}

.header {
    @include mq($until: $resp-nav) {
        .slick {
            &-arrow {
                top: 220px !important;
                left: 50px;
                right: unset !important;

                &:nth-of-type(2) {
                    left: 70px;
                }
            }
        }
    }
}

.page-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 240px;
    padding-left: 20px;
    padding-right: 20px;

    * {
        text-align: center;
    }

    &.not-centered {
        align-items: flex-start;

        * {
            text-align: left;
        }
    }

    &--title {

    }
    
    &--text {
        margin-top: 40px;
        max-width: 820px;

        p {
            margin-bottom: 0;
        }
    }

    @include mq($until: $resp-nav) {
        padding-top: 240px;
    }
    
    @include mq($until: $viewport--sm) {
        padding-top: 180px;
    }
}