.TextPagesPage {
    .textblock {
        background-color: unset;
        padding-top: 160px;

        .image {
            min-height: 700px;
        }

        @include mq($until: $viewport--sm) {
            padding-top: 0;
            padding-bottom: 0;

            .image {
                min-height: unset;
            }
        }
    }

    .header-text {
        max-width: 700px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-top: 160px;
        margin-bottom: 100px;
        padding: 0 20px;

        @include mq($until: $viewport--md) {
            margin-top: 90px;
            margin-bottom: 40px;
        }
       
        @include mq($until: $viewport--sm) {
            margin-top: 40px;
        }
    }
}