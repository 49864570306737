.Footer {
    margin-top: 80px;
    
    * {
        color: $cc-white;
    }

    &.mobile-extra-space-bottom {
        .Footer {
            &-allcontent {
                padding-bottom: 70px;
            }
        }
    }
    
    &-allcontent {
        background-color: var(--primaryDark);
        display: flex;
        flex-direction: column;
        z-index: 1;
        position: relative;
        
        .bottom {
            padding: 30px 0 20px;
            border-top: 1px solid #6A6A6E;
            display: flex;
            justify-content: space-between;

            .privacy {
                padding-left: 0;
                margin-bottom: 0;
                display: flex;
                // max-width: 700px;
                margin-right: 40px;
                flex-wrap: wrap;

                li {
                    margin-bottom: 10px;
                    list-style: none;
                    margin-right: 60px;
                }
            }
            
            a,
            button {
                color: #6A6A6E;
                text-decoration: none;
                text-transform: uppercase;
                font-size: 14px;

                span {
                    transition: color .2s ease;
                    color: inherit;
                    font-size: inherit;
                }                

                &:hover {
                    cursor: pointer;
                    
                    span {
                        color: $cc-white;
                    }
                }
            }
        }

        @include mq($until: $viewport--sm) {
            .bottom {
                flex-direction: column;
                justify-content: flex-start;
            }
        }
    }

    &-content {
        display: grid;
        grid-template-columns: auto auto 500px auto;
        grid-template-areas: 'first second third fourth';
        grid-column-gap: 70px;
        padding: 100px 0 70px;

        ul {
            padding-left: 0;
            margin-bottom: 0;

            li {
                margin-left: 0;
                list-style: none;

                &.active {
                    padding-bottom: 3px;
                    
                    a {
                        border-color: $cc-white !important;                        
                    }
                }

                a {
                    padding-bottom: 3px;
                }
            }
        }

        .first {
            grid-area: first;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            > p {
                margin-bottom: 5px;

                &.name {
                    text-transform: uppercase;
                }
        
                a {
                    color: $cc-white;
                    border-bottom: 1px solid rgba($cc-white, 0);
                    transition: border-color .2s ease;
                    text-decoration: none;

                    &.active, &:hover, &:focus {
                        color: $cc-white;
                    }

                    &.active {
                        border-color: $cc-white;
                    }

                    position: relative;

                    &:before {
                        content: '';
                        width: 100%;
                        height: 1px;
                        background-color: $cc-white;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        transform: scaleX(0);
                        transition: transform .2s ease, background-color .2s ease;
                        transform-origin: center;
                    }

                    &:hover {
                        &:before {
                            transform: scaleX(1);
                        }
                    }
                }
            }
        
            .socials {
                display: flex;
                margin-top: 20px;

                li {
                    a {
                        img {
                            filter: brightness(0) invert(1);
                        }
                    }

                    &:not(:last-child) {
                        margin-right: 15px;
                    }
                }                
            }
        }
        
        .second {
            grid-area: second;

            .sitemap {
                ul {
                    li {
                        a {
                            color: $cc-white;
                            border-bottom: 1px solid rgba($cc-white, 0);
                            transition: border-color .2s ease;
                            text-decoration: none;
        
                            &.active, &:hover, &:focus {
                                color: $cc-white;
                            }
        
                            &.active {
                                border-color: $cc-white;
                            }
        
                            position: relative;
        
                            &:before {
                                content: '';
                                width: 100%;
                                height: 1px;
                                background-color: $cc-white;
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                transform: scaleX(0);
                                transition: transform .2s ease, background-color .2s ease;
                                transform-origin: center;
                            }
        
                            &:hover {
                                &:before {
                                    transform: scaleX(1);
                                }
                            }
                        }
                    }
                }
            }
        }
        
        .third {
            grid-area: third;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .ctas {
                display: flex;
                align-items: center;

                .book {
                    margin-right: 45px;
                    
                    a {
                        font-size: 12px;
                    }
                }
        
                .phone {
                    display: flex;
                    align-items: center;
                    text-decoration: none;
    
                    span {
                        margin-left: 5px;
                    }
    
                    figure {
                        width: 32px;
                        height: 32px;
                        border: 1px solid $cc-white;
                        padding: 7px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 100%;
                        background-color: var(--primaryDark);
                        transition: background-color .2s ease;
    
                        svg {
                            path {
                                fill: $cc-white;
                                stroke: $cc-white;
                                transition: fill .2s ease, stroke .2s ease;
                            }
                        }
                    }
    
                    &:hover {
                        figure {
                            background-color: $cc-white;
    
                            svg {
                                path {
                                    fill: var(--primaryDark);
                                    stroke: var(--primaryDark);
                                }
                            }
                        }
                    }
                }
            }
        
            .newsletter {
                p {
                    margin-bottom: 10px;
                }
                
                form {
                    display: flex;
                }
            }
        }
        
        .fourth {
            grid-area: fourth;
            display: flex;
            justify-content: flex-end;

            .logos {
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                a {
                    svg {
                        pointer-events: none;
                    }
                }
                
                .rooms {
                    display: flex;
                    justify-content: flex-end;
                    flex-wrap: wrap;
                    align-items: center;
                    
                    .room {
                        height: 50px;
                        display: flex;
                        justify-content: flex-end;
                        margin-bottom: 10px;
                        margin-left: 20px;
    
                        svg {
                            height: 100%;
                            width: auto;
                        }
                    }
                }

                @include mq($until: $viewport--sm) {
                    align-items: flex-start;

                    .rooms {
                        flex-direction: column;
                        align-items: flex-start;
                        margin-bottom: 10px;
                        
                        .room {
                            margin-left: 0;
                            margin-bottom: 20px;

                            &:last-of-type {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
                
                svg {
                    max-width: 280px;
                }
            }
        }

        @include mq($until: 1500px) {
            grid-template-columns: auto auto 400px auto;
        }

        @include mq($until: 1200px) {
            grid-template-columns: 200px 200px auto;
            grid-template-areas:
                'first second fourth'
                'third third .';
            grid-gap: 70px;

            .third {
                .ctas {
                    margin-bottom: 30px;
                }
            }
        }

        @include mq($until: 900px) {
            grid-template-columns: auto auto;
            grid-template-areas: 'first second'
            'third third'
            'fourth .';
            grid-gap: 50px;

            .fourth {
                justify-content: flex-start;

                svg {
                    max-width: 200px;
                    width: 100%;
                }
            }
        }
    }
    
    @include mq($until: $viewport--sm) {
        &-allcontent {
            .container {
                padding: 0;
            }
        }

        &-content {
            padding: 50px 0;
            grid-template-columns: auto;
            grid-template-areas:
                'first'
                'second'
                'third'
                'fourth';
        }

        .third {
            .ctas {
                flex-direction: column;
                align-items: flex-start;

                .book {
                    margin-bottom: 20px;
                }
            }
        }
    }

    @include mq($until: 500px) {
        .third {
            .newsletter {
                form {
                    flex-direction: column;
                    align-items: flex-start;
                }
            }
        }
    }
}

