.flatpickr  {
    &-monthSelect {
        &-month {
            &.selected {
                background-color: var(--primary) !important;
                border-color: var(--primary) !important;
                border-radius: 0;
            }
            
            &.today {
                border-color: var(--primary) !important;
                border-radius: 0;
            }
        }
    }
}