.custom-select {
    position: relative;   
  }
  
  .custom-select select {
    display: none; /*hide original SELECT element:*/
  }
  
  .select-selected {
    background-color: var(--brandingShade); 

    &:after {
        content: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='7' viewBox='0 0 13 7'%3E%3Cpath id='Path_238' data-name='Path 238' d='M6.475,0h0L0-6.225.806-7,6.478-1.547,12.194-7,13-6.225,6.475,0Z' transform='translate(0 7)' fill='%23041E42'/%3E%3C/svg%3E");
        position: absolute;
        top: 15px;
        right: 1.5rem;
        transition: transform .3s ease;
    }

    &.select-arrow-active {
        &:after {
            transform: rotate(180deg);
        }
    }
  }

  .select-items div {
    transition: background-color .4s ease;
  }
  
  /*style the items (options), including the selected item:*/
  .select-items div,.select-selected {
    color: var(--primary);
    padding: 0 1.5rem;
    height: 50px;
    border: 1px solid var(--primary);
    cursor: pointer;
    user-select: none;  
    display: flex;
    align-items: center;   
    font-size: 12px;
    letter-spacing: 1px;    
  }
  
  /*style items (options):*/
  .select-items {
    position: absolute;
    background-color: white;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;

    > div {      
        border-top: none;        

        &.same-as-selected {
            background-color: var(--primary);
            color: white;
        }

        &:not(.same-as-selected) {
          opacity: .7;
          background-color: var(--brandingShade);
        }
    }
  }
  
  /*hide the items when the select box is closed:*/
  .select-hide {
    display: none;
  }
  
  .select-items div:hover, .same-as-selected {
    background-color: var(--primaryShade);
  }