@charset "UTF-8";
/* Style */
/* Settings: Base */
/* Settings: Colors */
/* Colors: basic */
/* Colors: Custom */
:root {
  --brandingColor: #C0924A;
  --brandingShade: #FDF9F6;
  --brandingOpacity: #EDDABF;
  --primary: #041E42;
  --primaryShade: #041e4236;
  --primaryDark: #070023;
  --whiteShade: #FDFAF5;
  --red: #CE2419; }

[data-theme="kusttheater"] {
  --brandingColor: #ae3030;
  --brandingShade: #f5f5f5;
  --brandingOpacity: #da5353;
  --primary: #171717;
  --primaryDark: #000000;
  --primaryShade: #17171736;
  --whiteShade: #fffffe;
  --red: #CE2419; }

/* Colors: Theme */
/* Settings: Fonts */
@font-face {
  font-family: "Gotham";
  src: url("../fonts/Gotham-Light.woff2") format("woff2"), url("../fonts/Gotham-Light.woff") format("woff");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: "Gotham";
  src: url("../fonts/Gotham-Lightitalic.woff2") format("woff2"), url("../fonts/Gotham-Lightitalic.woff") format("woff");
  font-style: italic;
  font-weight: 300; }

@font-face {
  font-family: "Gotham";
  src: url("../fonts/Gotham-Book.woff2") format("woff2"), url("../fonts/Gotham-Book.woff") format("woff");
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: "Gotham";
  src: url("../fonts/Gotham-BookItalic.woff2") format("woff2"), url("../fonts/Gotham-BookItalic.woff") format("woff");
  font-style: italic;
  font-weight: 400; }

@font-face {
  font-family: "Gotham";
  src: url("../fonts/Gotham-Medium.woff2") format("woff2"), url("../fonts/Gotham-Medium.woff") format("woff");
  font-style: normal;
  font-weight: 500; }

@font-face {
  font-family: "Gotham";
  src: url("../fonts/Gotham-MediumItalic.woff2") format("woff2"), url("../fonts/Gotham-MediumItalic.woff") format("woff");
  font-style: italic;
  font-weight: 500; }

@font-face {
  font-family: "Gotham";
  src: url("../fonts/Gotham-Bold.woff2") format("woff2"), url("../fonts/Gotham-Bold.woff") format("woff");
  font-style: normal;
  font-weight: 600; }

@font-face {
  font-family: "Gotham";
  src: url("../fonts/Gotham-Bolditalic.woff2") format("woff2"), url("../fonts/Gotham-Bolditalic.woff") format("woff");
  font-style: italic;
  font-weight: 600; }

@font-face {
  font-family: "Gotham";
  src: url("../fonts/Gotham-Black.woff2") format("woff2"), url("../fonts/Gotham-Black.woff") format("woff");
  font-style: normal;
  font-weight: 700; }

@font-face {
  font-family: "Gotham";
  src: url("../fonts/Gotham-Blackitalic.woff2") format("woff2"), url("../fonts/Gotham-Blackitalic.woff") format("woff");
  font-style: italic;
  font-weight: 700; }

@font-face {
  font-family: "Gotham";
  src: url("../fonts/Gotham-Ultra.woff2") format("woff2"), url("../fonts/Gotham-Ultra.woff") format("woff");
  font-style: normal;
  font-weight: 800; }

@font-face {
  font-family: "Gotham";
  src: url("../fonts/Gotham-Ultraitalic.woff2") format("woff2"), url("../fonts/Gotham-Ultraitalic.woff") format("woff");
  font-style: italic;
  font-weight: 800; }

@font-face {
  font-family: 'Didonesque';
  src: url("../fonts/DidonesqueLite-Black.woff2") format("woff2"), url("../fonts/DidonesqueLite-Black.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Didonesque';
  src: url("../fonts/DidonesqueLite-BlackItalic.woff2") format("woff2"), url("../fonts/DidonesqueLite-BlackItalic.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Didonesque';
  src: url("../fonts/DidonesqueLite-MediumItalic.woff2") format("woff2"), url("../fonts/DidonesqueLite-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Didonesque';
  src: url("../fonts/DidonesqueLite-BoldItalic.woff2") format("woff2"), url("../fonts/DidonesqueLite-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Didonesque Roman';
  src: url("../fonts/DidonesqueLite-Italic.woff2") format("woff2"), url("../fonts/DidonesqueLite-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Didonesque';
  src: url("../fonts/DidonesqueLite-Medium.woff2") format("woff2"), url("../fonts/DidonesqueLite-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Didonesque';
  src: url("../fonts/DidonesqueLite-Bold.woff2") format("woff2"), url("../fonts/DidonesqueLite-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Didonesque';
  src: url("../fonts/DidonesqueLite-Roman.woff2") format("woff2"), url("../fonts/DidonesqueLite-Roman.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

/* Elements: Heading */
/* Elements: HR */
/* Elements: Image */
/* Elements: Link */
/* Elements: List */
/* Elements: Paragraph */
/* Elements: Selection */
/* Objects: Alert */
/* Components: Button */
/* Objects: FlexEmbed */
/* Objects: Form */
/* Objects: Grid */
/* Objects: Nav */
/* Objects: Structure */
/* Objects: Table */
/* Objects: Toggle */
/* Components: Fields */
/* Components: Modal */
/* Functions: Units */
/* Functions: SVG */
/* Functions: Throw */
/* Functions: Checks */
/* Functions: Truncate list */
/* Functions: Maps */
/* Functions: Maps */
/* Functions: Order index */
/* Functions: String */
/* Mixin: Arrow */
/* Mixin: Background SVG */
/* Mixin: Caret */
/* Mixin: Context */
/* Mixin: Dimensions */
/* Mixin: Family */
/* Mixin: Font-responsive */
/* Mixin: Font-size */
/* Mixin: Font-smoothing */
/* Mixin: Grid */
/* Mixin: Hover */
/* Mixin: Image rendering */
/* Mixin: Last-children */
/* Mixin: Line-height crop */
/* Mixin: Maps */
/* Mixin: ParentNthStatus */
/* Mixin: ParentState */
/* Mixin: ParentStatus */
/* Mixin: Position */
/* Mixin: Maps */
/* Mixin: Transition */
/* Mixin: Truncate */
/* Reset */
*, *::before, *::after {
  box-sizing: inherit; }

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-size: 1em;
  line-height: 1.5;
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern" 1;
  font-kerning: normal; }

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

body,
blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0; }

iframe {
  border: 0; }

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

legend {
  display: block;
  width: 100%; }

option {
  font: inherit; }

textarea {
  resize: vertical; }

button {
  background: transparent;
  border: 0;
  padding: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

abbr[title],
dfn[title] {
  cursor: help; }

u,
ins {
  text-decoration: underline; }

img {
  font-style: italic; }

::selection {
  text-shadow: none; }

[tabindex="-1"]:focus {
  outline: none !important; }

body[data-aos-duration='50'] [data-aos], [data-aos][data-aos][data-aos-duration='50'] {
  transition-duration: 50ms; }

body[data-aos-delay='50'] [data-aos], [data-aos][data-aos][data-aos-delay='50'] {
  transition-delay: 0; }
  body[data-aos-delay='50'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='50'].aos-animate {
    transition-delay: 50ms; }

body[data-aos-duration='100'] [data-aos], [data-aos][data-aos][data-aos-duration='100'] {
  transition-duration: 100ms; }

body[data-aos-delay='100'] [data-aos], [data-aos][data-aos][data-aos-delay='100'] {
  transition-delay: 0; }
  body[data-aos-delay='100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='100'].aos-animate {
    transition-delay: 100ms; }

body[data-aos-duration='150'] [data-aos], [data-aos][data-aos][data-aos-duration='150'] {
  transition-duration: 150ms; }

body[data-aos-delay='150'] [data-aos], [data-aos][data-aos][data-aos-delay='150'] {
  transition-delay: 0; }
  body[data-aos-delay='150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='150'].aos-animate {
    transition-delay: 150ms; }

body[data-aos-duration='200'] [data-aos], [data-aos][data-aos][data-aos-duration='200'] {
  transition-duration: 200ms; }

body[data-aos-delay='200'] [data-aos], [data-aos][data-aos][data-aos-delay='200'] {
  transition-delay: 0; }
  body[data-aos-delay='200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='200'].aos-animate {
    transition-delay: 200ms; }

body[data-aos-duration='250'] [data-aos], [data-aos][data-aos][data-aos-duration='250'] {
  transition-duration: 250ms; }

body[data-aos-delay='250'] [data-aos], [data-aos][data-aos][data-aos-delay='250'] {
  transition-delay: 0; }
  body[data-aos-delay='250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='250'].aos-animate {
    transition-delay: 250ms; }

body[data-aos-duration='300'] [data-aos], [data-aos][data-aos][data-aos-duration='300'] {
  transition-duration: 300ms; }

body[data-aos-delay='300'] [data-aos], [data-aos][data-aos][data-aos-delay='300'] {
  transition-delay: 0; }
  body[data-aos-delay='300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='300'].aos-animate {
    transition-delay: 300ms; }

body[data-aos-duration='350'] [data-aos], [data-aos][data-aos][data-aos-duration='350'] {
  transition-duration: 350ms; }

body[data-aos-delay='350'] [data-aos], [data-aos][data-aos][data-aos-delay='350'] {
  transition-delay: 0; }
  body[data-aos-delay='350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='350'].aos-animate {
    transition-delay: 350ms; }

body[data-aos-duration='400'] [data-aos], [data-aos][data-aos][data-aos-duration='400'] {
  transition-duration: 400ms; }

body[data-aos-delay='400'] [data-aos], [data-aos][data-aos][data-aos-delay='400'] {
  transition-delay: 0; }
  body[data-aos-delay='400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='400'].aos-animate {
    transition-delay: 400ms; }

body[data-aos-duration='450'] [data-aos], [data-aos][data-aos][data-aos-duration='450'] {
  transition-duration: 450ms; }

body[data-aos-delay='450'] [data-aos], [data-aos][data-aos][data-aos-delay='450'] {
  transition-delay: 0; }
  body[data-aos-delay='450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='450'].aos-animate {
    transition-delay: 450ms; }

body[data-aos-duration='500'] [data-aos], [data-aos][data-aos][data-aos-duration='500'] {
  transition-duration: 500ms; }

body[data-aos-delay='500'] [data-aos], [data-aos][data-aos][data-aos-delay='500'] {
  transition-delay: 0; }
  body[data-aos-delay='500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='500'].aos-animate {
    transition-delay: 500ms; }

body[data-aos-duration='550'] [data-aos], [data-aos][data-aos][data-aos-duration='550'] {
  transition-duration: 550ms; }

body[data-aos-delay='550'] [data-aos], [data-aos][data-aos][data-aos-delay='550'] {
  transition-delay: 0; }
  body[data-aos-delay='550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='550'].aos-animate {
    transition-delay: 550ms; }

body[data-aos-duration='600'] [data-aos], [data-aos][data-aos][data-aos-duration='600'] {
  transition-duration: 600ms; }

body[data-aos-delay='600'] [data-aos], [data-aos][data-aos][data-aos-delay='600'] {
  transition-delay: 0; }
  body[data-aos-delay='600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='600'].aos-animate {
    transition-delay: 600ms; }

body[data-aos-duration='650'] [data-aos], [data-aos][data-aos][data-aos-duration='650'] {
  transition-duration: 650ms; }

body[data-aos-delay='650'] [data-aos], [data-aos][data-aos][data-aos-delay='650'] {
  transition-delay: 0; }
  body[data-aos-delay='650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='650'].aos-animate {
    transition-delay: 650ms; }

body[data-aos-duration='700'] [data-aos], [data-aos][data-aos][data-aos-duration='700'] {
  transition-duration: 700ms; }

body[data-aos-delay='700'] [data-aos], [data-aos][data-aos][data-aos-delay='700'] {
  transition-delay: 0; }
  body[data-aos-delay='700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='700'].aos-animate {
    transition-delay: 700ms; }

body[data-aos-duration='750'] [data-aos], [data-aos][data-aos][data-aos-duration='750'] {
  transition-duration: 750ms; }

body[data-aos-delay='750'] [data-aos], [data-aos][data-aos][data-aos-delay='750'] {
  transition-delay: 0; }
  body[data-aos-delay='750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='750'].aos-animate {
    transition-delay: 750ms; }

body[data-aos-duration='800'] [data-aos], [data-aos][data-aos][data-aos-duration='800'] {
  transition-duration: 800ms; }

body[data-aos-delay='800'] [data-aos], [data-aos][data-aos][data-aos-delay='800'] {
  transition-delay: 0; }
  body[data-aos-delay='800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='800'].aos-animate {
    transition-delay: 800ms; }

body[data-aos-duration='850'] [data-aos], [data-aos][data-aos][data-aos-duration='850'] {
  transition-duration: 850ms; }

body[data-aos-delay='850'] [data-aos], [data-aos][data-aos][data-aos-delay='850'] {
  transition-delay: 0; }
  body[data-aos-delay='850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='850'].aos-animate {
    transition-delay: 850ms; }

body[data-aos-duration='900'] [data-aos], [data-aos][data-aos][data-aos-duration='900'] {
  transition-duration: 900ms; }

body[data-aos-delay='900'] [data-aos], [data-aos][data-aos][data-aos-delay='900'] {
  transition-delay: 0; }
  body[data-aos-delay='900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='900'].aos-animate {
    transition-delay: 900ms; }

body[data-aos-duration='950'] [data-aos], [data-aos][data-aos][data-aos-duration='950'] {
  transition-duration: 950ms; }

body[data-aos-delay='950'] [data-aos], [data-aos][data-aos][data-aos-delay='950'] {
  transition-delay: 0; }
  body[data-aos-delay='950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='950'].aos-animate {
    transition-delay: 950ms; }

body[data-aos-duration='1000'] [data-aos], [data-aos][data-aos][data-aos-duration='1000'] {
  transition-duration: 1000ms; }

body[data-aos-delay='1000'] [data-aos], [data-aos][data-aos][data-aos-delay='1000'] {
  transition-delay: 0; }
  body[data-aos-delay='1000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1000'].aos-animate {
    transition-delay: 1000ms; }

body[data-aos-duration='1050'] [data-aos], [data-aos][data-aos][data-aos-duration='1050'] {
  transition-duration: 1050ms; }

body[data-aos-delay='1050'] [data-aos], [data-aos][data-aos][data-aos-delay='1050'] {
  transition-delay: 0; }
  body[data-aos-delay='1050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1050'].aos-animate {
    transition-delay: 1050ms; }

body[data-aos-duration='1100'] [data-aos], [data-aos][data-aos][data-aos-duration='1100'] {
  transition-duration: 1100ms; }

body[data-aos-delay='1100'] [data-aos], [data-aos][data-aos][data-aos-delay='1100'] {
  transition-delay: 0; }
  body[data-aos-delay='1100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1100'].aos-animate {
    transition-delay: 1100ms; }

body[data-aos-duration='1150'] [data-aos], [data-aos][data-aos][data-aos-duration='1150'] {
  transition-duration: 1150ms; }

body[data-aos-delay='1150'] [data-aos], [data-aos][data-aos][data-aos-delay='1150'] {
  transition-delay: 0; }
  body[data-aos-delay='1150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1150'].aos-animate {
    transition-delay: 1150ms; }

body[data-aos-duration='1200'] [data-aos], [data-aos][data-aos][data-aos-duration='1200'] {
  transition-duration: 1200ms; }

body[data-aos-delay='1200'] [data-aos], [data-aos][data-aos][data-aos-delay='1200'] {
  transition-delay: 0; }
  body[data-aos-delay='1200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1200'].aos-animate {
    transition-delay: 1200ms; }

body[data-aos-duration='1250'] [data-aos], [data-aos][data-aos][data-aos-duration='1250'] {
  transition-duration: 1250ms; }

body[data-aos-delay='1250'] [data-aos], [data-aos][data-aos][data-aos-delay='1250'] {
  transition-delay: 0; }
  body[data-aos-delay='1250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1250'].aos-animate {
    transition-delay: 1250ms; }

body[data-aos-duration='1300'] [data-aos], [data-aos][data-aos][data-aos-duration='1300'] {
  transition-duration: 1300ms; }

body[data-aos-delay='1300'] [data-aos], [data-aos][data-aos][data-aos-delay='1300'] {
  transition-delay: 0; }
  body[data-aos-delay='1300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1300'].aos-animate {
    transition-delay: 1300ms; }

body[data-aos-duration='1350'] [data-aos], [data-aos][data-aos][data-aos-duration='1350'] {
  transition-duration: 1350ms; }

body[data-aos-delay='1350'] [data-aos], [data-aos][data-aos][data-aos-delay='1350'] {
  transition-delay: 0; }
  body[data-aos-delay='1350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1350'].aos-animate {
    transition-delay: 1350ms; }

body[data-aos-duration='1400'] [data-aos], [data-aos][data-aos][data-aos-duration='1400'] {
  transition-duration: 1400ms; }

body[data-aos-delay='1400'] [data-aos], [data-aos][data-aos][data-aos-delay='1400'] {
  transition-delay: 0; }
  body[data-aos-delay='1400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1400'].aos-animate {
    transition-delay: 1400ms; }

body[data-aos-duration='1450'] [data-aos], [data-aos][data-aos][data-aos-duration='1450'] {
  transition-duration: 1450ms; }

body[data-aos-delay='1450'] [data-aos], [data-aos][data-aos][data-aos-delay='1450'] {
  transition-delay: 0; }
  body[data-aos-delay='1450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1450'].aos-animate {
    transition-delay: 1450ms; }

body[data-aos-duration='1500'] [data-aos], [data-aos][data-aos][data-aos-duration='1500'] {
  transition-duration: 1500ms; }

body[data-aos-delay='1500'] [data-aos], [data-aos][data-aos][data-aos-delay='1500'] {
  transition-delay: 0; }
  body[data-aos-delay='1500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1500'].aos-animate {
    transition-delay: 1500ms; }

body[data-aos-duration='1550'] [data-aos], [data-aos][data-aos][data-aos-duration='1550'] {
  transition-duration: 1550ms; }

body[data-aos-delay='1550'] [data-aos], [data-aos][data-aos][data-aos-delay='1550'] {
  transition-delay: 0; }
  body[data-aos-delay='1550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1550'].aos-animate {
    transition-delay: 1550ms; }

body[data-aos-duration='1600'] [data-aos], [data-aos][data-aos][data-aos-duration='1600'] {
  transition-duration: 1600ms; }

body[data-aos-delay='1600'] [data-aos], [data-aos][data-aos][data-aos-delay='1600'] {
  transition-delay: 0; }
  body[data-aos-delay='1600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1600'].aos-animate {
    transition-delay: 1600ms; }

body[data-aos-duration='1650'] [data-aos], [data-aos][data-aos][data-aos-duration='1650'] {
  transition-duration: 1650ms; }

body[data-aos-delay='1650'] [data-aos], [data-aos][data-aos][data-aos-delay='1650'] {
  transition-delay: 0; }
  body[data-aos-delay='1650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1650'].aos-animate {
    transition-delay: 1650ms; }

body[data-aos-duration='1700'] [data-aos], [data-aos][data-aos][data-aos-duration='1700'] {
  transition-duration: 1700ms; }

body[data-aos-delay='1700'] [data-aos], [data-aos][data-aos][data-aos-delay='1700'] {
  transition-delay: 0; }
  body[data-aos-delay='1700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1700'].aos-animate {
    transition-delay: 1700ms; }

body[data-aos-duration='1750'] [data-aos], [data-aos][data-aos][data-aos-duration='1750'] {
  transition-duration: 1750ms; }

body[data-aos-delay='1750'] [data-aos], [data-aos][data-aos][data-aos-delay='1750'] {
  transition-delay: 0; }
  body[data-aos-delay='1750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1750'].aos-animate {
    transition-delay: 1750ms; }

body[data-aos-duration='1800'] [data-aos], [data-aos][data-aos][data-aos-duration='1800'] {
  transition-duration: 1800ms; }

body[data-aos-delay='1800'] [data-aos], [data-aos][data-aos][data-aos-delay='1800'] {
  transition-delay: 0; }
  body[data-aos-delay='1800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1800'].aos-animate {
    transition-delay: 1800ms; }

body[data-aos-duration='1850'] [data-aos], [data-aos][data-aos][data-aos-duration='1850'] {
  transition-duration: 1850ms; }

body[data-aos-delay='1850'] [data-aos], [data-aos][data-aos][data-aos-delay='1850'] {
  transition-delay: 0; }
  body[data-aos-delay='1850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1850'].aos-animate {
    transition-delay: 1850ms; }

body[data-aos-duration='1900'] [data-aos], [data-aos][data-aos][data-aos-duration='1900'] {
  transition-duration: 1900ms; }

body[data-aos-delay='1900'] [data-aos], [data-aos][data-aos][data-aos-delay='1900'] {
  transition-delay: 0; }
  body[data-aos-delay='1900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1900'].aos-animate {
    transition-delay: 1900ms; }

body[data-aos-duration='1950'] [data-aos], [data-aos][data-aos][data-aos-duration='1950'] {
  transition-duration: 1950ms; }

body[data-aos-delay='1950'] [data-aos], [data-aos][data-aos][data-aos-delay='1950'] {
  transition-delay: 0; }
  body[data-aos-delay='1950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1950'].aos-animate {
    transition-delay: 1950ms; }

body[data-aos-duration='2000'] [data-aos], [data-aos][data-aos][data-aos-duration='2000'] {
  transition-duration: 2000ms; }

body[data-aos-delay='2000'] [data-aos], [data-aos][data-aos][data-aos-delay='2000'] {
  transition-delay: 0; }
  body[data-aos-delay='2000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2000'].aos-animate {
    transition-delay: 2000ms; }

body[data-aos-duration='2050'] [data-aos], [data-aos][data-aos][data-aos-duration='2050'] {
  transition-duration: 2050ms; }

body[data-aos-delay='2050'] [data-aos], [data-aos][data-aos][data-aos-delay='2050'] {
  transition-delay: 0; }
  body[data-aos-delay='2050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2050'].aos-animate {
    transition-delay: 2050ms; }

body[data-aos-duration='2100'] [data-aos], [data-aos][data-aos][data-aos-duration='2100'] {
  transition-duration: 2100ms; }

body[data-aos-delay='2100'] [data-aos], [data-aos][data-aos][data-aos-delay='2100'] {
  transition-delay: 0; }
  body[data-aos-delay='2100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2100'].aos-animate {
    transition-delay: 2100ms; }

body[data-aos-duration='2150'] [data-aos], [data-aos][data-aos][data-aos-duration='2150'] {
  transition-duration: 2150ms; }

body[data-aos-delay='2150'] [data-aos], [data-aos][data-aos][data-aos-delay='2150'] {
  transition-delay: 0; }
  body[data-aos-delay='2150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2150'].aos-animate {
    transition-delay: 2150ms; }

body[data-aos-duration='2200'] [data-aos], [data-aos][data-aos][data-aos-duration='2200'] {
  transition-duration: 2200ms; }

body[data-aos-delay='2200'] [data-aos], [data-aos][data-aos][data-aos-delay='2200'] {
  transition-delay: 0; }
  body[data-aos-delay='2200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2200'].aos-animate {
    transition-delay: 2200ms; }

body[data-aos-duration='2250'] [data-aos], [data-aos][data-aos][data-aos-duration='2250'] {
  transition-duration: 2250ms; }

body[data-aos-delay='2250'] [data-aos], [data-aos][data-aos][data-aos-delay='2250'] {
  transition-delay: 0; }
  body[data-aos-delay='2250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2250'].aos-animate {
    transition-delay: 2250ms; }

body[data-aos-duration='2300'] [data-aos], [data-aos][data-aos][data-aos-duration='2300'] {
  transition-duration: 2300ms; }

body[data-aos-delay='2300'] [data-aos], [data-aos][data-aos][data-aos-delay='2300'] {
  transition-delay: 0; }
  body[data-aos-delay='2300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2300'].aos-animate {
    transition-delay: 2300ms; }

body[data-aos-duration='2350'] [data-aos], [data-aos][data-aos][data-aos-duration='2350'] {
  transition-duration: 2350ms; }

body[data-aos-delay='2350'] [data-aos], [data-aos][data-aos][data-aos-delay='2350'] {
  transition-delay: 0; }
  body[data-aos-delay='2350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2350'].aos-animate {
    transition-delay: 2350ms; }

body[data-aos-duration='2400'] [data-aos], [data-aos][data-aos][data-aos-duration='2400'] {
  transition-duration: 2400ms; }

body[data-aos-delay='2400'] [data-aos], [data-aos][data-aos][data-aos-delay='2400'] {
  transition-delay: 0; }
  body[data-aos-delay='2400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2400'].aos-animate {
    transition-delay: 2400ms; }

body[data-aos-duration='2450'] [data-aos], [data-aos][data-aos][data-aos-duration='2450'] {
  transition-duration: 2450ms; }

body[data-aos-delay='2450'] [data-aos], [data-aos][data-aos][data-aos-delay='2450'] {
  transition-delay: 0; }
  body[data-aos-delay='2450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2450'].aos-animate {
    transition-delay: 2450ms; }

body[data-aos-duration='2500'] [data-aos], [data-aos][data-aos][data-aos-duration='2500'] {
  transition-duration: 2500ms; }

body[data-aos-delay='2500'] [data-aos], [data-aos][data-aos][data-aos-delay='2500'] {
  transition-delay: 0; }
  body[data-aos-delay='2500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2500'].aos-animate {
    transition-delay: 2500ms; }

body[data-aos-duration='2550'] [data-aos], [data-aos][data-aos][data-aos-duration='2550'] {
  transition-duration: 2550ms; }

body[data-aos-delay='2550'] [data-aos], [data-aos][data-aos][data-aos-delay='2550'] {
  transition-delay: 0; }
  body[data-aos-delay='2550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2550'].aos-animate {
    transition-delay: 2550ms; }

body[data-aos-duration='2600'] [data-aos], [data-aos][data-aos][data-aos-duration='2600'] {
  transition-duration: 2600ms; }

body[data-aos-delay='2600'] [data-aos], [data-aos][data-aos][data-aos-delay='2600'] {
  transition-delay: 0; }
  body[data-aos-delay='2600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2600'].aos-animate {
    transition-delay: 2600ms; }

body[data-aos-duration='2650'] [data-aos], [data-aos][data-aos][data-aos-duration='2650'] {
  transition-duration: 2650ms; }

body[data-aos-delay='2650'] [data-aos], [data-aos][data-aos][data-aos-delay='2650'] {
  transition-delay: 0; }
  body[data-aos-delay='2650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2650'].aos-animate {
    transition-delay: 2650ms; }

body[data-aos-duration='2700'] [data-aos], [data-aos][data-aos][data-aos-duration='2700'] {
  transition-duration: 2700ms; }

body[data-aos-delay='2700'] [data-aos], [data-aos][data-aos][data-aos-delay='2700'] {
  transition-delay: 0; }
  body[data-aos-delay='2700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2700'].aos-animate {
    transition-delay: 2700ms; }

body[data-aos-duration='2750'] [data-aos], [data-aos][data-aos][data-aos-duration='2750'] {
  transition-duration: 2750ms; }

body[data-aos-delay='2750'] [data-aos], [data-aos][data-aos][data-aos-delay='2750'] {
  transition-delay: 0; }
  body[data-aos-delay='2750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2750'].aos-animate {
    transition-delay: 2750ms; }

body[data-aos-duration='2800'] [data-aos], [data-aos][data-aos][data-aos-duration='2800'] {
  transition-duration: 2800ms; }

body[data-aos-delay='2800'] [data-aos], [data-aos][data-aos][data-aos-delay='2800'] {
  transition-delay: 0; }
  body[data-aos-delay='2800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2800'].aos-animate {
    transition-delay: 2800ms; }

body[data-aos-duration='2850'] [data-aos], [data-aos][data-aos][data-aos-duration='2850'] {
  transition-duration: 2850ms; }

body[data-aos-delay='2850'] [data-aos], [data-aos][data-aos][data-aos-delay='2850'] {
  transition-delay: 0; }
  body[data-aos-delay='2850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2850'].aos-animate {
    transition-delay: 2850ms; }

body[data-aos-duration='2900'] [data-aos], [data-aos][data-aos][data-aos-duration='2900'] {
  transition-duration: 2900ms; }

body[data-aos-delay='2900'] [data-aos], [data-aos][data-aos][data-aos-delay='2900'] {
  transition-delay: 0; }
  body[data-aos-delay='2900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2900'].aos-animate {
    transition-delay: 2900ms; }

body[data-aos-duration='2950'] [data-aos], [data-aos][data-aos][data-aos-duration='2950'] {
  transition-duration: 2950ms; }

body[data-aos-delay='2950'] [data-aos], [data-aos][data-aos][data-aos-delay='2950'] {
  transition-delay: 0; }
  body[data-aos-delay='2950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2950'].aos-animate {
    transition-delay: 2950ms; }

body[data-aos-duration='3000'] [data-aos], [data-aos][data-aos][data-aos-duration='3000'] {
  transition-duration: 3000ms; }

body[data-aos-delay='3000'] [data-aos], [data-aos][data-aos][data-aos-delay='3000'] {
  transition-delay: 0; }
  body[data-aos-delay='3000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='3000'].aos-animate {
    transition-delay: 3000ms; }

body[data-aos-easing="linear"] [data-aos], [data-aos][data-aos][data-aos-easing="linear"] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75); }

body[data-aos-easing="ease"] [data-aos], [data-aos][data-aos][data-aos-easing="ease"] {
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1); }

body[data-aos-easing="ease-in"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in"] {
  transition-timing-function: cubic-bezier(0.42, 0, 1, 1); }

body[data-aos-easing="ease-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out"] {
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1); }

body[data-aos-easing="ease-in-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out"] {
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1); }

body[data-aos-easing="ease-in-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-back"] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045); }

body[data-aos-easing="ease-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-back"] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); }

body[data-aos-easing="ease-in-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-back"] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }

body[data-aos-easing="ease-in-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-sine"] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715); }

body[data-aos-easing="ease-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-sine"] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1); }

body[data-aos-easing="ease-in-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-sine"] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95); }

body[data-aos-easing="ease-in-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quad"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quad"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quad"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-cubic"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-cubic"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-cubic"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quart"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quart"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quart"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

/**
 * Fade animations:
 * fade
 * fade-up, fade-down, fade-left, fade-right
 * fade-up-right, fade-up-left, fade-down-right, fade-down-left
 */
[data-aos^='fade'][data-aos^='fade'] {
  opacity: 0;
  transition-property: opacity, transform; }
  [data-aos^='fade'][data-aos^='fade'].aos-animate {
    opacity: 1;
    transform: translate3d(0, 0, 0); }

[data-aos='fade-up'] {
  transform: translate3d(0, 100px, 0); }

[data-aos='fade-down'] {
  transform: translate3d(0, -100px, 0); }

[data-aos='fade-right'] {
  transform: translate3d(-100px, 0, 0); }

[data-aos='fade-left'] {
  transform: translate3d(100px, 0, 0); }

[data-aos='fade-up-right'] {
  transform: translate3d(-100px, 100px, 0); }

[data-aos='fade-up-left'] {
  transform: translate3d(100px, 100px, 0); }

[data-aos='fade-down-right'] {
  transform: translate3d(-100px, -100px, 0); }

[data-aos='fade-down-left'] {
  transform: translate3d(100px, -100px, 0); }

/**
 * Zoom animations:
 * zoom-in, zoom-in-up, zoom-in-down, zoom-in-left, zoom-in-right
 * zoom-out, zoom-out-up, zoom-out-down, zoom-out-left, zoom-out-right
 */
[data-aos^='zoom'][data-aos^='zoom'] {
  opacity: 0;
  transition-property: opacity, transform; }
  [data-aos^='zoom'][data-aos^='zoom'].aos-animate {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1); }

[data-aos='zoom-in'] {
  transform: scale(0.6); }

[data-aos='zoom-in-up'] {
  transform: translate3d(0, 100px, 0) scale(0.6); }

[data-aos='zoom-in-down'] {
  transform: translate3d(0, -100px, 0) scale(0.6); }

[data-aos='zoom-in-right'] {
  transform: translate3d(-100px, 0, 0) scale(0.6); }

[data-aos='zoom-in-left'] {
  transform: translate3d(100px, 0, 0) scale(0.6); }

[data-aos='zoom-out'] {
  transform: scale(1.2); }

[data-aos='zoom-out-up'] {
  transform: translate3d(0, 100px, 0) scale(1.2); }

[data-aos='zoom-out-down'] {
  transform: translate3d(0, -100px, 0) scale(1.2); }

[data-aos='zoom-out-right'] {
  transform: translate3d(-100px, 0, 0) scale(1.2); }

[data-aos='zoom-out-left'] {
  transform: translate3d(100px, 0, 0) scale(1.2); }

/**
 * Slide animations
 */
[data-aos^='slide'][data-aos^='slide'] {
  transition-property: transform; }
  [data-aos^='slide'][data-aos^='slide'].aos-animate {
    transform: translate3d(0, 0, 0); }

[data-aos='slide-up'] {
  transform: translate3d(0, 100%, 0); }

[data-aos='slide-down'] {
  transform: translate3d(0, -100%, 0); }

[data-aos='slide-right'] {
  transform: translate3d(-100%, 0, 0); }

[data-aos='slide-left'] {
  transform: translate3d(100%, 0, 0); }

/**
 * Flip animations:
 * flip-left, flip-right, flip-up, flip-down
 */
[data-aos^='flip'][data-aos^='flip'] {
  backface-visibility: hidden;
  transition-property: transform; }

[data-aos='flip-left'] {
  transform: perspective(2500px) rotateY(-100deg); }
  [data-aos='flip-left'].aos-animate {
    transform: perspective(2500px) rotateY(0); }

[data-aos='flip-right'] {
  transform: perspective(2500px) rotateY(100deg); }
  [data-aos='flip-right'].aos-animate {
    transform: perspective(2500px) rotateY(0); }

[data-aos='flip-up'] {
  transform: perspective(2500px) rotateX(-100deg); }
  [data-aos='flip-up'].aos-animate {
    transform: perspective(2500px) rotateX(0); }

[data-aos='flip-down'] {
  transform: perspective(2500px) rotateX(100deg); }
  [data-aos='flip-down'].aos-animate {
    transform: perspective(2500px) rotateX(0); }

/* IE11 */
@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, [data-aos^=fade][data-aos^=fade], [data-aos^=zoom][data-aos^=zoom] {
    opacity: 1;
    transform: none !important; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

.slick-dotted.slick-slider {
  @apply mb-0; }

.slick-slider {
  @apply mb-0; }
  .slick-slider .slick-slide:focus {
    @apply outline-none; }

.slick-track,
.slick-list {
  @apply h-full; }

.slick-dots {
  @apply static text-left; }

/* HR */
hr {
  margin-top: 24px;
  margin-bottom: 23px;
  color: #bdbdbd;
  border: 0;
  border-bottom: 1px solid; }

/* Image */
img {
  max-width: 100%;
  height: auto; }

figure > img {
  display: block; }

/* Link */
a {
  transition-property: background-color, color;
  transition-duration: 200ms;
  transition-timing-function: linear;
  color: #3f51b5;
  text-decoration: underline; }
  a:hover, a:focus, a:active {
    color: #3949ab;
    text-decoration: none; }

/* List */
ol,
ul {
  margin-bottom: 24px;
  padding-left: 24px; }
  ol li,
  ul li {
    margin-bottom: 5px;
    list-style-type: '\2014'; }
  ol ol,
  ol ul,
  ul ol,
  ul ul {
    margin-top: 4px;
    margin-bottom: 4px; }

ul {
  list-style-type: disc; }

ul ul {
  list-style-type: circle; }

ul ul ul {
  list-style-type: square; }

ol {
  list-style-type: decimal; }

ol ol {
  list-style-type: upper-alpha; }

ol ol ol {
  list-style-type: lower-roman; }

ol ol ol ol {
  list-style-type: lower-alpha; }

dt {
  font-weight: 700; }

/* Selection */
::selection {
  color: #ffffff;
  background-color: var(--primary); }

/* Heading */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700; }
  .fonts-loaded h1, .fonts-loaded
  h2, .fonts-loaded
  h3, .fonts-loaded
  h4, .fonts-loaded
  h5, .fonts-loaded
  h6 {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; }
  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small {
    font-weight: normal; }

h1 {
  font-family: Gotham, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 48px;
  color: #000000; }

h2 {
  font-family: Gotham, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 32px;
  color: #000000; }

h3 {
  margin-bottom: 24px;
  font-size: 20px; }

h4 {
  margin-bottom: 24px;
  font-size: 18px; }

h5 {
  margin-bottom: 24px;
  font-size: 16px; }

h6 {
  margin-bottom: 24px;
  font-size: 14px; }

.Giga {
  margin-bottom: 24px;
  font-size: 96px;
  line-height: 1.1; }

.Mega {
  margin-bottom: 24px;
  font-size: 72px;
  line-height: 1.1; }

.Kilo {
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.2; }

.Milli {
  margin-bottom: 24px;
  font-size: 12px; }

.Micro {
  margin-bottom: 24px;
  font-size: 10px; }

/* Paragraph */
p {
  margin-bottom: 24px; }

.Lead {
  font-size: 18px; }

/* Main */
html {
  background-color: #ffffff;
  color: #000000;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  height: 100%;
  scroll-behavior: smooth; }
  html.fonts-loaded {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; }
  html.hide-scroll {
    overflow: hidden; }

body {
  min-height: 100%;
  position: relative;
  overflow-x: hidden; }

.hide {
  display: none; }

.inactive {
  opacity: .4;
  pointer-events: none; }

/* Alert */
.Alert {
  position: relative;
  margin-bottom: 24px;
  padding: 0.75em 1em 0.75em 1em;
  border: 1px solid;
  border-radius: 2px; }
  .Alert.is-dismissable {
    padding-right: 32px; }
  .Alert p,
  .Alert ul {
    margin-bottom: 0; }
    .Alert p + p,
    .Alert p + ul,
    .Alert ul + p,
    .Alert ul + ul {
      margin-top: 24px; }
  .Alert a:not(.Button--close) {
    font-weight: bold; }
    .Alert a:not(.Button--close):hover {
      text-decoration: underline; }

/* Nav */
.Nav-list {
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  flex: 0 1 auto; }
  @media screen and (max-width: 64em) {
    .Nav-list {
      flex-direction: column; } }

.Nav-item {
  margin: 0;
  list-style: none; }
  .Nav-item.has-dropdown {
    position: relative; }
    @media screen and (min-width: 105.6875em) {
      .Nav-item.has-dropdown:hover .Nav-dropdown {
        opacity: 1;
        visibility: visible; }
        .Nav-item.has-dropdown:hover .Nav-dropdown::before {
          height: 30px; } }

.Nav-link {
  position: relative;
  user-select: none;
  display: block;
  font-size: 13px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #293241;
  text-decoration: none;
  margin-left: 30px;
  padding-bottom: 5px; }
  .Nav-link:hover, .Nav-link:focus, .Nav-link:active {
    color: #293241; }
  .Nav-link.is-active {
    color: #293241; }

.Nav-dropdown {
  position: absolute;
  opacity: 0;
  list-style: none;
  visibility: hidden;
  transition: opacity ease .3s, visibility ease .3s;
  top: 30px;
  text-align: left;
  margin: 0 auto;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  padding: 20px;
  background-color: #ffffff;
  width: 160px; }
  .Nav-dropdown.showDropdown {
    opacity: 1;
    visibility: visible; }
    .Nav-dropdown.showDropdown::before {
      height: 30px; }
  .Nav-dropdown-container .Nav-item a {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    border-bottom: 2px solid rgba(255, 255, 255, 0);
    transition: border-color .2s ease;
    line-height: 1;
    padding-bottom: 5px; }
  .Nav-dropdown-container .Nav-item a + a {
    padding-top: 10px; }

.LangNav {
  position: relative;
  display: inline-block; }
  @media screen and (min-width: 68.75em) {
    .LangNav {
      margin-left: 10px; }
      .LangNav:hover .LangNav-list {
        display: flex !important; } }
  .LangNav-current {
    cursor: pointer; }
    .LangNav-current--item {
      display: flex;
      text-transform: uppercase !important;
      font-weight: 500; }
    @media screen and (max-width: 68.75em) {
      .LangNav-current {
        display: none !important; } }
  .LangNav-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 10px; }
    @media screen and (min-width: 68.75em) {
      .LangNav-list {
        top: 100%;
        left: 0;
        width: 100%;
        row-gap: 2px;
        display: none !important;
        position: absolute;
        flex-direction: column;
        border: 1px solid var(--brandingColor);
        padding: 0 2px !important;
        align-items: center;
        background-color: white;
        z-index: 10; } }
  .LangNav-item--icon {
    width: 12px;
    min-width: 12px;
    margin-left: 5px; }
  .LangNav-item:hover .LangNav-link, .LangNav-item.is-active .LangNav-link {
    color: var(--brandingColor); }
  .LangNav-link {
    text-transform: uppercase !important;
    font-weight: 500; }
  @media screen and (min-width: 68.75em) {
    .LangNav-seperater {
      display: none; } }

/* Button */
/*
 * 1. Corrects inability to style clickable `input` types in iOS.
 * 2. Normalize `box-sizing` across all elements that this component could be
 *    applied to.
 * 3. Inherit text color from ancestor.
 * 4. Inherit font styles from ancestor.
 * 5. Normalize `line-height`. For `input`, it can't be changed from `normal`
 *    in Firefox 4+.
 * 6. Prevent button text from being selectable.
 * 7. Make sure `input` will wrap text across multiple lines.
 *
 */
.Button {
  appearance: none;
  background: transparent;
  border-color: transparent;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0.5em 0.75em 0.5em 0.75em;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: normal; }
  .Button::-moz-focus-inner {
    border: 0;
    padding: 0; }
  .Button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color; }
  .Button:active, .Button:focus, .Button:hover, .Button.is-active {
    color: inherit;
    text-decoration: none; }
  .Button:disabled, .Button.is-disabled {
    cursor: default;
    opacity: 0.6;
    pointer-events: none; }

/* FlexEmbed */
.FlexEmbed {
  display: block;
  overflow: hidden;
  position: relative; }
  .FlexEmbed::before {
    content: '';
    display: block;
    padding-bottom: 100%;
    width: 100%; }

.FlexEmbed-content,
.FlexEmbed-content > iframe,
.FlexEmbed-content > object,
.FlexEmbed-content > embed,
.FlexEmbed-content > video {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

img.FlexEmbed-content {
  object-fit: cover; }

.FlexEmbed--2by1:before {
  padding-bottom: 50%; }

.FlexEmbed--3by1:before {
  padding-bottom: 33.33333%; }

.FlexEmbed--3by2:before {
  padding-bottom: 66.66667%; }

.FlexEmbed--4by3:before {
  padding-bottom: 75%; }

.FlexEmbed--16by9:before {
  padding-bottom: 56.25%; }

/* Form */
.Form-item:not(.Form-item--action) {
  margin-bottom: 24px; }

.Form-label {
  display: inline-block;
  margin-bottom: 6px; }
  .Form-item:not(.is-required) .Form-label::after {
    content: ' - Optional';
    margin-left: 6px; }
    html[lang^="nl"] .Form-item:not(.is-required) .Form-label::after {
      content: ' - Optioneel'; }
    html[lang^="fr"] .Form-item:not(.is-required) .Form-label::after {
      content: ' - Facultatif'; }
  .Form-item.is-error .Form-label {
    color: #f44336; }
  .Form-item.is-success .Form-label {
    color: #4caf50; }

.Form-help {
  margin-top: 6px;
  color: #bdbdbd;
  font-size: 12px; }
  .Form-help:first-child {
    margin-top: 0;
    margin-bottom: 12px; }
  .Form-help :last-child {
    margin-bottom: 0; }
  .Form-help + .Form-help {
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid #e0e0e0; }

.Form-item.is-error .Form-help--validation {
  color: #f44336; }

.Form-item.is-success .Form-help--validation {
  color: #4caf50; }

.Form-controls {
  position: relative; }

@media screen and (min-width: 37.5em) {
  .Form--horizontal .Form-item {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start; }
  .Form--horizontal .Form-label {
    margin-top: 6px;
    margin-bottom: 0;
    padding-right: 24px;
    width: 33.33333%; }
  .Form--horizontal .Form-controls {
    width: 66.66667%; }
  .Form--horizontal .Form-item--action {
    padding-left: 33.33333%; } }

.Media-offscreen {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal; }

.Media-container {
  background: #000;
  box-sizing: border-box;
  font-family: 'Helvetica', Arial, serif;
  position: relative;
  text-align: left;
  text-indent: 0;
  vertical-align: top; }

.Media-container * {
  box-sizing: border-box; }

/* Hide native play button and control bar from iOS to favor plugin button */
.Media-container video::-webkit-media-controls,
.Media-container video::-webkit-media-controls-panel,
.Media-container video::-webkit-media-controls-panel-container,
.Media-container video::-webkit-media-controls-start-playback-button {
  -webkit-appearance: none;
  display: none !important; }

.Media-fill-container,
.Media-fill-container .Media-container {
  height: 100%;
  width: 100%; }

.Media-fill-container {
  background: transparent;
  margin: 0 auto;
  overflow: hidden;
  position: relative; }

.Media-container:focus {
  outline: none; }

.Media-iframe-overlay {
  height: 100%;
  position: absolute;
  width: 100%; }

.Media-embed,
.Media-embed body {
  background: #000;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%; }

.Media-fullscreen {
  overflow: hidden !important; }

.Media-container-fullscreen {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000; }

.Media-container-fullscreen .Media-mediaelement,
.Media-container-fullscreen video {
  height: 100% !important;
  width: 100% !important; }

/* Start: LAYERS */
.Media-background {
  left: 0;
  position: absolute;
  top: 0; }

.Media-mediaelement {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0; }

.Media-poster {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 1; }

:root .Media-poster-img {
  display: none; }

.Media-poster-img {
  border: 0;
  padding: 0; }

.Media-overlay {
  align-items: center;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0; }

.Media-layer {
  z-index: 1; }

.Media-overlay-play {
  cursor: pointer; }

.Media-overlay-button {
  background: url("../img/media/controls.svg") no-repeat;
  background-position: 0 -39px;
  height: 80px;
  width: 80px; }

.Media-overlay:hover > .Media-overlay-button {
  background-position: -80px -39px; }

.Media-overlay-loading {
  height: 80px;
  width: 80px; }

.Media-overlay-loading-bg-img {
  animation: Media-loading-spinner 1s linear infinite;
  background: transparent url("../img/media/controls.svg") -160px -40px no-repeat;
  display: block;
  height: 80px;
  width: 80px;
  z-index: 1; }

@keyframes Media-loading-spinner {
  100% {
    transform: rotate(360deg); } }

/* End: LAYERS */
/* Start: CONTROL BAR */
.Media-controls {
  bottom: 0;
  display: flex;
  height: 40px;
  left: 0;
  list-style-type: none;
  margin: 0;
  padding: 0 10px;
  position: absolute;
  width: 100%;
  z-index: 3; }

.Media-controls:not([style*='display: none']) {
  background: rgba(255, 0, 0, 0.7);
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.35)); }

.Media-button,
.Media-time,
.Media-time-rail {
  font-size: 10px;
  height: 40px;
  line-height: 10px;
  margin: 0;
  width: 32px; }

.Media-button > button {
  background: transparent url("../img/media/controls.svg");
  border: 0;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 20px;
  line-height: 0;
  margin: 10px 6px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  text-decoration: none;
  width: 20px; }

/* :focus for accessibility */
.Media-button > button:focus {
  outline: dotted 1px #999; }

.Media-container-keyboard-inactive a,
.Media-container-keyboard-inactive a:focus,
.Media-container-keyboard-inactive button,
.Media-container-keyboard-inactive button:focus,
.Media-container-keyboard-inactive [role=slider],
.Media-container-keyboard-inactive [role=slider]:focus {
  outline: 0; }

/* End: CONTROL BAR */
/* Start: Time (Current / Duration) */
.Media-time {
  box-sizing: content-box;
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  height: 24px;
  overflow: hidden;
  padding: 16px 6px 0;
  text-align: center;
  width: auto; }

/* End: Time (Current / Duration) */
/* Start: Play/Pause/Stop */
.Media-play > button {
  background-position: 0 0; }

.Media-pause > button {
  background-position: -20px 0; }

.Media-replay > button {
  background-position: -160px 0; }

/* End: Play/Pause/Stop */
/* Start: Progress Bar */
.Media-time-rail {
  direction: ltr;
  flex-grow: 1;
  height: 40px;
  margin: 0 10px;
  padding-top: 10px;
  position: relative; }

.Media-time-total,
.Media-time-buffering,
.Media-time-loaded,
.Media-time-current,
.Media-time-float,
.Media-time-hovered,
.Media-time-float-current,
.Media-time-float-corner,
.Media-time-marker {
  border-radius: 2px;
  cursor: pointer;
  display: block;
  height: 10px;
  position: absolute; }

.Media-time-total {
  background: rgba(255, 255, 255, 0.3);
  margin: 5px 0 0;
  width: 100%; }

.Media-time-buffering {
  animation: buffering-stripes 2s linear infinite;
  background: linear-gradient(-45deg, rgba(255, 255, 255, 0.4) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.4) 75%, transparent 75%, transparent);
  background-size: 15px 15px;
  width: 100%; }

@keyframes buffering-stripes {
  from {
    background-position: 0 0; }
  to {
    background-position: 30px 0; } }

.Media-time-loaded {
  background: rgba(255, 255, 255, 0.3); }

.Media-time-current,
.Media-time-handle-content {
  background: rgba(255, 255, 255, 0.9); }

.Media-time-hovered {
  background: rgba(255, 255, 255, 0.5);
  z-index: 10; }

.Media-time-hovered.negative {
  background: rgba(0, 0, 0, 0.2); }

.Media-time-current,
.Media-time-buffering,
.Media-time-loaded,
.Media-time-hovered {
  left: 0;
  transform: scaleX(0);
  transform-origin: 0 0;
  transition: 0.15s ease-in all;
  width: 100%; }

.Media-time-buffering {
  transform: scaleX(1); }

.Media-time-hovered {
  transition: height 0.1s cubic-bezier(0.44, 0, 1, 1); }

.Media-time-hovered.no-hover {
  transform: scaleX(0) !important; }

.Media-time-handle,
.Media-time-handle-content {
  border: 4px solid transparent;
  cursor: pointer;
  left: 0;
  position: absolute;
  transform: translateX(0);
  z-index: 11; }

.Media-time-handle-content {
  border: 4px solid rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  height: 10px;
  left: -7px;
  top: -4px;
  transform: scale(0);
  width: 10px; }

.Media-time-rail:hover .Media-time-handle-content,
.Media-time-rail .Media-time-handle-content:focus,
.Media-time-rail .Media-time-handle-content:active {
  transform: scale(1); }

.Media-time-float {
  background: #eee;
  border: solid 1px #333;
  bottom: 100%;
  color: #111;
  display: none;
  height: 17px;
  margin-bottom: 9px;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
  width: 36px; }

.Media-time-float-current {
  display: block;
  left: 0;
  margin: 2px;
  text-align: center;
  width: 30px; }

.Media-time-float-corner {
  border: solid 5px #eee;
  border-color: #eee transparent transparent;
  border-radius: 0;
  display: block;
  height: 0;
  left: 50%;
  line-height: 0;
  position: absolute;
  top: 100%;
  transform: translateX(-50%);
  width: 0; }

.Media-long-video .Media-time-float {
  margin-left: -23px;
  width: 64px; }

.Media-long-video .Media-time-float-current {
  width: 60px; }

.Media-broadcast {
  color: #fff;
  height: 10px;
  position: absolute;
  top: 15px;
  width: 100%; }

/* End: Progress Bar */
/* Start: Fullscreen */
.Media-fullscreen-button > button {
  background-position: -80px 0; }

.Media-unfullscreen > button {
  background-position: -100px 0; }

/* End: Fullscreen */
/* Start: Mute/Volume */
.Media-mute > button {
  background-position: -60px 0; }

.Media-unmute > button {
  background-position: -40px 0; }

.Media-volume-button {
  position: relative; }

.Media-volume-button > .Media-volume-slider {
  -webkit-backface-visibility: hidden;
  background: rgba(50, 50, 50, 0.7);
  border-radius: 0;
  bottom: 100%;
  display: none;
  height: 115px;
  left: 50%;
  margin: 0;
  position: absolute;
  transform: translateX(-50%);
  width: 25px;
  z-index: 1; }

.Media-volume-button:hover {
  border-radius: 0 0 4px 4px; }

.Media-volume-total {
  background: rgba(255, 255, 255, 0.5);
  height: 100px;
  left: 50%;
  margin: 0;
  position: absolute;
  top: 8px;
  transform: translateX(-50%);
  width: 2px; }

.Media-volume-current {
  background: rgba(255, 255, 255, 0.9);
  left: 0;
  margin: 0;
  position: absolute;
  width: 100%; }

.Media-volume-handle {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 1px;
  cursor: ns-resize;
  height: 6px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 16px; }

.Media-horizontal-volume-slider {
  display: block;
  height: 36px;
  position: relative;
  vertical-align: middle;
  width: 56px; }

.Media-horizontal-volume-total {
  background: rgba(50, 50, 50, 0.8);
  border-radius: 2px;
  font-size: 1px;
  height: 8px;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 16px;
  width: 50px; }

.Media-horizontal-volume-current {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 2px;
  font-size: 1px;
  height: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%; }

.Media-horizontal-volume-handle {
  display: none; }

/* End: Mute/Volume */
/* Start: Track (Captions and Chapters) */
.Media-captions-button,
.Media-chapters-button {
  position: relative; }

.Media-captions-button > button {
  background-position: -140px 0; }

.Media-chapters-button > button {
  background-position: -180px 0; }

.Media-captions-button > .Media-captions-selector,
.Media-chapters-button > .Media-chapters-selector {
  background: rgba(50, 50, 50, 0.7);
  border: solid 1px transparent;
  border-radius: 0;
  bottom: 100%;
  margin-right: -43px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  right: 50%;
  visibility: visible;
  width: 86px; }

.Media-chapters-button > .Media-chapters-selector {
  margin-right: -55px;
  width: 110px; }

.Media-captions-selector-list,
.Media-chapters-selector-list {
  list-style-type: none !important;
  margin: 0;
  overflow: hidden;
  padding: 0; }

.Media-captions-selector-list-item,
.Media-chapters-selector-list-item {
  color: #fff;
  cursor: pointer;
  display: block;
  list-style-type: none !important;
  margin: 0 0 6px;
  overflow: hidden;
  padding: 0; }

.Media-captions-selector-list-item:hover,
.Media-chapters-selector-list-item:hover {
  background-color: #c8c8c8 !important;
  background-color: rgba(255, 255, 255, 0.4) !important; }

.Media-captions-selector-input,
.Media-chapters-selector-input {
  clear: both;
  float: left;
  left: -1000px;
  margin: 3px 3px 0 5px;
  position: absolute; }

.Media-captions-selector-label,
.Media-chapters-selector-label {
  cursor: pointer;
  float: left;
  font-size: 10px;
  line-height: 15px;
  padding: 4px 10px 0;
  width: 100%; }

.Media-captions-selected,
.Media-chapters-selected {
  color: #21f8f8; }

.Media-captions-translations {
  font-size: 10px;
  margin: 0 0 5px; }

.Media-captions-layer {
  bottom: 0;
  color: #fff;
  font-size: 16px;
  left: 0;
  line-height: 20px;
  position: absolute;
  text-align: center; }

.Media-captions-layer a {
  color: #fff;
  text-decoration: underline; }

.Media-captions-layer[lang=ar] {
  font-size: 20px;
  font-weight: normal; }

.Media-captions-position {
  bottom: 15px;
  left: 0;
  position: absolute;
  width: 100%; }

.Media-captions-position-hover {
  bottom: 35px; }

.Media-captions-text,
.Media-captions-text * {
  background: rgba(20, 20, 20, 0.5);
  box-shadow: 5px 0 0 rgba(20, 20, 20, 0.5), -5px 0 0 rgba(20, 20, 20, 0.5);
  padding: 0;
  white-space: pre-wrap; }

.Media-container.Media-hide-cues video::-webkit-media-text-track-container {
  display: none; }

/* End: Track (Captions and Chapters) */
/* Start: Error */
.Media-overlay-error {
  position: relative; }

.Media-overlay-error > img {
  left: 0;
  max-width: 100%;
  position: absolute;
  top: 0;
  z-index: -1; }

.Media-cannotplay,
.Media-cannotplay a {
  color: #fff;
  font-size: 0.8em; }

.Media-cannotplay {
  position: relative; }

.Media-cannotplay p,
.Media-cannotplay a {
  display: inline-block;
  padding: 0 15px;
  width: 100%; }

/* Grid */
/**
 * DO NOT apply dimension or offset utilities to the `Grid` element. All cell
 * widths and offsets should be applied to child grid cells.
 */
.Grid {
  box-sizing: border-box;
  display: flex;
  /* 1 */
  flex-flow: row wrap;
  margin: 0;
  /* 1 */
  padding: 0;
  /* 1 */ }

/**
    * No explicit width by default. Rely on combining `Grid-cell` with a dimension
    * utility or a component class that extends 'Grid'.
    *
    * 1. Set flex items to full width by default
    * 2. Fix issue where elements with overflow extend past the
    *      `Grid-cell` container - https://git.io/vw5oF
    */
.Grid-cell {
  box-sizing: inherit;
  flex-basis: 100%;
  /* 1 */
  min-width: 0;
  /* 2 */ }

/**
    * Modifiers
    */
.Grid--alignCenter {
  justify-content: center; }

.Grid--alignRight {
  justify-content: flex-end; }

.Grid--alignMiddle {
  align-items: center; }

.Grid--alignBottom {
  align-items: flex-end; }

.Grid--fill > .Grid-cell {
  flex: 1 1 0%;
  /* 1 */ }

.Grid--fit > .Grid-cell {
  flex-basis: auto; }

.Grid--equalHeight > .Grid-cell {
  display: flex; }

.Grid--withExtraSmallGutter {
  margin-left: -6px; }
  .Grid--withExtraSmallGutter > .Grid-cell {
    padding-left: 6px; }

.Grid--withSmallGutter {
  margin-left: -12px; }
  .Grid--withSmallGutter > .Grid-cell {
    padding-left: 12px; }

.Grid--withGutter {
  margin-left: -24px; }
  .Grid--withGutter > .Grid-cell {
    padding-left: 24px; }

.Grid--withLargeGutter {
  margin-left: -48px; }
  .Grid--withLargeGutter > .Grid-cell {
    padding-left: 48px; }

.Grid--withExtraLargeGutter {
  margin-left: -96px; }
  .Grid--withExtraLargeGutter > .Grid-cell {
    padding-left: 96px; }

.Grid--withExtraExtraLargeGutter {
  margin-left: -96px; }
  .Grid--withExtraExtraLargeGutter > .Grid-cell {
    padding-left: 96px; }

/* Icon */
.Icon {
  display: inline-flex;
  align-self: center;
  flex-shrink: 0; }
  .Icon svg {
    fill: currentColor;
    height: 1em;
    width: 1em; }
  .Icon.Icon--baseline svg {
    top: 0.125em;
    position: relative; }

/* Structure */
.Container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1100px;
  padding-right: 20px;
  padding-left: 20px;
  position: relative;
  width: 100%; }
  .Container--xxl {
    max-width: 1960px;
    padding-right: 100px;
    padding-left: 100px; }
  .Container--xl {
    max-width: 1480px; }
  .Container--lg {
    max-width: 1320px; }
  .Container--md {
    max-width: 1100px; }
  .Container--sm {
    max-width: 1000px; }
  .Container--xs {
    max-width: 640px; }
  @media screen and (min-width: 111.25em) {
    .Container {
      max-width: 1140px;
      padding-right: 40px;
      padding-left: 40px; }
      .Container--xxl {
        max-width: 2000px;
        padding-right: 360px;
        padding-left: 360px; }
      .Container--xl {
        max-width: 1520px; }
      .Container--lg {
        max-width: 1360px; }
      .Container--md {
        max-width: 1140px; }
      .Container--sm {
        max-width: 1040px; }
      .Container--xs {
        max-width: 680px; } }
  @media screen and (min-width: 20em) and (max-width: 37.5625em) {
    .Container {
      max-width: 1140px;
      padding-right: 40px;
      padding-left: 40px; }
      .Container--xxl {
        max-width: 2000px;
        padding-right: 20px;
        padding-left: 20px; }
      .Container--xl {
        max-width: 1520px; }
      .Container--lg {
        max-width: 1360px; }
      .Container--md {
        max-width: 1140px; }
      .Container--sm {
        max-width: 1040px; }
      .Container--xs {
        max-width: 680px; } }

body {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: "header" "main" "footer"; }

.Header {
  position: relative;
  z-index: 3;
  grid-area: header;
  height: 120px; }

.Footer {
  position: relative;
  z-index: 1;
  grid-area: footer; }

.Main {
  position: relative;
  z-index: 2;
  grid-area: main; }
  .Main > .Container {
    padding-top: 24px;
    padding-bottom: 24px; }
    @media screen and (min-width: 48em) {
      .Main > .Container {
        grid-gap: 48px; }
        .Main > .Container.has-leftSide {
          display: grid;
          grid-template-columns: 33.33333% 1fr;
          grid-template-rows: auto;
          grid-template-areas: "left content"; }
        .Main > .Container.has-rightSide {
          display: grid;
          grid-template-columns: 1fr 33.33333%;
          grid-template-rows: auto;
          grid-template-areas: "content right"; }
        .Main > .Container.has-sides {
          display: grid;
          grid-template-columns: 33.33333% auto 33.33333%;
          grid-template-rows: auto;
          grid-template-areas: "left content right"; } }

.Side {
  position: relative; }
  @media screen and (max-width: 47.9375em) {
    .Side {
      margin-top: 24px; } }
  @media screen and (min-width: 48em) {
    .Side--left {
      grid-area: left; }
    .Side--right {
      grid-area: right; } }

.Content {
  grid-area: content;
  position: relative; }

/* Table */
.Table {
  background-color: transparent;
  border: 1px solid #e0e0e0;
  table-layout: fixed;
  text-align: left;
  width: 100%; }
  .Table th,
  .Table td {
    vertical-align: middle;
    padding: 4px 8px 4px 8px; }
  .Table thead {
    background-color: #f5f5f5;
    border-bottom: 2px solid #e0e0e0; }
  .Table tbody tr + tr {
    border-top: 1px solid #e0e0e0; }
  .Table tfoot {
    background-color: #f5f5f5;
    border-top: 2px solid #e0e0e0; }
  @media screen and (max-width: 47.9375em) {
    .Table.is-responsive {
      border-width: 0; }
      .Table.is-responsive,
      .Table.is-responsive tbody,
      .Table.is-responsive tfoot,
      .Table.is-responsive tr {
        display: block; }
      .Table.is-responsive thead {
        display: none; }
      .Table.is-responsive tbody tr {
        border: 1px solid #e0e0e0;
        border-bottom-width: 0; }
      .Table.is-responsive tfoot {
        border: 1px solid #e0e0e0;
        border-top-width: 2px; }
      .Table.is-responsive .Table-cell {
        display: inline-block;
        vertical-align: top;
        width: 100%; }
        .Table.is-responsive .Table-cell[data-table-label]::before {
          content: attr(data-table-label);
          display: block;
          margin: -12px -12px 12px;
          padding: 12px;
          background-color: #fafafa;
          border-top: 1px solid #e0e0e0;
          border-bottom: 1px solid #e0e0e0; } }

.Table--striped tbody tr:nth-child(even) {
  background-color: #fafafa; }

@media screen and (max-width: 47.9375em) {
  .Table--striped.is-responsive tbody tr:nth-child(even) {
    background-color: transparent; } }

.Toggle {
  height: 24px;
  width: 30px;
  position: relative;
  cursor: pointer;
  transform: rotate(0deg);
  z-index: 999999999; }
  .Toggle-item {
    height: 2px;
    width: 100%;
    position: absolute;
    transition-property: top, transform, opacity;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
    background-color: #000000;
    border-radius: 0px;
    display: block;
    transform: rotate(0deg); }
    .Toggle-item:nth-child(1) {
      top: 0; }
      .show-nav .Toggle-item:nth-child(1) {
        top: 11px;
        background-color: #000000;
        transform: rotate(135deg); }
    .Toggle-item:nth-child(2) {
      top: 11px; }
      .show-nav .Toggle-item:nth-child(2) {
        opacity: 0; }
    .Toggle-item:nth-child(3) {
      top: 22px; }
      .show-nav .Toggle-item:nth-child(3) {
        top: 11px;
        background-color: #000000;
        transform: rotate(-135deg); }

.fancybox-active {
  height: auto; }

.fancybox-is-hidden {
  left: -9999px;
  margin: 0;
  position: absolute !important;
  top: -9999px;
  visibility: hidden; }

.fancybox-container {
  -webkit-backface-visibility: hidden;
  height: 100%;
  left: 0;
  outline: none;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  transform: translateZ(0);
  width: 100%;
  z-index: 99992; }

.fancybox-container * {
  box-sizing: border-box; }

.fancybox-outer,
.fancybox-inner,
.fancybox-bg,
.fancybox-stage {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0; }

.fancybox-outer {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto; }

.fancybox-bg {
  background: #1e1e1e;
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71); }

.fancybox-is-open .fancybox-bg {
  opacity: .9;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1); }

.fancybox-infobar,
.fancybox-toolbar,
.fancybox-caption,
.fancybox-navigation .fancybox-button {
  direction: ltr;
  opacity: 0;
  position: absolute;
  transition: opacity .25s ease, visibility 0s ease .25s;
  visibility: hidden;
  z-index: 99997; }

.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar,
.fancybox-show-caption .fancybox-caption,
.fancybox-show-nav .fancybox-navigation .fancybox-button {
  opacity: 1;
  transition: opacity .25s ease 0s, visibility 0s ease 0s;
  visibility: visible; }

.fancybox-infobar {
  color: #ccc;
  font-size: 13px;
  -webkit-font-smoothing: subpixel-antialiased;
  height: 44px;
  left: 0;
  line-height: 44px;
  min-width: 44px;
  mix-blend-mode: difference;
  padding: 0 10px;
  pointer-events: none;
  top: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.fancybox-toolbar {
  right: 0;
  top: 0; }

.fancybox-stage {
  direction: ltr;
  overflow: visible;
  transform: translateZ(0);
  z-index: 99994; }

.fancybox-is-open .fancybox-stage {
  overflow: hidden; }

.fancybox-slide {
  -webkit-backface-visibility: hidden;
  /* Using without prefix would break IE11 */
  display: none;
  height: 100%;
  left: 0;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: absolute;
  text-align: center;
  top: 0;
  transition-property: transform, opacity;
  white-space: normal;
  width: 100%;
  z-index: 99994; }

.fancybox-slide::before {
  content: '';
  display: inline-block;
  font-size: 0;
  height: 100%;
  vertical-align: middle;
  width: 0; }

.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--previous,
.fancybox-slide--current,
.fancybox-slide--next {
  display: block; }

.fancybox-slide--image {
  overflow: hidden;
  padding: 44px 0; }

.fancybox-slide--image::before {
  display: none; }

.fancybox-slide--html {
  padding: 6px; }

.fancybox-content {
  background: #fff;
  display: inline-block;
  margin: 0;
  max-width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: relative;
  text-align: left;
  vertical-align: middle; }

.fancybox-slide--image .fancybox-content {
  animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  -webkit-backface-visibility: hidden;
  background: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: 0;
  max-width: none;
  overflow: visible;
  padding: 0;
  position: absolute;
  top: 0;
  -ms-transform-origin: top left;
  transform-origin: top left;
  transition-property: transform, opacity;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 99995; }

.fancybox-can-zoomOut .fancybox-content {
  cursor: zoom-out; }

.fancybox-can-zoomIn .fancybox-content {
  cursor: zoom-in; }

.fancybox-can-swipe .fancybox-content,
.fancybox-can-pan .fancybox-content {
  cursor: -webkit-grab;
  cursor: grab; }

.fancybox-is-grabbing .fancybox-content {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.fancybox-container [data-selectable='true'] {
  cursor: text; }

.fancybox-image,
.fancybox-spaceball {
  background: transparent;
  border: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.fancybox-spaceball {
  z-index: 1; }

.fancybox-slide--video .fancybox-content,
.fancybox-slide--map .fancybox-content,
.fancybox-slide--pdf .fancybox-content,
.fancybox-slide--iframe .fancybox-content {
  height: 100%;
  overflow: visible;
  padding: 0;
  width: 100%; }

.fancybox-slide--video .fancybox-content {
  background: #000; }

.fancybox-slide--map .fancybox-content {
  background: #e5e3df; }

.fancybox-slide--iframe .fancybox-content {
  background: #fff; }

.fancybox-video,
.fancybox-iframe {
  background: transparent;
  border: 0;
  display: block;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%; }

/* Fix iOS */
.fancybox-iframe {
  left: 0;
  position: absolute;
  top: 0; }

.fancybox-error {
  background: #fff;
  cursor: default;
  max-width: 400px;
  padding: 40px;
  width: 100%; }

.fancybox-error p {
  color: #444;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0; }

/* Buttons */
.fancybox-button {
  background: rgba(30, 30, 30, 0.6);
  border: 0;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  margin: 0;
  padding: 10px;
  position: relative;
  transition: color .2s;
  vertical-align: top;
  visibility: inherit;
  width: 44px; }

.fancybox-button,
.fancybox-button:visited,
.fancybox-button:link {
  color: #ccc; }

.fancybox-button:hover {
  color: #fff; }

.fancybox-button:focus {
  outline: none; }

.fancybox-button.fancybox-focus {
  outline: 1px dotted; }

.fancybox-button[disabled],
.fancybox-button[disabled]:hover {
  color: #888;
  cursor: default;
  outline: none; }

/* Fix IE11 */
.fancybox-button div {
  height: 100%; }

.fancybox-button svg {
  display: block;
  height: 100%;
  overflow: visible;
  position: relative;
  width: 100%; }

.fancybox-button svg path {
  fill: currentColor;
  stroke-width: 0; }

.fancybox-button--play svg:nth-child(2),
.fancybox-button--fsenter svg:nth-child(2) {
  display: none; }

.fancybox-button--pause svg:nth-child(1),
.fancybox-button--fsexit svg:nth-child(1) {
  display: none; }

.fancybox-progress {
  background: #ff5268;
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -ms-transform-origin: 0;
  transform-origin: 0;
  transition-property: transform;
  transition-timing-function: linear;
  z-index: 99998; }

/* Close button on the top right corner of html content */
.fancybox-close-small {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: #ccc;
  cursor: pointer;
  opacity: .8;
  padding: 8px;
  position: absolute;
  right: -12px;
  top: -44px;
  z-index: 401; }

.fancybox-close-small:hover {
  color: #fff;
  opacity: 1; }

.fancybox-slide--html .fancybox-close-small {
  color: currentColor;
  padding: 10px;
  right: 0;
  top: 0; }

.fancybox-slide--image.fancybox-is-scaling .fancybox-content {
  overflow: hidden; }

.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small {
  display: none; }

/* Navigation arrows */
.fancybox-navigation .fancybox-button {
  background-clip: content-box;
  height: 100px;
  opacity: 0;
  position: absolute;
  top: calc(50% - 50px);
  width: 70px; }

.fancybox-navigation .fancybox-button div {
  padding: 7px; }

.fancybox-navigation .fancybox-button--arrow_left {
  left: 0;
  left: env(safe-area-inset-left);
  padding: 31px 26px 31px 6px; }

.fancybox-navigation .fancybox-button--arrow_right {
  padding: 31px 6px 31px 26px;
  right: 0;
  right: env(safe-area-inset-right); }

/* Caption */
.fancybox-caption {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.15) 65%, rgba(0, 0, 0, 0.075) 75.5%, rgba(0, 0, 0, 0.037) 82.85%, rgba(0, 0, 0, 0.019) 88%, rgba(0, 0, 0, 0) 100%);
  bottom: 0;
  color: #eee;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  line-height: 1.5;
  padding: 75px 44px 25px 44px;
  pointer-events: none;
  right: 0;
  text-align: center;
  z-index: 99996; }

.fancybox-caption--separate {
  margin-top: -50px; }

.fancybox-caption__body {
  max-height: 50vh;
  overflow: auto;
  pointer-events: all; }

.fancybox-caption a,
.fancybox-caption a:link,
.fancybox-caption a:visited {
  color: #ccc;
  text-decoration: none; }

.fancybox-caption a:hover {
  color: #fff;
  text-decoration: underline; }

/* Loading indicator */
.fancybox-loading {
  animation: fancybox-rotate 1s linear infinite;
  background: transparent;
  border: 4px solid #888;
  border-bottom-color: #fff;
  border-radius: 50%;
  height: 50px;
  left: 50%;
  margin: -25px 0 0 -25px;
  opacity: .7;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 50px;
  z-index: 99999; }

@keyframes fancybox-rotate {
  100% {
    transform: rotate(360deg); } }

/* Transition effects */
.fancybox-animated {
  transition-timing-function: cubic-bezier(0, 0, 0.25, 1); }

/* transitionEffect: slide */
.fancybox-fx-slide.fancybox-slide--previous {
  opacity: 0;
  transform: translate3d(-100%, 0, 0); }

.fancybox-fx-slide.fancybox-slide--next {
  opacity: 0;
  transform: translate3d(100%, 0, 0); }

.fancybox-fx-slide.fancybox-slide--current {
  opacity: 1;
  transform: translate3d(0, 0, 0); }

/* transitionEffect: fade */
.fancybox-fx-fade.fancybox-slide--previous,
.fancybox-fx-fade.fancybox-slide--next {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }

.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1; }

/* transitionEffect: zoom-in-out */
.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(1.5, 1.5, 1.5); }

.fancybox-fx-zoom-in-out.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5); }

.fancybox-fx-zoom-in-out.fancybox-slide--current {
  opacity: 1;
  transform: scale3d(1, 1, 1); }

/* transitionEffect: rotate */
.fancybox-fx-rotate.fancybox-slide--previous {
  opacity: 0;
  -ms-transform: rotate(-360deg);
  transform: rotate(-360deg); }

.fancybox-fx-rotate.fancybox-slide--next {
  opacity: 0;
  -ms-transform: rotate(360deg);
  transform: rotate(360deg); }

.fancybox-fx-rotate.fancybox-slide--current {
  opacity: 1;
  -ms-transform: rotate(0deg);
  transform: rotate(0deg); }

/* transitionEffect: circular */
.fancybox-fx-circular.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0); }

.fancybox-fx-circular.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0); }

.fancybox-fx-circular.fancybox-slide--current {
  opacity: 1;
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0); }

/* transitionEffect: tube */
.fancybox-fx-tube.fancybox-slide--previous {
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg); }

.fancybox-fx-tube.fancybox-slide--next {
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg); }

.fancybox-fx-tube.fancybox-slide--current {
  transform: translate3d(0, 0, 0) scale(1); }

/* Styling for Small-Screen Devices */
@media all and (max-height: 576px) {
  .fancybox-slide {
    padding-left: 6px;
    padding-right: 6px; }
  .fancybox-slide--image {
    padding: 6px 0; }
  .fancybox-close-small {
    right: -6px; }
  .fancybox-slide--image .fancybox-close-small {
    background: #4e4e4e;
    color: #f2f4f6;
    height: 36px;
    opacity: 1;
    padding: 6px;
    right: 0;
    top: 0;
    width: 36px; }
  .fancybox-caption {
    padding-left: 12px;
    padding-right: 12px; } }

/* Share */
.fancybox-share {
  background: #f4f4f4;
  border-radius: 3px;
  max-width: 90%;
  padding: 30px;
  text-align: center; }

.fancybox-share h1 {
  color: #222;
  font-size: 35px;
  font-weight: 700;
  margin: 0 0 20px 0; }

.fancybox-share p {
  margin: 0;
  padding: 0; }

.fancybox-share__button {
  border: 0;
  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  margin: 0 5px 10px 5px;
  min-width: 130px;
  padding: 0 15px;
  text-decoration: none;
  transition: all .2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap; }

.fancybox-share__button:visited,
.fancybox-share__button:link {
  color: #fff; }

.fancybox-share__button:hover {
  text-decoration: none; }

.fancybox-share__button--fb {
  background: #3b5998; }

.fancybox-share__button--fb:hover {
  background: #344e86; }

.fancybox-share__button--pt {
  background: #bd081d; }

.fancybox-share__button--pt:hover {
  background: #aa0719; }

.fancybox-share__button--tw {
  background: #1da1f2; }

.fancybox-share__button--tw:hover {
  background: #0d95e8; }

.fancybox-share__button svg {
  height: 25px;
  margin-right: 7px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 25px; }

.fancybox-share__button svg path {
  fill: #fff; }

.fancybox-share__input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 0;
  color: #5d5b5b;
  font-size: 14px;
  margin: 10px 0 0 0;
  outline: none;
  padding: 10px 15px;
  width: 100%; }

/* Thumbs */
.fancybox-thumbs {
  background: #ddd;
  bottom: 0;
  display: none;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 2px 2px 4px 2px;
  position: absolute;
  right: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  top: 0;
  width: 212px;
  z-index: 99995; }

.fancybox-thumbs-x {
  overflow-x: auto;
  overflow-y: hidden; }

.fancybox-show-thumbs .fancybox-thumbs {
  display: block; }

.fancybox-show-thumbs .fancybox-inner {
  right: 212px; }

.fancybox-thumbs__list {
  font-size: 0;
  height: 100%;
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  position: relative;
  white-space: nowrap;
  width: 100%; }

.fancybox-thumbs-x .fancybox-thumbs__list {
  overflow: hidden; }

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar {
  width: 7px; }

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 10px; }

.fancybox-thumbs__list a {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  float: left;
  height: 75px;
  margin: 2px;
  max-height: calc(100% - 8px);
  max-width: calc(50% - 4px);
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: 100px; }

.fancybox-thumbs__list a::before {
  border: 6px solid #ff5268;
  bottom: 0;
  content: '';
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 99991; }

.fancybox-thumbs__list a:focus::before {
  opacity: .5; }

.fancybox-thumbs__list a.fancybox-thumbs-active::before {
  opacity: 1; }

/* Styling for Small-Screen Devices */
@media all and (max-width: 576px) {
  .fancybox-thumbs {
    width: 110px; }
  .fancybox-show-thumbs .fancybox-inner {
    right: 110px; }
  .fancybox-thumbs__list a {
    max-width: calc(100% - 10px); } }

.accordion-container {
  border-top: 1px solid var(--brandingColor);
  margin-top: 40px; }

.accordion-item {
  border: none !important;
  background-color: unset !important;
  border-bottom: 1px solid var(--brandingColor) !important;
  margin-top: 0 !important; }
  .accordion-item .accordion-title:after {
    transform: rotate(90deg) !important; }
  .accordion-item.is-active .accordion-title:after {
    transform: rotate(-90deg) !important;
    opacity: 1 !important; }

.accordion-title, .accordion-text {
  font-family: "Gotham", sans-serif !important; }

.accordion-text {
  padding-left: 0 !important;
  padding-right: 0 !important; }
  .accordion-text .ac-text {
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .accordion-text > * {
    font-family: "Gotham", sans-serif !important; }
  .accordion-text * {
    margin-bottom: 5px; }
  .accordion-text ul {
    padding-left: 17px; }
    .accordion-text ul li {
      margin-top: 20px;
      list-style-type: disc; }
  .accordion-text a {
    color: #293241; }

.accordion-title {
  padding: 0;
  padding-left: 0 !important;
  padding-right: 40px;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  font-size: 16px !important;
  color: var(--primaryDark);
  text-decoration: none;
  font-weight: 600 !important; }
  .accordion-title:focus {
    outline: none;
    color: var(--primaryDark) !important;
    opacity: 1 !important; }
  .accordion-title:after {
    content: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.09' height='15.939' viewBox='0 0 10.09 15.939'%3E%3Cpath id='Path_84' data-name='Path 84' d='M17988.873-1860.946l-2.121-2.121,5.848-5.848-5.848-5.848,2.121-2.121,7.969,7.969Z' transform='translate(-17986.752 1876.885)' fill='%23c0924a'/%3E%3C/svg%3E") !important;
    width: 13 !important;
    height: 7 !important;
    transition: all .2s ease;
    right: 2px !important;
    top: calc(50% - 12px) !important;
    transform: rotate(-90deg) !important;
    opacity: 0.35 !important;
    transform-origin: center; }

@media screen and (max-width: 37.5em) {
  .accordion {
    padding-left: 0;
    padding-right: 0; } }

html, body {
  font-family: "Gotham", sans-serif;
  font-weight: 400;
  color: var(--primary); }

h1, h2, h3, h4 {
  font-family: "Didonesque", sans-serif;
  line-height: 1;
  font-weight: 400; }

h1 {
  font-size: 48px; }
  @media screen and (max-width: 48em) {
    h1 {
      font-size: 40px; } }
  @media screen and (max-width: 37.5em) {
    h1 {
      font-size: 30px; } }

h2 {
  font-size: 32px; }
  @media screen and (max-width: 48em) {
    h2 {
      font-size: 28px; } }
  @media screen and (max-width: 37.5em) {
    h2 {
      font-size: 22px; } }

h3 {
  font-size: 24px; }
  @media screen and (max-width: 48em) {
    h3 {
      font-size: 22px; } }
  @media screen and (max-width: 37.5em) {
    h3 {
      font-size: 18px; } }

p strong, p a {
  font-weight: 500;
  color: #293241; }

p a:hover {
  color: #293241; }

.triple-grid {
  display: grid;
  grid-template-columns: repeat(3, 400px);
  grid-column-gap: 85px;
  grid-row-gap: 70px;
  justify-content: center; }
  @media screen and (max-width: 93.75em) {
    .triple-grid {
      grid-column-gap: 40px;
      grid-row-gap: 35px; } }
  @media screen and (max-width: 87.5em) {
    .triple-grid {
      grid-template-columns: repeat(2, 500px); } }
  @media screen and (max-width: 68.75em) {
    .triple-grid {
      grid-template-columns: repeat(2, 350px); } }
  @media screen and (max-width: 56.25em) {
    .triple-grid {
      grid-template-columns: 460px; } }
  @media screen and (max-width: 37.5em) {
    .triple-grid {
      grid-template-columns: 100%; } }

.Main {
  margin-bottom: 100px; }
  @media screen and (max-width: 48em) {
    .Main {
      margin-bottom: 0; } }

.u-hidden {
  display: none !important; }

.quote {
  display: flex;
  align-items: center;
  height: fit-content;
  max-width: 670px;
  border-left: 1px solid var(--brandingColor);
  padding-left: 30px;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 40px;
  padding-bottom: 40px; }
  .quote > div {
    font-size: 40px;
    font-family: "Didonesque", sans-serif;
    line-height: 1; }
  .quote span {
    margin-top: 20px;
    text-transform: uppercase;
    color: var(--brandingColor); }
  @media screen and (max-width: 48em) {
    .quote {
      padding-top: 20px;
      padding-bottom: 20px; }
      .quote > div {
        font-size: 28px; }
      .quote span {
        margin-top: 10px;
        font-size: 14px; } }

.mobile-book {
  display: none; }
  @media screen and (max-width: 68.75em) {
    .mobile-book {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 20px;
      background-color: var(--red);
      text-decoration: none;
      z-index: 2;
      transition: opacity .2s ease; }
      .mobile-book:hover {
        opacity: .8; }
      .mobile-book span {
        font-size: 12px;
        color: white;
        font-family: "Gotham", sans-serif;
        letter-spacing: 2px;
        text-transform: uppercase;
        font-weight: 500; } }

.Header {
  display: flex;
  justify-content: space-between;
  padding: 15px 140px 15px;
  background-color: white;
  height: 120px !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: transform .2s ease;
  border-bottom: 1px solid var(--primaryShade); }
  .Header.hidden {
    transform: translateY(-120px); }
  .Header ul {
    display: flex;
    margin-bottom: 0;
    padding-left: 0; }
    .Header ul li {
      list-style: none;
      margin-bottom: 0; }
  .Header a {
    text-transform: lowercase;
    color: var(--primary);
    text-decoration: none; }
    .Header a.basket {
      margin-left: 10px;
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center; }
      .Header a.basket svg {
        width: 25px;
        height: 25px; }
        .Header a.basket svg path {
          fill: var(--primary);
          transition: fill .2s ease; }
      .Header a.basket:hover svg path {
        fill: var(--brandingColor); }
      @media screen and (max-width: 68.75em) {
        .Header a.basket {
          margin-left: 0; } }
  .Header .hamburger-checkbox {
    display: none; }
    @media screen and (max-width: 68.75em) {
      .Header .hamburger-checkbox:checked ~ .Header-nav {
        transform: translateY(130px);
        height: auto; }
      .Header .hamburger-checkbox:checked ~ .hamburger-icon span:nth-of-type(2) {
        width: 0%; }
      .Header .hamburger-checkbox:checked ~ .hamburger-icon span:first-of-type, .Header .hamburger-checkbox:checked ~ .hamburger-icon span:last-of-type {
        width: 100%; }
      .Header .hamburger-checkbox:checked ~ .hamburger-icon span:first-of-type {
        transform: rotate(45deg) translateY(7px) translateX(8px); }
      .Header .hamburger-checkbox:checked ~ .hamburger-icon span:last-of-type {
        transform: rotate(-45deg) translateY(-6px) translateX(6px); } }
  .Header .hamburger-icon {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    width: 35px;
    height: 20px; }
    .Header .hamburger-icon span {
      width: 100%;
      height: 1px;
      background-color: var(--primaryDark);
      transition: transform .2s ease, opacity .2s ease, width .2s ease; }
      .Header .hamburger-icon span:nth-of-type(2) {
        transform: translateY(-0.5px); }
    @media screen and (min-width: 68.75em) {
      .Header .hamburger-icon {
        display: none; } }
    .Header .hamburger-icon:hover span:last-of-type {
      width: 70%; }
  .Header-logo {
    display: flex;
    align-items: center;
    max-width: 180px;
    width: 100%; }
    .Header-logo img {
      max-height: 80px;
      height: 100%;
      object-fit: contain; }
    @media screen and (max-width: 68.75em) {
      .Header-logo {
        max-width: 140px; } }
  .Header-nav {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    transition: height .2s ease; }
    .Header-nav--top .pages li.active a, .Header-nav--base li.active a {
      border-bottom: 1px solid var(--primary);
      font-weight: 600; }
    .Header-nav--top .pages li a, .Header-nav--base li a {
      padding: 4px 0;
      border-bottom: 1px solid rgba(var(--primary), 0);
      transition: border-color .2s ease;
      position: relative; }
      .Header-nav--top .pages li a:before, .Header-nav--base li a:before {
        content: '';
        width: 100%;
        height: 1px;
        background-color: var(--primary);
        position: absolute;
        bottom: 0;
        left: 0;
        transform: scaleX(0);
        transition: transform .2s ease, background-color .2s ease;
        transform-origin: center; }
      .Header-nav--top .pages li a:hover:before, .Header-nav--base li a:hover:before {
        transform: scaleX(1); }
    .Header-nav--top {
      display: flex;
      align-items: center;
      margin-top: 10px;
      margin-bottom: 30px; }
      .Header-nav--top .socials, .Header-nav--top .pages {
        padding: 0 15px; }
        .Header-nav--top .socials ul li + li, .Header-nav--top .pages ul li + li {
          margin-left: 15px; }
        .Header-nav--top .socials ul li svg path, .Header-nav--top .pages ul li svg path {
          transition: fill .2s ease; }
        .Header-nav--top .socials ul li:hover svg path, .Header-nav--top .pages ul li:hover svg path {
          fill: var(--brandingColor); }
      .Header-nav--top a {
        font-size: 14px;
        line-height: 1; }
      .Header-nav--top .pages {
        border-left: 1px solid #6A6A6E;
        border-right: 1px solid #6A6A6E; }
      .Header-nav--top .phone {
        display: flex;
        align-items: center;
        padding-left: 15px; }
        .Header-nav--top .phone span {
          margin-left: 5px; }
        .Header-nav--top .phone figure {
          width: 32px;
          height: 32px;
          border: 1px solid var(--primary);
          padding: 7px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          background-color: white;
          transition: background-color .2s ease; }
          .Header-nav--top .phone figure svg path {
            fill: var(--primary);
            stroke: var(--primary);
            transition: fill .2s ease, stroke .2s ease; }
        .Header-nav--top .phone:hover figure {
          background-color: var(--primary); }
          .Header-nav--top .phone:hover figure svg path {
            fill: white;
            stroke: white; }
      .Header-nav--top .book {
        padding-left: 25px; }
        .Header-nav--top .book a {
          font-size: 12px; }
    .Header-nav--base {
      margin-bottom: 10px; }
      .Header-nav--base ul li a {
        font-weight: 500;
        text-transform: unset; }
      .Header-nav--base ul li + li {
        margin-left: 30px; }
    @media screen and (max-width: 68.75em) {
      .Header-nav {
        width: 100%;
        left: 0;
        top: 0;
        align-items: flex-start;
        justify-content: flex-start;
        background-color: white;
        border-top: 1px solid var(--primaryDark);
        padding: 30px 50px 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        pointer-events: all !important;
        flex-direction: column-reverse; }
        .Header-nav--base nav ul {
          flex-direction: column;
          align-items: center; }
          .Header-nav--base nav ul li {
            margin-left: 0 !important; }
            .Header-nav--base nav ul li:not(:last-of-type) {
              margin-bottom: 20px; }
        .Header-nav--top {
          flex-direction: column; }
          .Header-nav--top > *:not(:last-child) {
            margin-bottom: 20px; }
          .Header-nav--top .pages {
            border-left: none;
            border-right: none; }
            .Header-nav--top .pages ul {
              flex-direction: column; }
              .Header-nav--top .pages ul li {
                margin-left: 0 !important;
                text-align: center; }
          .Header-nav--top .book, .Header-nav--top .phone {
            padding-left: 0; } }
  @media screen and (max-width: 84.375em) {
    .Header {
      padding: 20px 40px; } }
  @media screen and (max-width: 93.75em) {
    .Header {
      padding: 20px 30px; } }
  @media screen and (max-width: 68.75em) {
    .Header {
      height: 130px !important;
      align-items: center; }
      .Header-nav {
        position: absolute;
        pointer-events: none;
        transform: translateY(-100vh);
        transition: transform .2s ease, opacity .2s ease; } }
  @media screen and (max-width: 25em) {
    .Header {
      height: 90px !important;
      padding: 20px; }
      .Header-logo {
        max-width: 100px; }
      .Header-nav--base {
        margin-bottom: 0px; }
        .Header-nav--base nav ul li:not(:last-of-type) {
          margin-bottom: 5px; }
      .Header .hamburger-checkbox:checked ~ .Header-nav {
        transform: translateY(90px); } }

.Footer {
  margin-top: 80px; }
  .Footer * {
    color: white; }
  .Footer.mobile-extra-space-bottom .Footer-allcontent {
    padding-bottom: 70px; }
  .Footer-allcontent {
    background-color: var(--primaryDark);
    display: flex;
    flex-direction: column;
    z-index: 1;
    position: relative; }
    .Footer-allcontent .bottom {
      padding: 30px 0 20px;
      border-top: 1px solid #6A6A6E;
      display: flex;
      justify-content: space-between; }
      .Footer-allcontent .bottom .privacy {
        padding-left: 0;
        margin-bottom: 0;
        display: flex;
        margin-right: 40px;
        flex-wrap: wrap; }
        .Footer-allcontent .bottom .privacy li {
          margin-bottom: 10px;
          list-style: none;
          margin-right: 60px; }
      .Footer-allcontent .bottom a,
      .Footer-allcontent .bottom button {
        color: #6A6A6E;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 14px; }
        .Footer-allcontent .bottom a span,
        .Footer-allcontent .bottom button span {
          transition: color .2s ease;
          color: inherit;
          font-size: inherit; }
        .Footer-allcontent .bottom a:hover,
        .Footer-allcontent .bottom button:hover {
          cursor: pointer; }
          .Footer-allcontent .bottom a:hover span,
          .Footer-allcontent .bottom button:hover span {
            color: white; }
    @media screen and (max-width: 37.5em) {
      .Footer-allcontent .bottom {
        flex-direction: column;
        justify-content: flex-start; } }
  .Footer-content {
    display: grid;
    grid-template-columns: auto auto 500px auto;
    grid-template-areas: 'first second third fourth';
    grid-column-gap: 70px;
    padding: 100px 0 70px; }
    .Footer-content ul {
      padding-left: 0;
      margin-bottom: 0; }
      .Footer-content ul li {
        margin-left: 0;
        list-style: none; }
        .Footer-content ul li.active {
          padding-bottom: 3px; }
          .Footer-content ul li.active a {
            border-color: white !important; }
        .Footer-content ul li a {
          padding-bottom: 3px; }
    .Footer-content .first {
      grid-area: first;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      .Footer-content .first > p {
        margin-bottom: 5px; }
        .Footer-content .first > p.name {
          text-transform: uppercase; }
        .Footer-content .first > p a {
          color: white;
          border-bottom: 1px solid rgba(255, 255, 255, 0);
          transition: border-color .2s ease;
          text-decoration: none;
          position: relative; }
          .Footer-content .first > p a.active, .Footer-content .first > p a:hover, .Footer-content .first > p a:focus {
            color: white; }
          .Footer-content .first > p a.active {
            border-color: white; }
          .Footer-content .first > p a:before {
            content: '';
            width: 100%;
            height: 1px;
            background-color: white;
            position: absolute;
            bottom: 0;
            left: 0;
            transform: scaleX(0);
            transition: transform .2s ease, background-color .2s ease;
            transform-origin: center; }
          .Footer-content .first > p a:hover:before {
            transform: scaleX(1); }
      .Footer-content .first .socials {
        display: flex;
        margin-top: 20px; }
        .Footer-content .first .socials li a img {
          filter: brightness(0) invert(1); }
        .Footer-content .first .socials li:not(:last-child) {
          margin-right: 15px; }
    .Footer-content .second {
      grid-area: second; }
      .Footer-content .second .sitemap ul li a {
        color: white;
        border-bottom: 1px solid rgba(255, 255, 255, 0);
        transition: border-color .2s ease;
        text-decoration: none;
        position: relative; }
        .Footer-content .second .sitemap ul li a.active, .Footer-content .second .sitemap ul li a:hover, .Footer-content .second .sitemap ul li a:focus {
          color: white; }
        .Footer-content .second .sitemap ul li a.active {
          border-color: white; }
        .Footer-content .second .sitemap ul li a:before {
          content: '';
          width: 100%;
          height: 1px;
          background-color: white;
          position: absolute;
          bottom: 0;
          left: 0;
          transform: scaleX(0);
          transition: transform .2s ease, background-color .2s ease;
          transform-origin: center; }
        .Footer-content .second .sitemap ul li a:hover:before {
          transform: scaleX(1); }
    .Footer-content .third {
      grid-area: third;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      .Footer-content .third .ctas {
        display: flex;
        align-items: center; }
        .Footer-content .third .ctas .book {
          margin-right: 45px; }
          .Footer-content .third .ctas .book a {
            font-size: 12px; }
        .Footer-content .third .ctas .phone {
          display: flex;
          align-items: center;
          text-decoration: none; }
          .Footer-content .third .ctas .phone span {
            margin-left: 5px; }
          .Footer-content .third .ctas .phone figure {
            width: 32px;
            height: 32px;
            border: 1px solid white;
            padding: 7px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            background-color: var(--primaryDark);
            transition: background-color .2s ease; }
            .Footer-content .third .ctas .phone figure svg path {
              fill: white;
              stroke: white;
              transition: fill .2s ease, stroke .2s ease; }
          .Footer-content .third .ctas .phone:hover figure {
            background-color: white; }
            .Footer-content .third .ctas .phone:hover figure svg path {
              fill: var(--primaryDark);
              stroke: var(--primaryDark); }
      .Footer-content .third .newsletter p {
        margin-bottom: 10px; }
      .Footer-content .third .newsletter form {
        display: flex; }
    .Footer-content .fourth {
      grid-area: fourth;
      display: flex;
      justify-content: flex-end; }
      .Footer-content .fourth .logos {
        display: flex;
        flex-direction: column;
        align-items: flex-end; }
        .Footer-content .fourth .logos a svg {
          pointer-events: none; }
        .Footer-content .fourth .logos .rooms {
          display: flex;
          justify-content: flex-end;
          flex-wrap: wrap;
          align-items: center; }
          .Footer-content .fourth .logos .rooms .room {
            height: 50px;
            display: flex;
            justify-content: flex-end;
            margin-bottom: 10px;
            margin-left: 20px; }
            .Footer-content .fourth .logos .rooms .room svg {
              height: 100%;
              width: auto; }
        @media screen and (max-width: 37.5em) {
          .Footer-content .fourth .logos {
            align-items: flex-start; }
            .Footer-content .fourth .logos .rooms {
              flex-direction: column;
              align-items: flex-start;
              margin-bottom: 10px; }
              .Footer-content .fourth .logos .rooms .room {
                margin-left: 0;
                margin-bottom: 20px; }
                .Footer-content .fourth .logos .rooms .room:last-of-type {
                  margin-bottom: 0; } }
        .Footer-content .fourth .logos svg {
          max-width: 280px; }
    @media screen and (max-width: 93.75em) {
      .Footer-content {
        grid-template-columns: auto auto 400px auto; } }
    @media screen and (max-width: 75em) {
      .Footer-content {
        grid-template-columns: 200px 200px auto;
        grid-template-areas: 'first second fourth' 'third third .';
        grid-gap: 70px; }
        .Footer-content .third .ctas {
          margin-bottom: 30px; } }
    @media screen and (max-width: 56.25em) {
      .Footer-content {
        grid-template-columns: auto auto;
        grid-template-areas: 'first second' 'third third' 'fourth .';
        grid-gap: 50px; }
        .Footer-content .fourth {
          justify-content: flex-start; }
          .Footer-content .fourth svg {
            max-width: 200px;
            width: 100%; } }
  @media screen and (max-width: 37.5em) {
    .Footer-allcontent .container {
      padding: 0; }
    .Footer-content {
      padding: 50px 0;
      grid-template-columns: auto;
      grid-template-areas: 'first' 'second' 'third' 'fourth'; }
    .Footer .third .ctas {
      flex-direction: column;
      align-items: flex-start; }
      .Footer .third .ctas .book {
        margin-bottom: 20px; } }
  @media screen and (max-width: 31.25em) {
    .Footer .third .newsletter form {
      flex-direction: column;
      align-items: flex-start; } }

.cta {
  position: relative;
  color: white !important;
  text-transform: uppercase !important;
  border: 1px solid var(--brandingColor);
  transition: border-color .2s ease,  color .2s ease;
  padding: 10px 15px !important;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 12px;
  text-decoration: none;
  font-family: "Gotham", sans-serif;
  text-align: center;
  display: inline-block; }
  .cta.no-cta {
    pointer-events: none; }
  .cta.not-clicktrough {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 30px !important;
    height: 50px;
    color: var(--primaryDark) !important;
    border-color: var(--brandingShade) !important;
    letter-spacing: 2px;
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer; }
    .cta.not-clicktrough:after {
      background-color: var(--brandingShade); }
    .cta.not-clicktrough:hover {
      border-color: var(--brandingColor) !important; }
    .cta.not-clicktrough:active {
      outline: none; }
  .cta:after {
    content: '';
    z-index: -1;
    transition: opacity .2s ease;
    background-color: var(--brandingColor);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1; }
  .cta:not(.fake-filled):not(.not-filled).fake, .cta:not(.fake-filled):not(.not-filled).simple {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 10px !important;
    color: var(--brandingColor) !important;
    border: none;
    font-size: 12px;
    position: relative; }
    .cta:not(.fake-filled):not(.not-filled).fake:before, .cta:not(.fake-filled):not(.not-filled).simple:before {
      content: '';
      width: 100%;
      height: 1px;
      background-color: var(--brandingColor);
      position: absolute;
      bottom: 0;
      left: 0;
      transition: transform .2s ease, background-color .2s ease;
      transform-origin: center; }
    .cta:not(.fake-filled):not(.not-filled).fake:hover:before, .cta:not(.fake-filled):not(.not-filled).simple:hover:before {
      transform: scaleX(0); }
    .cta:not(.fake-filled):not(.not-filled).fake:after, .cta:not(.fake-filled):not(.not-filled).simple:after {
      opacity: 0; }
  .cta.not-filled {
    padding: 20px 25px !important;
    color: var(--primaryDark) !important;
    border-color: var(--primaryDark) !important; }
    .cta.not-filled:after {
      opacity: 0; }
    .cta.not-filled:hover {
      color: white !important;
      border-color: var(--brandingColor) !important; }
      .cta.not-filled:hover:after {
        opacity: 1; }
  .cta.glowing span {
    z-index: 1;
    position: relative; }
  .cta.glowing:after {
    background: #bf8239;
    background: linear-gradient(118deg, #bf8239 0%, #e5b066 23%, #de9f48 40%, #dfa85f 67%, #b87f3b 100%);
    z-index: 0; }
  .cta:hover {
    color: var(--brandingColor) !important; }
    .cta:hover:after {
      opacity: 0; }
  .cta.filled, .cta.fake-filled {
    position: relative;
    color: white !important;
    text-transform: uppercase !important;
    border: 1px solid var(--brandingColor);
    transition: border-color .2s ease,  color .2s ease;
    padding: 10px 0 !important;
    padding-right: 15px !important;
    padding-left: 15px !important;
    letter-spacing: 1px;
    font-weight: 500;
    font-size: 12px;
    text-decoration: none;
    font-family: "Gotham", sans-serif;
    text-align: center;
    display: inline-block; }
    .cta.filled:after, .cta.fake-filled:after {
      content: '';
      z-index: -1;
      transition: opacity .2s ease;
      background-color: var(--brandingColor);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 1; }
    .cta.filled.dark, .cta.fake-filled.dark {
      border-color: var(--primary); }
      .cta.filled.dark:after, .cta.fake-filled.dark:after {
        background-color: var(--primary); }
    .cta.filled.red, .cta.fake-filled.red {
      border-color: var(--red); }
      .cta.filled.red:after, .cta.fake-filled.red:after {
        background-color: var(--red); }

.custom-select {
  position: relative; }

.custom-select select {
  display: none;
  /*hide original SELECT element:*/ }

.select-selected {
  background-color: var(--brandingShade); }
  .select-selected:after {
    content: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='7' viewBox='0 0 13 7'%3E%3Cpath id='Path_238' data-name='Path 238' d='M6.475,0h0L0-6.225.806-7,6.478-1.547,12.194-7,13-6.225,6.475,0Z' transform='translate(0 7)' fill='%23041E42'/%3E%3C/svg%3E");
    position: absolute;
    top: 15px;
    right: 1.5rem;
    transition: transform .3s ease; }
  .select-selected.select-arrow-active:after {
    transform: rotate(180deg); }

.select-items div {
  transition: background-color .4s ease; }

/*style the items (options), including the selected item:*/
.select-items div, .select-selected {
  color: var(--primary);
  padding: 0 1.5rem;
  height: 50px;
  border: 1px solid var(--primary);
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  font-size: 12px;
  letter-spacing: 1px; }

/*style items (options):*/
.select-items {
  position: absolute;
  background-color: white;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99; }
  .select-items > div {
    border-top: none; }
    .select-items > div.same-as-selected {
      background-color: var(--primary);
      color: white; }
    .select-items > div:not(.same-as-selected) {
      opacity: .7;
      background-color: var(--brandingShade); }

/*hide the items when the select box is closed:*/
.select-hide {
  display: none; }

.select-items div:hover, .same-as-selected {
  background-color: var(--primaryShade); }

form .hide {
  display: none; }

form input {
  border: 1px solid var(--primary);
  background-color: var(--primary);
  font-size: 12px;
  font-family: "Gotham", sans-serif;
  color: white !important;
  appearance: none;
  resize: none;
  padding: 15px 10px;
  width: 100%; }
  form input::placeholder {
    color: white; }

form button {
  font-family: "Gotham", sans-serif !important;
  font-size: 12px;
  font-weight: 500;
  margin-top: 0;
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 15px !important;
  border-color: #B9B9B9;
  cursor: pointer; }
  form button:after {
    opacity: 0; }
  form button:hover {
    color: var(--brandingColor);
    border-color: var(--brandingColor); }

.breadcrumbs {
  padding: 10px 0; }
  .breadcrumbs-container {
    width: 100%;
    border-bottom: 1px solid var(--primary); }
  .breadcrumbs ul {
    margin-bottom: 0;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap; }
    .breadcrumbs ul li {
      list-style: none;
      margin-bottom: 0;
      padding-left: 0;
      font-size: 14px;
      font-family: "Gotham", sans-serif;
      text-transform: lowercase; }
      .breadcrumbs ul li * {
        font-size: 14px;
        font-family: "Gotham", sans-serif;
        text-transform: lowercase; }
      .breadcrumbs ul li a {
        text-decoration: none; }
        .breadcrumbs ul li a > .title {
          transition: color .2s ease; }
          .breadcrumbs ul li a > .title:hover {
            color: white !important; }
        .breadcrumbs ul li a > .arrow {
          width: 4px;
          height: 6.7px; }
        .breadcrumbs ul li a > * {
          color: #6A6A6E;
          margin-right: 5px; }

.HomePage .full-page-header h1 {
  margin-bottom: 50px; }

.HomePage .full-page-header .cta {
  color: white !important;
  border-color: white !important;
  pointer-events: all;
  transition: background-color .2s ease, border-color .2s ease; }
  .HomePage .full-page-header .cta span {
    transition: color .2s ease; }
  .HomePage .full-page-header .cta:hover {
    color: var(--primaryDark) !important;
    background-color: white !important; }
    .HomePage .full-page-header .cta:hover span {
      color: var(--primaryDark) !important; }

.HomePage .full-page-header-whiteblock--content {
  background-color: white; }

.HomePage .js-carousel-shows .slick-slide .full-page-header--background {
  max-height: 940px;
  overflow: hidden; }

@media screen and (max-width: 37.5em) {
  .HomePage .js-carousel-shows .slick-arrow {
    left: 30px; }
    .HomePage .js-carousel-shows .slick-arrow:last-of-type {
      left: 50px; } }

.full-page-header {
  padding-top: 240px;
  position: relative;
  padding-bottom: 240px; }
  .full-page-header.has-video {
    padding-top: 350px;
    padding-bottom: 350px; }
    .full-page-header.has-video video {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .full-page-header--container {
    margin-bottom: 120px;
    margin-top: 120px; }
    @media screen and (max-width: 68.75em) {
      .full-page-header--container {
        margin-top: 130px; } }
    .full-page-header--container.has-map {
      pointer-events: none; }
      .full-page-header--container.has-map .full-page-header--background, .full-page-header--container.has-map .full-page-header-whiteblock--content {
        pointer-events: all; }
    @media screen and (max-width: 37.5em) {
      .full-page-header--container {
        margin-bottom: 80px; } }
  .full-page-header.has-block {
    padding-bottom: 400px;
    pointer-events: none; }
  .full-page-header-whiteblock {
    margin-top: -250px;
    margin-left: 140px !important; }
    .full-page-header-whiteblock .parameters {
      display: flex;
      flex-direction: column;
      padding-left: 0;
      margin-bottom: 0; }
      .full-page-header-whiteblock .parameters li {
        list-style: none;
        display: flex;
        margin-bottom: 0; }
        .full-page-header-whiteblock .parameters li figure {
          width: 80px;
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid var(--primaryDark);
          border-radius: 100%; }
          .full-page-header-whiteblock .parameters li figure img {
            width: 40px;
            height: 40px; }
        .full-page-header-whiteblock .parameters li .text {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 30px; }
          .full-page-header-whiteblock .parameters li .text > p {
            font-weight: 600;
            text-transform: uppercase; }
          .full-page-header-whiteblock .parameters li .text p {
            margin-bottom: 5px; }
          .full-page-header-whiteblock .parameters li .text a {
            position: relative;
            cursor: pointer;
            color: var(--primaryDark);
            text-decoration: none;
            padding-bottom: 2px; }
            .full-page-header-whiteblock .parameters li .text a:before {
              content: '';
              width: 100%;
              height: 1px;
              background-color: #293241;
              position: absolute;
              bottom: 0;
              left: 0;
              transition: transform .2s ease, background-color .2s ease;
              transform-origin: center; }
            .full-page-header-whiteblock .parameters li .text a:hover:before {
              transform: scaleX(0); }
        .full-page-header-whiteblock .parameters li:not(:last-of-type) {
          margin-bottom: 50px; }
    .full-page-header-whiteblock .arrangement-options {
      padding-left: 0;
      margin-bottom: 50px;
      width: 100%;
      display: flex;
      flex-direction: column; }
      .full-page-header-whiteblock .arrangement-options li {
        list-style: none;
        width: 100%;
        font-weight: 500;
        border-bottom: 1px solid var(--brandingColor);
        display: flex;
        justify-content: space-between; }
        .full-page-header-whiteblock .arrangement-options li a {
          color: var(--brandingColor);
          text-decoration: none;
          font-size: 16px;
          font-weight: 400;
          text-transform: uppercase;
          display: flex;
          justify-content: space-between;
          width: 100%; }
          .full-page-header-whiteblock .arrangement-options li a span {
            padding-bottom: 2px;
            color: #293241;
            font-weight: 500;
            font-size: 18px; }
        .full-page-header-whiteblock .arrangement-options li:not(:first-of-type) {
          margin-top: 20px; }
        @media screen and (max-width: 31.25em) {
          .full-page-header-whiteblock .arrangement-options li {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start; } }
    .full-page-header-whiteblock .custom-cta {
      z-index: 1;
      position: relative; }
      .full-page-header-whiteblock .custom-cta .cta {
        padding: 20px 25px !important;
        color: var(--brandingColor) !important; }
        .full-page-header-whiteblock .custom-cta .cta:after {
          z-index: 0;
          opacity: 0; }
        .full-page-header-whiteblock .custom-cta .cta:hover {
          color: white !important; }
          .full-page-header-whiteblock .custom-cta .cta:hover:after {
            opacity: 1;
            z-index: -1; }
    .full-page-header-whiteblock--content {
      background-color: var(--whiteShade);
      padding: 80px 140px !important;
      max-width: 660px; }
    @media screen and (min-width: 131.25em) {
      .full-page-header-whiteblock {
        max-width: 800px;
        margin-left: auto !important;
        margin-right: auto !important;
        transform: translateX(-140px); } }
  .full-page-header * {
    color: white; }
  .full-page-header--content-title {
    max-width: 670px; }
  .full-page-header--content-text {
    margin-top: 20px; }
    .full-page-header--content-text p {
      margin-bottom: 0; }
  .full-page-header--background {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    z-index: -1; }
    .full-page-header--background > * {
      height: 100%;
      width: 100%; }
    .full-page-header--background .video-bg {
      background-color: #000310;
      position: absolute;
      z-index: -3; }
    .full-page-header--background .gradient {
      width: 100%;
      max-width: 80vw;
      background: #000310;
      background: linear-gradient(90deg, #000310 0vw, #000310 30vw, rgba(0, 3, 16, 0) 100%);
      position: absolute;
      z-index: 1;
      pointer-events: none; }
      @media screen and (min-width: 131.25em) {
        .full-page-header--background .gradient:not(.image-full-visible-desktop) {
          max-width: unset; } }
      @media screen and (max-width: 48em) {
        .full-page-header--background .gradient {
          background: linear-gradient(90deg, #000310 0vw, #000310 10vw, rgba(0, 3, 16, 0) 100%); } }
    .full-page-header--background .content {
      width: 70%;
      margin-left: auto;
      height: 100%;
      background-color: var(--primaryDark);
      z-index: -2;
      position: relative; }
      .full-page-header--background .content.video {
        width: auto;
        background-color: #000310; }
        .full-page-header--background .content.video:before {
          content: '';
          position: absolute;
          top: 0%;
          left: 0%;
          width: 100%;
          height: 100%;
          background: linear-gradient(90deg, #000310 0, #000310 140px, rgba(0, 3, 16, 0) 200px); }
        .full-page-header--background .content.video video {
          object-fit: contain;
          object-position: right; }
        @media screen and (max-width: 37.5em) {
          .full-page-header--background .content.video:before {
            background: unset; }
          .full-page-header--background .content.video video {
            object-fit: cover;
            object-position: center; } }
      .full-page-header--background .content.image figure {
        position: absolute;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%; }
        .full-page-header--background .content.image figure img {
          height: 100%;
          object-fit: cover;
          object-position: right;
          width: 70vw;
          margin-left: auto; }
          @media screen and (max-width: 131.25em) {
            .full-page-header--background .content.image figure img {
              width: 100%; } }
          @media screen and (max-width: 37.5em) {
            .full-page-header--background .content.image figure img {
              object-position: center; } }
      .full-page-header--background .content.map {
        pointer-events: all; }
        .full-page-header--background .content.map .Maps {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0; }
          @media screen and (max-width: 48em) {
            .full-page-header--background .content.map .Maps {
              width: 160%; }
              .full-page-header--background .content.map .Maps > div {
                margin-left: -50%; } }
          .full-page-header--background .content.map .Maps-container {
            width: 100%;
            height: 100%;
            overflow: hidden; }
      @media screen and (max-width: 48em) {
        .full-page-header--background .content {
          width: 90%; } }
  @media screen and (max-width: 68.75em) {
    .full-page-header.has-block {
      padding-top: calc(180px + 90px);
      padding-bottom: 350px; }
    .full-page-header.without-block {
      padding-top: calc(180px + 80px);
      padding-bottom: 140px; }
    .full-page-header-whiteblock {
      margin-top: -290px;
      margin-left: 30px !important;
      margin-right: 30px; }
      .full-page-header-whiteblock--content {
        padding: 50px !important; }
        .full-page-header-whiteblock--content .arrangement-options {
          margin-bottom: 40px; }
          .full-page-header-whiteblock--content .arrangement-options li a {
            font-size: 14px;
            display: flex;
            align-items: flex-end; }
            .full-page-header-whiteblock--content .arrangement-options li a span {
              font-size: 16px; }
    .full-page-header--background {
      max-height: 550px !important; } }
  @media screen and (max-width: 50em) {
    .full-page-header-whiteblock .parameters li:not(:last-of-type) {
      margin-bottom: 30px; }
    .full-page-header-whiteblock .parameters li figure {
      min-width: 60px;
      max-width: 60px;
      min-height: 60px;
      max-height: 60px; }
      .full-page-header-whiteblock .parameters li figure img {
        width: 30px;
        height: 30px; } }
  @media screen and (max-width: 37.5em) {
    .full-page-header-whiteblock--content {
      padding: 30px !important; }
    .full-page-header-whiteblock .parameters li:not(:last-of-type) {
      margin-bottom: 20px; } }
  @media screen and (max-width: 21.875em) {
    .full-page-header-whiteblock--content .arrangement-options li a {
      flex-direction: column;
      align-items: flex-start; } }

@media screen and (max-width: 68.75em) {
  .header .slick-arrow {
    top: 220px !important;
    left: 50px;
    right: unset !important; }
    .header .slick-arrow:nth-of-type(2) {
      left: 70px; } }

.page-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 240px;
  padding-left: 20px;
  padding-right: 20px; }
  .page-header * {
    text-align: center; }
  .page-header.not-centered {
    align-items: flex-start; }
    .page-header.not-centered * {
      text-align: left; }
  .page-header--text {
    margin-top: 40px;
    max-width: 820px; }
    .page-header--text p {
      margin-bottom: 0; }
  @media screen and (max-width: 68.75em) {
    .page-header {
      padding-top: 240px; } }
  @media screen and (max-width: 37.5em) {
    .page-header {
      padding-top: 180px; } }

.container {
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px; }
  .container--regular {
    margin-left: 260px;
    margin-right: 260px; }
  @media screen and (min-width: 131.25em) {
    .container--regular {
      max-width: 1450px;
      width: 100%;
      margin-left: auto;
      margin-right: auto; } }
  @media screen and (max-width: 118.75em) {
    .container--regular {
      margin-left: 160px;
      margin-right: 160px; } }
  @media screen and (min-width: 68.75em) and (max-width: 93.75em) {
    .container--regular {
      margin-left: 60px;
      margin-right: 60px; } }
  @media screen and (max-width: 68.75em) {
    .container--regular {
      margin-left: 30px;
      margin-right: 30px; } }
  @media screen and (max-width: 37.5em) {
    .container--regular {
      padding: 0; } }

.pagination {
  margin-top: 120px; }
  .pagination .pages {
    display: flex;
    justify-content: center;
    align-items: center; }
    .pagination .pages .progress {
      margin: 0 10px; }

.golden-ctas {
  padding: 0 140px;
  display: flex;
  justify-content: center; }
  .golden-ctas.small {
    flex-direction: column;
    padding: 0;
    margin-top: 320px !important; }
    .golden-ctas.small > span {
      padding: 50px 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      max-width: unset; }
      .golden-ctas.small > span .top {
        max-width: 340px; }
        .golden-ctas.small > span .top > h2 {
          border-bottom: none;
          margin-bottom: 0;
          padding-bottom: 0;
          margin-right: 40px;
          font-size: 28px;
          line-height: 34px; }
        .golden-ctas.small > span .top > div {
          display: none; }
      .golden-ctas.small > span .cta {
        width: 180px; }
        .golden-ctas.small > span .cta-container {
          display: flex;
          flex-direction: column; }
    .golden-ctas.small > span + span {
      margin-top: 2px; }
    @media screen and (max-width: 37.5em) {
      .golden-ctas.small > span {
        flex-direction: column;
        align-items: flex-start;
        padding: 30px 40px; }
        .golden-ctas.small > span .top > h2 {
          margin-right: 0px; }
        .golden-ctas.small > span .cta-container {
          margin-top: 20px; } }
    @media screen and (max-width: 25em) {
      .golden-ctas.small > span {
        padding: 20px 30px; }
        .golden-ctas.small > span .top h2 {
          font-size: 24px;
          line-height: 28px; }
      .golden-ctas.small .cta {
        font-size: 11px; }
        .golden-ctas.small .cta.not-filled {
          padding: 15px 20px !important; } }
  .golden-ctas.light > span {
    background-color: white; }
    .golden-ctas.light > span strong {
      transition: color .2s ease; }
    .golden-ctas.light > span:hover {
      background-color: var(--brandingColor); }
      .golden-ctas.light > span:hover strong {
        color: white !important; }
  .golden-ctas > span {
    width: 100%;
    text-decoration: none;
    background-color: var(--brandingShade);
    transition: background-color .2s ease;
    color: var(--primaryDark);
    padding: 85px 70px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start; }
    .golden-ctas > span:not(:last-of-type) {
      margin-right: 15px; }
    .golden-ctas > span > .top > h2 {
      border-bottom: 1px solid var(--brandingColor);
      padding-bottom: 30px;
      margin-bottom: 50px;
      transition: color .2s ease, border-color .2s ease; }
    .golden-ctas > span > .top > div {
      margin-bottom: 60px;
      transition: color .2s ease; }
    .golden-ctas > span .cta {
      pointer-events: all;
      transition: color .2s ease, border-color .2s ease;
      margin-right: 10px;
      margin-bottom: 10px; }
    .golden-ctas > span strong {
      transition: color .2s ease; }
    .golden-ctas > span:hover {
      background-color: var(--brandingColor); }
      .golden-ctas > span:hover > .top > h2 {
        color: white;
        border-color: white; }
      .golden-ctas > span:hover > .top > div {
        color: white; }
      .golden-ctas > span:hover strong {
        color: white !important; }
      .golden-ctas > span:hover .cta {
        color: white !important;
        border-color: white !important; }
  @media screen and (min-width: 131.25em) {
    .golden-ctas {
      max-width: 1760px;
      margin-left: auto;
      margin-right: auto; } }
  @media screen and (max-width: 100em) {
    .golden-ctas {
      padding: 0 30px; }
      .golden-ctas > span {
        padding: 55px 40px; }
        .golden-ctas > span > .top h2 {
          padding-bottom: 30px;
          margin-bottom: 30px; }
        .golden-ctas > span > .top > div {
          margin-bottom: 40px; } }
  @media screen and (max-width: 68.75em) {
    .golden-ctas {
      flex-direction: column; }
      .golden-ctas > span {
        margin-right: 0 !important; }
        .golden-ctas > span:not(:last-of-type) {
          margin-bottom: 20px; } }
  @media screen and (max-width: 37.5em) {
    .golden-ctas > span {
      padding: 35px 25px; }
      .golden-ctas > span > .top > div {
        margin-bottom: 0px; } }
  @media screen and (max-width: 31.25em) {
    .golden-ctas {
      padding-left: 0;
      padding-right: 0; }
      .golden-ctas > * {
        padding-left: 50px;
        padding-right: 50px; }
        .golden-ctas > * > div {
          width: 100%; }
          .golden-ctas > * > div .cta {
            margin-right: 0;
            width: 100%; } }

.slider {
  position: relative; }
  .slider.title-not-aligned .slider-item--image {
    margin-top: 165px; }
  .slider.title-not-aligned .slider-item--info {
    margin-top: 0; }
  .slider-item {
    display: grid;
    grid-template-columns: repeat(2, 50%); }
    .slider-item--image {
      width: 100%;
      height: 550px; }
      .slider-item--image img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .slider-item--info {
      padding: 80px 100px;
      margin-left: -40px;
      background-color: white;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 100%; }
      .slider-item--info h2 {
        width: 100%;
        border-bottom: 1px solid var(--brandingColor);
        padding-bottom: 20px;
        margin-bottom: 30px; }
      .slider-item--info .text {
        margin-bottom: 30px; }
      .slider-item--info .cta {
        z-index: 2; }
  .slider__counter {
    position: relative; }
    .slider__counter-container {
      position: absolute;
      left: 50%;
      bottom: 2px;
      margin-left: 60px;
      z-index: -1; }
    .slider__counter span {
      font-weight: 500; }
  @media screen and (max-width: 87.5em) {
    .slider.slick-slider {
      margin-left: 50px;
      margin-right: 50px; }
    .slider-item--image {
      margin-top: 140px !important; }
    .slider-item--info {
      padding: 60px; } }
  @media screen and (max-width: 60em) {
    .slider-item {
      grid-template-columns: 100%; }
      .slider-item--image {
        margin-top: 0px !important; }
      .slider-item--info {
        margin-left: 0; }
    .slider__counter-container {
      left: 0;
      margin-left: 130px;
      z-index: 1;
      position: relative;
      margin-top: 20px; } }
  @media screen and (max-width: 48em) {
    .slider.slick-slider {
      margin-left: 20px;
      margin-right: 20px; }
    .slider-item--info {
      padding: 30px 20px; }
      .slider-item--info h2 {
        margin-bottom: 20px; }
      .slider-item--info .text * {
        margin-bottom: 0; }
    .slider__counter-container {
      margin-left: 40px; } }
  @media screen and (max-width: 37.5em) {
    .slider-item--image {
      height: 280px !important; } }

.quote-block {
  display: flex;
  align-items: flex-start; }
  .quote-block .quote {
    background-color: white;
    padding: 40px 120px 40px 30px;
    margin-right: -80px;
    margin-top: 80px;
    z-index: 1; }
  .quote-block .image {
    max-width: 820px;
    max-height: 480px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    overflow: hidden; }
    .quote-block .image img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  @media screen and (max-width: 48em) {
    .quote-block {
      flex-direction: column-reverse; } }
  @media screen and (max-width: 37.5em) {
    .quote-block .quote {
      margin-top: 40px; } }

.Show-list {
  margin-top: 80px; }
  .Show-list > .show + .show {
    margin-top: 40px; }
  @media screen and (max-width: 37.5em) {
    .Show-list {
      margin-top: 100px;
      margin-bottom: 40px; } }
  .Show-list .show.Filter-tile > h2 {
    margin-bottom: 30px;
    text-align: center;
    margin-top: 120px; }

.Show-tile {
  display: grid;
  grid-template-columns: 340px auto;
  text-decoration: none;
  color: #293241; }
  .Show-tile:focus, .Show-tile:active {
    color: #293241; }
  .Show-tile.arrangement:hover .ctas .no-cta span {
    color: var(--brandingColor) !important; }
  .Show-tile.arrangement:hover .ctas .no-cta:after {
    background-color: rgba(var(--brandingColor), 0); }
  .Show-tile .categories > * {
    margin-left: 20px; }
  .Show-tile--image {
    width: 100%;
    overflow: hidden; }
    .Show-tile--image img {
      width: 100%;
      height: 100%;
      transition: transform .2s ease; }
    .Show-tile--image.resizable img {
      object-fit: cover; }
  .Show-tile:hover {
    color: #293241;
    cursor: pointer; }
    .Show-tile:hover .Show-tile--image img {
      transform: scale(1.05); }
    .Show-tile:hover .cta.fake:before {
      transform: scaleX(0); }
  .Show-tile--info {
    background-color: var(--brandingShade);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 100px 140px;
    position: relative; }
    .Show-tile--info .highlight {
      background-color: var(--primaryDark);
      color: white;
      font-size: 13px;
      letter-spacing: 1px;
      padding: 10px 15px;
      position: absolute;
      right: 0;
      top: 0; }
      .Show-tile--info .highlight img {
        height: 35px; }
    .Show-tile--info .subtitle {
      color: var(--brandingColor);
      font-weight: 400;
      text-transform: uppercase;
      font-size: 14px;
      margin-top: 10px; }
    .Show-tile--info .intro {
      max-width: 500px; }
      .Show-tile--info .intro p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        /* number of lines to show */
        line-clamp: 3;
        -webkit-box-orient: vertical; }
      .Show-tile--info .intro-list ul {
        padding-left: 0;
        margin-bottom: 0; }
        .Show-tile--info .intro-list ul li {
          list-style: none; }
          .Show-tile--info .intro-list ul li svg {
            width: 15px;
            height: auto; }
          .Show-tile--info .intro-list ul li span {
            margin-left: 10px; }
    .Show-tile--info .bottom {
      display: flex;
      align-items: flex-end;
      justify-content: space-between; }
      .Show-tile--info .bottom .ctas {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        margin-top: 30px; }
        .Show-tile--info .bottom .ctas > * {
          margin-top: 20px; }
        .Show-tile--info .bottom .ctas.full {
          width: 100%; }
          .Show-tile--info .bottom .ctas.full .cta {
            width: inherit;
            padding: 15px !important; }
        .Show-tile--info .bottom .ctas .cta {
          z-index: 1; }
          .Show-tile--info .bottom .ctas .cta:not(.simple) {
            transition: background-color .2s ease, border-color .2s ease; }
            .Show-tile--info .bottom .ctas .cta:not(.simple) span {
              color: white;
              transition: color .2s ease;
              z-index: 1; }
            .Show-tile--info .bottom .ctas .cta:not(.simple):after {
              transition: background-color .2s ease; }
            .Show-tile--info .bottom .ctas .cta:not(.simple):hover {
              border-color: var(--primaryDark) !important; }
              .Show-tile--info .bottom .ctas .cta:not(.simple):hover:after {
                opacity: 1 !important;
                background-color: var(--primaryDark) !important; }
          .Show-tile--info .bottom .ctas .cta:nth-of-type(2) {
            border-color: var(--primary); }
            .Show-tile--info .bottom .ctas .cta:nth-of-type(2):after {
              background-color: var(--primary); }
            .Show-tile--info .bottom .ctas .cta:nth-of-type(2):hover span {
              color: var(--primary); }
            .Show-tile--info .bottom .ctas .cta:nth-of-type(2):hover:after {
              background-color: var(--brandingShade) !important; }
          .Show-tile--info .bottom .ctas .cta:not(:last-of-type) {
            margin-right: 20px; }
      .Show-tile--info .bottom .price {
        margin-bottom: 0;
        font-size: 35px;
        color: var(--brandingColor);
        line-height: 1;
        font-family: "Didonesque", sans-serif; }
        .Show-tile--info .bottom .price .person, .Show-tile--info .bottom .price .small {
          font-family: "Gotham", sans-serif;
          font-size: 14px;
          font-weight: 600;
          text-transform: uppercase; }
  @media screen and (max-width: 93.75em) {
    .Show-tile--info {
      padding: 70px 80px; }
      .Show-tile--info .bottom {
        flex-direction: column;
        align-items: flex-start; }
        .Show-tile--info .bottom .categories {
          margin-top: 40px; }
          .Show-tile--info .bottom .categories > svg {
            margin-left: 0;
            margin-right: 20px; } }
  @media screen and (max-width: 62.5em) {
    .Show-tile--info .bottom .ctas {
      flex-direction: column;
      align-items: flex-start !important; }
      .Show-tile--info .bottom .ctas > .price {
        margin-top: 20px; } }
  @media screen and (max-width: 56.25em) {
    .Show-tile {
      grid-template-columns: auto; }
      .Show-tile--image {
        height: 100%; }
        .Show-tile--image img {
          object-fit: cover; }
      .Show-tile--info .bottom .ctas {
        flex-direction: column;
        align-items: flex-start; }
        .Show-tile--info .bottom .ctas > a:last-of-type {
          margin-top: 10px; }
      .Show-tile--info .bottom .price {
        font-size: 26px; } }
  @media screen and (max-width: 43.75em) {
    .Show-tile {
      max-width: 300px;
      margin-left: auto;
      margin-right: auto; } }
  @media screen and (max-width: 37.5em) {
    .Show-tile--info {
      padding: 60px 40px 40px; } }

.Show-tile + .Show-tile {
  margin-top: 40px; }

.ShowsOverviewPage .Show-tile .ctas, .ComedyPage .Show-tile .ctas {
  display: flex;
  align-items: center; }
  .ShowsOverviewPage .Show-tile .ctas .cta:last-of-type, .ComedyPage .Show-tile .ctas .cta:last-of-type {
    margin-right: 30px; }

.cta-block {
  background-color: var(--brandingShade);
  position: relative;
  display: flex;
  justify-content: center;
  z-index: -1; }
  .cta-block--content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    padding: 50px 360px; }
    .cta-block--content figure {
      position: absolute;
      max-width: 360px;
      right: 0;
      bottom: 0; }
    .cta-block--content h2 {
      margin-bottom: 20px;
      color: var(--primaryDark);
      text-align: center; }
    .cta-block--content * {
      color: var(--primary);
      text-align: center; }
    .cta-block--content .cta {
      color: var(--primaryDark) !important;
      transition: background-color .2s ease;
      margin-top: 20px; }
      .cta-block--content .cta * {
        color: var(--primaryDark) !important; }
      .cta-block--content .cta:hover {
        background-color: var(--primaryDark) !important;
        border-color: var(--primaryDark) !important;
        color: white !important; }
        .cta-block--content .cta:hover * {
          color: white !important; }
    @media screen and (max-width: 68.75em) {
      .cta-block--content {
        padding: 50px 160px; }
        .cta-block--content figure {
          max-width: 300px;
          right: -180px; } }
    @media screen and (max-width: 56.25em) {
      .cta-block--content {
        padding: 50px 160px; }
        .cta-block--content > *:not(.image) {
          z-index: 1; }
        .cta-block--content figure {
          max-width: 300px;
          right: -80px;
          z-index: 0; } }
    @media screen and (max-width: 48em) {
      .cta-block--content {
        padding: 50px 40px; }
        .cta-block--content figure {
          right: -140px; } }
  @media screen and (max-width: 37.5em) {
    .cta-block {
      overflow-x: hidden; } }

.timing {
  display: grid;
  grid-template-columns: repeat(2, 50%); }
  .timing-left {
    margin-top: 80px;
    padding-top: 80px;
    padding-right: 80px;
    background-color: white;
    z-index: 1; }
    .timing-left .head-title {
      max-width: 360px; }
    .timing-left .content {
      margin-top: 20px;
      padding-top: 30px;
      border-top: 1px solid var(--brandingColor); }
      .timing-left .content > *:not(:last-of-type) {
        margin-bottom: 100px; }
      .timing-left .content-timing--items {
        margin-bottom: 60px; }
      .timing-left .content-timing--item .title {
        font-weight: 600;
        margin-bottom: 10px; }
        .timing-left .content-timing--item .title span {
          color: var(--brandingColor);
          margin-right: 5px; }
      .timing-left .content-timing--item .text {
        margin-bottom: 20px; }
        .timing-left .content-timing--item .text * {
          margin-bottom: 10px; }
      .timing-left .content-timing--item:not(:first-of-type) {
        margin-top: 50px; }
      .timing-left .content-parameters {
        margin-right: -80px; }
        .timing-left .content-parameters .parameters {
          display: grid;
          grid-template-columns: repeat(2, 50%);
          flex-direction: column;
          padding: 20px 0;
          margin-bottom: 0;
          background-color: var(--brandingShade);
          grid-column-gap: 30px;
          margin-bottom: 1px;
          padding-right: 30px; }
          .timing-left .content-parameters .parameters:first-of-type {
            padding-top: 50px; }
          .timing-left .content-parameters .parameters:last-of-type {
            padding-bottom: 50px; }
          .timing-left .content-parameters .parameters li {
            list-style: none;
            display: flex;
            margin-bottom: 0; }
            .timing-left .content-parameters .parameters li:first-of-type {
              padding-left: 40px; }
            .timing-left .content-parameters .parameters li:last-of-type {
              padding-right: 40px; }
            .timing-left .content-parameters .parameters li figure {
              max-width: 80px;
              max-height: 80px;
              min-width: 80px;
              min-height: 80px;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 1px solid var(--primaryDark);
              border-radius: 100%; }
              .timing-left .content-parameters .parameters li figure img {
                width: 40px;
                height: 40px; }
            .timing-left .content-parameters .parameters li .text {
              display: flex;
              flex-direction: column;
              justify-content: center;
              margin-left: 20px; }
              .timing-left .content-parameters .parameters li .text p {
                margin-bottom: 5px; }
              .timing-left .content-parameters .parameters li .text a {
                cursor: pointer;
                color: var(--primaryDark);
                text-decoration: none;
                border-bottom: 1px solid var(--primaryDark);
                padding-bottom: 2px; }
  .timing-right .images {
    margin-left: -80px;
    position: relative; }
    .timing-right .images figure {
      width: 820px;
      height: 480px; }
      .timing-right .images figure img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .timing-right .images .slick-arrow {
      position: absolute;
      right: 0;
      bottom: -20px;
      z-index: 1; }
      .timing-right .images .slick-arrow:not(.slick-disabled) {
        cursor: pointer; }
      .timing-right .images .slick-arrow.slick-disabled {
        opacity: .5; }
      .timing-right .images .slick-arrow:nth-of-type(1) {
        right: 20px; }
  .timing-right .quote {
    padding-top: 40px;
    padding-left: 40px;
    margin-left: 100px;
    margin-top: 100px; }
  @media screen and (max-width: 93.75em) {
    .timing {
      margin-left: 100px;
      margin-right: 60px; } }
  @media screen and (max-width: 84.375em) {
    .timing {
      margin-left: 30px;
      margin-right: 30px;
      padding-left: 0;
      padding-right: 0; }
      .timing-left {
        padding-top: 50px;
        padding-right: 30px; }
        .timing-left .content > *:not(:last-of-type) {
          margin-bottom: 50px; }
        .timing-left .content-parameters {
          margin-right: -30px; }
      .timing-right .images .slick-list {
        width: 100%; }
        .timing-right .images .slick-list * {
          width: 100%; } }
  @media screen and (max-width: 71.875em) {
    .timing-left .content-parameters .parameters {
      grid-template-columns: auto;
      margin-bottom: 0;
      padding: 0; }
      .timing-left .content-parameters .parameters li {
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 20px; }
      .timing-left .content-parameters .parameters:last-of-type li:last-of-type {
        padding-bottom: 0; }
    .timing-right .quote {
      margin-left: 60px;
      margin-top: 60px;
      margin-right: 30px; } }
  @media screen and (max-width: 56.25em) {
    .timing {
      display: flex;
      flex-direction: column-reverse; }
      .timing-left {
        margin-top: 0; }
      .timing-right .images {
        margin-left: 0px; }
      .timing-right .quote {
        display: none; } }
  @media screen and (max-width: 37.5em) {
    .timing-right .images {
      max-height: 300px; }
      .timing-right .images .slick-list {
        height: 100%; }
        .timing-right .images .slick-list * {
          height: 100%; }
      .timing-right .images .slick-arrow {
        bottom: 15px;
        right: 15px; }
        .timing-right .images .slick-arrow:first-of-type {
          right: 35px; } }

.spotlight-container {
  padding: 320px 0 400px;
  background-color: var(--brandingShade);
  margin-top: -250px; }
  @media screen and (min-width: 131.25em) {
    .spotlight-container {
      margin-top: -180px; } }
  @media screen and (max-width: 68.75em) {
    .spotlight-container {
      margin-top: -270px;
      padding-top: 270px;
      padding-bottom: 530px; } }

.spotlight h1 {
  text-align: center;
  padding-bottom: 20px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .spotlight h1 .element {
    letter-spacing: 10px;
    color: var(--brandingColor); }

.spotlight .show-content {
  display: flex;
  flex-direction: column; }
  .spotlight .show-content > * {
    display: flex;
    justify-content: center; }
    .spotlight .show-content > * > *:not(.line) {
      max-width: 670px;
      width: 100%; }
    .spotlight .show-content > * > *:first-child {
      margin-right: 10px; }
    .spotlight .show-content > * > *:last-child {
      margin-left: 10px; }
    .spotlight .show-content > *.titles {
      padding: 50px 0; }
      .spotlight .show-content > *.titles .title {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; }
    .spotlight .show-content > *.texts .text {
      padding: 50px 30px;
      text-align: center; }
      .spotlight .show-content > *.texts .text P {
        margin-bottom: 0; }
    .spotlight .show-content > *.ctas .cta {
      padding: 20px 25px !important;
      color: var(--primaryDark) !important;
      border-color: var(--primaryDark) !important;
      transition: color .2s ease, background-color .2s ease; }
      .spotlight .show-content > *.ctas .cta:after {
        opacity: 0; }
      .spotlight .show-content > *.ctas .cta * {
        color: var(--primaryDark) !important;
        transition: color .2s ease; }
      .spotlight .show-content > *.ctas .cta:hover {
        background-color: var(--primaryDark) !important;
        color: white !important; }
        .spotlight .show-content > *.ctas .cta:hover * {
          color: white !important; }
      .spotlight .show-content > *.ctas .cta-container {
        display: flex;
        justify-content: center;
        margin: 0 !important; }
    .spotlight .show-content > *.images figure, .spotlight .show-content > * .image figure {
      position: relative; }
    .spotlight .show-content > *.images figure, .spotlight .show-content > *.images img, .spotlight .show-content > * .image figure, .spotlight .show-content > * .image img {
      width: 100%;
      object-fit: cover; }
    .spotlight .show-content > *.images img, .spotlight .show-content > * .image img {
      height: 100%; }
    .spotlight .show-content > *.images .highlight, .spotlight .show-content > * .image .highlight {
      position: absolute;
      top: 0;
      left: 0;
      color: white;
      background-color: var(--primaryDark);
      padding: 10px 15px;
      text-transform: lowercase;
      letter-spacing: 1px;
      font-size: 13px; }
    .spotlight .show-content > *.images img, .spotlight .show-content > * .image img {
      object-fit: cover; }
  .spotlight .show-content > .line {
    height: 1px;
    max-width: 1380px;
    width: 100%;
    background-color: var(--brandingColor);
    margin-left: auto;
    margin-right: auto; }
  .spotlight .show-content.mobile {
    display: none;
    max-width: 440px;
    width: 100%;
    margin-left: auto;
    margin-right: auto; }
    .spotlight .show-content.mobile .show {
      flex-direction: column;
      align-items: center;
      justify-content: center; }
    .spotlight .show-content.mobile .js-carousel-agenda .slick-list  {
      z-index: 1; }
    .spotlight .show-content.mobile .js-carousel-agenda .slick-slide > * * {
      margin-left: 0;
      margin-right: 0;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center; }
    .spotlight .show-content.mobile .js-carousel-agenda .slick-slide .image {
      height: 650px; }
    .spotlight .show-content.mobile .js-carousel-agenda .slick-slide .title {
      margin-top: 40px;
      margin-bottom: 20px; }
    .spotlight .show-content.mobile .js-carousel-agenda .slick-slide .text {
      flex-direction: column;
      margin-bottom: 20px; }
      .spotlight .show-content.mobile .js-carousel-agenda .slick-slide .text p {
        margin-bottom: 0; }
    .spotlight .show-content.mobile .js-carousel-agenda .slick-slide .cta {
      width: max-content;
      margin-left: auto;
      margin-right: auto; }
      .spotlight .show-content.mobile .js-carousel-agenda .slick-slide .cta * {
        color: var(--brandingColor);
        transition: color .2s ease;
        z-index: 1; }
      .spotlight .show-content.mobile .js-carousel-agenda .slick-slide .cta:after {
        opacity: 0;
        z-index: 0;
        transition: opacity .2s ease; }
      .spotlight .show-content.mobile .js-carousel-agenda .slick-slide .cta:hover:after {
        opacity: 1; }
      .spotlight .show-content.mobile .js-carousel-agenda .slick-slide .cta:hover * {
        color: white; }
    .spotlight .show-content.mobile .js-carousel-agenda .slick-arrow {
      position: absolute;
      cursor: pointer;
      z-index: 2;
      top: calc(50% - 40px);
      max-width: unset;
      width: 60px;
      height: 60px; }
      .spotlight .show-content.mobile .js-carousel-agenda .slick-arrow path {
        transition: fill .2s ease; }
      .spotlight .show-content.mobile .js-carousel-agenda .slick-arrow circle {
        transition: fill .2s ease; }
      .spotlight .show-content.mobile .js-carousel-agenda .slick-arrow.slick-disabled {
        pointer-events: none;
        opacity: .5; }
      .spotlight .show-content.mobile .js-carousel-agenda .slick-arrow:first-of-type {
        left: -80px; }
      .spotlight .show-content.mobile .js-carousel-agenda .slick-arrow:last-of-type {
        right: -80px; }
      .spotlight .show-content.mobile .js-carousel-agenda .slick-arrow:hover path {
        fill: white; }
      .spotlight .show-content.mobile .js-carousel-agenda .slick-arrow:hover circle {
        fill: var(--brandingColor); }
    @media screen and (max-width: 48em) {
      .spotlight .show-content.mobile .js-carousel-agenda {
        margin-bottom: 80px; }
        .spotlight .show-content.mobile .js-carousel-agenda .slick-arrow {
          top: unset;
          bottom: -100px; }
          .spotlight .show-content.mobile .js-carousel-agenda .slick-arrow:first-of-type {
            left: calc(50% - 70px); }
          .spotlight .show-content.mobile .js-carousel-agenda .slick-arrow:last-of-type {
            right: calc(50% - 70px); } }
    @media screen and (max-width: 30em) {
      .spotlight .show-content.mobile {
        max-width: 320px; }
        .spotlight .show-content.mobile .js-carousel-agenda .slick-slide .image {
          height: 450px; } }
    @media screen and (max-width: 21.875em) {
      .spotlight .show-content.mobile {
        max-width: 290px; }
        .spotlight .show-content.mobile .js-carousel-agenda .slick-slide .image {
          height: 400px; } }
  @media screen and (max-width: 56.25em) {
    .spotlight .show-content.desktop {
      display: none; }
    .spotlight .show-content.mobile {
      display: flex; } }

@media screen and (max-width: 93.75em) {
  .spotlight {
    margin-left: 80px;
    margin-right: 80px; } }

@media screen and (max-width: 71.875em) {
  .spotlight {
    margin-left: 40px;
    margin-right: 40px; }
    .spotlight > *.titles {
      padding: 30px 0px; } }

@media screen and (max-width: 60em) {
  .spotlight {
    margin-left: 20px;
    margin-right: 20px; }
    .spotlight > *.titles {
      padding: 30px 0px; } }

@media screen and (max-width: 53.125em) {
  .spotlight h1 {
    margin-bottom: 20px; }
  .spotlight .show-content > *.titles {
    padding: 30px 0px; }
  .spotlight .show-content > *.text {
    padding: 30px 10px; } }

.textblock {
  background-color: var(--brandingShade);
  padding: 250px 0 200px 0;
  position: relative;
  z-index: 1;
  overflow-x: hidden; }
  .textblock > div {
    display: grid;
    grid-template-columns: 480px 830px;
    grid-column-gap: 70px;
    margin-top: -50px; }
    .textblock > div .text {
      max-width: 480px;
      min-width: 480px;
      width: 100%; }
      .textblock > div .text h1 {
        padding-bottom: 20px;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column; }
        .textblock > div .text h1 .element {
          letter-spacing: 10px;
          color: var(--brandingColor); }
      .textblock > div .text .quote {
        margin-top: 100px; }
      .textblock > div .text .ctas {
        display: flex;
        flex-wrap: wrap; }
        .textblock > div .text .ctas .cta {
          margin-right: 10px;
          margin-bottom: 10px; }
    .textblock > div .image {
      position: relative; }
      .textblock > div .image.amount-1 {
        padding-left: 70px; }
        .textblock > div .image.amount-1 img:nth-child(1) {
          max-width: 660px;
          position: absolute;
          right: 0; }
      .textblock > div .image.amount-2 img:nth-child(1), .textblock > div .image.amount-2 img:nth-child(2) {
        position: absolute; }
      .textblock > div .image.amount-2 img:nth-child(1) {
        width: 700px !important;
        height: 500px !important;
        top: 0;
        right: 0; }
      .textblock > div .image.amount-2 img:nth-child(2) {
        width: 480px !important;
        height: 300px !important;
        bottom: 0;
        left: 300px; }
      .textblock > div .image.amount-3 img:nth-child(1), .textblock > div .image.amount-3 img:nth-child(2), .textblock > div .image.amount-3 img:nth-child(3) {
        position: absolute; }
      .textblock > div .image.amount-3 img:nth-child(1) {
        width: 700px !important;
        height: 500px;
        top: 0;
        right: 0; }
      .textblock > div .image.amount-3 img:nth-child(2) {
        width: 480px !important;
        height: 300px !important;
        bottom: -20px;
        left: 300px; }
      .textblock > div .image.amount-3 img:nth-child(3) {
        width: 350px !important;
        height: 530px !important;
        bottom: 20px;
        left: 20px; }
      .textblock > div .image.parallax img:nth-child(1) {
        top: -100px; }
      .textblock > div .image.parallax img:nth-child(2) {
        bottom: 100px; }
      .textblock > div .image.parallax img:nth-child(3) {
        bottom: 200px; }
    @media screen and (min-width: 131.25em) {
      .textblock > div {
        grid-template-columns: 480px 800px;
        justify-content: space-between; }
        .textblock > div .image.amount-2 img:nth-child(1) {
          width: 80% !important; }
        .textblock > div .image.amount-2 img:nth-child(2) {
          width: 440px !important; }
        .textblock > div .image.amount-3 {
          width: 100% !important; }
          .textblock > div .image.amount-3 img:nth-child(2) {
            bottom: -20px; } }
    @media screen and (max-width: 125em) {
      .textblock > div .image {
        max-width: 1000px; } }
    @media screen and (max-width: 112.5em) {
      .textblock > div .image {
        max-width: 800px; } }
    @media screen and (max-width: 106.25em) {
      .textblock > div {
        padding: 140px 0 140px 0;
        grid-column-gap: 100px; } }
    @media screen and (max-width: 90.625em) {
      .textblock > div {
        grid-template-columns: 480px 1fr;
        padding-right: 0; }
        .textblock > div .text .quote {
          margin-top: 60px; }
        .textblock > div .image.amount-2 img:nth-child(1) {
          width: 100% !important;
          height: 440px; }
        .textblock > div .image.amount-2 img:nth-child(2) {
          left: -20px; }
        .textblock > div .image.amount-3 img:nth-child(1) {
          top: -40px;
          right: -110px; } }
    @media screen and (max-width: 84.375em) {
      .textblock > div .image {
        min-height: 700px;
        max-height: calc(100vh - 600px);
        max-width: 590px; } }
    @media screen and (max-width: 78.125em) {
      .textblock > div {
        padding: 80px 0; } }
    @media screen and (max-width: 68.75em) {
      .textblock > div {
        padding: 70px 0 70px 0; } }
    @media screen and (max-width: 62.5em) {
      .textblock > div {
        padding: 70px 0;
        grid-template-columns: 100%;
        grid-row-gap: 40px;
        margin-bottom: 0; }
        .textblock > div .text {
          min-width: unset;
          max-width: 480px; }
          .textblock > div .text .quote {
            margin-top: 30px; }
        .textblock > div .image {
          max-width: unset;
          min-height: 500px; }
          .textblock > div .image.amount-1 {
            padding-left: 0;
            max-width: 600px; }
            .textblock > div .image.amount-1 img:nth-child(1) {
              max-width: unset;
              position: relative; }
          .textblock > div .image.amount-3 img:nth-child(1) {
            position: relative;
            max-height: 500px;
            top: 0;
            right: 0; }
          .textblock > div .image img:nth-child(1) {
            height: 100%;
            transform: translateY(0) !important; }
          .textblock > div .image img:nth-child(2), .textblock > div .image img:nth-child(3) {
            display: none; } }
    @media screen and (max-width: 37.5em) {
      .textblock > div .text h1 {
        align-items: center; }
      .textblock > div .image {
        min-height: 300px;
        max-height: 300px;
        height: 100%;
        overflow: hidden; }
        .textblock > div .image img:nth-child(1) {
          height: 300px !important; } }
  @media screen and (max-width: 90.625em) {
    .textblock {
      padding: 100px 0 60px 0;
      padding-bottom: 300px !important; } }
  @media screen and (max-width: 62.5em) {
    .textblock {
      padding: 40px 0 0 0;
      padding-bottom: 0 !important; } }
  .textblock > .container + .container {
    padding-top: 200px;
    padding-bottom: 200px; }

.partners {
  padding: 220px 0; }
  .partners h1 {
    text-align: center;
    padding-bottom: 20px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
    .partners h1 .element {
      letter-spacing: 10px;
      color: var(--brandingColor); }
  .partners .partner {
    filter: grayscale(1);
    display: flex !important;
    justify-content: center;
    max-height: 100px; }
    .partners .partner img {
      object-fit: contain; }
  .partners .slick-track {
    display: flex;
    align-items: center; }
  .partners .slick-slide {
    padding: 0 10px; }
  .partners .slick-list {
    margin-top: 100px; }
  .partners .slick-dots {
    position: relative;
    margin-top: 100px; }
    .partners .slick-dots li button:before {
      font-size: 30px;
      color: #3F3F3F;
      opacity: 1; }
    .partners .slick-dots li.slick-active button {
      border-radius: 100%;
      border: 1px solid var(--brandingColor); }
      .partners .slick-dots li.slick-active button:before {
        color: var(--brandingColor); }
  @media screen and (max-width: 68.75em) {
    .partners {
      padding: 130px 0; } }
  @media screen and (max-width: 48em) {
    .partners {
      padding: 80px 0; } }
  @media screen and (max-width: 37.5em) {
    .partners .slick-list {
      margin-top: 40px;
      max-height: 120px;
      height: 100%; }
      .partners .slick-list * {
        max-height: inherit; }
    .partners .slick-dots {
      margin-top: 40px; } }

.agenda {
  padding: 220px 30px;
  background-color: var(--brandingShade); }
  .agenda > .top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 30px;
    margin-bottom: 100px; }
    .agenda > .top h1 {
      text-align: left;
      padding-bottom: 20px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column; }
      .agenda > .top h1 .element {
        letter-spacing: 10px;
        color: var(--brandingColor); }
    @media screen and (max-width: 53.125em) {
      .agenda > .top {
        flex-direction: column;
        padding: 0; }
        .agenda > .top a {
          margin-top: 40px; } }
    @media screen and (max-width: 37.5em) {
      .agenda > .top {
        padding: 0;
        margin: 0 10px 50px; }
        .agenda > .top a {
          margin-top: 10px; } }
  .agenda .js-carousel-agenda {
    display: flex;
    align-items: center; }
    .agenda .js-carousel-agenda .slick-arrow {
      position: absolute;
      cursor: pointer; }
      .agenda .js-carousel-agenda .slick-arrow path {
        transition: fill .2s ease; }
      .agenda .js-carousel-agenda .slick-arrow circle {
        transition: fill .2s ease; }
      .agenda .js-carousel-agenda .slick-arrow.slick-disabled {
        pointer-events: none;
        opacity: .5; }
      .agenda .js-carousel-agenda .slick-arrow:first-of-type {
        left: -180px; }
      .agenda .js-carousel-agenda .slick-arrow:last-of-type {
        right: -180px; }
      .agenda .js-carousel-agenda .slick-arrow:hover path {
        fill: white; }
      .agenda .js-carousel-agenda .slick-arrow:hover circle {
        fill: var(--brandingColor); }
  .agenda-items .slick-track {
    display: flex !important;
    height: 100%; }
  .agenda-items .slick-slide {
    margin: 0 10px;
    height: auto; }
    .agenda-items .slick-slide > div {
      height: 100%; }
  .agenda-items .Show-tile {
    position: relative;
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%; }
    .agenda-items .Show-tile--image {
      min-height: 620px;
      max-height: 620px; }
      .agenda-items .Show-tile--image img {
        height: inherit;
        object-fit: cover; }
    .agenda-items .Show-tile--info {
      position: unset;
      padding: 40px 30px;
      background-color: white;
      height: 100%;
      justify-content: flex-start; }
      .agenda-items .Show-tile--info .highlight {
        left: 0;
        right: unset; }
      .agenda-items .Show-tile--info .top {
        min-height: 120px; }
      .agenda-items .Show-tile--info .intro {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 0; }
        .agenda-items .Show-tile--info .intro p {
          margin-bottom: 0; }
      .agenda-items .Show-tile--info .bottom {
        margin-top: auto; }
  @media screen and (max-width: 118.75em) {
    .agenda-items {
      margin-left: 90px;
      margin-right: 90px; }
    .agenda .js-carousel-agenda .slick-arrow:first-of-type {
      left: -100px; }
    .agenda .js-carousel-agenda .slick-arrow:last-of-type {
      right: -100px; } }
  @media screen and (max-width: 87.5em) {
    .agenda {
      padding: 100px 30px 140px; }
      .agenda-items .Show-tile--image {
        min-height: 520px;
        max-height: 520px; }
      .agenda-items .Show-tile--info .intro {
        margin-top: 20px; }
      .agenda-items svg {
        width: 60px;
        height: 60px; } }
  @media screen and (max-width: 60em) {
    .agenda {
      padding: 80px 20px 140px; }
      .agenda-items {
        margin-left: 60px;
        margin-right: 60px; }
      .agenda .js-carousel-agenda .slick-arrow:first-of-type {
        left: -80px; }
      .agenda .js-carousel-agenda .slick-arrow:last-of-type {
        right: -80px; } }
  @media screen and (max-width: 48em) {
    .agenda {
      padding: 80px 20px 200px; }
      .agenda-items {
        margin-left: 0;
        margin-right: 0; }
      .agenda .js-carousel-agenda .slick-arrow {
        bottom: -80px; }
        .agenda .js-carousel-agenda .slick-arrow:first-of-type {
          left: calc(50% - 70px); }
        .agenda .js-carousel-agenda .slick-arrow:last-of-type {
          right: calc(50% - 70px); } }
  @media screen and (max-width: 43.75em) {
    .agenda-items .Show-tile--info .top {
      min-height: 0; } }
  @media screen and (max-width: 25em) {
    .agenda-items .Show-tile--image {
      min-height: 400px;
      max-height: 400px; } }

.Filters {
  display: flex;
  justify-content: space-between; }
  .Filters.unusable > * {
    pointer-events: none;
    opacity: .6; }
  .Filters > * {
    display: flex;
    transition: opacity .2s ease; }
    @media screen and (max-width: 37.5em) {
      .Filters > * {
        flex-direction: column;
        width: 100%; } }
  .Filters-active-list {
    display: flex;
    align-items: center;
    max-width: 800px;
    flex-wrap: wrap;
    margin-bottom: 70px; }
    .Filters-active-list > div {
      display: flex;
      align-items: center;
      margin-right: 30px;
      margin-bottom: 20px; }
      .Filters-active-list > div button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 10px;
        height: 10px;
        margin-right: 10px;
        cursor: pointer; }
        .Filters-active-list > div button svg {
          width: 100%;
          height: 100%; }
      .Filters-active-list > div span {
        font-size: 14px; }
    @media screen and (max-width: 37.5em) {
      .Filters-active-list {
        margin-bottom: 30px;
        justify-content: center; } }
    @media screen and (min-width: 131.25em) {
      .Filters-active-list {
        max-width: 1450px; } }
  .Filters .Filter-items--options.icons {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 30px 60px; }
    @media screen and (max-width: 62.5em) {
      .Filters .Filter-items--options.icons {
        display: flex;
        flex-wrap: wrap;
        max-width: 380px;
        justify-content: flex-end;
        gap: unset; } }
  .Filters .Filter-items--option {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start; }
    .Filters .Filter-items--option_input {
      display: none; }
      .Filters .Filter-items--option_input:checked ~ .Filter-items--option_label span {
        color: #293241;
        font-weight: 500; }
        .Filters .Filter-items--option_input:checked ~ .Filter-items--option_label span:after {
          transform: scaleX(1); }
    .Filters .Filter-items--option svg {
      margin-right: 20px; }
    .Filters .Filter-items--option_label {
      display: flex;
      align-items: center;
      cursor: pointer; }
      .Filters .Filter-items--option_label span {
        position: relative; }
        .Filters .Filter-items--option_label span:after {
          content: '';
          position: absolute;
          width: 100%;
          transform: scaleX(0);
          transition: transform .2s ease;
          height: 1px;
          left: 0;
          bottom: -2px;
          background-color: #293241;
          transform-origin: center; }
      .Filters .Filter-items--option_label:hover span:after {
        transform: scaleX(1); }
    @media screen and (max-width: 62.5em) {
      .Filters .Filter-items--option {
        margin-left: 40px; } }
  .Filters .Filter-dropdown {
    position: relative;
    margin-right: 20px;
    margin-bottom: 20px; }
    .Filters .Filter-dropdown.month .Filter-dropdown--name.cta.not-clicktrough {
      padding: 0 20px !important; }
    .Filters .Filter-dropdown.month input {
      outline: none;
      background-color: unset;
      border: none;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: 500;
      font-family: "Gotham", sans-serif; }
      .Filters .Filter-dropdown.month input:focus, .Filters .Filter-dropdown.month input:active {
        outline: none; }
      .Filters .Filter-dropdown.month input::placeholder {
        color: var(--primary); }
    .Filters .Filter-dropdown.month svg {
      position: absolute;
      right: 20px;
      transform: scale(0.8) rotate(0deg); }
    .Filters .Filter-dropdown--name {
      z-index: 1; }
      .Filters .Filter-dropdown--name.hide {
        display: none; }
      .Filters .Filter-dropdown--name svg {
        transform: rotate(90deg);
        margin-left: 20px;
        transform-origin: center;
        transition: transform .2s ease; }
    .Filters .Filter-dropdown--activity:checked ~ .Filter-dropdown--name {
      border-color: var(--brandingColor) !important;
      color: var(--brandingColor) !important; }
      .Filters .Filter-dropdown--activity:checked ~ .Filter-dropdown--name svg {
        transform: rotate(-90deg); }
      .Filters .Filter-dropdown--activity:checked ~ .Filter-dropdown--name:after {
        background-color: white !important; }
    .Filters .Filter-dropdown--activity:checked ~ .Filter-dropdown--options {
      opacity: 1;
      top: 50px;
      pointer-events: all; }
    .Filters .Filter-dropdown--options {
      position: absolute;
      top: 0;
      opacity: 0;
      transition: top .2s ease, opacity .2s ease;
      background-color: var(--brandingShade);
      display: flex;
      flex-direction: column;
      width: max-content;
      min-width: 100%;
      max-height: 250px;
      overflow-y: scroll;
      pointer-events: none;
      z-index: 10;
      /* width */
      /* Track */
      /* Handle */ }
      .Filters .Filter-dropdown--options::-webkit-scrollbar {
        width: 10px; }
      .Filters .Filter-dropdown--options::-webkit-scrollbar-track {
        background-color: var(--brandingShade); }
      .Filters .Filter-dropdown--options::-webkit-scrollbar-thumb {
        cursor: pointer;
        background: var(--brandingColor);
        transition: opacity .2s ease; }
        .Filters .Filter-dropdown--options::-webkit-scrollbar-thumb:hover {
          background: rgba(var(--brandingColor), 0.5); }
    .Filters .Filter-dropdown--option:first-of-type label {
      padding-top: 15px; }
    .Filters .Filter-dropdown--option:last-of-type label {
      padding-bottom: 15px; }
    .Filters .Filter-dropdown--option_label {
      display: flex;
      cursor: pointer;
      font-size: 14px;
      padding: 10px 20px;
      transition: background-color .2s ease, color .2s ease, border-color .2s ease; }
      .Filters .Filter-dropdown--option_label:hover {
        background-color: var(--brandingColor);
        color: white;
        border-color: white; }
    .Filters .Filter-dropdown--option_input {
      display: none; }
      .Filters .Filter-dropdown--option_input:checked ~ .Filter-dropdown--option_label span {
        color: #293241;
        font-weight: 500; }
    @media screen and (max-width: 37.5em) {
      .Filters .Filter-dropdown {
        margin-right: 0; } }
  @media screen and (max-width: 56.25em) {
    .Filters {
      flex-direction: column; }
      .Filters .Filter-items--options.icons {
        margin-top: 20px;
        justify-content: flex-start; }
      .Filters .Filter-items--option {
        margin-left: 0;
        margin-right: 20px;
        margin-bottom: 10px; } }

.Popup {
  background-color: white;
  padding: 120px 90px;
  position: relative;
  width: 100%;
  max-width: 800px;
  margin-left: 20px;
  margin-right: 20px; }
  .Popup-container {
    position: fixed;
    z-index: 10;
    width: 100%;
    height: 100%;
    pointer-events: all;
    background-color: rgba(var(--primary), 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity .2s ease; }
    .Popup-container.remove {
      opacity: 0;
      pointer-events: none; }
    .Popup-container.kusttheater .cta {
      color: var(--brandingColor) !important;
      border-color: var(--brandingColor) !important; }
      .Popup-container.kusttheater .cta:after {
        background-color: var(--brandingColor) !important; }
  .Popup-close {
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;
    transition: transform .2s ease; }
    .Popup-close:hover {
      transform: rotate(90deg); }
  .Popup .line {
    margin-top: 30px;
    margin-bottom: 30px;
    background-color: var(--brandingColor);
    width: 100%;
    height: 1px; }
  .Popup .text {
    margin-right: 80px; }
    .Popup .text > * {
      margin-bottom: 0; }
  .Popup .cta {
    color: var(--primaryDark) !important;
    padding: 20px 25px !important;
    border-color: var(--primaryDark) !important; }
    .Popup .cta:after {
      opacity: 0 !important;
      background-color: var(--primaryDark) !important; }
    .Popup .cta:hover {
      color: white !important; }
      .Popup .cta:hover:after {
        content: '' !important;
        opacity: 1 !important; }
    .Popup .cta-container {
      position: relative;
      z-index: 11;
      margin-top: 50px;
      margin-right: 80px; }
  .Popup .image {
    position: absolute;
    bottom: 0;
    right: -30px;
    width: 200px;
    height: 250px; }
    .Popup .image img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: bottom; }
  @media screen and (max-width: 48em) {
    .Popup {
      padding: 70px 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .Popup * {
        text-align: center; }
      .Popup .text, .Popup .cta-container {
        margin-right: 0; }
      .Popup-container {
        padding-top: 20px;
        padding-bottom: 20px; }
      .Popup .image {
        display: none; } }
  @media screen and (max-width: 37.5em) {
    .Popup {
      padding: 70px 20px; }
      .Popup .line {
        margin-top: 20px;
        margin-bottom: 20px; }
      .Popup .cta-container {
        margin-top: 20px; }
      .Popup-close {
        right: 20px;
        top: 20px; } }

#cc-main {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  background: transparent;
  color: var(--cc-primary-color);
  font-family: var(--cc-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.15;
  position: relative;
  text-rendering: optimizeLegibility; }

#cc-main :after,
#cc-main :before,
#cc-main a,
#cc-main button,
#cc-main div,
#cc-main input,
#cc-main span {
  all: unset;
  box-sizing: border-box; }

#cc-main a,
#cc-main button,
#cc-main input {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  font-family: inherit;
  font-size: 100%;
  line-height: normal;
  margin: 0;
  outline: revert;
  outline-offset: 2px;
  overflow: hidden; }

#cc-main table,
#cc-main tbody,
#cc-main td,
#cc-main th,
#cc-main thead,
#cc-main tr {
  all: revert;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit; }

:root {
  --cc-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
		"Segoe UI Symbol";
  --cc-modal-border-radius: 0.3em;
  --cc-btn-border-radius: 0em;
  --cc-modal-transition-duration: 0.25s;
  --cc-btn-gap: 5px;
  --cc-link-color: var(--brandingColor);
  --cc-modal-margin: 1em;
  --cc-bg: #fff;
  --cc-primary-color: var(--primary);
  --cc-secondary-color: var(--primary);
  --cc-btn-primary-bg: var(--brandingColor);
  --cc-btn-primary-color: white;
  --cc-btn-primary-border-color: var(--brandingColor);
  --cc-btn-primary-hover-bg: theme(colors.secondary);
  --cc-btn-primary-hover-color: var(--brandingColor);
  --cc-btn-primary-hover-border-color: theme(colors.secondary);
  --cc-btn-secondary-bg: #fff;
  --cc-btn-secondary-color: var(--primaryDark);
  --cc-btn-secondary-border-color: var(--primaryDark);
  --cc-btn-secondary-hover-bg: var(--primaryDark);
  --cc-btn-secondary-hover-color: #fff;
  --cc-btn-secondary-hover-border-color: var(--primaryDark);
  --cc-separator-border-color: #f0f4f7;
  --cc-toggle-on-bg: var(--cc-btn-primary-bg);
  --cc-toggle-off-bg: #667481;
  --cc-toggle-on-knob-bg: #fff;
  --cc-toggle-off-knob-bg: var(--cc-toggle-on-knob-bg);
  --cc-toggle-enabled-icon-color: var(--cc-bg);
  --cc-toggle-disabled-icon-color: var(--cc-bg);
  --cc-toggle-readonly-bg: #d5dee2;
  --cc-toggle-readonly-knob-bg: #fff;
  --cc-toggle-readonly-knob-icon-color: var(--cc-toggle-readonly-bg);
  --cc-section-category-border: var(--cc-cookie-category-block-bg);
  --cc-cookie-category-block-bg: #f0f4f7;
  --cc-cookie-category-block-border: #f0f4f7;
  --cc-cookie-category-block-hover-bg: #e9eff4;
  --cc-cookie-category-block-hover-border: #e9eff4;
  --cc-cookie-category-expanded-block-bg: transparent;
  --cc-cookie-category-expanded-block-hover-bg: #dee4e9;
  --cc-overlay-bg: rgba(0, 0, 0, 0.541);
  --cc-webkit-scrollbar-bg: var(--cc-section-category-border);
  --cc-webkit-scrollbar-hover-bg: var(--cc-btn-primary-hover-bg);
  --cc-footer-bg: var(--cc-btn-secondary-bg);
  --cc-footer-color: var(--cc-secondary-color);
  --cc-footer-border-color: #e4eaed;
  --cc-pm-toggle-border-radius: 4em; }

#cc-main.cc--rtl {
  direction: rtl; }

#cc-main .cm__title,
#cc-main a,
#cc-main b,
#cc-main em,
#cc-main strong {
  font-weight: 600; }

#cc-main .cc__link,
#cc-main a {
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 0 1px;
  color: var(--cc-link-color);
  font-weight: 600;
  position: relative;
  transition: background-size 0.25s, color 0.25s ease; }

#cc-main .cc__link:hover,
#cc-main a:hover {
  background-size: 100% 1px;
  color: var(--cc-primary-color); }

#cc-main .cm__desc,
#cc-main .pm__body {
  overscroll-behavior: auto contain;
  scrollbar-width: thin; }
  @media (min-width: theme("screens.md")) {
    #cc-main .cm__desc p,
    #cc-main .pm__body p {
      font-size: 16px;
      line-height: 25px; } }

@media screen and (min-width: 640px) {
  #cc-main ::-webkit-scrollbar,
  #cc-main ::-webkit-scrollbar-thumb,
  #cc-main ::-webkit-scrollbar-track {
    all: revert; }
  #cc-main ::-webkit-scrollbar-thumb {
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    background: var(--cc-toggle-readonly-bg);
    border: 0.25em solid var(--cc-bg);
    border-radius: 1em; }
  #cc-main ::-webkit-scrollbar-thumb:hover {
    background: var(--cc-toggle-off-bg); }
  #cc-main ::-webkit-scrollbar {
    background: transparent;
    width: 12px; } }

html.disable--interaction.show--consent,
html.disable--interaction.show--consent body {
  height: auto !important;
  overflow: hidden !important; }

.cc--darkmode {
  --cc-bg: #161a1c;
  --cc-primary-color: #ebf3f6;
  --cc-secondary-color: #aebbc5;
  --cc-btn-primary-bg: #c2d0e0;
  --cc-btn-primary-color: var(--cc-bg);
  --cc-btn-primary-border-color: var(--cc-btn-primary-bg);
  --cc-btn-primary-hover-bg: #98a7b6;
  --cc-btn-primary-hover-color: #000;
  --cc-btn-primary-hover-border-color: var(--cc-btn-primary-hover-bg);
  --cc-btn-secondary-bg: #242c31;
  --cc-btn-secondary-color: var(--cc-primary-color);
  --cc-btn-secondary-border-color: var(--cc-btn-secondary-bg);
  --cc-btn-secondary-hover-bg: #353d43;
  --cc-btn-secondary-hover-color: #fff;
  --cc-btn-secondary-hover-border-color: var(--cc-btn-secondary-hover-bg);
  --cc-separator-border-color: #222a30;
  --cc-toggle-on-bg: var(--cc-btn-primary-bg);
  --cc-toggle-off-bg: #525f6b;
  --cc-toggle-on-knob-bg: var(--cc-btn-primary-color);
  --cc-toggle-off-knob-bg: var(--cc-btn-primary-color);
  --cc-toggle-enabled-icon-color: var(--cc-btn-primary-color);
  --cc-toggle-disabled-icon-color: var(--cc-btn-primary-color);
  --cc-toggle-readonly-bg: #343e45;
  --cc-toggle-readonly-knob-bg: #5f6b72;
  --cc-toggle-readonly-knob-icon-color: var(--cc-toggle-readonly-bg);
  --cc-section-category-border: #1e2428;
  --cc-cookie-category-block-bg: #1e2428;
  --cc-cookie-category-block-border: var(--cc-section-category-border);
  --cc-cookie-category-block-hover-bg: #242c31;
  --cc-cookie-category-block-hover-border: #232a2f;
  --cc-cookie-category-expanded-block-bg: transparent;
  --cc-cookie-category-expanded-block-hover-bg: var(--cc-toggle-readonly-bg);
  --cc-overlay-bg: rgba(0, 0, 0, 0.541);
  --cc-webkit-scrollbar-bg: var(--cc-section-category-border);
  --cc-webkit-scrollbar-hover-bg: var(--cc-btn-primary-hover-bg);
  --cc-footer-bg: #0c0e0f;
  --cc-footer-color: var(--cc-secondary-color);
  --cc-footer-border-color: #060809; }

.cc--darkmode #cc-main {
  color-scheme: dark; }

#cc-main .cm {
  background: var(--cc-bg);
  border-radius: var(--cc-modal-border-radius);
  box-shadow: 0 0.625em 1.875em rgba(0, 0, 2, 0.3);
  display: flex;
  flex-direction: column;
  max-width: 24em;
  opacity: 0;
  overflow: hidden;
  position: fixed;
  transform: translateY(1.6em);
  visibility: hidden;
  z-index: 1; }

#cc-main .cm--top {
  top: var(--cc-modal-margin); }

#cc-main .cm--middle {
  top: 50%;
  transform: translateY(calc(-50% + 1.6em)); }

#cc-main .cm--bottom {
  bottom: var(--cc-modal-margin); }

#cc-main .cm--center {
  left: var(--cc-modal-margin);
  margin: 0 auto;
  right: var(--cc-modal-margin);
  width: unset; }

#cc-main .cm--left {
  left: var(--cc-modal-margin);
  margin-right: var(--cc-modal-margin); }

#cc-main .cm--right {
  margin-left: var(--cc-modal-margin);
  right: var(--cc-modal-margin); }

#cc-main .cm__body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative; }

#cc-main .cm__btns,
#cc-main .cm__links,
#cc-main .cm__texts {
  padding: 1em 1.3em;
  width: unset; }

#cc-main .cm__texts {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 0; }

#cc-main .cm__title {
  font-size: 1.05em; }

#cc-main .cm__title + .cm__desc {
  margin-top: 1.1em; }

#cc-main .cm__desc {
  color: var(--cc-secondary-color);
  font-size: 0.9em;
  line-height: 1.5;
  max-height: 40vh;
  overflow-x: visible;
  overflow-y: auto;
  padding-bottom: 1em; }

#cc-main .cm__btns {
  border-top: 1px solid var(--cc-separator-border-color);
  display: flex;
  flex-direction: column;
  gap: var(--cc-btn-gap);
  justify-content: center; }

#cc-main .cm__btn-group {
  display: grid;
  gap: var(--cc-btn-gap);
  grid-auto-columns: minmax(0, 1fr); }

#cc-main .cm--bar:not(.cm--inline) .cm__btn-group--uneven,
#cc-main .cm--wide .cm__btn-group--uneven {
  display: flex;
  flex: 1;
  justify-content: space-between; }

#cc-main .cm--bar:not(.cm--inline).cm--flip .cm__btn-group--uneven,
#cc-main .cm--wide.cm--flip .cm__btn-group--uneven {
  flex-direction: row-reverse; }

#cc-main .cm__btn {
  background: var(--cc-btn-primary-bg);
  border: 1px solid var(--cc-btn-primary-border-color);
  border-radius: var(--cc-btn-border-radius);
  color: var(--cc-btn-primary-color);
  font-size: 0.82em;
  font-weight: 600;
  min-height: 42px;
  padding: 0.5em 1em;
  text-align: center; }

#cc-main .cm__btn:hover {
  background: var(--cc-btn-primary-hover-bg);
  border-color: var(--cc-btn-primary-hover-border-color);
  color: var(--cc-btn-primary-hover-color); }

#cc-main .cm__btn--secondary {
  background: var(--cc-btn-secondary-bg);
  border-color: var(--cc-btn-secondary-border-color);
  color: var(--cc-btn-secondary-color); }

#cc-main .cm__btn--secondary:hover {
  background: var(--cc-btn-secondary-hover-bg);
  border-color: var(--cc-btn-secondary-hover-border-color);
  color: var(--cc-btn-secondary-hover-color); }

#cc-main .cm__btn--close {
  border-radius: 0;
  border-bottom-left-radius: var(--cc-btn-border-radius);
  border-right: none;
  border-top: none;
  display: none;
  font-size: 1em;
  height: 42px;
  min-width: auto !important;
  overflow: hidden;
  padding: 0 !important;
  position: absolute;
  right: 0;
  top: 0;
  width: 42px; }

#cc-main .cm__btn--close:after,
#cc-main .cm__btn--close:before {
  background: var(--cc-btn-primary-color);
  content: "";
  height: 0.9em;
  left: 0;
  margin: 1px auto auto;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  transition: all 0.15s ease;
  width: 1.5px; }

#cc-main .cm__btn--close:after {
  transform: translateY(-50%) rotate(-45deg); }

#cc-main .cm__btn--close:hover:after,
#cc-main .cm__btn--close:hover:before {
  background: var(--cc-btn-primary-hover-color); }

#cc-main .cm__btn--close.cm__btn--secondary:after,
#cc-main .cm__btn--close.cm__btn--secondary:before {
  background: var(--cc-btn-secondary-color); }

#cc-main .cm__btn--close.cm__btn--secondary:hover:after,
#cc-main .cm__btn--close.cm__btn--secondary:hover:before {
  background: var(--cc-btn-secondary-hover-color); }

#cc-main .cm__btn--close + .cm__texts .cm__title {
  padding-right: 2em; }

#cc-main .cm--inline .cm__btn-group {
  grid-auto-flow: column; }

#cc-main .cm__footer {
  background: var(--cc-footer-bg);
  border-top: 1px solid var(--cc-footer-border-color);
  color: var(--cc-footer-color);
  padding: 0.4em 0 0.5em; }

#cc-main .cm__links {
  display: flex;
  flex-direction: row;
  padding-bottom: 0;
  padding-top: 0; }

#cc-main .cm__link-group {
  display: flex;
  flex-direction: row;
  font-size: 0.8em;
  gap: 1.3em;
  width: 100%; }

#cc-main .cm--flip .cm__btn:last-child {
  grid-row: 1; }

#cc-main .cm--inline.cm--flip .cm__btn:last-child {
  grid-column: 1; }

#cc-main .cm--box .cm__btn--close {
  display: block; }

#cc-main .cm--box.cm--flip .cm__btns {
  flex-direction: column-reverse; }

#cc-main .cm--box.cm--wide {
  max-width: 36em; }

#cc-main .cm--box.cm--wide .cm__btns {
  flex-direction: row;
  justify-content: space-between; }

#cc-main .cm--box.cm--wide .cm__btn-group {
  grid-auto-flow: column; }

#cc-main .cm--box.cm--wide .cm__btn {
  min-width: 120px;
  padding-left: 1.8em;
  padding-right: 1.8em; }

#cc-main .cm--box.cm--wide.cm--flip .cm__btns {
  flex-direction: row-reverse; }

#cc-main .cm--box.cm--wide.cm--flip .cm__btn:last-child {
  grid-column: 1; }

#cc-main .cm--cloud {
  max-width: 54em;
  width: unset; }

#cc-main .cm--cloud .cm__body {
  flex-direction: row; }

#cc-main .cm--cloud .cm__texts {
  flex: 1; }

#cc-main .cm--cloud .cm__desc {
  max-height: 9.4em; }

#cc-main .cm--cloud .cm__btns {
  border-left: 1px solid var(--cc-separator-border-color);
  border-top: none;
  max-width: 23em; }

#cc-main .cm--cloud .cm__btn-group {
  flex-direction: column; }

#cc-main .cm--cloud .cm__btn {
  min-width: 19em; }

#cc-main .cm--cloud.cm--flip .cm__btn-group,
#cc-main .cm--cloud.cm--flip .cm__btns {
  flex-direction: column-reverse; }

#cc-main .cm--cloud.cm--inline .cm__btn-group {
  flex-direction: row; }

#cc-main .cm--cloud.cm--inline .cm__btn {
  min-width: 10em; }

#cc-main .cm--cloud.cm--inline.cm--flip .cm__btn-group {
  flex-direction: row-reverse; }

#cc-main .cm--bar {
  --cc-modal-transition-duration: 0.35s;
  border-radius: 0;
  left: 0;
  margin: 0;
  max-width: unset;
  opacity: 1;
  right: 0;
  transform: translateY(0);
  width: 100vw; }

#cc-main .cm--bar.cm--top {
  top: 0;
  transform: translateY(-100%); }

#cc-main .cm--bar.cm--bottom {
  bottom: 0;
  transform: translateY(100%); }

#cc-main .cm--bar .cm__body,
#cc-main .cm--bar .cm__links {
  margin: 0 auto;
  max-width: 55em;
  width: 100%; }

#cc-main .cm--bar .cm__body {
  padding: 0.5em 0 0.9em; }

#cc-main .cm--bar .cm__btns {
  border-top: none;
  flex-direction: row;
  justify-content: space-between; }

#cc-main .cm--bar .cm__btn-group {
  grid-auto-flow: column; }

#cc-main .cm--bar .cm__btn {
  min-width: 120px;
  padding-left: 2em;
  padding-right: 2em; }

#cc-main .cm--bar.cm--flip .cm__btns {
  flex-direction: row-reverse; }

#cc-main .cm--bar.cm--flip .cm__btn:last-child {
  grid-column: 1; }

#cc-main .cm--bar.cm--inline .cm__body,
#cc-main .cm--bar.cm--inline .cm__links {
  max-width: 74em; }

#cc-main .cm--bar.cm--inline .cm__body {
  flex-direction: row;
  padding: 0; }

#cc-main .cm--bar.cm--inline .cm__btns {
  flex-direction: column;
  justify-content: center; }

#cc-main .cm--bar.cm--inline.cm--flip .cm__btns {
  flex-direction: column-reverse; }

#cc-main .cc--anim .cm,
#cc-main .cc--anim.cm-wrapper:before {
  transition: opacity var(--cc-modal-transition-duration) ease, visibility var(--cc-modal-transition-duration) ease, transform var(--cc-modal-transition-duration) ease; }

#cc-main .cc--anim .cm__btn,
#cc-main .cc--anim .cm__close {
  transition: background-color .3s ease, border-color .3s ease, color .3s ease; }

.disable--interaction #cc-main .cm-wrapper:before {
  background: var(--cc-overlay-bg);
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
  z-index: 0; }

.show--consent #cc-main .cc--anim .cm {
  opacity: 1;
  transform: translateY(0);
  visibility: visible !important; }

.show--consent #cc-main .cc--anim .cm--middle {
  transform: translateY(-50%); }

.show--consent #cc-main .cc--anim .cm--bar {
  transform: translateY(0); }

.show--consent #cc-main .cc--anim.cm-wrapper:before {
  opacity: 1;
  visibility: visible; }

#cc-main.cc--rtl .cm__btn--close {
  border-bottom-left-radius: unset;
  border-bottom-right-radius: var(--cc-btn-border-radius);
  left: 0;
  right: unset; }

#cc-main.cc--rtl .cm__btn--close + .cm__texts .cm__title {
  padding-left: 2em;
  padding-right: unset; }

@media screen and (max-width: 640px) {
  #cc-main {
    --cc-modal-margin: 0.5em; }
  #cc-main .cm {
    max-width: none !important;
    width: auto !important; }
  #cc-main .cm__body {
    flex-direction: column !important;
    padding: 0 !important; }
  #cc-main .cm__btns,
  #cc-main .cm__links,
  #cc-main .cm__texts {
    padding-left: 1.1em !important;
    padding-right: 1.1em !important; }
  #cc-main .cm__btns {
    border-left: none !important;
    border-top: 1px solid var(--cc-separator-border-color) !important;
    max-width: none !important; }
  #cc-main .cm__btn-group,
  #cc-main .cm__btns {
    flex-direction: column !important;
    min-width: auto !important; }
  #cc-main .cm__btn-group {
    display: flex !important; }
  #cc-main .cm__btn {
    flex: auto !important; }
  #cc-main .cm__link-group {
    justify-content: center !important; }
  #cc-main .cm--flip .cm__btn-group,
  #cc-main .cm--flip .cm__btns {
    flex-direction: column-reverse !important; } }

#cc-main .pm-wrapper {
  position: relative;
  z-index: 2; }

#cc-main .pm {
  background: var(--cc-bg);
  border-radius: var(--cc-modal-border-radius);
  box-shadow: 0 0.625em 1.875em rgba(0, 0, 2, 0.3);
  display: flex;
  flex-direction: column;
  opacity: 0;
  overflow: hidden;
  position: fixed;
  visibility: hidden;
  width: 100%;
  width: unset;
  z-index: 1; }

#cc-main .pm__body,
#cc-main .pm__footer,
#cc-main .pm__header {
  padding: 1em 1.5em; }

#cc-main .pm__header {
  align-items: center;
  border-bottom: 1px solid var(--cc-separator-border-color);
  display: flex;
  gap: 2em;
  justify-content: space-between; }

#cc-main .pm__title {
  align-items: center;
  display: flex;
  flex: 1;
  font-weight: 600; }

#cc-main .pm__close-btn {
  background: var(--cc-btn-secondary-bg);
  border: 1px solid var(--cc-btn-secondary-border-color);
  border-radius: var(--cc-btn-border-radius);
  height: 40px;
  position: relative;
  transition: all 0.15s ease;
  width: 40px; }

#cc-main .pm__close-btn:after,
#cc-main .pm__close-btn:before {
  background: var(--cc-btn-secondary-color);
  content: "";
  height: 0.9em;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  transition: all 0.15s ease;
  width: 1.5px; }

#cc-main .pm__close-btn:after {
  transform: translateY(-50%) rotate(-45deg); }

#cc-main .pm__close-btn:hover {
  background: var(--cc-btn-secondary-hover-bg);
  border-color: var(--cc-btn-secondary-hover-border-color); }

#cc-main .pm__close-btn:hover:after,
#cc-main .pm__close-btn:hover:before {
  background: var(--cc-btn-secondary-hover-color); }

#cc-main .pm__body {
  flex: 1;
  overflow-y: auto;
  overflow-y: overlay; }

#cc-main .pm__section,
#cc-main .pm__section--toggle {
  border-radius: var(--cc-btn-border-radius);
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5em; }

#cc-main .pm__section--toggle .pm__section-desc-wrapper {
  border: 1px solid var(--cc-cookie-category-block-border);
  border-radius: var(--cc-btn-border-radius);
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  display: none;
  margin-top: 0 !important;
  overflow: hidden; }

#cc-main .pm__section {
  border: 1px solid var(--cc-separator-border-color);
  padding: 1em;
  transition: background-color 0.25s ease, border-color 0.25s ease; }

#cc-main .pm__section:first-child {
  border: none;
  margin-bottom: 2em;
  margin-top: 0;
  padding: 0;
  transition: none; }

#cc-main .pm__section:not(:first-child):hover {
  background: var(--cc-cookie-category-block-bg);
  border-color: var(--cc-cookie-category-block-border); }

#cc-main .pm__section-toggles + .pm__section {
  margin-top: 2em; }

#cc-main .pm__section--toggle {
  background: var(--cc-cookie-category-block-bg);
  border-top: none;
  margin-bottom: var(--cc-btn-gap); }

#cc-main .pm__section--toggle .pm__section-title {
  align-items: center;
  background: var(--cc-cookie-category-block-bg);
  border: 1px solid var(--cc-cookie-category-block-border);
  display: flex;
  justify-content: space-between;
  min-height: 58px;
  padding: 1.1em 5.4em 1.1em 1.2em;
  position: relative;
  transition: background-color 0.25s ease, border-color 0.25s ease;
  width: 100%; }

#cc-main .pm__section--toggle .pm__section-title:hover {
  background: var(--cc-cookie-category-block-hover-bg);
  border-color: var(--cc-cookie-category-block-hover-border); }

#cc-main .pm__section--toggle .pm__section-desc {
  margin-top: 0;
  padding: 1em; }

#cc-main .pm__section--toggle.is-expanded {
  --cc-cookie-category-block-bg: var(--cc-cookie-category-expanded-block-bg);
  --cc-cookie-category-block-border: var(
		--cc-cookie-category-expanded-block-hover-bg
	); }

#cc-main .pm__section--toggle.is-expanded .pm__section-title {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

#cc-main .pm__section--toggle.is-expanded .pm__section-arrow:before {
  margin-top: 0;
  transform: translateY(-20%) rotate(225deg); }

#cc-main .pm__section--toggle.is-expanded .pm__section-desc-wrapper {
  display: flex; }

#cc-main .pm__section--expandable .pm__section-title {
  cursor: pointer;
  padding-left: 3.4em; }

#cc-main .pm__section--expandable .pm__section-arrow {
  background: var(--cc-toggle-readonly-bg);
  border-radius: 100%;
  display: flex;
  height: 20px;
  justify-content: center;
  left: 18px;
  pointer-events: none;
  position: absolute;
  width: 20px; }

#cc-main .pm__section--expandable .pm__section-arrow:before {
  border: solid var(--cc-btn-secondary-color);
  border-width: 0 2px 2px 0;
  content: "";
  margin-top: -1px;
  padding: 2px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  z-index: 1; }

#cc-main .pm__section-title-wrapper {
  align-items: center;
  display: flex;
  position: relative; }

#cc-main .pm__section-title-wrapper + .pm__section-desc-wrapper {
  margin-top: 0.85em; }

#cc-main .pm__section-title {
  border-radius: var(--cc-btn-border-radius);
  font-size: 0.95em;
  font-weight: 600; }

#cc-main .pm__badge {
  align-items: center;
  background: var(--cc-btn-secondary-bg);
  border-radius: 5em;
  color: var(--cc-secondary-color);
  display: flex;
  flex: none;
  font-size: 0.8em;
  font-weight: 600;
  height: 23px;
  justify-content: center;
  margin-left: 1em;
  min-width: 23px;
  overflow: hidden;
  padding: 0 0.6em 1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: auto; }

#cc-main .pm__service-counter {
  background: var(--cc-btn-primary-bg);
  color: var(--cc-btn-primary-color);
  padding: 0;
  width: 23px; }

#cc-main .pm__service-counter[data-counterlabel] {
  padding: 0 0.6em 1px;
  width: auto; }

#cc-main .section__toggle,
#cc-main .section__toggle-wrapper,
#cc-main .toggle__icon,
#cc-main .toggle__label {
  border-radius: var(--cc-pm-toggle-border-radius);
  height: 23px;
  width: 50px; }

#cc-main .section__toggle-wrapper {
  cursor: pointer;
  position: absolute;
  right: 18px;
  z-index: 1; }

#cc-main .toggle-service {
  height: 19px;
  position: relative;
  right: 0;
  width: 42px; }

#cc-main .toggle-service .section__toggle,
#cc-main .toggle-service .toggle__icon,
#cc-main .toggle-service .toggle__label {
  height: 19px;
  width: 42px; }

#cc-main .toggle-service .toggle__icon {
  position: relative; }

#cc-main .toggle-service .toggle__icon:after {
  height: 19px;
  width: 19px; }

#cc-main .toggle-service .section__toggle:checked ~ .toggle__icon:after {
  transform: translateX(23px); }

#cc-main .toggle-service .toggle__icon-off:after,
#cc-main .toggle-service .toggle__icon-off:before {
  height: 9px;
  margin: 0.1px 2px 0 auto;
  width: 1.7px; }

#cc-main .toggle-service .toggle__icon-on:before {
  border-width: 0 1.7px 1.7px 0;
  margin: -3.8px auto 0 6px;
  padding: 1.3px 1.3px 6px; }

#cc-main .pm__section--toggle:nth-child(2) .section__toggle-wrapper:after {
  display: none !important; }

#cc-main .section__toggle {
  border: 0;
  cursor: pointer;
  display: block;
  left: 0;
  margin: 0;
  position: absolute;
  top: 0; }

#cc-main .section__toggle:disabled {
  cursor: not-allowed; }

#cc-main .toggle__icon {
  background: var(--cc-toggle-off-bg);
  box-shadow: 0 0 0 1px var(--cc-toggle-off-bg);
  display: flex;
  flex-direction: row;
  pointer-events: none;
  position: absolute;
  transition: all 0.25s ease; }

#cc-main .toggle__icon:after {
  background: var(--cc-toggle-off-knob-bg);
  border: none;
  border-radius: 100%;
  box-shadow: 0 1px 2px rgba(24, 32, 3, 0.36);
  content: "";
  display: block;
  height: 23px;
  left: 0;
  position: absolute;
  top: 0;
  transition: transform 0.25s ease, background-color 0.25s ease;
  width: 23px; }

#cc-main .toggle__icon-off,
#cc-main .toggle__icon-on {
  align-items: center;
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  position: relative;
  text-align: center;
  transform: translateZ(0);
  transition: opacity 0.15s ease; }

#cc-main .toggle__icon-on {
  left: 0;
  opacity: 0;
  z-index: 1; }

#cc-main .toggle__icon-on:before {
  border: solid var(--cc-toggle-on-bg);
  border-width: 0 2px 2px 0;
  content: "";
  display: inline-block;
  margin: -4.5px auto 0 7px;
  padding: 1.5px 1.5px 7px;
  position: absolute;
  top: 50%;
  transform: rotate(45deg) translate(-50%); }

#cc-main .toggle__icon-off {
  opacity: 1;
  z-index: 1; }

#cc-main .toggle__icon-off:after,
#cc-main .toggle__icon-off:before {
  background: var(--cc-toggle-off-bg);
  content: "";
  display: block;
  height: 0.7em;
  margin: 0.5px 2px 0 auto;
  position: absolute;
  top: 50%;
  transform-origin: center;
  width: 2px; }

#cc-main .toggle__icon-off:before {
  transform: translateY(-50%) rotate(45deg); }

#cc-main .toggle__icon-off:after {
  transform: translateY(-50%) rotate(-45deg); }

#cc-main .section__toggle:checked ~ .toggle__icon {
  background: var(--cc-toggle-on-bg);
  box-shadow: 0 0 0 1px var(--cc-toggle-on-bg); }

#cc-main .section__toggle:checked ~ .toggle__icon:after {
  background-color: var(--cc-toggle-on-knob-bg);
  transform: translateX(27px); }

#cc-main .section__toggle:checked ~ .toggle__icon .toggle__icon-on {
  opacity: 1; }

#cc-main .section__toggle:checked ~ .toggle__icon .toggle__icon-off {
  opacity: 0; }

#cc-main .section__toggle:checked:disabled ~ .toggle__icon {
  background: var(--cc-toggle-readonly-bg);
  box-shadow: 0 0 0 1px var(--cc-toggle-readonly-bg); }

#cc-main .section__toggle:checked:disabled ~ .toggle__icon:after {
  background: var(--cc-toggle-readonly-knob-bg);
  box-shadow: none; }

#cc-main
.section__toggle:checked:disabled
~ .toggle__icon
.toggle__icon-off:after,
#cc-main
.section__toggle:checked:disabled
~ .toggle__icon
.toggle__icon-off:before,
#cc-main
.section__toggle:checked:disabled
~ .toggle__icon
.toggle__icon-on:before {
  border-color: var(--cc-toggle-readonly-knob-icon-color); }

#cc-main .toggle__label {
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: -1; }

#cc-main .pm__section-desc-wrapper {
  color: var(--cc-secondary-color);
  display: flex;
  flex-direction: column;
  font-size: 0.9em; }

#cc-main .pm__section-services {
  display: flex;
  flex-direction: column; }

#cc-main .pm__section-services + div,
#cc-main .pm__section-services + table {
  border-top: 1px solid var(--cc-cookie-category-block-border); }

#cc-main .pm__service {
  align-items: center;
  display: flex;
  gap: 1em;
  justify-content: space-between;
  padding: 0.4em 1.2em;
  position: relative;
  transition: background-color 0.15s ease; }

#cc-main .pm__service:hover {
  background-color: var(--cc-cookie-category-block-hover-bg); }

#cc-main .pm__service-header {
  align-items: center;
  display: flex;
  width: 100%; }

#cc-main .pm__service-icon {
  border: 2px solid;
  border-radius: 100%;
  height: 8px;
  margin-left: 7px;
  margin-right: 20px;
  margin-top: 1px;
  min-width: 8px; }

#cc-main .pm__service-title {
  font-size: 0.95em;
  width: 100%;
  word-break: break-word; }

#cc-main .pm__section-desc {
  line-height: 1.5em; }

#cc-main .pm__section-table {
  border-collapse: collapse;
  font-size: 0.9em;
  margin: 0;
  overflow: hidden;
  padding: 0;
  text-align: left;
  width: 100%; }

#cc-main .pm__table-head > tr {
  border-bottom: 1px dashed var(--cc-separator-border-color);
  color: var(--cc-primary-color);
  font-weight: 600; }

#cc-main .pm__table-tr {
  transition: background-color 0.15s ease; }

#cc-main .pm__table-tr:hover {
  background: var(--cc-cookie-category-block-hover-bg); }

#cc-main .pm__table-td,
#cc-main .pm__table-th {
  padding: 0.625em 0.625em 0.625em 1.2em;
  vertical-align: top; }

#cc-main .pm__footer {
  border-top: 1px solid var(--cc-separator-border-color);
  justify-content: space-between; }

#cc-main .pm__btn-group,
#cc-main .pm__footer {
  display: flex;
  gap: var(--cc-btn-gap); }

#cc-main .pm__btn {
  background: var(--cc-btn-primary-bg);
  border: 1px solid var(--cc-btn-primary-border-color);
  border-radius: var(--cc-btn-border-radius);
  color: var(--cc-btn-primary-color);
  flex: auto;
  font-size: 0.82em;
  font-weight: 600;
  min-height: 42px;
  min-width: 110px;
  padding: 0.5em 1.5em;
  text-align: center;
  transition: background-color 0.15s ease, border-color 0.15s ease, color 0.15s ease; }

#cc-main .pm__btn:hover {
  background: var(--cc-btn-primary-hover-bg);
  border-color: var(--cc-btn-primary-hover-border-color);
  color: var(--cc-btn-primary-hover-color); }

#cc-main .pm__btn--secondary {
  background: var(--cc-btn-secondary-bg);
  border-color: var(--cc-btn-secondary-border-color);
  color: var(--cc-btn-secondary-color); }

#cc-main .pm__btn--secondary:hover {
  background: var(--cc-btn-secondary-hover-bg);
  border-color: var(--cc-btn-secondary-hover-border-color);
  color: var(--cc-btn-secondary-hover-color); }

#cc-main .pm--box {
  height: calc(100% - 2em);
  left: var(--cc-modal-margin);
  margin: 0 auto;
  max-height: 37.5em;
  max-width: 43em;
  right: var(--cc-modal-margin);
  top: 50%;
  transform: translateY(calc(-50% + 1.6em)); }

#cc-main .pm--box.pm--flip .pm__btn-group,
#cc-main .pm--box.pm--flip .pm__footer {
  flex-direction: row-reverse; }

#cc-main .pm--bar {
  --cc-modal-transition-duration: 0.35s;
  border-radius: 0;
  bottom: 0;
  height: 100%;
  margin: 0;
  max-height: none;
  max-width: 29em;
  opacity: 1;
  top: 0; }

#cc-main .pm--bar .pm__section-table,
#cc-main .pm--bar .pm__table-body,
#cc-main .pm--bar .pm__table-td,
#cc-main .pm--bar .pm__table-th,
#cc-main .pm--bar .pm__table-tr {
  display: block; }

#cc-main .pm--bar .pm__table-head {
  display: none; }

#cc-main .pm--bar .pm__table-tr:not(:last-child) {
  border-bottom: 1px solid var(--cc-separator-border-color); }

#cc-main .pm--bar .pm__table-td {
  display: flex;
  justify-content: space-between; }

#cc-main .pm--bar .pm__table-td:before {
  color: var(--cc-primary-color);
  content: attr(data-column);
  flex: 1;
  font-weight: 600;
  min-width: 100px;
  overflow: hidden;
  padding-right: 2em;
  text-overflow: ellipsis; }

#cc-main .pm--bar .pm__table-td > div {
  flex: 3; }

#cc-main .pm--bar:not(.pm--wide) .pm__body,
#cc-main .pm--bar:not(.pm--wide) .pm__footer,
#cc-main .pm--bar:not(.pm--wide) .pm__header {
  padding: 1em 1.3em; }

#cc-main .pm--bar:not(.pm--wide) .pm__btn-group,
#cc-main .pm--bar:not(.pm--wide) .pm__footer {
  flex-direction: column; }

#cc-main .pm--bar:not(.pm--wide).pm--flip .pm__btn-group,
#cc-main .pm--bar:not(.pm--wide).pm--flip .pm__footer {
  flex-direction: column-reverse; }

#cc-main .pm--bar:not(.pm--wide) .pm__badge {
  display: none; }

#cc-main .pm--bar.pm--left {
  left: 0;
  transform: translateX(-100%); }

#cc-main .pm--bar.pm--right {
  right: 0;
  transform: translateX(100%); }

#cc-main .pm--bar.pm--wide {
  max-width: 35em; }

#cc-main .pm--bar.pm--wide .pm__body,
#cc-main .pm--bar.pm--wide .pm__footer,
#cc-main .pm--bar.pm--wide .pm__header {
  padding: 1em 1.65em; }

#cc-main .pm--bar.pm--wide.pm--flip .pm__btn-group,
#cc-main .pm--bar.pm--wide.pm--flip .pm__footer {
  flex-direction: row-reverse; }

#cc-main .pm-wrapper:before {
  background: var(--cc-overlay-bg);
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
  z-index: 1; }

#cc-main .cc--anim .pm,
#cc-main .cc--anim.pm-wrapper:before {
  transition: opacity var(--cc-modal-transition-duration) ease, visibility var(--cc-modal-transition-duration) ease, transform var(--cc-modal-transition-duration) ease; }

.show--preferences #cc-main .cc--anim .pm {
  opacity: 1;
  visibility: visible !important; }

.show--preferences #cc-main .cc--anim .pm--box {
  transform: translateY(-50%); }

.show--preferences #cc-main .cc--anim .pm--bar {
  transform: translateX(0); }

.show--preferences #cc-main .cc--anim.pm-wrapper:before {
  opacity: 1;
  visibility: visible; }

#cc-main.cc--rtl .pm__section-arrow {
  left: unset;
  right: 18px; }

#cc-main.cc--rtl .section__toggle-wrapper {
  left: 18px;
  right: unset;
  transform-origin: left; }

#cc-main.cc--rtl .toggle-service {
  left: 0; }

#cc-main.cc--rtl .pm__service-icon {
  margin-left: 20px;
  margin-right: 5px; }

#cc-main.cc--rtl .pm__section--toggle .pm__section-title {
  padding-left: 5.4em;
  padding-right: 1.2em; }

#cc-main.cc--rtl .pm__section--expandable .pm__section-title {
  padding-right: 3.4em; }

#cc-main.cc--rtl .pm__badge {
  margin-left: unset;
  margin-right: 1em; }

#cc-main.cc--rtl .toggle__icon:after {
  transform: translateX(27px); }

#cc-main.cc--rtl .toggle-service .toggle__icon:after {
  transform: translateX(23px); }

#cc-main.cc--rtl .section__toggle:checked ~ .toggle__icon:after {
  transform: translateX(0); }

#cc-main.cc--rtl .toggle__icon-off:after,
#cc-main.cc--rtl .toggle__icon-off:before,
#cc-main.cc--rtl .toggle__icon-on:before {
  margin-left: 2px;
  margin-right: unset; }

#cc-main.cc--rtl .pm__table-td,
#cc-main.cc--rtl .pm__table-th {
  text-align: right; }

#cc-main.cc--rtl .pm__table-td {
  padding-left: unset;
  padding-right: 1.2em; }

#cc-main.cc--rtl .pm__table-td:before {
  padding-left: 2em;
  padding-right: unset; }

@media screen and (max-width: 640px) {
  #cc-main .pm {
    border-radius: 0;
    bottom: 0;
    height: auto;
    left: 0;
    max-height: 100%;
    max-width: none !important;
    right: 0;
    top: 0;
    transform: translateY(1.6em); }
  #cc-main .pm__body,
  #cc-main .pm__footer,
  #cc-main .pm__header {
    padding: 0.9em !important; }
  #cc-main .pm__badge {
    display: none; }
  #cc-main .pm__section-table,
  #cc-main .pm__table-body,
  #cc-main .pm__table-td,
  #cc-main .pm__table-th,
  #cc-main .pm__table-tr {
    display: block; }
  #cc-main .pm__table-head {
    display: none; }
  #cc-main .pm__table-tr:not(:last-child) {
    border-bottom: 1px solid var(--cc-separator-border-color); }
  #cc-main .pm__table-td {
    display: flex;
    justify-content: space-between; }
  #cc-main .pm__table-td:before {
    color: var(--cc-primary-color);
    content: attr(data-column);
    flex: 1;
    font-weight: 600;
    min-width: 100px;
    overflow: hidden;
    padding-right: 2em;
    text-overflow: ellipsis; }
  #cc-main .pm__table-td > div {
    flex: 3; }
  #cc-main .pm__btn-group,
  #cc-main .pm__footer {
    flex-direction: column !important; }
  #cc-main .pm__btn-group {
    display: flex !important; }
  #cc-main .pm--flip .pm__btn-group,
  #cc-main .pm--flip .pm__footer {
    flex-direction: column-reverse !important; }
  .show--preferences #cc-main .cc--anim .pm {
    transform: translateY(0) !important; } }

html.kusttheater .slick-arrow circle {
  stroke: var(--brandingColor); }

html.kusttheater .slick-arrow path {
  fill: var(--brandingColor); }

html.kusttheater .pagination .page svg circle {
  stroke: var(--brandingColor); }

html.kusttheater .pagination .page svg path {
  fill: var(--brandingColor); }

html.kusttheater .cta.not-filled {
  border-color: var(--brandingColor) !important;
  color: var(--brandingColor) !important; }
  html.kusttheater .cta.not-filled * {
    color: var(--brandingColor) !important; }
  html.kusttheater .cta.not-filled:after {
    background-color: var(--brandingColor) !important; }
  html.kusttheater .cta.not-filled:hover {
    color: white !important;
    background-color: unset !important; }
    html.kusttheater .cta.not-filled:hover * {
      color: white !important; }

html.kusttheater .golden-ctas > span:hover a {
  border-color: white !important;
  color: white !important; }
  html.kusttheater .golden-ctas > span:hover a * {
    color: white !important; }

html.kusttheater .accordion-title:after {
  content: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.09' height='15.939' viewBox='0 0 10.09 15.939'%3E%3Cpath id='Path_84' data-name='Path 84' d='M17988.873-1860.946l-2.121-2.121,5.848-5.848-5.848-5.848,2.121-2.121,7.969,7.969Z' transform='translate(-17986.752 1876.885)' fill='%23ae3030'/%3E%3C/svg%3E") !important; }

html.kusttheater .PraktischeInfoPage .full-page-header-whiteblock .parameters li .text a {
  color: var(--brandingColor); }
  html.kusttheater .PraktischeInfoPage .full-page-header-whiteblock .parameters li .text a:before {
    background-color: var(--brandingColor); }

html.kusttheater .Filter-items--options.icons svg path, html.kusttheater .Filter-items--options.icons svg line {
  stroke: var(--brandingColor); }

html.kusttheater .Show-list .show .categories svg path, html.kusttheater .Show-list .show .categories svg line {
  stroke: var(--brandingColor); }

html.kusttheater .header-slider .right:before {
  content: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='71.888' height='71.888' viewBox='0 0 71.888 71.888'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Cpath id='Path_146' data-name='Path 146' d='M0,60.776H71.888V-11.112H0Z' transform='translate(0 11.112)' fill='%23fcf9f6'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Group_112' data-name='Group 112' transform='translate(0 11.112)'%3E%3Cg id='Group_110' data-name='Group 110' transform='translate(0 -11.112)' clip-path='url(%23clip-path)'%3E%3Cg id='Group_109' data-name='Group 109' transform='translate(-0.001 0.001)'%3E%3Cpath id='Path_145' data-name='Path 145' d='M60.776,30.388A35.944,35.944,0,0,1,24.832-5.556,35.94,35.94,0,0,1-11.112,30.388,35.94,35.94,0,0,1,24.832,66.332,35.944,35.944,0,0,1,60.776,30.388' transform='translate(11.112 5.556)' fill='%23f5f5f5'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E"); }

html.kusttheater .custom-select .select-selected:after {
  content: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='7' viewBox='0 0 13 7'%3E%3Cpath id='Path_238' data-name='Path 238' d='M6.475,0h0L0-6.225.806-7,6.478-1.547,12.194-7,13-6.225,6.475,0Z' transform='translate(0 7)' fill='%23171717'/%3E%3C/svg%3E"); }

html.kusttheater .calendar-container svg path {
  stroke: #171717; }

.header-slider {
  margin-top: 120px;
  display: grid;
  grid-template-columns: 65% 35%; }
  .header-slider .left .slick-slide > div {
    max-height: 1000px; }
    .header-slider .left .slick-slide > div .figure {
      height: 100%;
      max-height: inherit; }
      .header-slider .left .slick-slide > div .figure .square-size {
        display: none; }
      @media screen and (min-width: 81.25em) and (max-width: 112.5em) {
        .header-slider .left .slick-slide > div .figure .normal-size {
          display: none; }
        .header-slider .left .slick-slide > div .figure .square-size {
          display: flex; } }
      @media screen and (max-width: 37.5em) {
        .header-slider .left .slick-slide > div .figure {
          max-height: 350px; } }
  .header-slider .left * {
    width: 100%;
    height: 100%; }
  .header-slider .left img {
    object-fit: contain !important;
    object-position: top left !important; }
  .header-slider .left video {
    object-fit: contain;
    object-position: top; }
  .header-slider .left .video-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    /* 16:9 aspect ratio (360/640) */ }
    .header-slider .left .video-container #vimeo-player {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--brandingColor);
      pointer-events: none; }
      .header-slider .left .video-container #vimeo-player iframe {
        width: 100%;
        height: 100%;
        pointer-events: none; }
  .header-slider .right {
    background-color: var(--brandingShade);
    position: relative; }
    .header-slider .right:before {
      content: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='71.888' height='71.888' viewBox='0 0 71.888 71.888'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Cpath id='Path_146' data-name='Path 146' d='M0,60.776H71.888V-11.112H0Z' transform='translate(0 11.112)' fill='%23fcf9f6'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Group_112' data-name='Group 112' transform='translate(0 11.112)'%3E%3Cg id='Group_110' data-name='Group 110' transform='translate(0 -11.112)' clip-path='url(%23clip-path)'%3E%3Cg id='Group_109' data-name='Group 109' transform='translate(-0.001 0.001)'%3E%3Cpath id='Path_145' data-name='Path 145' d='M60.776,30.388A35.944,35.944,0,0,1,24.832-5.556,35.94,35.94,0,0,1-11.112,30.388,35.94,35.94,0,0,1,24.832,66.332,35.944,35.944,0,0,1,60.776,30.388' transform='translate(11.112 5.556)' fill='%23fcf9f6'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      position: absolute;
      width: 70px;
      height: 70px;
      top: calc(50% - 35px);
      left: -35px; }
    .header-slider .right .slick-list, .header-slider .right .slick-track, .header-slider .right .slick-slide, .header-slider .right .slick-slide > div {
      height: 100%; }
    .header-slider .right .slick-slide {
      padding: 80px 135px; }
      .header-slider .right .slick-slide > div {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto; }
    .header-slider .right .slick-arrow {
      width: 40px;
      height: 40px;
      z-index: 11;
      bottom: 0;
      position: absolute;
      background-color: var(--brandingColor);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: opacity .3s ease; }
      .header-slider .right .slick-arrow:hover {
        opacity: .7; }
      .header-slider .right .slick-arrow:last-of-type {
        right: 0; }
      .header-slider .right .slick-arrow svg {
        height: 20px; }
    .header-slider .right .calendar-container {
      height: 100%; }
    .header-slider .right .calendar-content {
      display: flex !important;
      flex-direction: column;
      justify-content: center;
      height: 100%; }
      .header-slider .right .calendar-content .intro {
        margin-top: 20px; }
      .header-slider .right .calendar-content .buttons {
        display: flex;
        margin-bottom: 40px; }
        .header-slider .right .calendar-content .buttons > * {
          margin-right: 10px;
          margin-bottom: 10px; }
      .header-slider .right .calendar-content .ticket-options {
        padding-left: 0;
        margin-bottom: 0; }
        .header-slider .right .calendar-content .ticket-options li {
          list-style: none;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-top: 1px solid var(--brandingColor);
          margin-bottom: 0;
          padding: 20px 0; }
          .header-slider .right .calendar-content .ticket-options li > div {
            display: flex;
            flex-direction: column; }
          .header-slider .right .calendar-content .ticket-options li p {
            margin-bottom: 0; }
          .header-slider .right .calendar-content .ticket-options li:last-of-type {
            border-bottom: 1px solid var(--brandingColor); }
      .header-slider .right .calendar-content .kalender-legend {
        display: flex;
        align-items: center; }
        .header-slider .right .calendar-content .kalender-legend--option {
          display: flex;
          align-items: center;
          margin-right: 20px;
          margin-bottom: 10px; }
          .header-slider .right .calendar-content .kalender-legend--option .square {
            width: 12px;
            height: 12px;
            background-color: var(--primary);
            margin-right: 10px; }
            .header-slider .right .calendar-content .kalender-legend--option .square.red {
              background-color: var(--red); }
          .header-slider .right .calendar-content .kalender-legend--option p {
            margin-bottom: 0;
            font-size: 13px; }
        @media screen and (max-width: 37.5em) {
          .header-slider .right .calendar-content .kalender-legend {
            flex-direction: column;
            align-items: flex-start; } }
  @media screen and (max-width: 112.5em) {
    .header-slider {
      grid-template-columns: 55% 45%; } }
  @media screen and (max-width: 87.5em) {
    .header-slider {
      grid-template-columns: 50% 50%; }
      .header-slider .right .slick-slide {
        padding: 60px 70px; } }
  @media screen and (max-width: 68.75em) {
    .header-slider {
      grid-template-columns: 100%; }
      .header-slider .right .slick-slide {
        padding: 40px; }
        .header-slider .right .slick-slide > div {
          margin-left: auto;
          margin-right: auto; } }
  @media screen and (min-width: 37.5em) {
    .header-slider .right .slick-slide .buttons-mobile {
      display: none; } }
  @media screen and (max-width: 37.5em) {
    .header-slider .right .slick-slide .buttons {
      margin-right: 0;
      margin-bottom: 30px;
      width: 100%; }
      .header-slider .right .slick-slide .buttons-mobile {
        display: flex;
        margin-top: 30px; }
        .header-slider .right .slick-slide .buttons-mobile > a {
          width: 100%;
          margin-bottom: 0;
          margin-right: 0; }
      .header-slider .right .slick-slide .buttons > a {
        width: 100%;
        margin-bottom: 0;
        margin-right: 0; }
        .header-slider .right .slick-slide .buttons > a:nth-of-type(2) {
          display: none; } }
  @media screen and (max-width: 31.25em) {
    .header-slider .right .slick-slide {
      padding: 40px 20px; }
      .header-slider .right .slick-slide > div {
        max-width: 280px; } }
  @media screen and (max-width: 25em) {
    .header-slider {
      margin-top: 90px; } }

.kalender {
  max-width: 350px; }
  .kalender .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--brandingOpacity);
    padding: 10px 20px; }
    .kalender .top button {
      cursor: pointer; }

.js-calendar {
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(7, 50px);
  grid-template-rows: 50px; }

.calendar-day {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin-bottom: 0;
  position: relative;
  border: 0px solid var(--brandingOpacity);
  border-bottom-width: 1px;
  border-right-width: 1px; }
  .calendar-day:nth-of-type(7n + 1) {
    border-left-width: 1px; }
  .calendar-day.today:after {
    content: '';
    position: absolute;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: var(--primary); }
  .calendar-day.today span {
    color: white;
    position: relative;
    z-index: 1; }
  .calendar-day.base-tickets.sibling-month, .calendar-day.last-tickets.sibling-month {
    opacity: .6; }
  .calendar-day.base-tickets.today:after, .calendar-day.last-tickets.today:after {
    border: 2px solid white;
    background-color: unset; }
  .calendar-day.base-tickets a, .calendar-day.last-tickets a {
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1; }
  .calendar-day.base-tickets span, .calendar-day.last-tickets span {
    font-weight: bold;
    color: white; }
  .calendar-day.base-tickets:before, .calendar-day.last-tickets:before {
    content: '';
    width: 45px;
    height: 45px;
    left: 2px;
    top: 2px;
    position: absolute; }
  .calendar-day.base-tickets:before {
    background-color: var(--primary); }
  .calendar-day.last-tickets:before {
    background-color: var(--red); }
  .calendar-day.sibling-month, .calendar-day a {
    text-decoration: none;
    color: #ccc; }

@media screen and (max-width: 31.25em) {
  .kalender {
    max-width: 280px; }
  .js-calendar {
    grid-template-columns: repeat(7, 40px);
    grid-template-rows: 40px; }
  .calendar-day {
    width: 40px;
    height: 40px; }
    .calendar-day.today:after {
      width: 25px;
      height: 25px; }
    .calendar-day.base-tickets a, .calendar-day.last-tickets a {
      width: 35px;
      height: 35px; }
    .calendar-day.base-tickets:before, .calendar-day.last-tickets:before {
      width: 35px;
      height: 35px; } }

.flatpickr-monthSelect-month.selected {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  border-radius: 0; }

.flatpickr-monthSelect-month.today {
  border-color: var(--primary) !important;
  border-radius: 0; }

.cast-container {
  background-color: var(--brandingShade);
  padding: 120px 20px;
  margin-top: 120px; }
  .cast-container h1 {
    text-align: left;
    padding-bottom: 20px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: fit-content;
    margin-left: auto;
    margin-right: auto; }
    .cast-container h1 .element {
      letter-spacing: 10px;
      color: var(--brandingColor); }
  .cast-container--content {
    display: flex;
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto; }
    .cast-container--content-image {
      max-width: 500px; }
    .cast-container--content .cast {
      display: grid;
      grid-template-columns: repeat(2, auto);
      grid-row-gap: 50px;
      margin-left: 180px;
      margin-top: 60px; }
      .cast-container--content .cast h2 {
        margin-bottom: 30px; }
      .cast-container--content .cast-item {
        display: flex;
        flex-direction: column;
        min-width: 340px; }
        .cast-container--content .cast-item:not(:nth-of-type(even)) {
          margin-right: 180px; }
        .cast-container--content .cast-item--list {
          display: flex;
          flex-direction: column;
          padding-left: 0;
          margin-bottom: 0; }
          .cast-container--content .cast-item--list-item {
            margin-top: 5px;
            list-style: none; }
          .cast-container--content .cast-item--list .list-item {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid var(--brandingColor);
            padding-bottom: 10px;
            font-weight: 500;
            text-transform: uppercase;
            position: relative; }
          .cast-container--content .cast-item--list .members .member {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(2, auto);
            grid-template-rows: repeat(2, auto);
            grid-template-areas: 'name bio-label' 'bio bio';
            padding: 5px 0;
            justify-content: space-between; }
            .cast-container--content .cast-item--list .members .member-name {
              font-weight: 500;
              font-size: 14px;
              color: var(--primary);
              max-width: 200px;
              grid-area: name; }
            .cast-container--content .cast-item--list .members .member:first-of-type {
              padding-top: 15px; }
            .cast-container--content .cast-item--list .members .member:last-of-type {
              padding-bottom: 15px; }
            .cast-container--content .cast-item--list .members .member a {
              font-size: 12px;
              color: var(--primary); }
            .cast-container--content .cast-item--list .members .member-bio {
              display: none;
              grid-area: bio;
              flex-direction: column;
              margin-bottom: 10px;
              margin-top: 5px;
              max-width: 300px; }
              .cast-container--content .cast-item--list .members .member-bio p {
                margin-bottom: 0;
                font-size: 14px;
                color: var(--primary);
                word-break: break-word; }
              .cast-container--content .cast-item--list .members .member-bio--input:checked ~ .member-bio {
                display: flex; }
              .cast-container--content .cast-item--list .members .member-bio--input:checked ~ .member-bio--label {
                opacity: .7; }
              .cast-container--content .cast-item--list .members .member-bio--label {
                grid-area: bio-label;
                display: flex;
                justify-content: flex-end;
                font-size: 12px;
                text-decoration: underline;
                color: var(--primary); }
          .cast-container--content .cast-item--list.tabs .tab {
            width: 100%;
            color: var(--primary);
            overflow: hidden; }
            .cast-container--content .cast-item--list.tabs .tab-label {
              display: flex;
              justify-content: space-between;
              cursor: pointer;
              border-bottom: 1px solid var(--brandingColor);
              padding-bottom: 10px;
              font-weight: 500;
              text-transform: uppercase;
              position: relative; }
              .cast-container--content .cast-item--list.tabs .tab-label::after {
                content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.887' height='22.359' viewBox='0 0 11.887 22.359'%3E%3Cpath id='Path_133' data-name='Path 133' d='M17987.813-1875.824l10.826,10.826-10.826,10.826' transform='translate(-17987.459 1876.178)' fill='none' stroke='%23000000' stroke-width='1'/%3E%3C/svg%3E");
                width: 14px;
                height: 24px;
                transform-origin: center;
                text-align: center;
                transition: all .35s;
                transform: scale(0.8) rotate(90deg);
                right: 5px;
                position: absolute; }
            .cast-container--content .cast-item--list.tabs .tab-content {
              max-height: 0;
              color: var(--brandingColor);
              transition: all .35s; }
              .cast-container--content .cast-item--list.tabs .tab-content .item {
                display: flex;
                justify-content: space-between; }
                .cast-container--content .cast-item--list.tabs .tab-content .item span {
                  padding-right: 20px; }
          .cast-container--content .cast-item--list.tabs input {
            display: none; }
            .cast-container--content .cast-item--list.tabs input:checked + .tab-label::after {
              transform: scale(0.8) rotate(-90deg); }
            .cast-container--content .cast-item--list.tabs input:checked ~ .tab-content {
              max-height: 100%; }
        .cast-container--content .cast-item:nth-of-type(n+3) {
          margin-top: 30px; }
    @media screen and (max-width: 100em) {
      .cast-container--content .cast {
        margin-left: 50px;
        margin-top: 40px; }
        .cast-container--content .cast-item:not(:nth-of-type(even)) {
          margin-right: 60px; }
        .cast-container--content .cast-item .members .member-name {
          max-width: 180px; } }
    @media screen and (max-width: 75em) {
      .cast-container--content .cast {
        margin-left: 0; }
        .cast-container--content .cast-container--content-image {
          display: none; } }
    @media screen and (max-width: 51.25em) {
      .cast-container--content .cast-item {
        min-width: 280px; }
        .cast-container--content .cast-item .members .member-name {
          max-width: 150px; } }
    @media screen and (max-width: 25em) {
      .cast-container--content .cast-item {
        min-width: 250px; } }
  @media screen and (max-width: 48em) {
    .cast-container {
      padding: 60px 20px;
      margin-top: 60px; }
      .cast-container .cast {
        grid-template-columns: auto; }
        .cast-container .cast-item:not(:nth-of-type(even)) {
          margin-right: 0; } }
  @media screen and (max-width: 37.5em) {
    .cast-container > h1 {
      padding-bottom: 10px;
      margin-bottom: 0px; } }

.accordion--container > .text {
  margin-top: 20px; }
  @media screen and (max-width: 37.5em) {
    .accordion--container > .text {
      margin-top: 10px;
      font-size: 14px; } }

.PressPage .press-items {
  margin-top: 180px; }
  .PressPage .press-items .press-item {
    position: relative;
    background-color: var(--brandingShade);
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: var(--primary);
    transition: background-color .2s ease; }
    .PressPage .press-items .press-item figure {
      width: 100%;
      min-height: 320px;
      max-height: 320px;
      overflow: hidden; }
      .PressPage .press-items .press-item figure img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        transition: transform .2s ease; }
    .PressPage .press-items .press-item .highlight {
      position: absolute;
      left: 0;
      top: 0;
      background-color: var(--primaryDark);
      color: white;
      padding: 10px 15px;
      font-size: 13px;
      letter-spacing: 1px;
      z-index: 1; }
    .PressPage .press-items .press-item .text {
      padding: 55px 65px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      height: 100%;
      z-index: 1; }
      .PressPage .press-items .press-item .text h3 {
        margin-bottom: 40px;
        transition: color .2s ease;
        line-height: 28px; }
      .PressPage .press-items .press-item .text .cta {
        transition: color .2s ease, border-color .2s ease; }
      @media screen and (max-width: 68.75em) {
        .PressPage .press-items .press-item .text {
          padding: 40px 30px; } }
    .PressPage .press-items .press-item:hover {
      background-color: var(--brandingColor); }
      .PressPage .press-items .press-item:hover figure img {
        transform: scale(1.05); }
      .PressPage .press-items .press-item:hover .text h3 {
        color: white !important; }
      .PressPage .press-items .press-item:hover .text .cta {
        color: white !important;
        border-color: white !important; }
        .PressPage .press-items .press-item:hover .text .cta:before {
          transform: scaleX(0);
          background-color: white; }
  @media screen and (max-width: 48em) {
    .PressPage .press-items {
      margin-top: 80px; } }

.media-items {
  margin-top: 180px; }
  .media-items--invisible {
    display: none; }

.media-item {
  display: flex;
  flex-direction: column; }
  .media-item > a {
    position: relative; }
    .media-item > a .media-item--figure {
      overflow: hidden;
      height: 300px; }
      .media-item > a .media-item--figure img {
        transition: transform .2s ease;
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .media-item > a .media-item--button {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(var(--primary), 0.75);
      opacity: 0;
      transition: opacity .2s ease;
      z-index: 1; }
      .media-item > a .media-item--button .cta {
        background-color: var(--brandingColor);
        pointer-events: none; }
      .media-item > a .media-item--button:hover {
        opacity: 1; }
        .media-item > a .media-item--button:hover ~ .media-item--figure img {
          transform: scale(1.02); }
  .media-item > h3 {
    margin-top: 20px;
    margin-bottom: 0; }

@media screen and (max-width: 48em) {
  .media-items {
    margin-top: 80px; } }

.PagesWithListPage .page-header--image {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  margin-top: 100px;
  margin-bottom: 100px; }
  .PagesWithListPage .page-header--image img {
    max-width: 1160px; }
  @media screen and (max-width: 37.5em) {
    .PagesWithListPage .page-header--image {
      margin-top: 60px;
      margin-bottom: 60px; } }

.PagesWithListPage .listing {
  margin-top: 180px; }
  .PagesWithListPage .listing-item {
    display: grid;
    grid-template-columns: 540px auto; }
    .PagesWithListPage .listing-item--image {
      width: 100%;
      overflow: hidden; }
      .PagesWithListPage .listing-item--image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform-origin: center;
        transition: transform .2s ease; }
    .PagesWithListPage .listing-item.hovering .listing-item--image img {
      transform: scale(1.05); }
    .PagesWithListPage .listing-item--info {
      background-color: var(--brandingShade);
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 100px 140px; }
      .PagesWithListPage .listing-item--info .subtitle {
        color: var(--brandingColor);
        font-weight: 400;
        text-transform: uppercase;
        font-size: 14px;
        margin-top: 10px; }
      .PagesWithListPage .listing-item--info > div p {
        margin-bottom: 0; }
      .PagesWithListPage .listing-item--info .ctas {
        display: flex;
        align-items: flex-end;
        margin-top: 30px; }
        .PagesWithListPage .listing-item--info .ctas .cta {
          color: var(--brandingColor) !important;
          z-index: 1;
          position: relative; }
          .PagesWithListPage .listing-item--info .ctas .cta:after {
            opacity: 0 !important; }
          .PagesWithListPage .listing-item--info .ctas .cta:hover {
            color: white !important; }
            .PagesWithListPage .listing-item--info .ctas .cta:hover:after {
              opacity: 1 !important; }
          .PagesWithListPage .listing-item--info .ctas .cta:not(:last-of-type) {
            margin-right: 20px; }
  .PagesWithListPage .listing .listing-item + .listing-item {
    margin-top: 40px; }
  @media screen and (max-width: 93.75em) {
    .PagesWithListPage .listing {
      margin-left: 80px;
      margin-right: 80px; } }
  @media screen and (max-width: 84.375em) {
    .PagesWithListPage .listing-item--info {
      padding: 60px; } }
  @media screen and (max-width: 78.125em) {
    .PagesWithListPage .listing-item {
      grid-template-columns: 400px auto; } }
  @media screen and (max-width: 62.5em) {
    .PagesWithListPage .listing {
      margin-left: 30px;
      margin-right: 30px; }
      .PagesWithListPage .listing-item {
        grid-template-columns: 320px auto; }
        .PagesWithListPage .listing-item--info {
          padding: 40px; } }
  @media screen and (max-width: 48em) {
    .PagesWithListPage .listing {
      margin-top: 80px; }
      .PagesWithListPage .listing-item {
        grid-template-columns: auto; }
        .PagesWithListPage .listing-item--image {
          max-height: 400px; }
        .PagesWithListPage .listing-item--info {
          padding: 40px 30px; }
          .PagesWithListPage .listing-item--info .text * {
            margin-bottom: 0; } }
  @media screen and (max-width: 37.5em) {
    .PagesWithListPage .listing-item--image {
      max-height: 280px; } }

.PagesWithListPage .golden-ctas, .PagesWithListPage .slider {
  margin-top: 300px; }
  @media screen and (max-width: 87.5em) {
    .PagesWithListPage .golden-ctas, .PagesWithListPage .slider {
      margin-top: 160px; } }
  @media screen and (max-width: 48em) {
    .PagesWithListPage .golden-ctas, .PagesWithListPage .slider {
      margin-top: 80px; } }

.PagesWithListPage .slider-item--info {
  height: fit-content; }

.NieuwsPage .textblocks {
  position: relative; }
  .NieuwsPage .textblocks .content-block--container {
    margin-top: 40px; }
    .NieuwsPage .textblocks .content-block--container > *:not(:first-of-type) {
      margin-top: 60px; }
  .NieuwsPage .textblocks .content-block.textBlock {
    margin-right: 200px; }
    .NieuwsPage .textblocks .content-block.textBlock .title {
      margin-bottom: 10px;
      font-weight: 600; }
  .NieuwsPage .textblocks .share {
    display: flex;
    align-items: flex-start;
    margin-bottom: 40px; }
    .NieuwsPage .textblocks .share p {
      margin-right: 10px; }
    .NieuwsPage .textblocks .share-buttons {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .NieuwsPage .textblocks .share-buttons a {
        text-decoration: none;
        height: 20px; }
        .NieuwsPage .textblocks .share-buttons a svg {
          height: 100%; }
          .NieuwsPage .textblocks .share-buttons a svg path {
            fill: var(--brandingColor); }
      .NieuwsPage .textblocks .share-buttons a + a {
        margin-top: 5px; }
    .NieuwsPage .textblocks .share-press {
      position: absolute;
      top: 0;
      right: 140px;
      display: flex;
      flex-direction: column;
      align-items: flex-end; }
      .NieuwsPage .textblocks .share-press--container {
        position: sticky;
        top: 200px; }
      .NieuwsPage .textblocks .share-press li {
        list-style: none;
        display: flex;
        justify-content: flex-end; }
        .NieuwsPage .textblocks .share-press li:not(:first-of-type) {
          margin-top: 10px; }
  @media screen and (max-width: 68.75em) {
    .NieuwsPage .textblocks .content-block.textBlock {
      margin-right: 0; }
    .NieuwsPage .textblocks .share {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      left: 0; }
      .NieuwsPage .textblocks .share p {
        margin-top: 5px;
        margin-bottom: 0px; }
      .NieuwsPage .textblocks .share-buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-left: 10px; }
        .NieuwsPage .textblocks .share-buttons a + a {
          margin-top: 0;
          margin-left: 10px; }
      .NieuwsPage .textblocks .share-press {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        position: relative;
        right: unset;
        left: unset; }
        .NieuwsPage .textblocks .share-press--container {
          position: relative;
          justify-content: flex-start;
          top: 0;
          left: 0;
          display: flex;
          padding: 0 50px;
          margin-bottom: 50px; }
        .NieuwsPage .textblocks .share-press .press {
          padding-left: 0;
          display: flex;
          flex-direction: column;
          align-items: flex-start; } }
  @media screen and (max-width: 37.5em) {
    .NieuwsPage .textblocks .share-press--container {
      padding: 0 30px; } }

.NieuwsOverviewPage .listing {
  margin-top: 180px; }
  .NieuwsOverviewPage .listing-item {
    display: grid;
    grid-template-columns: 540px auto; }
    .NieuwsOverviewPage .listing-item--image {
      width: 100%;
      overflow: hidden; }
      .NieuwsOverviewPage .listing-item--image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform-origin: center;
        transition: transform .2s ease; }
    .NieuwsOverviewPage .listing-item.hovering .listing-item--image img {
      transform: scale(1.05); }
    .NieuwsOverviewPage .listing-item--info {
      background-color: var(--brandingShade);
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 100px 140px; }
      .NieuwsOverviewPage .listing-item--info h2 {
        max-width: 400px; }
      .NieuwsOverviewPage .listing-item--info .subtitle {
        color: var(--brandingColor);
        font-weight: 400;
        text-transform: uppercase;
        font-size: 14px;
        margin-top: 10px; }
      .NieuwsOverviewPage .listing-item--info .text {
        margin-bottom: 30px; }
      .NieuwsOverviewPage .listing-item--info .ctas {
        display: flex;
        align-items: flex-end; }
        .NieuwsOverviewPage .listing-item--info .ctas .cta:not(.simple) {
          transition: background-color .2s ease, border-color .2s ease; }
          .NieuwsOverviewPage .listing-item--info .ctas .cta:not(.simple) span {
            color: var(--brandingColor);
            transition: color .2s ease; }
          .NieuwsOverviewPage .listing-item--info .ctas .cta:not(.simple):hover {
            background-color: var(--primaryDark) !important;
            border-color: var(--primaryDark) !important; }
            .NieuwsOverviewPage .listing-item--info .ctas .cta:not(.simple):hover span {
              color: white !important; }
        .NieuwsOverviewPage .listing-item--info .ctas .cta:not(:last-of-type) {
          margin-right: 20px; }
  .NieuwsOverviewPage .listing .listing-item + .listing-item {
    margin-top: 40px; }
  @media screen and (max-width: 93.75em) {
    .NieuwsOverviewPage .listing {
      margin-left: 80px;
      margin-right: 80px; } }
  @media screen and (max-width: 84.375em) {
    .NieuwsOverviewPage .listing-item--info {
      padding: 60px; } }
  @media screen and (max-width: 78.125em) {
    .NieuwsOverviewPage .listing-item {
      grid-template-columns: 400px auto; } }
  @media screen and (max-width: 62.5em) {
    .NieuwsOverviewPage .listing {
      margin-left: 30px;
      margin-right: 30px; }
      .NieuwsOverviewPage .listing-item {
        grid-template-columns: 320px auto; }
        .NieuwsOverviewPage .listing-item--info {
          padding: 40px; } }
  @media screen and (max-width: 48em) {
    .NieuwsOverviewPage .listing {
      margin-top: 80px; }
      .NieuwsOverviewPage .listing-item {
        grid-template-columns: auto; }
        .NieuwsOverviewPage .listing-item--image {
          max-height: 400px; }
        .NieuwsOverviewPage .listing-item--info {
          padding: 40px 30px; }
          .NieuwsOverviewPage .listing-item--info .text * {
            margin-bottom: 0; } }
  @media screen and (max-width: 37.5em) {
    .NieuwsOverviewPage .listing-item--image {
      max-height: 280px; } }

.ArrangementsOverviewPage .Filters, .ShowsOverviewPage .Filters {
  margin-top: 140px;
  margin-bottom: 30px;
  z-index: 1;
  position: relative;
  flex-wrap: wrap; }
  @media screen and (max-width: 37.5em) {
    .ArrangementsOverviewPage .Filters, .ShowsOverviewPage .Filters {
      margin-top: 80px;
      margin-bottom: 80px; } }

.ArrangementsOverviewPage .Content-list .no-results, .ShowsOverviewPage .Content-list .no-results {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 200px;
  margin-bottom: 100px; }
  .ArrangementsOverviewPage .Content-list .no-results h1, .ArrangementsOverviewPage .Content-list .no-results h2, .ShowsOverviewPage .Content-list .no-results h1, .ShowsOverviewPage .Content-list .no-results h2 {
    text-align: center; }

.ArrangementsOverviewPage .golden-ctas, .ShowsOverviewPage .golden-ctas {
  padding-top: 320px;
  padding-bottom: 160px; }

.ArrangementsOverviewPage .nothing-found, .ShowsOverviewPage .nothing-found {
  margin-top: 80px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .ArrangementsOverviewPage .nothing-found h1, .ShowsOverviewPage .nothing-found h1 {
    font-size: 36px;
    max-width: 600px;
    margin-left: 20px;
    margin-right: 20px;
    line-height: 44px; }
  .ArrangementsOverviewPage .nothing-found a, .ShowsOverviewPage .nothing-found a {
    margin-top: 20px;
    color: var(--primary); }

@media screen and (max-width: 75em) {
  .ArrangementsOverviewPage .Filters, .ArrangementsOverviewPage .Content-list, .ShowsOverviewPage .Filters, .ShowsOverviewPage .Content-list {
    margin-left: 30px;
    margin-right: 30px; }
  .ArrangementsOverviewPage .golden-ctas, .ShowsOverviewPage .golden-ctas {
    padding-top: 160px;
    padding-bottom: 80px; } }

@media screen and (max-width: 37.5em) {
  .ArrangementsOverviewPage .Filters, .ArrangementsOverviewPage .Content-list, .ShowsOverviewPage .Filters, .ShowsOverviewPage .Content-list {
    padding-left: 0;
    padding-right: 0; }
  .ArrangementsOverviewPage .golden-ctas, .ShowsOverviewPage .golden-ctas {
    padding-top: 80px;
    padding-bottom: 30px; } }

.ShowsPage .videos, .ArrangementsPage .videos, .ExtrasOverviewPage .videos {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  display: grid;
  max-width: 1120px;
  grid-row-gap: 20px;
  grid-template-columns: repeat(2, 50%); }
  .ShowsPage .videos .video, .ArrangementsPage .videos .video, .ExtrasOverviewPage .videos .video {
    max-width: 560px;
    width: 100%;
    height: 400px; }
    .ShowsPage .videos .video:nth-of-type(odd), .ArrangementsPage .videos .video:nth-of-type(odd), .ExtrasOverviewPage .videos .video:nth-of-type(odd) {
      padding-right: 10px; }
    .ShowsPage .videos .video:nth-of-type(even), .ArrangementsPage .videos .video:nth-of-type(even), .ExtrasOverviewPage .videos .video:nth-of-type(even) {
      padding-left: 10px; }
  @media screen and (max-width: 56.25em) {
    .ShowsPage .videos, .ArrangementsPage .videos, .ExtrasOverviewPage .videos {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .ShowsPage .videos .video:nth-of-type(odd), .ArrangementsPage .videos .video:nth-of-type(odd), .ExtrasOverviewPage .videos .video:nth-of-type(odd) {
        padding-right: 0; }
      .ShowsPage .videos .video:nth-of-type(even), .ArrangementsPage .videos .video:nth-of-type(even), .ExtrasOverviewPage .videos .video:nth-of-type(even) {
        padding-left: 0; } }
  .ShowsPage .videos.amount-1, .ArrangementsPage .videos.amount-1, .ExtrasOverviewPage .videos.amount-1 {
    display: flex;
    justify-content: center; }
    .ShowsPage .videos.amount-1 .video, .ArrangementsPage .videos.amount-1 .video, .ExtrasOverviewPage .videos.amount-1 .video {
      max-width: 1100px;
      height: 700px;
      padding-left: 0 !important;
      padding-right: 0 !important; }
      @media screen and (max-width: 56.25em) {
        .ShowsPage .videos.amount-1 .video, .ArrangementsPage .videos.amount-1 .video, .ExtrasOverviewPage .videos.amount-1 .video {
          height: 500px; } }
  .ShowsPage .videos-container, .ArrangementsPage .videos-container, .ExtrasOverviewPage .videos-container {
    margin-top: 150px;
    margin-bottom: 100px; }
  @media screen and (max-width: 48em) {
    .ShowsPage .videos .video, .ArrangementsPage .videos .video, .ExtrasOverviewPage .videos .video {
      height: 400px !important; }
    .ShowsPage .videos-container, .ArrangementsPage .videos-container, .ExtrasOverviewPage .videos-container {
      margin-top: 120px;
      margin-bottom: 80px; } }
  @media screen and (max-width: 37.5em) {
    .ShowsPage .videos .video, .ArrangementsPage .videos .video, .ExtrasOverviewPage .videos .video {
      height: 250px !important; }
    .ShowsPage .videos-container, .ArrangementsPage .videos-container, .ExtrasOverviewPage .videos-container {
      margin-top: 60px;
      margin-bottom: 40px; } }

.ShowsPage .header, .ArrangementsPage .header, .ExtrasOverviewPage .header {
  display: grid;
  grid-template-columns: 65% 30%;
  grid-template-areas: 'left right';
  margin-top: 120px; }
  .ShowsPage .header.mobile, .ArrangementsPage .header.mobile, .ExtrasOverviewPage .header.mobile {
    display: none;
    flex-direction: column;
    align-items: center;
    grid-template-columns: unset; }
    .ShowsPage .header.mobile .text-container, .ArrangementsPage .header.mobile .text-container, .ExtrasOverviewPage .header.mobile .text-container {
      display: flex; }
  .ShowsPage .header .left, .ArrangementsPage .header .left, .ExtrasOverviewPage .header .left {
    grid-area: left; }
  .ShowsPage .header .right, .ArrangementsPage .header .right, .ExtrasOverviewPage .header .right {
    grid-area: right; }
  .ShowsPage .header .calendar-container, .ArrangementsPage .header .calendar-container, .ExtrasOverviewPage .header .calendar-container {
    height: 100%; }
  .ShowsPage .header .calendar-content, .ArrangementsPage .header .calendar-content, .ExtrasOverviewPage .header .calendar-content {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .ShowsPage .header .calendar-content .intro, .ArrangementsPage .header .calendar-content .intro, .ExtrasOverviewPage .header .calendar-content .intro {
      margin-top: 20px; }
    .ShowsPage .header .calendar-content .buttons, .ArrangementsPage .header .calendar-content .buttons, .ExtrasOverviewPage .header .calendar-content .buttons {
      display: flex;
      margin-bottom: 40px; }
      .ShowsPage .header .calendar-content .buttons > *, .ArrangementsPage .header .calendar-content .buttons > *, .ExtrasOverviewPage .header .calendar-content .buttons > * {
        margin-right: 10px;
        margin-bottom: 10px; }
    .ShowsPage .header .calendar-content .ticket-options, .ArrangementsPage .header .calendar-content .ticket-options, .ExtrasOverviewPage .header .calendar-content .ticket-options {
      padding-left: 0;
      margin-bottom: 0; }
      .ShowsPage .header .calendar-content .ticket-options li, .ArrangementsPage .header .calendar-content .ticket-options li, .ExtrasOverviewPage .header .calendar-content .ticket-options li {
        list-style: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid var(--brandingColor);
        margin-bottom: 0;
        padding: 20px 0; }
        .ShowsPage .header .calendar-content .ticket-options li > div, .ArrangementsPage .header .calendar-content .ticket-options li > div, .ExtrasOverviewPage .header .calendar-content .ticket-options li > div {
          display: flex;
          flex-direction: column; }
        .ShowsPage .header .calendar-content .ticket-options li p, .ArrangementsPage .header .calendar-content .ticket-options li p, .ExtrasOverviewPage .header .calendar-content .ticket-options li p {
          margin-bottom: 0; }
        .ShowsPage .header .calendar-content .ticket-options li:last-of-type, .ArrangementsPage .header .calendar-content .ticket-options li:last-of-type, .ExtrasOverviewPage .header .calendar-content .ticket-options li:last-of-type {
          border-bottom: 1px solid var(--brandingColor); }
    .ShowsPage .header .calendar-content .kalender-legend, .ArrangementsPage .header .calendar-content .kalender-legend, .ExtrasOverviewPage .header .calendar-content .kalender-legend {
      display: flex;
      align-items: center; }
      .ShowsPage .header .calendar-content .kalender-legend--option, .ArrangementsPage .header .calendar-content .kalender-legend--option, .ExtrasOverviewPage .header .calendar-content .kalender-legend--option {
        display: flex;
        align-items: center;
        margin-right: 20px;
        margin-bottom: 10px; }
        .ShowsPage .header .calendar-content .kalender-legend--option .square, .ArrangementsPage .header .calendar-content .kalender-legend--option .square, .ExtrasOverviewPage .header .calendar-content .kalender-legend--option .square {
          width: 12px;
          height: 12px;
          background-color: var(--primary);
          margin-right: 10px; }
          .ShowsPage .header .calendar-content .kalender-legend--option .square.red, .ArrangementsPage .header .calendar-content .kalender-legend--option .square.red, .ExtrasOverviewPage .header .calendar-content .kalender-legend--option .square.red {
            background-color: var(--red); }
        .ShowsPage .header .calendar-content .kalender-legend--option p, .ArrangementsPage .header .calendar-content .kalender-legend--option p, .ExtrasOverviewPage .header .calendar-content .kalender-legend--option p {
          margin-bottom: 0;
          font-size: 13px; }
      @media screen and (max-width: 37.5em) {
        .ShowsPage .header .calendar-content .kalender-legend, .ArrangementsPage .header .calendar-content .kalender-legend, .ExtrasOverviewPage .header .calendar-content .kalender-legend {
          flex-direction: column;
          align-items: flex-start; } }
  .ShowsPage .header .figure, .ArrangementsPage .header .figure, .ExtrasOverviewPage .header .figure {
    width: 100%; }
    .ShowsPage .header .figure .square-size, .ArrangementsPage .header .figure .square-size, .ExtrasOverviewPage .header .figure .square-size {
      display: none; }
    @media screen and (min-width: 81.25em) and (max-width: 112.5em) {
      .ShowsPage .header .figure .normal-size, .ArrangementsPage .header .figure .normal-size, .ExtrasOverviewPage .header .figure .normal-size {
        display: none; }
      .ShowsPage .header .figure .square-size, .ArrangementsPage .header .figure .square-size, .ExtrasOverviewPage .header .figure .square-size {
        display: flex; } }
    @media screen and (max-width: 25em) {
      .ShowsPage .header .figure .normal-size, .ArrangementsPage .header .figure .normal-size, .ExtrasOverviewPage .header .figure .normal-size {
        display: none; }
      .ShowsPage .header .figure .square-size, .ArrangementsPage .header .figure .square-size, .ExtrasOverviewPage .header .figure .square-size {
        display: flex; } }
    .ShowsPage .header .figure *, .ArrangementsPage .header .figure *, .ExtrasOverviewPage .header .figure * {
      width: inherit; }
    .ShowsPage .header .figure .image, .ArrangementsPage .header .figure .image, .ExtrasOverviewPage .header .figure .image {
      height: 620px; }
      .ShowsPage .header .figure .image.landscape, .ArrangementsPage .header .figure .image.landscape, .ExtrasOverviewPage .header .figure .image.landscape {
        height: auto !important; }
        .ShowsPage .header .figure .image.landscape img, .ArrangementsPage .header .figure .image.landscape img, .ExtrasOverviewPage .header .figure .image.landscape img {
          object-fit: contain !important; }
  .ShowsPage .header .parameters, .ArrangementsPage .header .parameters, .ExtrasOverviewPage .header .parameters {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 20px 40px;
    margin-bottom: 50px; }
    .ShowsPage .header .parameters > *, .ArrangementsPage .header .parameters > *, .ExtrasOverviewPage .header .parameters > * {
      margin: 10px 30px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      max-width: 280px; }
      .ShowsPage .header .parameters > * .icon, .ArrangementsPage .header .parameters > * .icon, .ExtrasOverviewPage .header .parameters > * .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 50px;
        min-height: 50px;
        max-width: 50px;
        max-height: 50px;
        border: 1px solid var(--primary);
        border-radius: 100%;
        margin-right: 15px; }
        .ShowsPage .header .parameters > * .icon svg, .ArrangementsPage .header .parameters > * .icon svg, .ExtrasOverviewPage .header .parameters > * .icon svg {
          width: 20px;
          height: 20px; }
      .ShowsPage .header .parameters > * .text p, .ArrangementsPage .header .parameters > * .text p, .ExtrasOverviewPage .header .parameters > * .text p {
        margin-bottom: 0;
        line-height: 20px; }
      .ShowsPage .header .parameters > * .text a, .ArrangementsPage .header .parameters > * .text a, .ExtrasOverviewPage .header .parameters > * .text a {
        color: grey;
        text-decoration: none;
        font-size: 12px;
        border-bottom: 1px solid #B9B9B9;
        padding-bottom: 3px; }
    @media screen and (max-width: 37.5em) {
      .ShowsPage .header .parameters, .ArrangementsPage .header .parameters, .ExtrasOverviewPage .header .parameters {
        padding: 20px; } }
  .ShowsPage .header .textcontent-container, .ArrangementsPage .header .textcontent-container, .ExtrasOverviewPage .header .textcontent-container {
    margin-left: 260px;
    margin-right: 260px;
    margin-top: 20px; }
    .ShowsPage .header .textcontent-container.more-spacing-top, .ArrangementsPage .header .textcontent-container.more-spacing-top, .ExtrasOverviewPage .header .textcontent-container.more-spacing-top {
      margin-top: 120px; }
    .ShowsPage .header .textcontent-container .back, .ArrangementsPage .header .textcontent-container .back, .ExtrasOverviewPage .header .textcontent-container .back {
      text-decoration: none;
      color: var(--primary);
      display: flex;
      align-items: center;
      transition: transform .2s ease;
      margin-bottom: 80px; }
      .ShowsPage .header .textcontent-container .back span, .ArrangementsPage .header .textcontent-container .back span, .ExtrasOverviewPage .header .textcontent-container .back span {
        margin-left: 10px; }
      .ShowsPage .header .textcontent-container .back:hover, .ArrangementsPage .header .textcontent-container .back:hover, .ExtrasOverviewPage .header .textcontent-container .back:hover {
        transform: translateX(-5px); }
    .ShowsPage .header .textcontent-container .zaal, .ArrangementsPage .header .textcontent-container .zaal, .ExtrasOverviewPage .header .textcontent-container .zaal {
      text-transform: uppercase;
      color: var(--brandingColor);
      margin-bottom: 5px; }
    .ShowsPage .header .textcontent-container .head-title, .ArrangementsPage .header .textcontent-container .head-title, .ExtrasOverviewPage .header .textcontent-container .head-title {
      margin-bottom: 50px; }
    .ShowsPage .header .textcontent-container .quote, .ArrangementsPage .header .textcontent-container .quote, .ExtrasOverviewPage .header .textcontent-container .quote {
      margin-top: 60px; }
  .ShowsPage .header .event-list, .ArrangementsPage .header .event-list, .ExtrasOverviewPage .header .event-list {
    min-height: 620px;
    background-color: var(--brandingShade);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 100px 80px; }
    .ShowsPage .header .event-list h2, .ArrangementsPage .header .event-list h2, .ExtrasOverviewPage .header .event-list h2 {
      margin-bottom: 20px; }
    .ShowsPage .header .event-list .ticket-options, .ArrangementsPage .header .event-list .ticket-options, .ExtrasOverviewPage .header .event-list .ticket-options {
      max-width: 400px;
      width: 100%; }
      .ShowsPage .header .event-list .ticket-options .option, .ArrangementsPage .header .event-list .ticket-options .option, .ExtrasOverviewPage .header .event-list .ticket-options .option {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0;
        border-bottom: 1px solid var(--brandingOpacity); }
        .ShowsPage .header .event-list .ticket-options .option p, .ArrangementsPage .header .event-list .ticket-options .option p, .ExtrasOverviewPage .header .event-list .ticket-options .option p {
          margin-bottom: 0; }
          .ShowsPage .header .event-list .ticket-options .option p.date, .ArrangementsPage .header .event-list .ticket-options .option p.date, .ExtrasOverviewPage .header .event-list .ticket-options .option p.date {
            font-weight: 500;
            text-transform: capitalize; }
          .ShowsPage .header .event-list .ticket-options .option p.hour, .ArrangementsPage .header .event-list .ticket-options .option p.hour, .ExtrasOverviewPage .header .event-list .ticket-options .option p.hour {
            display: flex;
            align-items: center; }
            .ShowsPage .header .event-list .ticket-options .option p.hour span, .ArrangementsPage .header .event-list .ticket-options .option p.hour span, .ExtrasOverviewPage .header .event-list .ticket-options .option p.hour span {
              margin-left: 5px; }
        .ShowsPage .header .event-list .ticket-options .option .cta, .ArrangementsPage .header .event-list .ticket-options .option .cta, .ExtrasOverviewPage .header .event-list .ticket-options .option .cta {
          z-index: 1; }
        .ShowsPage .header .event-list .ticket-options .option:first-of-type, .ArrangementsPage .header .event-list .ticket-options .option:first-of-type, .ExtrasOverviewPage .header .event-list .ticket-options .option:first-of-type {
          border-top: 1px solid var(--brandingOpacity); }
      .ShowsPage .header .event-list .ticket-options .month-navigation--top, .ArrangementsPage .header .event-list .ticket-options .month-navigation--top, .ExtrasOverviewPage .header .event-list .ticket-options .month-navigation--top {
        padding: 5px 10px;
        background-color: var(--brandingOpacity); }
        .ShowsPage .header .event-list .ticket-options .month-navigation--top_title, .ArrangementsPage .header .event-list .ticket-options .month-navigation--top_title, .ExtrasOverviewPage .header .event-list .ticket-options .month-navigation--top_title {
          margin-bottom: 0; }
        .ShowsPage .header .event-list .ticket-options .month-navigation--top_month, .ArrangementsPage .header .event-list .ticket-options .month-navigation--top_month, .ExtrasOverviewPage .header .event-list .ticket-options .month-navigation--top_month {
          text-transform: uppercase;
          font-size: 14px; }
        .ShowsPage .header .event-list .ticket-options .month-navigation--top_arrows, .ArrangementsPage .header .event-list .ticket-options .month-navigation--top_arrows, .ExtrasOverviewPage .header .event-list .ticket-options .month-navigation--top_arrows {
          display: flex;
          justify-content: space-between; }
        .ShowsPage .header .event-list .ticket-options .month-navigation--top_arrow, .ArrangementsPage .header .event-list .ticket-options .month-navigation--top_arrow, .ExtrasOverviewPage .header .event-list .ticket-options .month-navigation--top_arrow {
          cursor: pointer; }
          .ShowsPage .header .event-list .ticket-options .month-navigation--top_arrow svg, .ArrangementsPage .header .event-list .ticket-options .month-navigation--top_arrow svg, .ExtrasOverviewPage .header .event-list .ticket-options .month-navigation--top_arrow svg {
            width: 8px;
            height: 20px; }
  .ShowsPage .header .content-parameters, .ArrangementsPage .header .content-parameters, .ExtrasOverviewPage .header .content-parameters {
    display: flex;
    flex-direction: column; }
    .ShowsPage .header .content-parameters .parameters, .ArrangementsPage .header .content-parameters .parameters, .ExtrasOverviewPage .header .content-parameters .parameters {
      display: grid;
      grid-template-columns: repeat(2, 50%);
      grid-column-gap: 20px;
      align-items: center;
      justify-content: center;
      padding: 20px 40px;
      margin-bottom: 0;
      margin-top: 1px;
      background-color: var(--brandingShade); }
      .ShowsPage .header .content-parameters .parameters li, .ArrangementsPage .header .content-parameters .parameters li, .ExtrasOverviewPage .header .content-parameters .parameters li {
        list-style: none;
        margin-bottom: 0;
        display: flex;
        align-items: center; }
        .ShowsPage .header .content-parameters .parameters li figure, .ArrangementsPage .header .content-parameters .parameters li figure, .ExtrasOverviewPage .header .content-parameters .parameters li figure {
          min-width: 80px;
          max-width: 80px;
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid black;
          border-radius: 100px;
          margin-right: 20px; }
        .ShowsPage .header .content-parameters .parameters li .text, .ArrangementsPage .header .content-parameters .parameters li .text, .ExtrasOverviewPage .header .content-parameters .parameters li .text {
          max-width: 130px; }
          .ShowsPage .header .content-parameters .parameters li .text p, .ArrangementsPage .header .content-parameters .parameters li .text p, .ExtrasOverviewPage .header .content-parameters .parameters li .text p {
            margin-bottom: 0; }
  @media screen and (min-width: 131.25em) {
    .ShowsPage .header .left .figure .image, .ArrangementsPage .header .left .figure .image, .ExtrasOverviewPage .header .left .figure .image {
      height: 800px; }
    .ShowsPage .header .right .event-list, .ArrangementsPage .header .right .event-list, .ExtrasOverviewPage .header .right .event-list {
      min-height: 800px; } }
  @media screen and (max-width: 112.5em) {
    .ShowsPage .header, .ArrangementsPage .header, .ExtrasOverviewPage .header {
      grid-template-columns: 55% 40%; }
      .ShowsPage .header .left .textcontent-container, .ArrangementsPage .header .left .textcontent-container, .ExtrasOverviewPage .header .left .textcontent-container {
        margin-left: 100px;
        margin-right: 100px; } }
  @media screen and (max-width: 93.75em) {
    .ShowsPage .header, .ArrangementsPage .header, .ExtrasOverviewPage .header {
      grid-template-columns: 50% 45%; } }
  @media screen and (max-width: 81.25em) {
    .ShowsPage .header, .ArrangementsPage .header, .ExtrasOverviewPage .header {
      grid-template-columns: 50% 50%;
      padding-right: 30px; }
      .ShowsPage .header .left .figure .image, .ArrangementsPage .header .left .figure .image, .ExtrasOverviewPage .header .left .figure .image {
        height: 360px; }
      .ShowsPage .header .left .textcontent-container, .ArrangementsPage .header .left .textcontent-container, .ExtrasOverviewPage .header .left .textcontent-container {
        margin-left: 30px;
        margin-right: 30px; }
        .ShowsPage .header .left .textcontent-container .back, .ArrangementsPage .header .left .textcontent-container .back, .ExtrasOverviewPage .header .left .textcontent-container .back {
          margin-bottom: 40px; }
      .ShowsPage .header .right .event-list, .ArrangementsPage .header .right .event-list, .ExtrasOverviewPage .header .right .event-list {
        padding: 60px 40px;
        min-height: 500px; }
      .ShowsPage .header .right .golden-ctas.small, .ArrangementsPage .header .right .golden-ctas.small, .ExtrasOverviewPage .header .right .golden-ctas.small {
        margin-top: 250px !important; } }
  @media screen and (max-width: 68.75em) {
    .ShowsPage .header, .ArrangementsPage .header, .ExtrasOverviewPage .header {
      padding-right: 0; }
      .ShowsPage .header.desktop, .ArrangementsPage .header.desktop, .ExtrasOverviewPage .header.desktop {
        display: none; }
      .ShowsPage .header.mobile, .ArrangementsPage .header.mobile, .ExtrasOverviewPage .header.mobile {
        display: flex; }
      .ShowsPage .header .event-list, .ArrangementsPage .header .event-list, .ExtrasOverviewPage .header .event-list {
        padding: 50px 20px;
        min-height: unset;
        width: 100%; }
      .ShowsPage .header .figure, .ArrangementsPage .header .figure, .ExtrasOverviewPage .header .figure {
        min-height: unset; }
        .ShowsPage .header .figure .image, .ArrangementsPage .header .figure .image, .ExtrasOverviewPage .header .figure .image {
          height: 500px; }
      .ShowsPage .header .text-container, .ArrangementsPage .header .text-container, .ExtrasOverviewPage .header .text-container {
        flex-direction: column; }
        .ShowsPage .header .text-container .textcontent-container, .ArrangementsPage .header .text-container .textcontent-container, .ExtrasOverviewPage .header .text-container .textcontent-container {
          margin-left: 50px;
          margin-right: 50px; }
          .ShowsPage .header .text-container .textcontent-container .back, .ArrangementsPage .header .text-container .textcontent-container .back, .ExtrasOverviewPage .header .text-container .textcontent-container .back {
            margin-bottom: 40px; }
        .ShowsPage .header .text-container .golden-ctas.small, .ArrangementsPage .header .text-container .golden-ctas.small, .ExtrasOverviewPage .header .text-container .golden-ctas.small {
          margin-top: 20px !important;
          padding: 0 50px; }
          .ShowsPage .header .text-container .golden-ctas.small > span:not(:last-of-type), .ArrangementsPage .header .text-container .golden-ctas.small > span:not(:last-of-type), .ExtrasOverviewPage .header .text-container .golden-ctas.small > span:not(:last-of-type) {
            margin-bottom: 0; } }
  @media screen and (max-width: 37.5em) {
    .ShowsPage .header .figure .image, .ArrangementsPage .header .figure .image, .ExtrasOverviewPage .header .figure .image {
      height: 300px; }
    .ShowsPage .header .parameters, .ArrangementsPage .header .parameters, .ExtrasOverviewPage .header .parameters {
      margin-bottom: 20px; }
      .ShowsPage .header .parameters > *, .ArrangementsPage .header .parameters > *, .ExtrasOverviewPage .header .parameters > * {
        margin: 10px 0px; } }
  @media screen and (max-width: 25em) {
    .ShowsPage .header, .ArrangementsPage .header, .ExtrasOverviewPage .header {
      margin-top: 90px; }
      .ShowsPage .header .text-container .textcontent-container, .ArrangementsPage .header .text-container .textcontent-container, .ExtrasOverviewPage .header .text-container .textcontent-container {
        margin-left: 30px;
        margin-right: 30px; }
      .ShowsPage .header .text-container .golden-ctas.small, .ArrangementsPage .header .text-container .golden-ctas.small, .ExtrasOverviewPage .header .text-container .golden-ctas.small {
        padding: 0 30px; }
      .ShowsPage .header .event-list .ticket-options .option, .ArrangementsPage .header .event-list .ticket-options .option, .ExtrasOverviewPage .header .event-list .ticket-options .option {
        flex-direction: column;
        align-items: flex-start; }
        .ShowsPage .header .event-list .ticket-options .option .right, .ArrangementsPage .header .event-list .ticket-options .option .right, .ExtrasOverviewPage .header .event-list .ticket-options .option .right {
          margin-top: 10px; } }

.ArrangementsPage .book-container, .ShowsPage .book-container, .ExtrasOverviewPage .book-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 200px 0;
  background-color: var(--brandingShade);
  margin-top: 180px; }
  .ArrangementsPage .book-container.golden-ctas-active, .ShowsPage .book-container.golden-ctas-active, .ExtrasOverviewPage .book-container.golden-ctas-active {
    padding-bottom: 350px; }
    @media screen and (max-width: 48em) {
      .ArrangementsPage .book-container.golden-ctas-active, .ShowsPage .book-container.golden-ctas-active, .ExtrasOverviewPage .book-container.golden-ctas-active {
        padding-bottom: 200px !important; } }
  @media screen and (max-width: 66.25em) {
    .ArrangementsPage .book-container, .ShowsPage .book-container, .ExtrasOverviewPage .book-container {
      padding-top: 100px;
      margin-top: 100px;
      padding-bottom: 100px; } }
  @media screen and (max-width: 37.5em) {
    .ArrangementsPage .book-container, .ShowsPage .book-container, .ExtrasOverviewPage .book-container {
      padding-top: 80px;
      margin-top: 80px;
      padding-bottom: 80px; } }
  .ArrangementsPage .book-container .book, .ShowsPage .book-container .book, .ExtrasOverviewPage .book-container .book {
    width: 100%; }
    .ArrangementsPage .book-container .book h1, .ShowsPage .book-container .book h1, .ExtrasOverviewPage .book-container .book h1 {
      text-align: center;
      padding-bottom: 20px;
      margin-bottom: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column; }
      .ArrangementsPage .book-container .book h1 .element, .ShowsPage .book-container .book h1 .element, .ExtrasOverviewPage .book-container .book h1 .element {
        letter-spacing: 10px;
        color: var(--brandingColor); }
    .ArrangementsPage .book-container .book .content, .ShowsPage .book-container .book .content, .ExtrasOverviewPage .book-container .book .content {
      width: 100%;
      display: flex;
      justify-content: space-between; }
      .ArrangementsPage .book-container .book .content .book-images, .ShowsPage .book-container .book .content .book-images, .ExtrasOverviewPage .book-container .book .content .book-images {
        width: 670px;
        height: 420px;
        position: relative;
        margin-right: 100px; }
        .ArrangementsPage .book-container .book .content .book-images .slick-arrow, .ShowsPage .book-container .book .content .book-images .slick-arrow, .ExtrasOverviewPage .book-container .book .content .book-images .slick-arrow {
          cursor: pointer;
          position: absolute;
          bottom: -30px;
          right: 0; }
          .ArrangementsPage .book-container .book .content .book-images .slick-arrow:first-of-type, .ShowsPage .book-container .book .content .book-images .slick-arrow:first-of-type, .ExtrasOverviewPage .book-container .book .content .book-images .slick-arrow:first-of-type {
            right: 20px; }
        .ArrangementsPage .book-container .book .content .book-images .slick-disabled, .ShowsPage .book-container .book .content .book-images .slick-disabled, .ExtrasOverviewPage .book-container .book .content .book-images .slick-disabled {
          pointer-events: none;
          opacity: .5; }
        .ArrangementsPage .book-container .book .content .book-images .slick-list, .ShowsPage .book-container .book .content .book-images .slick-list, .ExtrasOverviewPage .book-container .book .content .book-images .slick-list {
          height: inherit; }
          .ArrangementsPage .book-container .book .content .book-images .slick-list *, .ShowsPage .book-container .book .content .book-images .slick-list *, .ExtrasOverviewPage .book-container .book .content .book-images .slick-list * {
            height: inherit; }
        .ArrangementsPage .book-container .book .content .book-images figure, .ShowsPage .book-container .book .content .book-images figure, .ExtrasOverviewPage .book-container .book .content .book-images figure {
          width: inherit;
          height: inherit; }
          .ArrangementsPage .book-container .book .content .book-images figure img, .ShowsPage .book-container .book .content .book-images figure img, .ExtrasOverviewPage .book-container .book .content .book-images figure img {
            width: inherit;
            height: inherit;
            object-fit: cover; }
      .ArrangementsPage .book-container .book .content .ticket-options, .ShowsPage .book-container .book .content .ticket-options, .ExtrasOverviewPage .book-container .book .content .ticket-options {
        max-width: 550px;
        width: 100%;
        display: flex;
        flex-direction: column; }
        .ArrangementsPage .book-container .book .content .ticket-options .no-results *, .ShowsPage .book-container .book .content .ticket-options .no-results *, .ExtrasOverviewPage .book-container .book .content .ticket-options .no-results * {
          text-align: center; }
        .ArrangementsPage .book-container .book .content .ticket-options .month-navigation--top, .ShowsPage .book-container .book .content .ticket-options .month-navigation--top, .ExtrasOverviewPage .book-container .book .content .ticket-options .month-navigation--top {
          margin-bottom: 60px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          user-select: none; }
          .ArrangementsPage .book-container .book .content .ticket-options .month-navigation--top_arrow, .ShowsPage .book-container .book .content .ticket-options .month-navigation--top_arrow, .ExtrasOverviewPage .book-container .book .content .ticket-options .month-navigation--top_arrow {
            cursor: pointer; }
            .ArrangementsPage .book-container .book .content .ticket-options .month-navigation--top_arrow.inactive, .ShowsPage .book-container .book .content .ticket-options .month-navigation--top_arrow.inactive, .ExtrasOverviewPage .book-container .book .content .ticket-options .month-navigation--top_arrow.inactive {
              opacity: .5;
              pointer-events: none; }
            .ArrangementsPage .book-container .book .content .ticket-options .month-navigation--top_arrow:first-of-type, .ShowsPage .book-container .book .content .ticket-options .month-navigation--top_arrow:first-of-type, .ExtrasOverviewPage .book-container .book .content .ticket-options .month-navigation--top_arrow:first-of-type {
              margin-right: 10px; }
        .ArrangementsPage .book-container .book .content .ticket-options .month-navigation--bottom, .ShowsPage .book-container .book .content .ticket-options .month-navigation--bottom, .ExtrasOverviewPage .book-container .book .content .ticket-options .month-navigation--bottom {
          margin-top: 50px; }
          .ArrangementsPage .book-container .book .content .ticket-options .month-navigation--bottom_arrows, .ShowsPage .book-container .book .content .ticket-options .month-navigation--bottom_arrows, .ExtrasOverviewPage .book-container .book .content .ticket-options .month-navigation--bottom_arrows {
            display: flex;
            justify-content: space-between;
            align-items: center; }
          .ArrangementsPage .book-container .book .content .ticket-options .month-navigation--bottom_arrow, .ShowsPage .book-container .book .content .ticket-options .month-navigation--bottom_arrow, .ExtrasOverviewPage .book-container .book .content .ticket-options .month-navigation--bottom_arrow {
            cursor: pointer; }
            .ArrangementsPage .book-container .book .content .ticket-options .month-navigation--bottom_arrow.inactive, .ShowsPage .book-container .book .content .ticket-options .month-navigation--bottom_arrow.inactive, .ExtrasOverviewPage .book-container .book .content .ticket-options .month-navigation--bottom_arrow.inactive {
              opacity: 0;
              pointer-events: none; }
            .ArrangementsPage .book-container .book .content .ticket-options .month-navigation--bottom_arrow .month, .ShowsPage .book-container .book .content .ticket-options .month-navigation--bottom_arrow .month, .ExtrasOverviewPage .book-container .book .content .ticket-options .month-navigation--bottom_arrow .month {
              font-size: 12px;
              text-transform: uppercase;
              color: var(--brandingColor);
              padding: 0 10px; }
        .ArrangementsPage .book-container .book .content .ticket-options .option, .ShowsPage .book-container .book .content .ticket-options .option, .ExtrasOverviewPage .book-container .book .content .ticket-options .option {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          border-bottom: 1px solid var(--brandingColor);
          padding-bottom: 5px; }
          .ArrangementsPage .book-container .book .content .ticket-options .option *, .ShowsPage .book-container .book .content .ticket-options .option *, .ExtrasOverviewPage .book-container .book .content .ticket-options .option * {
            font-size: 16px; }
          .ArrangementsPage .book-container .book .content .ticket-options .option .left p, .ShowsPage .book-container .book .content .ticket-options .option .left p, .ExtrasOverviewPage .book-container .book .content .ticket-options .option .left p {
            margin-bottom: 0;
            text-transform: uppercase; }
          .ArrangementsPage .book-container .book .content .ticket-options .option .left .date, .ShowsPage .book-container .book .content .ticket-options .option .left .date, .ExtrasOverviewPage .book-container .book .content .ticket-options .option .left .date {
            font-weight: 500;
            color: var(--primaryDark); }
          .ArrangementsPage .book-container .book .content .ticket-options .option .left .hour, .ShowsPage .book-container .book .content .ticket-options .option .left .hour, .ExtrasOverviewPage .book-container .book .content .ticket-options .option .left .hour {
            text-transform: uppercase;
            color: #6A6A6E; }
          .ArrangementsPage .book-container .book .content .ticket-options .option .right .book-btn, .ShowsPage .book-container .book .content .ticket-options .option .right .book-btn, .ExtrasOverviewPage .book-container .book .content .ticket-options .option .right .book-btn {
            color: var(--brandingColor);
            text-transform: uppercase;
            letter-spacing: 1px;
            text-decoration: none;
            transition: opacity .2s ease; }
            .ArrangementsPage .book-container .book .content .ticket-options .option .right .book-btn.warning, .ShowsPage .book-container .book .content .ticket-options .option .right .book-btn.warning, .ExtrasOverviewPage .book-container .book .content .ticket-options .option .right .book-btn.warning {
              color: #e20a0a; }
            .ArrangementsPage .book-container .book .content .ticket-options .option .right .book-btn.sold, .ShowsPage .book-container .book .content .ticket-options .option .right .book-btn.sold, .ExtrasOverviewPage .book-container .book .content .ticket-options .option .right .book-btn.sold {
              opacity: .5; }
            .ArrangementsPage .book-container .book .content .ticket-options .option .right .book-btn:not(.sold):hover, .ShowsPage .book-container .book .content .ticket-options .option .right .book-btn:not(.sold):hover, .ExtrasOverviewPage .book-container .book .content .ticket-options .option .right .book-btn:not(.sold):hover {
              opacity: .5; }
          .ArrangementsPage .book-container .book .content .ticket-options .option:not(:last-of-type), .ShowsPage .book-container .book .content .ticket-options .option:not(:last-of-type), .ExtrasOverviewPage .book-container .book .content .ticket-options .option:not(:last-of-type) {
            margin-bottom: 40px; }
    @media screen and (max-width: 100em) {
      .ArrangementsPage .book-container .book .content .book-images, .ShowsPage .book-container .book .content .book-images, .ExtrasOverviewPage .book-container .book .content .book-images {
        width: 550px;
        height: 350px; }
        .ArrangementsPage .book-container .book .content .book-images .slick-arrow, .ShowsPage .book-container .book .content .book-images .slick-arrow, .ExtrasOverviewPage .book-container .book .content .book-images .slick-arrow {
          bottom: -100px; } }
    @media screen and (max-width: 81.25em) {
      .ArrangementsPage .book-container .book, .ShowsPage .book-container .book, .ExtrasOverviewPage .book-container .book {
        margin-left: 50px;
        margin-right: 50px; }
        .ArrangementsPage .book-container .book .content .book-images, .ShowsPage .book-container .book .content .book-images, .ExtrasOverviewPage .book-container .book .content .book-images {
          margin-right: 40px; } }
    @media screen and (max-width: 71.875em) {
      .ArrangementsPage .book-container .book, .ShowsPage .book-container .book, .ExtrasOverviewPage .book-container .book {
        margin-left: 50px;
        margin-right: 50px; } }
    @media screen and (max-width: 66.25em) {
      .ArrangementsPage .book-container .book, .ShowsPage .book-container .book, .ExtrasOverviewPage .book-container .book {
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 0;
        padding-right: 0; }
        .ArrangementsPage .book-container .book .content, .ShowsPage .book-container .book .content, .ExtrasOverviewPage .book-container .book .content {
          flex-direction: column;
          align-items: center; }
          .ArrangementsPage .book-container .book .content .book-images, .ShowsPage .book-container .book .content .book-images, .ExtrasOverviewPage .book-container .book .content .book-images {
            margin-right: 0; }
            .ArrangementsPage .book-container .book .content .book-images .slick-arrow, .ShowsPage .book-container .book .content .book-images .slick-arrow, .ExtrasOverviewPage .book-container .book .content .book-images .slick-arrow {
              z-index: 1;
              bottom: 15px;
              right: 15px; }
              .ArrangementsPage .book-container .book .content .book-images .slick-arrow:first-of-type, .ShowsPage .book-container .book .content .book-images .slick-arrow:first-of-type, .ExtrasOverviewPage .book-container .book .content .book-images .slick-arrow:first-of-type {
                right: 35px; }
          .ArrangementsPage .book-container .book .content .ticket-options, .ShowsPage .book-container .book .content .ticket-options, .ExtrasOverviewPage .book-container .book .content .ticket-options {
            margin-top: 80px;
            padding: 0 30px; }
            .ArrangementsPage .book-container .book .content .ticket-options .option:not(:last-of-type), .ShowsPage .book-container .book .content .ticket-options .option:not(:last-of-type), .ExtrasOverviewPage .book-container .book .content .ticket-options .option:not(:last-of-type) {
              margin-bottom: 30px; }
            .ArrangementsPage .book-container .book .content .ticket-options .option *, .ShowsPage .book-container .book .content .ticket-options .option *, .ExtrasOverviewPage .book-container .book .content .ticket-options .option * {
              font-size: 15px; } }
    @media screen and (max-width: 48em) {
      .ArrangementsPage .book-container .book h1, .ShowsPage .book-container .book h1, .ExtrasOverviewPage .book-container .book h1 {
        margin-bottom: 20px; }
      .ArrangementsPage .book-container .book .content .book-images, .ShowsPage .book-container .book .content .book-images, .ExtrasOverviewPage .book-container .book .content .book-images {
        width: 100%; }
      .ArrangementsPage .book-container .book .content .ticket-options, .ShowsPage .book-container .book .content .ticket-options, .ExtrasOverviewPage .book-container .book .content .ticket-options {
        margin-top: 50px; }
        .ArrangementsPage .book-container .book .content .ticket-options .month-navigation--top, .ShowsPage .book-container .book .content .ticket-options .month-navigation--top, .ExtrasOverviewPage .book-container .book .content .ticket-options .month-navigation--top {
          margin-bottom: 30px; }
        .ArrangementsPage .book-container .book .content .ticket-options .month-navigation--bottom, .ShowsPage .book-container .book .content .ticket-options .month-navigation--bottom, .ExtrasOverviewPage .book-container .book .content .ticket-options .month-navigation--bottom {
          margin-top: 30px; } }
    @media screen and (max-width: 31.25em) {
      .ArrangementsPage .book-container .book .content .ticket-options .option, .ShowsPage .book-container .book .content .ticket-options .option, .ExtrasOverviewPage .book-container .book .content .ticket-options .option {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start; }
        .ArrangementsPage .book-container .book .content .ticket-options .option:not(:last-of-type), .ShowsPage .book-container .book .content .ticket-options .option:not(:last-of-type), .ExtrasOverviewPage .book-container .book .content .ticket-options .option:not(:last-of-type) {
          margin-bottom: 20px;
          padding-bottom: 15px; } }
  .ArrangementsPage .book-container .nothing-available, .ShowsPage .book-container .nothing-available, .ExtrasOverviewPage .book-container .nothing-available {
    margin-top: 50px;
    margin-bottom: 40px; }
    .ArrangementsPage .book-container .nothing-available.center, .ShowsPage .book-container .nothing-available.center, .ExtrasOverviewPage .book-container .nothing-available.center {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      text-align: center; }
  .ArrangementsPage .book-container .Filter, .ShowsPage .book-container .Filter, .ExtrasOverviewPage .book-container .Filter {
    position: relative;
    z-index: 1;
    padding-top: 20px;
    padding-bottom: 100px; }
    .ArrangementsPage .book-container .Filter-options, .ShowsPage .book-container .Filter-options, .ExtrasOverviewPage .book-container .Filter-options {
      display: flex;
      flex-wrap: wrap; }
      .ArrangementsPage .book-container .Filter-options > *, .ShowsPage .book-container .Filter-options > *, .ExtrasOverviewPage .book-container .Filter-options > * {
        margin-right: 20px;
        margin-bottom: 20px; }
    .ArrangementsPage .book-container .Filter .cta.not-clicktrough:after, .ShowsPage .book-container .Filter .cta.not-clicktrough:after, .ExtrasOverviewPage .book-container .Filter .cta.not-clicktrough:after {
      background-color: #F7EFE3 !important; }
    .ArrangementsPage .book-container .Filter-option, .ShowsPage .book-container .Filter-option, .ExtrasOverviewPage .book-container .Filter-option {
      display: none; }
      .ArrangementsPage .book-container .Filter-option:checked ~ .cta, .ShowsPage .book-container .Filter-option:checked ~ .cta, .ExtrasOverviewPage .book-container .Filter-option:checked ~ .cta {
        color: white !important; }
        .ArrangementsPage .book-container .Filter-option:checked ~ .cta:after, .ShowsPage .book-container .Filter-option:checked ~ .cta:after, .ExtrasOverviewPage .book-container .Filter-option:checked ~ .cta:after {
          background-color: var(--brandingColor) !important; }
        .ArrangementsPage .book-container .Filter-option:checked ~ .cta:hover, .ShowsPage .book-container .Filter-option:checked ~ .cta:hover, .ExtrasOverviewPage .book-container .Filter-option:checked ~ .cta:hover {
          color: white !important; }
          .ArrangementsPage .book-container .Filter-option:checked ~ .cta:hover:after, .ShowsPage .book-container .Filter-option:checked ~ .cta:hover:after, .ExtrasOverviewPage .book-container .Filter-option:checked ~ .cta:hover:after {
            background-color: var(--brandingColor) !important;
            opacity: 1 !important; }
    @media screen and (max-width: 66.25em) {
      .ArrangementsPage .book-container .Filter, .ShowsPage .book-container .Filter, .ExtrasOverviewPage .book-container .Filter {
        padding-bottom: 0; }
        .ArrangementsPage .book-container .Filter-options, .ShowsPage .book-container .Filter-options, .ExtrasOverviewPage .book-container .Filter-options {
          justify-content: center;
          margin: 0 20px; }
          .ArrangementsPage .book-container .Filter-options > *, .ShowsPage .book-container .Filter-options > *, .ExtrasOverviewPage .book-container .Filter-options > * {
            margin-left: 10px;
            margin-right: 10px;
            margin-bottom: 20px;
            display: flex;
            align-items: center; }
            .ArrangementsPage .book-container .Filter-options > * .cta, .ShowsPage .book-container .Filter-options > * .cta, .ExtrasOverviewPage .book-container .Filter-options > * .cta {
              text-align: center;
              width: auto;
              display: flex;
              min-height: 50px;
              height: auto;
              padding: 20px 30px !important;
              line-height: 16px; } }

.ArrangementsPage .sfeerbeelden .slick-list, .ShowsPage .sfeerbeelden .slick-list, .ExtrasOverviewPage .sfeerbeelden .slick-list {
  height: 560px; }
  .ArrangementsPage .sfeerbeelden .slick-list *, .ShowsPage .sfeerbeelden .slick-list *, .ExtrasOverviewPage .sfeerbeelden .slick-list * {
    height: inherit; }
  @media screen and (max-width: 37.5em) {
    .ArrangementsPage .sfeerbeelden .slick-list, .ShowsPage .sfeerbeelden .slick-list, .ExtrasOverviewPage .sfeerbeelden .slick-list {
      height: 300px; } }

.ArrangementsPage .sfeerbeelden .slick-slide, .ShowsPage .sfeerbeelden .slick-slide, .ExtrasOverviewPage .sfeerbeelden .slick-slide {
  padding: 0 10px;
  max-width: 70vw; }
  .ArrangementsPage .sfeerbeelden .slick-slide img, .ShowsPage .sfeerbeelden .slick-slide img, .ExtrasOverviewPage .sfeerbeelden .slick-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
    @media screen and (max-width: 37.5em) {
      .ArrangementsPage .sfeerbeelden .slick-slide img, .ShowsPage .sfeerbeelden .slick-slide img, .ExtrasOverviewPage .sfeerbeelden .slick-slide img {
        height: 300px !important; } }
  @media screen and (max-width: 25em) {
    .ArrangementsPage .sfeerbeelden .slick-slide, .ShowsPage .sfeerbeelden .slick-slide, .ExtrasOverviewPage .sfeerbeelden .slick-slide {
      max-width: 250px; } }
  @media screen and (min-width: 137.5em) {
    .ArrangementsPage .sfeerbeelden .slick-slide, .ShowsPage .sfeerbeelden .slick-slide, .ExtrasOverviewPage .sfeerbeelden .slick-slide {
      max-width: 1200px; } }

.ArrangementsPage .sfeerbeelden .slick-arrow, .ShowsPage .sfeerbeelden .slick-arrow, .ExtrasOverviewPage .sfeerbeelden .slick-arrow {
  position: absolute;
  top: -160px;
  cursor: pointer;
  transition: opacity .3s ease; }
  .ArrangementsPage .sfeerbeelden .slick-arrow.prev, .ShowsPage .sfeerbeelden .slick-arrow.prev, .ExtrasOverviewPage .sfeerbeelden .slick-arrow.prev {
    left: 140px;
    right: unset; }
  .ArrangementsPage .sfeerbeelden .slick-arrow.next, .ShowsPage .sfeerbeelden .slick-arrow.next, .ExtrasOverviewPage .sfeerbeelden .slick-arrow.next {
    left: unset;
    right: 140px; }
  .ArrangementsPage .sfeerbeelden .slick-arrow:hover, .ShowsPage .sfeerbeelden .slick-arrow:hover, .ExtrasOverviewPage .sfeerbeelden .slick-arrow:hover {
    opacity: .6; }
  @media screen and (max-width: 56.25em) {
    .ArrangementsPage .sfeerbeelden .slick-arrow.prev, .ShowsPage .sfeerbeelden .slick-arrow.prev, .ExtrasOverviewPage .sfeerbeelden .slick-arrow.prev {
      left: 80px; }
    .ArrangementsPage .sfeerbeelden .slick-arrow.next, .ShowsPage .sfeerbeelden .slick-arrow.next, .ExtrasOverviewPage .sfeerbeelden .slick-arrow.next {
      right: 80px; } }
  @media screen and (max-width: 37.5em) {
    .ArrangementsPage .sfeerbeelden .slick-arrow, .ShowsPage .sfeerbeelden .slick-arrow, .ExtrasOverviewPage .sfeerbeelden .slick-arrow {
      top: -100px; }
      .ArrangementsPage .sfeerbeelden .slick-arrow svg, .ShowsPage .sfeerbeelden .slick-arrow svg, .ExtrasOverviewPage .sfeerbeelden .slick-arrow svg {
        min-width: 50px;
        max-width: 50px;
        min-height: 50px;
        max-height: 50px; }
      .ArrangementsPage .sfeerbeelden .slick-arrow.prev, .ShowsPage .sfeerbeelden .slick-arrow.prev, .ExtrasOverviewPage .sfeerbeelden .slick-arrow.prev {
        left: 40px; }
      .ArrangementsPage .sfeerbeelden .slick-arrow.next, .ShowsPage .sfeerbeelden .slick-arrow.next, .ExtrasOverviewPage .sfeerbeelden .slick-arrow.next {
        right: 40px; } }
  @media screen and (max-width: 28.125em) {
    .ArrangementsPage .sfeerbeelden .slick-arrow, .ShowsPage .sfeerbeelden .slick-arrow, .ExtrasOverviewPage .sfeerbeelden .slick-arrow {
      visibility: hidden; } }

.ArrangementsPage .sfeerbeelden-container, .ShowsPage .sfeerbeelden-container, .ExtrasOverviewPage .sfeerbeelden-container {
  margin-top: 120px; }
  .ArrangementsPage .sfeerbeelden-container h1, .ShowsPage .sfeerbeelden-container h1, .ExtrasOverviewPage .sfeerbeelden-container h1 {
    text-align: center;
    padding-bottom: 20px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
    .ArrangementsPage .sfeerbeelden-container h1 .element, .ShowsPage .sfeerbeelden-container h1 .element, .ExtrasOverviewPage .sfeerbeelden-container h1 .element {
      letter-spacing: 10px;
      color: var(--brandingColor); }
  @media screen and (max-width: 37.5em) {
    .ArrangementsPage .sfeerbeelden-container, .ShowsPage .sfeerbeelden-container, .ExtrasOverviewPage .sfeerbeelden-container {
      margin-top: 60px; }
      .ArrangementsPage .sfeerbeelden-container h1, .ShowsPage .sfeerbeelden-container h1, .ExtrasOverviewPage .sfeerbeelden-container h1 {
        margin-bottom: 20px; } }

.ArrangementsPage .golden-ctas, .ShowsPage .golden-ctas, .ExtrasOverviewPage .golden-ctas {
  margin-top: 120px; }

.ArrangementsPage .slider, .ShowsPage .slider, .ExtrasOverviewPage .slider {
  margin-top: 240px; }
  @media screen and (max-width: 66.25em) {
    .ArrangementsPage .slider, .ShowsPage .slider, .ExtrasOverviewPage .slider {
      margin-top: 140px; } }
  @media screen and (max-width: 37.5em) {
    .ArrangementsPage .slider, .ShowsPage .slider, .ExtrasOverviewPage .slider {
      margin-top: 80px; } }

.HomePage .textblock {
  padding-bottom: 600px; }
  @media screen and (max-width: 37.5em) {
    .HomePage .textblock {
      padding-bottom: 400px; } }

.HomePage .agenda {
  background-color: unset !important; }
  .HomePage .agenda .Show-tile--image {
    min-height: 470px;
    max-height: 470px; }
  .HomePage .agenda .Show-tile--info {
    background-color: var(--brandingShade); }
  @media screen and (min-width: 37.5em) {
    .HomePage .agenda .mobile-button, .HomePage .agenda .mobile-shows {
      display: none; } }
  @media screen and (max-width: 37.5em) {
    .HomePage .agenda {
      padding: 80px 20px; }
      .HomePage .agenda .top {
        align-items: center; }
        .HomePage .agenda .top h1 {
          align-items: center; }
        .HomePage .agenda .top > a {
          display: none; }
      .HomePage .agenda .mobile-button, .HomePage .agenda .mobile-shows {
        display: flex; }
      .HomePage .agenda .mobile-button {
        margin-top: 50px;
        justify-content: center;
        width: 100%; }
        .HomePage .agenda .mobile-button a {
          max-width: 360px;
          width: 100%; }
      .HomePage .agenda .mobile-shows {
        flex-direction: column; }
        .HomePage .agenda .mobile-shows .Show-tile--image {
          min-height: 400px;
          max-height: 400px; }
      .HomePage .agenda .js-carousel-agenda {
        display: none; } }

@media screen and (max-width: 106.25em) {
  .HomePage .textblock {
    padding-top: 0;
    padding-bottom: 200px; } }

.HomePage .golden-ctas {
  padding-top: 120px;
  position: relative;
  z-index: 1; }
  @media screen and (max-width: 68.75em) {
    .HomePage .golden-ctas {
      padding-top: 80px; } }

.HomePage .slider-item--image {
  height: 460px; }

.NewsletterPage .newsletter-form {
  margin-top: 90px;
  margin-bottom: 140px;
  max-width: 760px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px; }
  .NewsletterPage .newsletter-form form {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .NewsletterPage .newsletter-form form p {
      display: none !important; }
    .NewsletterPage .newsletter-form form input {
      text-align: center;
      font-size: 16px;
      background-color: unset;
      border: 1px solid var(--primaryDark);
      color: var(--primaryDark) !important; }
      .NewsletterPage .newsletter-form form input::placeholder {
        color: rgba(var(--primaryDark), 0.3); }
    .NewsletterPage .newsletter-form form .error {
      color: #e20a0a;
      margin-bottom: 10px; }
    .NewsletterPage .newsletter-form form .cta {
      margin-top: 20px;
      padding: 15px 25px !important;
      color: var(--brandingColor) !important;
      border-color: var(--brandingColor) !important;
      transition: border-color .2s ease, color .2s ease, background-color .2s ease; }
      .NewsletterPage .newsletter-form form .cta span {
        position: relative;
        z-index: 1;
        color: white; }
      .NewsletterPage .newsletter-form form .cta:hover {
        background-color: var(--primaryDark) !important;
        border-color: var(--primaryDark) !important;
        color: white !important; }

@media screen and (max-width: 37.5em) {
  .NewsletterThanksPage .container {
    margin-left: 20px;
    margin-right: 20px; }
    .NewsletterThanksPage .container .page-header {
      padding-left: 0;
      padding-right: 0; } }

.AgendaPage .agenda-container {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-template-areas: 'filter productions';
  position: relative;
  margin-top: 120px;
  padding: 0 140px; }
  @media screen and (min-width: 131.25em) {
    .AgendaPage .agenda-container {
      max-width: 1700px;
      margin-left: auto;
      margin-right: auto;
      padding: 0; } }
  @media screen and (max-width: 106.25em) {
    .AgendaPage .agenda-container {
      padding: 0 40px; } }
  @media screen and (max-width: 93.75em) {
    .AgendaPage .agenda-container {
      grid-template-columns: 40% 60%; } }
  @media screen and (max-width: 37.5em) {
    .AgendaPage .agenda-container {
      padding: 0 30px;
      margin-top: 40px; } }

.AgendaPage .filter {
  padding: 60px;
  background-color: var(--brandingShade); }
  .AgendaPage .filter-container {
    grid-area: filter;
    padding-right: 50px;
    height: fit-content;
    position: sticky;
    top: 160px; }
    .AgendaPage .filter-container .full-experience-container {
      border: 1px solid var(--primary);
      width: 100%;
      padding: 40px 60px;
      margin-top: 30px; }
    .AgendaPage .filter-container .full-experience-text {
      margin-top: 20px; }
      .AgendaPage .filter-container .full-experience-text p {
        margin-bottom: 0; }
  .AgendaPage .filter-title {
    margin-bottom: 40px; }
  .AgendaPage .filter-dropdown--container {
    margin-top: 30px; }
    .AgendaPage .filter-dropdown--container .name {
      letter-spacing: 1px;
      font-size: 14px;
      margin-bottom: 10px;
      text-transform: uppercase; }
    .AgendaPage .filter-dropdown--container .calendar {
      border-radius: 0;
      appearance: none;
      border: 1px solid;
      width: 100%;
      height: 50px;
      padding: 0 1.5rem;
      background-color: var(--brandingShade);
      outline: none;
      font-size: 12px;
      letter-spacing: 1px; }
      .AgendaPage .filter-dropdown--container .calendar:focus, .AgendaPage .filter-dropdown--container .calendar:active {
        outline: none; }
      .AgendaPage .filter-dropdown--container .calendar::placeholder {
        color: var(--primary); }
      .AgendaPage .filter-dropdown--container .calendar-container {
        position: relative; }
        .AgendaPage .filter-dropdown--container .calendar-container svg {
          position: absolute;
          right: 17px;
          top: 12px;
          height: 25px;
          pointer-events: none; }
  @media screen and (max-width: 68.75em) {
    .AgendaPage .filter {
      padding: 60px; }
      .AgendaPage .filter .filter {
        padding: 40px; }
        .AgendaPage .filter .filter-title {
          margin-bottom: 30px; }
        .AgendaPage .filter .filter-dropdown--container {
          margin-top: 20px; } }
  @media screen and (max-width: 37.5em) {
    .AgendaPage .filter {
      padding: 40px; } }

.AgendaPage .productions {
  display: flex;
  flex-direction: column;
  grid-area: productions;
  padding-left: 50px; }
  .AgendaPage .productions .nothing-found h2 {
    line-height: 40px;
    max-width: 560px; }
    .AgendaPage .productions .nothing-found h2 a {
      color: var(--primary);
      text-decoration: none;
      border-bottom: 2px solid var(--primary); }
  .AgendaPage .productions .nothing-found .cta {
    margin-top: 20px;
    cursor: pointer; }
  .AgendaPage .productions .day + .day {
    margin-top: 80px; }
  .AgendaPage .productions .day {
    display: flex;
    flex-direction: column; }
    .AgendaPage .productions .day > * {
      width: 100%; }
    .AgendaPage .productions .day > h2 {
      border-bottom: 1px solid var(--brandingOpacity);
      padding-bottom: 20px; }
    .AgendaPage .productions .day .production {
      display: flex;
      justify-content: space-between;
      position: relative;
      border-bottom: 1px solid var(--brandingOpacity);
      padding: 5px 10px; }
      .AgendaPage .productions .day .production .url-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: background-color .4s ease;
        z-index: 1; }
      .AgendaPage .productions .day .production .left, .AgendaPage .productions .day .production .right {
        position: relative;
        z-index: 2; }
      .AgendaPage .productions .day .production .left {
        display: flex;
        align-items: center;
        margin-right: 40px;
        pointer-events: none; }
        .AgendaPage .productions .day .production .left .info {
          display: flex;
          flex-direction: column;
          margin-left: 20px;
          margin-top: 10px; }
          .AgendaPage .productions .day .production .left .info .title {
            font-weight: 500;
            text-transform: uppercase;
            margin-bottom: 0;
            max-width: 250px; }
          .AgendaPage .productions .day .production .left .info .bottom {
            display: flex; }
            .AgendaPage .productions .day .production .left .info .bottom > div {
              margin-right: 20px;
              margin-bottom: 10px;
              display: flex;
              align-items: center; }
              .AgendaPage .productions .day .production .left .info .bottom > div > svg {
                margin-right: 5px; }
      .AgendaPage .productions .day .production .right {
        display: flex;
        align-items: center; }
        .AgendaPage .productions .day .production .right .more-info-url {
          color: var(--primary);
          opacity: .5;
          text-decoration: none;
          border-bottom: 1px solid var(--primary);
          line-height: 18px;
          margin-right: 10px; }
      .AgendaPage .productions .day .production .cover {
        width: 50px;
        transition: width .4s ease; }
      .AgendaPage .productions .day .production:hover .url-bg {
        background-color: var(--brandingShade); }
      .AgendaPage .productions .day .production:hover .cover {
        width: 100px; }
  @media screen and (max-width: 68.75em) {
    .AgendaPage .productions .day + .day {
      margin-top: 40px; } }
  @media screen and (max-width: 48em) {
    .AgendaPage .productions .production {
      flex-direction: column; }
      .AgendaPage .productions .production .left {
        margin-right: 0;
        margin-bottom: 20px; }
      .AgendaPage .productions .production .right {
        justify-content: flex-end; } }
  @media screen and (max-width: 37.5em) {
    .AgendaPage .productions .day > h2 {
      text-align: center; }
    .AgendaPage .productions .day .production .left .info {
      margin-top: 0px; }
      .AgendaPage .productions .day .production .left .info .bottom {
        flex-direction: column; }
        .AgendaPage .productions .day .production .left .info .bottom > div {
          margin-top: 5px;
          margin-bottom: 0 !important; } }

@media screen and (max-width: 106.25em) {
  .AgendaPage .filter-container {
    padding-right: 30px; }
  .AgendaPage .productions {
    padding-left: 30px; } }

.AgendaPage .Shows-section {
  background-color: var(--brandingShade);
  margin-top: 120px;
  padding-bottom: 400px; }
  .AgendaPage .Shows-section > div {
    display: flex; }
  .AgendaPage .Shows-section-info {
    background-color: var(--brandingColor);
    padding: 20px 50px 100px;
    padding-top: 180px;
    max-width: 500px;
    margin-left: 120px;
    height: fit-content; }
    .AgendaPage .Shows-section-info h2 {
      margin-bottom: 40px; }
    .AgendaPage .Shows-section-info * {
      color: white; }
    .AgendaPage .Shows-section-info ul {
      padding-left: 0;
      margin-bottom: 0; }
      .AgendaPage .Shows-section-info ul li {
        margin-bottom: 0;
        padding-top: 20px;
        list-style: none;
        display: flex;
        align-items: center; }
        .AgendaPage .Shows-section-info ul li figure {
          width: 60px;
          height: 60px;
          border-radius: 100%;
          border: 1px solid white;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 20px; }
          .AgendaPage .Shows-section-info ul li figure svg {
            width: 25px; }
            .AgendaPage .Shows-section-info ul li figure svg path, .AgendaPage .Shows-section-info ul li figure svg rect {
              stroke: white; }
        .AgendaPage .Shows-section-info ul li:not(:last-child) {
          padding-bottom: 20px;
          border-bottom: 1px solid white; }
        .AgendaPage .Shows-section-info ul li a {
          text-decoration: none;
          border-bottom: 1px solid white;
          padding-bottom: 3px; }
        .AgendaPage .Shows-section-info ul li p {
          margin-bottom: 0;
          text-transform: uppercase;
          font-weight: 500; }
  .AgendaPage .Shows-section-shows {
    padding-top: 180px;
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column; }
    .AgendaPage .Shows-section-shows a {
      text-decoration: none; }
    .AgendaPage .Shows-section-shows .nothing-available {
      text-align: center;
      padding: 0 20px; }
    .AgendaPage .Shows-section-shows .month-navigation--top {
      margin-bottom: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      user-select: none; }
      .AgendaPage .Shows-section-shows .month-navigation--top_arrow {
        cursor: pointer; }
        .AgendaPage .Shows-section-shows .month-navigation--top_arrow.inactive {
          opacity: .5;
          pointer-events: none; }
        .AgendaPage .Shows-section-shows .month-navigation--top_arrow:first-of-type {
          margin-right: 10px; }
    .AgendaPage .Shows-section-shows .month-navigation--bottom {
      margin-top: 50px; }
      .AgendaPage .Shows-section-shows .month-navigation--bottom_arrows {
        display: flex;
        justify-content: space-between;
        align-items: center; }
      .AgendaPage .Shows-section-shows .month-navigation--bottom_arrow {
        cursor: pointer; }
        .AgendaPage .Shows-section-shows .month-navigation--bottom_arrow.inactive {
          opacity: 0;
          pointer-events: none; }
        .AgendaPage .Shows-section-shows .month-navigation--bottom_arrow .month {
          font-size: 12px;
          text-transform: uppercase;
          color: var(--brandingColor);
          padding: 0 10px; }
    .AgendaPage .Shows-section-shows .option {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      border-bottom: 1px solid var(--brandingColor);
      padding-bottom: 5px; }
      .AgendaPage .Shows-section-shows .option * {
        font-size: 16px; }
      .AgendaPage .Shows-section-shows .option .left p {
        margin-bottom: 0;
        text-transform: uppercase; }
      .AgendaPage .Shows-section-shows .option .left .date {
        font-weight: 500;
        color: var(--primaryDark); }
      .AgendaPage .Shows-section-shows .option .left .hour {
        text-transform: uppercase;
        color: #6A6A6E; }
      .AgendaPage .Shows-section-shows .option .right .book-btn {
        color: var(--brandingColor);
        text-transform: uppercase;
        letter-spacing: 1px;
        text-decoration: none;
        transition: opacity .2s ease; }
        .AgendaPage .Shows-section-shows .option .right .book-btn.warning {
          color: #e20a0a; }
        .AgendaPage .Shows-section-shows .option .right .book-btn.sold {
          opacity: .5; }
        .AgendaPage .Shows-section-shows .option .right .book-btn:not(.sold):hover {
          opacity: .5; }
      .AgendaPage .Shows-section-shows .option:not(:last-of-type) {
        margin-bottom: 40px; }
    @media screen and (max-width: 66.25em) {
      .AgendaPage .Shows-section-shows .option:not(:last-of-type) {
        margin-bottom: 30px; }
      .AgendaPage .Shows-section-shows .option * {
        font-size: 15px; } }
    @media screen and (max-width: 48em) {
      .AgendaPage .Shows-section-shows .month-navigation--top {
        margin-bottom: 30px; }
      .AgendaPage .Shows-section-shows .month-navigation--bottom {
        margin-top: 30px; } }
    @media screen and (max-width: 31.25em) {
      .AgendaPage .Shows-section-shows .option {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start; }
        .AgendaPage .Shows-section-shows .option:not(:last-of-type) {
          margin-bottom: 20px;
          padding-bottom: 15px; } }
  @media screen and (max-width: 96.875em) {
    .AgendaPage .Shows-section > div {
      margin-left: 80px;
      margin-right: 80px; } }
  @media screen and (max-width: 84.375em) {
    .AgendaPage .Shows-section-info {
      margin-left: 60px; } }
  @media screen and (max-width: 90em) {
    .AgendaPage .Shows-section {
      padding-bottom: 320px; }
      .AgendaPage .Shows-section > div {
        flex-direction: column; }
        .AgendaPage .Shows-section > div .Shows-section-info {
          margin-left: 0;
          max-width: unset;
          padding: 40px 30px;
          margin-top: 60px; } }
  @media screen and (max-width: 48em) {
    .AgendaPage .Shows-section {
      margin-top: 80px;
      padding-bottom: 260px; }
      .AgendaPage .Shows-section > div {
        margin-left: 30px;
        margin-right: 30px;
        padding: 0; }
      .AgendaPage .Shows-section-shows {
        padding-top: 60px; } }

.AgendaPage .golden-ctas {
  margin-top: -180px; }

@media screen and (max-width: 68.75em) {
  .AgendaPage .agenda-container {
    grid-template-columns: 100%;
    grid-template-areas: 'filter' 'productions'; }
  .AgendaPage .filter-container {
    padding-right: 0;
    position: relative;
    top: unset; }
  .AgendaPage .productions {
    padding-left: 0;
    padding-top: 30px; } }

.TextPagesPage .textblock {
  background-color: unset;
  padding-top: 160px; }
  .TextPagesPage .textblock .image {
    min-height: 700px; }
  @media screen and (max-width: 37.5em) {
    .TextPagesPage .textblock {
      padding-top: 0;
      padding-bottom: 0; }
      .TextPagesPage .textblock .image {
        min-height: unset; } }

.TextPagesPage .header-text {
  max-width: 700px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 160px;
  margin-bottom: 100px;
  padding: 0 20px; }
  @media screen and (max-width: 48em) {
    .TextPagesPage .header-text {
      margin-top: 90px;
      margin-bottom: 40px; } }
  @media screen and (max-width: 37.5em) {
    .TextPagesPage .header-text {
      margin-top: 40px; } }
