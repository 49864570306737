.partners {
    padding: 220px 0;

    h1 {
        text-align: center;
        padding-bottom: 20px;
        margin-bottom: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .element {
            letter-spacing: 10px;
            color: var(--brandingColor);
        }
    }

    .partner {
        filter: grayscale(1);
        display: flex !important;
        justify-content: center;   
        max-height: 100px;
        
        img {
            object-fit: contain;
        }
    }

    .slick {
        &-track {
            display: flex;
            align-items: center;
        }

        &-slide {
            padding: 0 10px;
        }

        &-list {
            margin-top: 100px;
        }

        &-dots {
            position: relative;
            margin-top: 100px;
    
            li {
                button {
                    &:before {
                        font-size: 30px;
                        color: #3F3F3F;
                        opacity: 1;
                    }
                }
    
                &.slick-active {                
                    button {
                        border-radius: 100%;
                        border: 1px solid var(--brandingColor);
    
                        &:before {
                            color: var(--brandingColor);
                        }
                    }
                }            
            }
        }
    }

    @include mq($until: $resp-nav) {
        padding: 130px 0;
    }
   
    @include mq($until: $viewport--md) {
        padding: 80px 0;
    }
   
    @include mq($until: $viewport--sm) {
        .slick {
            &-list {
                margin-top: 40px;
                max-height: 120px;
                height: 100%;

                * {
                    max-height: inherit;
                }
            }            

            &-dots {
                margin-top: 40px;
            }
        }
    }
}