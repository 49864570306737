.accordion {
    &--container {
        > .text {
            margin-top: 20px;

            @include mq($until: $viewport--sm) {
                margin-top: 10px;
                font-size: 14px;
            }
        }
    }
}