.Header {
    display: flex;
    justify-content: space-between;
    padding: 15px $space-base 15px;
    background-color: $cc-white;
    height: $navHeight !important;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transition: transform .2s ease;   
    border-bottom: 1px solid var(--primaryShade);     

    &.hidden {
        transform: translateY(-$navHeight);
    }

    ul {
        display: flex;
        margin-bottom: 0;
        padding-left: 0;
        
        li {
            list-style: none;
            margin-bottom: 0;
        }
    }
    
    a {
        text-transform: lowercase;
        color: var(--primary);
        text-decoration: none;       
        
        &.basket {
            margin-left: 10px;
            width: 35px;
            height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                width: 25px;
                height: 25px;

                path {
                    fill: var(--primary);
                    transition: fill .2s ease;
                }
            }

            &:hover {
                svg {
                    path {
                        fill: var(--brandingColor);
                    }
                }
            }

            @include mq($until: $resp-nav) {
                margin-left: 0;
            }
        }
    }

    .hamburger {
        &-checkbox {
            display: none;

            @include mq($until: $resp-nav) {
                &:checked {
                    & ~ .Header-nav {
                        transform: translateY(130px);
                        height: auto;
                    }
    
                    & ~ .hamburger-icon {
                        span {
                            &:nth-of-type(2) {
                                width: 0%;
                            }
    
                            &:first-of-type, &:last-of-type {
                                width: 100%;
                            }
    
                            &:first-of-type {
                                transform: rotate(45deg) translateY(7px) translateX(8px);
                            }
                            
                            &:last-of-type {
                                transform: rotate(-45deg) translateY(-6px) translateX(6px);
                            }
                        }
                    }
                }
            }
        }

        &-icon {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            cursor: pointer;
            width: 35px;
            height: 20px;

            span {
                width: 100%;
                height: 1px;
                background-color: var(--primaryDark);
                transition: transform .2s ease, opacity .2s ease, width .2s ease;

                &:nth-of-type(2) {
                    transform: translateY(-0.5px);
                }
            }

            @include mq($from: $resp-nav) {
                display: none;
            }

            &:hover {
                span {
                    &:last-of-type {
                        width: 70%;
                    }
                }
            }
        }
    }
    
    &-logo {
        display: flex;
        align-items: center;
        max-width: 180px;
        width: 100%;

        img {
            max-height: 80px;
            height: 100%;
            object-fit: contain;
        }

        @include mq($until: $resp-nav) {
            max-width: 140px;
        }
    }

    &-nav {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        transition: height .2s ease;

        &--top .pages, &--base {
            li {
                &.active {
                    a {
                        border-bottom: 1px solid var(--primary);
                        font-weight: 600;
                    }
                }
                
                a {
                    padding: 4px 0;
    
                    border-bottom: 1px solid rgba(var(--primary), 0);
                    transition: border-color .2s ease;

                    position: relative;

                    &:before {
                        content: '';
                        width: 100%;
                        height: 1px;
                        background-color: var(--primary);
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        transform: scaleX(0);
                        transition: transform .2s ease, background-color .2s ease;
                        transform-origin: center;
                    }

                    &:hover {
                        &:before {
                            transform: scaleX(1);
                        }
                    }
                }
            }
        }

        &--top {
            display: flex;
            align-items: center;
            margin-top: 10px;   
            margin-bottom: 30px;        

            .socials, .pages {
                padding: 0 15px;

                ul {
                    li + li {
                        margin-left: 15px;
                    }

                    li {
                        svg {
                            path {
                                transition: fill .2s ease;
                            }
                        }

                        &:hover {
                            svg {
                                path {
                                    fill: var(--brandingColor);
                                }
                            }
                        }
                    }
                }                
            }

            a {
                font-size: 14px;
                line-height: 1;                
            }

            .pages {              
                border-left: 1px solid $cc-darkGrey;
                border-right: 1px solid $cc-darkGrey;
            }

            .phone {
                display: flex;
                align-items: center;
                padding-left: 15px;

                span {
                    margin-left: 5px;
                }

                figure {
                    width: 32px;
                    height: 32px;
                    border: 1px solid var(--primary);
                    padding: 7px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100%;
                    background-color: $cc-white;
                    transition: background-color .2s ease;

                    svg {
                        path {
                            fill: var(--primary);
                            stroke: var(--primary);
                            transition: fill .2s ease, stroke .2s ease;
                        }
                    }
                }

                &:hover {
                    figure {
                        background-color: var(--primary);

                        svg {
                            path {
                                fill: $cc-white;
                                stroke: $cc-white;
                            }
                        }
                    }
                }
            }

            .book {
                padding-left: 25px;

                a {
                    font-size: 12px;
                }
            }
        }

        &--base {
            margin-bottom: 10px;

            ul {
                li {
                    a {                        
                        font-weight: 500;
                        text-transform: unset;
                    }    
                }

                li + li {
                    margin-left: 30px;
                }
            }
        }

        @include mq($until: $resp-nav) {            
            width: 100%;
            left: 0;
            top: 0;            
            align-items: flex-start;
            justify-content: flex-start;
            background-color: $cc-white;
            border-top: 1px solid var(--primaryDark);
            padding: 30px 50px 40px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            pointer-events: all !important;
            flex-direction: column-reverse;

            &--base {
                nav {
                    ul {
                        flex-direction: column;
                        align-items: center;

                        li {
                            margin-left: 0 !important;

                            &:not(:last-of-type) {
                                margin-bottom: 20px;
                            }
                        }
                    }
                }
            }
            
            &--top {
                flex-direction: column;
                
                > * {
                    &:not(:last-child) {
                        margin-bottom: 20px;
                    }
                }

                .pages {
                    border-left: none;
                    border-right: none;

                    ul {
                        flex-direction: column;

                        li {
                            margin-left: 0 !important;
                            text-align: center;
                        }
                    }
                }

                .book, .phone {
                    padding-left: 0;
                }
            }
        }
    }

    @include mq($until: 1350px) {
        padding: 20px 40px;
    }

    @include mq($until: $viewport--laptop) {
        padding: 20px 30px;
    }
    
    @include mq($until: $resp-nav) {
        height: 130px !important;
        align-items: center;

        &-nav {
            position: absolute;
            pointer-events: none;
            transform: translateY(-100vh);
            transition: transform .2s ease, opacity .2s ease;
        }
    }

    @include mq($until: 400px) {
        height: 90px !important;
        padding: 20px;

        &-logo {
            max-width: 100px;
        }

        &-nav {
            &--base {
                margin-bottom: 0px;
                
                nav {
                    ul {
                        li {
                            &:not(:last-of-type) {
                                margin-bottom: 5px;
                            }
                        }
                    }
                }
            }
        }

        .hamburger{
            &-checkbox {
                &:checked {
                    & ~ .Header {
                        &-nav {
                            transform: translateY(90px);
                        }
                    }
                }
            }
        }
    }
}