.pagination {
    margin-top: 120px;

    .pages {
        display: flex;
        justify-content: center;
        align-items: center;

        .progress {
            margin: 0 10px;
        }
    }
}