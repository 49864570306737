.timing {
    display: grid;
    grid-template-columns: repeat(2, 50%);

    &-left {
        margin-top: 80px;
        padding-top: 80px;
        padding-right: 80px;
        background-color: $cc-white;
        z-index: 1;

        .head-title {
            max-width: 360px;
        }

        .content {
            margin-top: 20px;
            padding-top: 30px;
            border-top: 1px solid var(--brandingColor);

            > * {
                &:not(:last-of-type) {
                    margin-bottom: 100px;
                }
            }

            &-timing {
                &--items {
                    margin-bottom: 60px;
                }

                &--item {
                    .title {
                        font-weight: 600;
                        margin-bottom: 10px;
    
                        span {
                            color: var(--brandingColor);
                            margin-right: 5px;
                        }
                    }
    
                    .text {
                        margin-bottom: 20px;

                        * {
                            margin-bottom: 10px;
                        }
                    }

                    &:not(:first-of-type) {
                        margin-top: 50px;
                    }
                }                
            }

            &-text {}
            
            &-parameters {
                margin-right: -80px;

                .parameters {
                    display: grid;
                    grid-template-columns: repeat(2, 50%);
                    flex-direction: column;
                    padding: 20px 0;
                    margin-bottom: 0;
                    background-color: var(--brandingShade);
                    grid-column-gap: 30px;
                    margin-bottom: 1px;
                    padding-right: 30px;

                    &:first-of-type {
                        padding-top: 50px;                        
                    }
                    
                    &:last-of-type {
                        padding-bottom: 50px;                        
                    }
        
                    li {
                        list-style: none;
                        display: flex;
                        margin-bottom: 0;       
                        
                        &:first-of-type {
                            padding-left: 40px;                        
                        }
                        
                        &:last-of-type {
                            padding-right: 40px;                        
                        }
        
                        figure {
                            max-width: 80px;
                            max-height: 80px;
                            min-width: 80px;
                            min-height: 80px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border: 1px solid var(--primaryDark);
                            border-radius: 100%;
        
                            img {
                                width: 40px;
                                height: 40px;
                            }
                        }
        
                        .text {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            margin-left: 20px;        
        
                            p {
                                margin-bottom: 5px;
                            }
        
                            a {
                                cursor: pointer;
                                color: var(--primaryDark);
                                text-decoration: none;
                                border-bottom: 1px solid var(--primaryDark);
                                padding-bottom: 2px;
                            }
                        }
                    }
                }   
            }
        }
    }

    &-right {
        .images {
            margin-left: -80px;
            position: relative;

            figure {
                width: 820px;
                height: 480px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .slick-arrow {
                position: absolute;
                right: 0;
                bottom: -20px;
                z-index: 1;
                
                &:not(.slick-disabled) {
                    cursor: pointer;
                }

                &.slick-disabled {
                    opacity: .5;
                }

                &:nth-of-type(1) {
                    right: 20px;
                }
            }
        }

        .quote {
            padding-top: 40px;
            padding-left: 40px;
            margin-left: 100px;
            margin-top: 100px;
        }
    }

    @include mq($until: 1500px) {
        margin-left: 100px;
        margin-right: 60px;
    }
    
    @include mq($until: 1350px) {
        margin-left: 30px;
        margin-right: 30px;
        padding-left: 0;
        padding-right: 0;

        &-left {
            padding-top: 50px;
            padding-right: 30px;

            .content {
                > *:not(:last-of-type) {
                    margin-bottom: 50px;
                }

                &-parameters {
                    margin-right: -30px;
                }
            }
        }

        &-right {
            .images {
                .slick-list {
                    width: 100%;
                    
                    * {
                        width: 100%;
                    }
                }
            }
        }
    }

    @include mq($until: 1150px) {
        &-left {
            .content {
                &-parameters {
                    .parameters {
                        grid-template-columns: auto;
                        margin-bottom: 0;
                        padding: 0;

                        li {
                            padding-left: 40px;
                            padding-right: 40px;
                            padding-bottom: 20px;
                        }

                        &:last-of-type {
                            li {
                                &:last-of-type {
                                    padding-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
        }

        &-right {
            .quote {
                margin-left: 60px;
                margin-top: 60px;
                margin-right: 30px;
            }
        }
    }

    @include mq($until: 900px) {
        display: flex;
        flex-direction: column-reverse;

        &-left {
            margin-top: 0;
        }

        &-right {
            .images {
                margin-left: 0px;
            }

            .quote {
                display: none;
            }
        }
    }

    @include mq($until: $viewport--sm) {
        &-right {
            .images {
                max-height: 300px;

                .slick {
                    &-list {
                        height: 100%;
    
                        * {
                            height: 100%;
                        }
                    }

                    &-arrow {
                        bottom: 15px;
                        right: 15px;

                        &:first-of-type {
                            right: 35px;
                        }
                    }
                }
            }
        }
    }
} 