.Filters {
    display: flex;
    justify-content: space-between;
    
    &.unusable {
        > * {
            pointer-events: none;
            opacity: .6;
        }
    }
    
    > * {
        display: flex;
        transition: opacity .2s ease;

        @include mq($until: $viewport--sm) {
            flex-direction: column;
            width: 100%;
        }
    }

    &-active-list {
        display: flex;
        align-items: center;
        max-width: 800px;
        flex-wrap: wrap;
        margin-bottom: 70px;

        > div {
            display: flex;
            align-items: center;
            margin-right: 30px;
            margin-bottom: 20px;

            button {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 10px;
                height: 10px;
                margin-right: 10px;
                cursor: pointer;

                svg {
                    width: 100%;
                    height: 100%;
                }
            }

            span {
                font-size: 14px;
            }
        }

        @include mq($until: $viewport--sm) {
            margin-bottom: 30px;
            justify-content: center;
        }

        @include mq($from: $desktop-size) {
            max-width: 1450px;
        }
    }

    .Filter {
        &-items {
            &--options {
                &.icons {
                    display: grid;
                    grid-template-columns: repeat(3, auto);
                    grid-gap: 30px 60px;
                    
                    @include mq($until: 1000px) {
                        display: flex;
                        flex-wrap: wrap;
                        max-width: 380px;
                        justify-content: flex-end;
                        gap: unset;
                    }
                }
            }

            &--option {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                
                &_input {
                    display: none;

                    &:checked {
                        & ~ .Filter-items--option_label {
                            span {
                                color: $cc-black;
                                font-weight: 500;

                                &:after {
                                    transform: scaleX(1);
                                }
                            }
                        }
                    }
                }

                svg {
                    margin-right: 20px;
                }

                &_label {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    
                    span {
                        position: relative;

                        &:after {
                            content: '';
                            position: absolute;
                            width: 100%;
                            transform: scaleX(0);
                            transition: transform .2s ease;
                            height: 1px;
                            left: 0;
                            bottom: -2px;
                            background-color: $cc-black;
                            transform-origin: center;
                        }
                    }

                    &:hover {
                        span {
                            &:after {
                                transform: scaleX(1);
                            }
                        }
                    }
                }

                @include mq($until: 1000px) {
                    margin-left: 40px;
                }
            }
        }
        
        &-dropdown {
            position: relative;
            margin-right: 20px;
            margin-bottom: 20px; 
            
            &.month {
                .Filter-dropdown--name {
                    &.cta {
                        &.not-clicktrough {                        
                            padding: 0 20px !important;
                        }
                    }
                }

                input {
                    outline: none;
                    background-color: unset;
                    border: none;
                    font-size: 12px;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    font-weight: 500;
                    font-family: $font-base;

                    &:focus, &:active {
                        outline: none;
                    }

                    &::placeholder {
                        color: var(--primary);
                    }
                }

                svg {
                    position: absolute;
                    right: 20px;
                    transform: scale(.8) rotate(0deg);
                }
            }

            &--name {
                z-index: 1;

                &.hide {
                    display: none;
                }

                svg {
                    transform: rotate(90deg);
                    margin-left: 20px;
                    transform-origin: center;
                    transition: transform .2s ease;
                }
            }

            &--activity {
                &:checked {
                    & ~ .Filter-dropdown--name {
                        border-color: var(--brandingColor) !important;
                        color: var(--brandingColor) !important;

                        svg {
                            transform: rotate(-90deg);
                        }

                        &:after {
                            background-color: $cc-white !important;
                        }
                    }

                    & ~ .Filter-dropdown--options {
                        opacity: 1;
                        top: 50px;
                        pointer-events: all;
                    }
                }
            }

            &--options {
                position: absolute;
                top: 0;    
                opacity: 0;    
                transition: top .2s ease, opacity .2s ease;     
                background-color: var(--brandingShade);   
                display: flex;
                flex-direction: column;                
                width: max-content;
                min-width: 100%;
                max-height: 250px;
                overflow-y: scroll;
                pointer-events: none;
                z-index: 10;

                /* width */
                &::-webkit-scrollbar {
                    width: 10px;
                }
                
                /* Track */
                &::-webkit-scrollbar-track {
                    background-color: var(--brandingShade);
                }
                
                /* Handle */
                &::-webkit-scrollbar-thumb {
                    cursor: pointer;
                    background: var(--brandingColor);
                    transition: opacity .2s ease;

                    &:hover {
                        background: rgba(var(--brandingColor), .5);
                    }
                }
            }

            &--option {
                &:first-of-type {
                    label {
                        padding-top: 15px;
                    }
                }
                
                &:last-of-type {
                    label {
                        padding-bottom: 15px;
                    }
                }

                &_label {
                    display: flex;
                    cursor: pointer;
                    font-size: 14px;                      
                    padding: 10px 20px;  
                    transition: background-color .2s ease, color .2s ease, border-color .2s ease;
                    
                    &:hover {
                        background-color: var(--brandingColor);
                        color: $cc-white;
                        border-color: $cc-white;
                    }
                }

                &_input {
                    display: none;

                    &:checked {
                        & ~ .Filter-dropdown--option_label {
                            span {
                                color: $cc-black;
                                font-weight: 500;                                
                            }
                        }
                    }
                }
            }

            @include mq($until: $viewport--sm) {
                margin-right: 0;
            }
        }        
    }

    @include mq($until: 900px) {
        flex-direction: column;

        .Filter {
            &-items {
                &--options {
                    &.icons {
                        margin-top: 20px;
                        justify-content: flex-start;
                    }
                }
                
                &--option {
                    margin-left: 0;
                    margin-right: 20px;
                    margin-bottom: 10px;
                }
            }
        }
    }
}