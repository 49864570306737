.cta {
    position: relative;
    color: $cc-white !important;
    text-transform: uppercase !important;
    border: 1px solid var(--brandingColor);
    transition: border-color .2s ease,  color .2s ease;
    padding: 10px 15px !important;
    letter-spacing: 1px;
    font-weight: 500;
    font-size: 12px;
    text-decoration: none;
    font-family: $font-base;
    text-align: center;
    display: inline-block;

    &.no-cta {
        pointer-events: none;
    }

    &.not-clicktrough {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 30px !important;
        height: 50px;
        color: var(--primaryDark) !important;
        border-color: var(--brandingShade) !important;
        letter-spacing: 2px;
        font-weight: 500;
        text-transform: uppercase;
        cursor: pointer;
        
        &:after {
            background-color: var(--brandingShade);
        }

        &:hover {
            border-color: var(--brandingColor) !important;
        }

        &:active {
            outline: none;
        }
    }

    &:after {
        content: '';
        z-index: -1;
        transition: opacity .2s ease;
        background-color: var(--brandingColor);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 1;
    }

    &:not(.fake-filled) {
        &:not(.not-filled) {
            &.fake, &.simple {
                padding-left: 0 !important;
                padding-right: 0 !important;
                padding-bottom: 10px !important;
                color: var(--brandingColor) !important;
                border: none;            
                font-size: 12px;
                position: relative;
    
                &:before {
                    content: '';
                    width: 100%;
                    height: 1px;
                    background-color: var(--brandingColor);
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    transition: transform .2s ease, background-color .2s ease;
                    transform-origin: center;
                }
    
                &:hover {
                    &:before {
                        transform: scaleX(0);
                    }
                }
        
                &:after {
                    opacity: 0;
                }
            }
        }
    }


    &.not-filled {
        padding: 20px 25px !important;
        color: var(--primaryDark) !important;
        border-color: var(--primaryDark) !important;
        
        &:after {
            opacity: 0;
        }

        &:hover {
            color: $cc-white !important;
            border-color: var(--brandingColor) !important;

            &:after {
                opacity: 1;
            }
        }
    }
    

    &.glowing {
        span {
            z-index: 1;
            position: relative;
        }

        &:after {
            background: rgb(191,130,57);
            background: linear-gradient(118deg, rgba(191,130,57,1) 0%, rgba(229,176,102,1) 23%, rgba(222,159,72,1) 40%, rgba(223,168,95,1) 67%, rgba(184,127,59,1) 100%);
            z-index: 0;
        }
    }

    &:hover {
        color: var(--brandingColor) !important;

        &:after {
            opacity: 0;
        }
    }


    &.filled, &.fake-filled {
        position: relative;
        color: $cc-white !important;
        text-transform: uppercase !important;
        border: 1px solid var(--brandingColor);
        transition: border-color .2s ease,  color .2s ease;
        padding: 10px 0 !important;
        padding-right: 15px !important;
        padding-left: 15px !important;
        letter-spacing: 1px;
        font-weight: 500;
        font-size: 12px;
        text-decoration: none;
        font-family: $font-base;
        text-align: center;
        display: inline-block;

        &:after {
            content: '';
            z-index: -1;
            transition: opacity .2s ease;
            background-color: var(--brandingColor);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 1;
        }

        &.dark {
            border-color: var(--primary);

            &:after {
                background-color: var(--primary);
            }
        }
       
        &.red {
            border-color: var(--red);

            &:after {
                background-color: var(--red);
            }
        }
    }    
}