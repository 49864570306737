.spotlight {       
    &-container {
        padding: 320px 0 400px;
        background-color: var(--brandingShade);
        margin-top: -250px;

        @include mq($from: $desktop-size) {
            margin-top: -180px;
        }

        @include mq($until: $resp-nav) {
            margin-top: -270px;
            padding-top: 270px;
            padding-bottom: 530px;
        }
    }

    h1 {
        text-align: center;
        padding-bottom: 20px;
        margin-bottom: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .element {
            letter-spacing: 10px;
            color: var(--brandingColor);
        }
    }

    .show-content {
        display: flex;
        flex-direction: column;

        > * {
            display: flex;  
            justify-content: center;             

            > * {
                &:not(.line) {
                    max-width: 670px;
                    width: 100%;
                }

                &:first-child {
                    margin-right: 10px;
                }

                &:last-child {
                    margin-left: 10px;
                }
            }

            &.titles {
                padding: 50px 0;

                .title {
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }
            }

            &.texts {
                .text {
                    padding: 50px 30px;
                    text-align: center;

                    P {
                        margin-bottom: 0;
                    }
                }
            }

            &.ctas {
                .cta {
                    padding: 20px 25px !important;
                    color: var(--primaryDark) !important;
                    border-color: var(--primaryDark) !important;
                    transition: color .2s ease, background-color .2s ease;

                    &:after {
                        opacity: 0;
                    }
                    
                    * {                        
                        color: var(--primaryDark) !important;
                        transition: color .2s ease;
                    }

                    &:hover {
                        background-color: var(--primaryDark) !important;
                        color: $cc-white !important;

                        * {                        
                            color: $cc-white !important;
                        }
                    }

                    &-container {
                        display: flex;
                        justify-content: center;
                        margin: 0 !important;
                    }
                }
            }

            &.images, .image {                    
                figure {
                    position: relative;
                }

                figure, img {
                    width: 100%;
                    object-fit: cover;                    
                }

                img {
                    height: 100%;
                }

                .highlight {
                    position: absolute;
                    top: 0;
                    left: 0;
                    color: $cc-white;
                    background-color: var(--primaryDark);
                    padding: 10px 15px;
                    text-transform: lowercase;
                    letter-spacing: 1px;
                    font-size: 13px;
                }

                img {
                    object-fit: cover;
                }
            }
        }

        > .line {
           height: 1px;
           max-width: 1380px;
           width: 100%;
           background-color: var(--brandingColor);
           margin-left: auto;
           margin-right: auto; 
        }

        &.mobile {
            display: none;
            max-width: 440px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;

            .show {
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }

            .js-carousel-agenda {
                .slick {
                    &-list {
                        z-index: 1;
                    }

                    &-slide {
                        > * {
                            * {                    
                                margin-left: 0;
                                margin-right: 0;
                                text-align: center;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }

                        .image {
                            height: 650px;
                        }
        
                        .title {
                            margin-top: 40px;
                            margin-bottom: 20px;
                        }
        
                        .text {
                            flex-direction: column;
                            margin-bottom: 20px;
        
                            p {
                                margin-bottom: 0;
                            }
                        }
        
                        .cta {
                            width: max-content;
                            margin-left: auto;
                            margin-right: auto;
        
                            * {
                                color: var(--brandingColor);
                                transition: color .2s ease;
                                z-index: 1;
                            }
        
                            &:after {
                                opacity: 0;
                                z-index: 0;
                                transition: opacity .2s ease;
                            }
        
                            &:hover {
                                &:after {
                                    opacity: 1;
                                }
        
                                * {
                                    color: $cc-white;
                                }
                            }
                        }
                    }

                    &-arrow {
                        position: absolute;
                        cursor: pointer;
                        z-index: 2;
                        top: calc(50% - 40px);
                        max-width: unset;
                        width: 60px;
                        height: 60px;
            
                        path {
                            transition: fill .2s ease;
                        }
            
                        circle {
                            transition: fill .2s ease;
                        }
            
                        &.slick-disabled {
                            pointer-events: none;
                            opacity: .5;
                        }
            
                        &:first-of-type {
                            left: -80px;
                        }
                        
                        &:last-of-type {
                            right: -80px;
                        }
            
                        &:hover {
                            path {
                                fill: $cc-white;
                            }
            
                            circle {
                                fill: var(--brandingColor);
                            }
                        }
                    }
                    
                }
                
            }
            
            @include mq($until: $viewport--md) {
                .js-carousel-agenda {
                    margin-bottom: 80px;

                    .slick {
                        &-arrow {
                            top: unset;
                            bottom: -100px;
        
                            &:first-of-type {
                                left: calc(50% - 70px);
                            }
        
                            &:last-of-type {
                                right: calc(50% - 70px);
                            }
                        }
                    }
                }
            }

            @include mq($until: 480px) {
                max-width: 320px;

                .js-carousel-agenda {
                    .slick {
                        &-slide {
                            .image {
                                height: 450px;
                            }
                        }
                    }
                }
            }
            
            @include mq($until: 350px) {
                max-width: 290px;

                .js-carousel-agenda {
                    .slick {
                        &-slide {
                            .image {
                                height: 400px;
                            }
                        }
                    }
                }
            }
        }        

        @include mq($until: 900px) {
            &.desktop {
                display: none;
            }

            &.mobile {
                display: flex;
            }
        }
    }

    @include mq($until: 1500px) {
        margin-left: 80px;
        margin-right: 80px;
    }
   
    @include mq($until: 1150px) {
        margin-left: 40px;
        margin-right: 40px;

        > * {
            &.titles {
                padding: 30px 0px;
            }
        }
    }
    
    @include mq($until: 960px) {
        margin-left: 20px;
        margin-right: 20px;

        > * {
            &.titles {
                padding: 30px 0px;
            }
        }
    }

    @include mq($until: 850px) {
        h1 {
            margin-bottom: 20px;
        }

        .show {
            &-content {
                > * {
                    &.titles {
                        padding: 30px 0px;
                    }

                    &.text {
                        padding: 30px 10px;
                    }
                }
            }
        }
    }
}