.kalender {
	max-width: 350px;

	.top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: var(--brandingOpacity);
		padding: 10px 20px;

		button {
			cursor: pointer;
		}
	}
}

.js-calendar {
	padding: 0;
	list-style: none;
	display: grid;
    grid-template-columns: repeat(7, 50px);
    grid-template-rows: 50px;	
}

.calendar-day {
	width: 50px;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	list-style: none;
	margin-bottom: 0;	
	position: relative;
	
	border: 0px solid var(--brandingOpacity);
	border-bottom-width: 1px;
	border-right-width: 1px;
	
	&:nth-of-type(7n + 1) {
		border-left-width: 1px;
	}

	&.today {
		&:after {
			content: '';
			position: absolute;
			width: 35px;
			height: 35px;
			border-radius: 50%;
			background-color: var(--primary);
		}

		span {
			color: white;
			position: relative;
			z-index: 1;
		}
	}

	&.base-tickets, &.last-tickets {
		&.sibling-month {
			opacity: .6;
		}

		&.today {
			&:after {
				border: 2px solid $cc-white;
				background-color: unset;
			}			
		}

		a {
			width: 45px;
			height: 45px;
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			z-index: 1;
		}

		span {
			font-weight: bold;
			color: $cc-white;			
		}

		&:before {
			content: '';
			width: 45px;
			height: 45px;
			left: 2px;
			top: 2px;
			position: absolute;
		}
	}

	&.base-tickets {		
		&:before {
			background-color: var(--primary);
		}
	}

	&.last-tickets {		
		&:before {
			background-color: var(--red);			
		}
	}

	&.sibling-month, a {
		text-decoration: none;
		color: #ccc;
	}
}

@include mq($until: 500px) {
	.kalender {
		max-width: 280px;
	}

	.js-calendar {
		grid-template-columns: repeat(7, 40px);
    	grid-template-rows: 40px;	
	}

	.calendar-day {
		width: 40px;
		height: 40px;

		&.today {
			&:after {
				width: 25px;
				height: 25px;
			}
		}

		&.base-tickets, &.last-tickets {
			a {
				width: 35px;
				height: 35px;
			}

			&:before {
				width: 35px;
				height: 35px;
			}
		}
	}
}