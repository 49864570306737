.golden-ctas {
    padding: 0 $space-base;
    display: flex;
    justify-content: center;

    &.small {
        flex-direction: column;
        padding: 0;
        margin-top: 320px !important;

        > span {
            padding: 50px 40px;
            display: flex;
            flex-direction: row;
            align-items: center;
            max-width: unset;

            .top {
                max-width: 340px;

                > h2 {
                    border-bottom: none;
                    margin-bottom: 0;
                    padding-bottom: 0;
                    margin-right: 40px;
                    font-size: 28px;
                    line-height: 34px;
                }

                > div {
                    display: none;
                }
            }

            .cta {
                width: 180px;

                &-container {
                    display: flex;
                    flex-direction: column;
                }
            }
        }

        > span + span {
            margin-top: 2px;
        }

        @include mq($until: $viewport--sm) {
            > span {
                flex-direction: column;
                align-items: flex-start;
                padding: 30px 40px;

                .top {
                    > h2 {
                        margin-right: 0px;
                    }
                }

                .cta-container {
                    margin-top: 20px;
                }
            }
        }
       
        @include mq($until: 400px) {
            > span {
                padding: 20px 30px;

                .top {
                    h2 {
                        font-size: 24px;
                        line-height: 28px;
                    }
                }
            }

            .cta {
                font-size: 11px;

                &.not-filled {
                    padding: 15px 20px !important;
                }
            }
        }
    }

    &.light {
        > span {
            background-color: $cc-white;

            strong {
                transition: color .2s ease;
            }

            &:hover {
                background-color: var(--brandingColor);

                strong {
                    color: $cc-white !important;
                }
            }
        }
    }

    > span {
        width: 100%;
        text-decoration: none;
        background-color: var(--brandingShade);
        transition: background-color .2s ease;
        color: var(--primaryDark);
        padding: 85px 70px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        &:not(:last-of-type) {
            margin-right: 15px;
        }

        > .top {
            > h2 {
                border-bottom: 1px solid var(--brandingColor);
                padding-bottom: 30px;
                margin-bottom: 50px;
                transition: color .2s ease, border-color .2s ease;
            }
    
            > div {
                margin-bottom: 60px;
                transition: color .2s ease;
            }
        }

        .cta {
            pointer-events: all;
            transition: color .2s ease, border-color .2s ease;
            margin-right: 10px;
            margin-bottom: 10px;
        }

        strong {
            transition: color .2s ease;
        }

        &:hover {
            background-color: var(--brandingColor);

            > .top {
                > h2 {
                    color: $cc-white;
                    border-color: $cc-white;
                }
    
                > div {
                    color: $cc-white;                
                }
            }

            strong {
                color: $cc-white !important;
            }

            .cta {
                color: $cc-white !important;
                border-color: $cc-white !important;
            }
        }
    }

    @include mq($from: $desktop-size) {
        max-width: 1760px;
        margin-left: auto;
        margin-right: auto;
    }

    @include mq($until: 1600px) {
        padding: 0 30px;

        > span {
            padding: 55px 40px;

            > .top {
                h2 {
                    padding-bottom: 30px;
                    margin-bottom: 30px;
                }
    
                > div {
                    margin-bottom: 40px;
                }
            }
        }
    }
  
    @include mq($until: $resp-nav) {
        flex-direction: column;        

        > span {
            margin-right: 0 !important;

            &:not(:last-of-type) {
                margin-bottom: 20px;
            }
        }
    }
    
    @include mq($until: $viewport--sm) {
        > span {
            padding: 35px 25px;

            > .top {
                > div {
                    margin-bottom: 0px;
                }
            }
        }
    }

    @include mq($until: 500px) {
        padding-left: 0;
        padding-right: 0;

        > * {
            padding-left: 50px;
            padding-right: 50px;

            > div {
                width: 100%;
                
                .cta {
                    margin-right: 0;
                    width: 100%;
                }
            }
        }
    }
}