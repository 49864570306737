.ArrangementsOverviewPage, .ShowsOverviewPage {
    .Filters {
        margin-top: 140px;
        margin-bottom: 30px;
        z-index: 1;
        position: relative;
        flex-wrap: wrap;
        
        @include mq($until: $viewport--sm) {
            margin-top: 80px;
            margin-bottom: 80px;
        }
    }

    .Content-list {
        .no-results {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 200px;
            margin-bottom: 100px;

            h1, h2 {
                text-align: center;
            }
        }
    }   

    .golden-ctas {
        padding-top: 320px;
        padding-bottom: 160px;
    }

    .nothing-found {
        margin-top: 80px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        h1 {
            font-size: 36px;
            max-width: 600px;
            margin-left: 20px;
            margin-right: 20px;
            line-height: 44px;
        }

        a {
            margin-top: 20px;
            color: var(--primary);
        }
    }
    
    @include mq($until: 1200px) {
        .Filters, .Content-list {
            margin-left: 30px;
            margin-right: 30px;
        }

        .golden-ctas {
            padding-top: 160px;
            padding-bottom: 80px;
        }
    }
    
    @include mq($until: $viewport--sm) {
        .Filters, .Content-list {
            padding-left: 0;
            padding-right: 0;
        }

        .golden-ctas {
            padding-top: 80px;
            padding-bottom: 30px;
        }
    }
}

.ShowsPage, .ArrangementsPage, .ExtrasOverviewPage {
    .videos {
        max-width: 1300px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 20px;
        padding-right: 20px;
        display: grid;
        max-width: 1120px;
        grid-row-gap: 20px;
        grid-template-columns: repeat(2, 50%);             

        .video {
            max-width: 560px;
            width: 100%;
            height: 400px;

            &:nth-of-type(odd) {
                padding-right: 10px;
            }
            
            &:nth-of-type(even) {
                padding-left: 10px;
            }
        }

        @include mq($until: 900px) {
            display: flex;
            flex-direction: column;
            align-items: center;

            .video {
                &:nth-of-type(odd) {
                    padding-right: 0;
                }
                
                &:nth-of-type(even) {
                    padding-left: 0;
                }
            }
        }

        &.amount-1 {
            display: flex;         
            justify-content: center;

            .video {
                max-width: 1100px;
                height: 700px;
                padding-left: 0 !important;
                padding-right: 0 !important;

                @include mq($until: 900px) {
                    height: 500px;
                }
            }
        }

        &-container {
            margin-top: 150px;
            margin-bottom: 100px;
        }

        @include mq($until: $viewport--md) {
            .video {
                height: 400px !important;
            }

            &-container {
                margin-top: 120px;
                margin-bottom: 80px;
            }
        }
        
        @include mq($until: $viewport--sm) {
            .video {
                height: 250px !important;
            }

            &-container {
                margin-top: 60px;
                margin-bottom: 40px;
            }
        }
    }

    .header {
        display: grid;
        grid-template-columns: 65% 30%;
        grid-template-areas: 'left right';
        margin-top: $navHeight;

        &.mobile {
            display: none;
            flex-direction: column;
            align-items: center;
            grid-template-columns: unset;            

            .text-container {
                display: flex;
            }
        }

        .left {
            grid-area: left;           
        }

        .right {
            grid-area: right;            
        }

        .calendar {            
            &-container {
                height: 100%;
            }

            &-content {
                display: flex !important;
                flex-direction: column;
                justify-content: center;
                height: 100%;

                .intro {
                    margin-top: 20px;
                }

                .buttons {
                    display: flex;
                    margin-bottom: 40px;

                    > * {
                        margin-right: 10px;
                        margin-bottom: 10px;
                    }
                }

                .ticket-options {
                    padding-left: 0;
                    margin-bottom: 0;

                    li {
                        list-style: none;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border-top: 1px solid var(--brandingColor);
                        margin-bottom: 0;
                        padding: 20px 0;

                        > div {
                            display: flex;
                            flex-direction: column;
                        }

                        p {
                            margin-bottom: 0;
                        }


                        &:last-of-type {
                            border-bottom: 1px solid var(--brandingColor);
                        }
                    }
                }

                .kalender {
                    &-legend {
                        display: flex;
                        align-items: center;

                        &--option {
                            display: flex;
                            align-items: center;
                            margin-right: 20px;
                            margin-bottom: 10px;

                            .square {
                                width: 12px;
                                height: 12px;
                                background-color: var(--primary);
                                margin-right: 10px;

                                &.red {
                                    background-color: var(--red);
                                }
                            }

                            p {
                                margin-bottom: 0;
                                font-size: 13px;
                            }
                        }

                        @include mq($until: $viewport--sm) {
                            flex-direction: column;
                            align-items: flex-start;
                        }
                    }
                }
            }
        }

        .figure {
            width: 100%;            

            .square-size {
                display: none;
            }
            
            @include mq($from: 1300px, $until: 1800px) {
                .normal-size {
                    display: none;
                }
                
                .square-size {
                    display: flex;
                }
            }

            @include mq($until: 400px) {
                .normal-size {
                    display: none;
                }
                
                .square-size {
                    display: flex;
                }
            }

            * {
                width: inherit;
            }

            .image {
                height: 620px;
                
                &.landscape {                    
                    height: auto !important;
                    
                    img {
                        object-fit: contain !important;
                    }
                }
            }

        }

        .parameters {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            padding: 20px 40px;
            margin-bottom: 50px;

            > * {
                margin: 10px 30px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                max-width: 280px;

                .icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-width: 50px;
                    min-height: 50px;
                    max-width: 50px;
                    max-height: 50px;
                    border: 1px solid var(--primary);
                    border-radius: 100%;
                    margin-right: 15px;

                    svg {
                        width: 20px;
                        height: 20px;
                    }
                }

                .text {
                    p {
                        margin-bottom: 0;
                        line-height: 20px;
                    }

                    a {
                        color: grey;
                        text-decoration: none;
                        font-size: 12px;
                        border-bottom: 1px solid $cc-grey;
                        padding-bottom: 3px;
                    }
                }
            }

            @include mq($until: $viewport--sm) {
                padding: 20px;
            }
        }

        .textcontent {               
            &-container {
                margin-left: 260px;
                margin-right: 260px;
                margin-top: 20px;

                &.more-spacing-top {
                    margin-top: 120px;
                }

                .back {
                    text-decoration: none;
                    color: var(--primary);
                    display: flex;
                    align-items: center;
                    transition: transform .2s ease;
                    margin-bottom: 80px;

                    span {
                        margin-left: 10px;
                    }

                    &:hover {                         
                        transform: translateX(-5px);                        
                    }
                }

                .zaal {
                    text-transform: uppercase;
                    color: var(--brandingColor);
                    margin-bottom: 5px;
                }

                .head {
                    &-title {
                        margin-bottom: 50px;
                    }
                }
                
                .quote {
                    margin-top: 60px;
                }                    
            }
        }

        .event-list {
            min-height: 620px;
            background-color: var(--brandingShade);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 100px 80px;

            h2 {
                margin-bottom: 20px;
            }

            .ticket-options {  
                max-width: 400px;
                width: 100%;

                .option {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 20px 0;
                    border-bottom: 1px solid var(--brandingOpacity);

                    p {
                        margin-bottom: 0;

                        &.date {
                            font-weight: 500;
                            text-transform: capitalize;
                        }

                        &.hour {
                            display: flex;
                            align-items: center;

                            span {
                                margin-left: 5px;
                            }
                        }
                    }

                    .cta {
                        z-index: 1;
                    }

                    &:first-of-type {
                        border-top: 1px solid var(--brandingOpacity);
                    }
                }

                .month {
                    &-navigation {
                        &--top {                                
                            padding: 5px 10px;
                            background-color: var(--brandingOpacity);
                            
                            &_title {
                                margin-bottom: 0;
                            }

                            &_month {
                                text-transform: uppercase;
                                font-size: 14px;
                            }

                            &_arrows {
                                display: flex;
                                justify-content: space-between;
                            }

                            &_arrow {
                                cursor: pointer;

                                svg {
                                    width: 8px;
                                    height: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }            

        .content {
            &-parameters {
                display: flex;
                flex-direction: column;

                .parameters {
                    display: grid;
                    grid-template-columns: repeat(2, 50%);
                    grid-column-gap: 20px;
                    align-items: center;
                    justify-content: center;
                    padding: 20px 40px;
                    margin-bottom: 0;
                    margin-top: 1px;
                    background-color: var(--brandingShade);

                    li {
                        list-style: none;
                        margin-bottom: 0;
                        display: flex;
                        align-items: center;

                        figure {
                            min-width: 80px;
                            max-width: 80px;
                            height: 80px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border: 1px solid black;
                            border-radius: 100px;
                            margin-right: 20px;
                        }

                        .text {
                            max-width: 130px;
                            
                            p {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }       

        @include mq($from: $desktop-size) {
            .left {
                .figure {
                    .image {
                        height: 800px;
                    }
                }
            }
            
            .right {
                .event-list {
                    min-height: 800px;
                }
            }
        }

        @include mq($until: 1800px) {
            grid-template-columns: 55% 40%;
            
            .left {
                .textcontent {    
                    &-container {
                        margin-left: 100px;
                        margin-right: 100px;
                    }
                }
            }
        }

        @include mq($until: 1500px) {
            grid-template-columns: 50% 45%;
        }
        
        @include mq($until: 1300px) {
            grid-template-columns: 50% 50%;
            padding-right: 30px;
            
            .left {
                .figure {    
                    .image {
                        height: 360px;
                    }
                }
    
                .textcontent-container {
                    margin-left: 30px;
                    margin-right: 30px;
    
                    .back {
                        margin-bottom: 40px;
                    }
                }
            }

            .right {
                .event-list {
                    padding: 60px 40px;
                    min-height: 500px;
                }

                .golden-ctas {
                    &.small {
                        margin-top: 250px !important;
                    }
                }
            }
        }

        @include mq($until: 1100px) {            
            padding-right: 0;            

            &.desktop {
                display: none;
            }

            &.mobile {
                display: flex;
                
            }
            
            .event-list {
                padding: 50px 20px;
                min-height: unset;
                width: 100%;
            }

            .figure {
                min-height: unset;

                .image {
                    height: 500px;
                }
            }

            .text-container {
                flex-direction: column;               
    
                .textcontent-container {
                    margin-left: 50px;
                    margin-right: 50px;

                    .back {
                        margin-bottom: 40px;
                    }
                }
    
                .golden-ctas {
                    &.small {
                        margin-top: 20px !important;
                        padding: 0 50px;

                        > span {
                            &:not(:last-of-type) {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }

        @include mq($until: $viewport--sm) {
            .figure {
                .image {
                    height: 300px;
                }
            }

            .parameters {
                margin-bottom: 20px;

                > * {
                    margin: 10px 0px;
                }
            }
        }

        @include mq($until: 400px) {
            margin-top: 90px;

            .text-container {
                .textcontent-container {
                    margin-left: 30px;
                    margin-right: 30px;
                }
    
                .golden-ctas {
                    &.small {
                        padding: 0 30px;
                    }
                }
            }

            .event-list {
                .ticket-options {
                    .option {
                        flex-direction: column;
                        align-items: flex-start;
    
                        .right {
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
    }
}

.ArrangementsPage, .ShowsPage, .ExtrasOverviewPage {    
    .book-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 200px 0;
        background-color: var(--brandingShade);
        margin-top: 180px;

        &.golden-ctas-active {
            padding-bottom: 350px;  
            
            @include mq($until: $viewport--md) {
                padding-bottom: 200px !important;
            }
        }

        @include mq($until: 1060px) {
            padding-top: 100px;
            margin-top: 100px;   
            padding-bottom: 100px;     
        }
        
        @include mq($until: $viewport--sm) {
            padding-top: 80px;
            margin-top: 80px; 
            padding-bottom: 80px;       
        }

        .book {
            width: 100%;
    
            h1 {
                text-align: center;
                padding-bottom: 20px;
                margin-bottom: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
    
                .element {
                    letter-spacing: 10px;
                    color: var(--brandingColor);
                }
            }
    
            .content {
                width: 100%;
                display: flex;
                justify-content: space-between;
    
                .book-images {
                    width: 670px;
                    height: 420px;
                    position: relative;
                    margin-right: 100px;

                    .slick {
                        &-arrow {
                            cursor: pointer;
                            position: absolute;
                            bottom: -30px;
                            right: 0;

                            &:first-of-type {
                                right: 20px;
                            }
                        }

                        &-disabled {
                            pointer-events: none;
                            opacity: .5;
                        }

                        &-list {
                            height: inherit;
    
                            * {
                                height: inherit;
                            }
                        }
                    }

                    figure {
                        width: inherit;
                        height: inherit;
        
                        img {
                            width: inherit;
                            height: inherit;
                            object-fit: cover;
                        }
                    }
                }
    
                .ticket-options {
                    max-width: 550px;
                    width: 100%;
                    display: flex;
                    flex-direction: column;

                    .no-results {
                        * {
                            text-align: center;
                        }
                    }

                    .month {
                        &-navigation {                            
                            &--top {
                                margin-bottom: 60px;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                user-select: none;
    
                                &_title {
    
                                }
    
                                &_arrows {
    
                                }

                                &_arrow {
                                    cursor: pointer;

                                    &.inactive {
                                        opacity: .5;
                                        pointer-events: none;
                                    }

                                    &:first-of-type {
                                        margin-right: 10px;
                                    }
                                }
                            }

                            &--bottom {
                                margin-top: 50px;
                                
                                &_arrows {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                }

                                &_arrow {
                                    cursor: pointer;

                                    &.inactive {
                                        opacity: 0;
                                        pointer-events: none;
                                    }

                                    .month {
                                        font-size: 12px;
                                        text-transform: uppercase;
                                        color: var(--brandingColor);
                                        padding: 0 10px;
                                    }
                                }
                            }
                        }
                        
                        &-slider {
                            &--month {

                            }
                        }
                    }
    
                    .option {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-end;
                        border-bottom: 1px solid var(--brandingColor);
                        padding-bottom: 5px;
    
                        * {
                            font-size: 16px;
                        }
    
                        .left {
                            p {
                                margin-bottom: 0;
                                text-transform: uppercase;
                            }
    
                            .date {
                                font-weight: 500;
                                color: var(--primaryDark);
                            }
    
                            .hour {
                                text-transform: uppercase;
                                color: $cc-darkGrey;
                            }
                        }
    
                        .right {
                            .book-btn {
                                color: var(--brandingColor);
                                text-transform: uppercase;
                                letter-spacing: 1px;
                                text-decoration: none;
                                transition: opacity .2s ease;
    
                                &.warning {
                                    color: $cc-red;
                                }
    
                                &.sold {
                                    opacity: .5;
                                }
    
                                &:not(.sold) {
                                    &:hover {
                                        opacity: .5;
                                    }
                                }
                            }
                        }
    
                        &:not(:last-of-type) {
                            margin-bottom: 40px;
                        }
                    }
                }

            }
            
            @include mq($until: 1600px) {
                .content {
                    .book-images {
                        width: 550px;
                        height: 350px;

                        .slick-arrow {
                            bottom: -100px;
                        }
                    }
                }
            }
            
            @include mq($until: 1300px) {
                margin-left: 50px;
                margin-right: 50px;

                .content {
                    .book-images {
                        margin-right: 40px;
                    }
                }
            }

            @include mq($until: 1150px) {
                margin-left: 50px;
                margin-right: 50px;

                .content {
                    
                }
            }
           
            @include mq($until: 1060px) {
                margin-left: 0px;
                margin-right: 0px;
                padding-left: 0;
                padding-right: 0;                

                .content {
                    flex-direction: column;
                    align-items: center;

                    .book-images {
                        margin-right: 0;

                        .slick-arrow {
                            z-index: 1;
                            bottom: 15px;
                            right: 15px;

                            &:first-of-type {
                                right: 35px;
                            }
                        }
                    }

                    .ticket-options {
                        margin-top: 80px;
                        padding: 0 30px;

                        .option {
                            &:not(:last-of-type) {
                                margin-bottom: 30px;
                            }

                            * {
                                font-size: 15px;
                            }
                        }
                    }
                }
            }

            @include mq($until: $viewport--md) {
                h1 {
                    margin-bottom: 20px;
                }

                .content {
                    .book-images {
                        width: 100%;
                    }

                    .ticket-options {
                        margin-top: 50px;

                        .month-navigation {
                            &--top {
                                margin-bottom: 30px;
                            }

                            &--bottom {
                                margin-top: 30px;
                            }
                        }
                    }
                }
            }

            @include mq($until: 500px) {
                .content {
                    .ticket-options {
                        .option {
                            flex-direction: column;
                            justify-content: flex-start;
                            align-items: flex-start;

                            &:not(:last-of-type) {
                                margin-bottom: 20px;
                                padding-bottom: 15px;
                            }
                        }
                    }
                }
            }
        }

        .nothing-available {
            margin-top: 50px;
            margin-bottom: 40px;

            &.center {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: auto;
                margin-right: auto;
                text-align: center;
            }
        }
        
        .Filter {
            position: relative;
            z-index: 1;
            padding-top: 20px;
            padding-bottom: 100px;

            &-options {
                display: flex;
                flex-wrap: wrap;

                > * {
                    margin-right: 20px;
                    margin-bottom: 20px;
                }
            }            

            .cta {
                &.not-clicktrough {
                    &:after {
                        background-color: #F7EFE3 !important;
                    }
                }
            }

            &-option {
                display: none;

                &:checked {
                    & ~ .cta {
                        color: $cc-white !important;

                        &:after {
                            background-color: var(--brandingColor) !important;
                        }

                        &:hover {
                            color: $cc-white !important;
                            
                            &:after {
                                background-color: var(--brandingColor) !important;
                                opacity: 1 !important;
                            }
                        }
                    }
                }
            }

            @include mq($until: 1060px) {
                padding-bottom: 0;

                &-options {
                    justify-content: center;
                    margin: 0 20px;

                    > * {
                        margin-left: 10px;
                        margin-right: 10px;
                        margin-bottom: 20px;
                        display: flex;
                        align-items: center;

                        .cta {
                            text-align: center;
                            width: auto;
                            display: flex;
                            min-height: 50px;
                            height: auto;
                            padding: 20px 30px !important;
                            line-height: 16px;
                        }
                    }
                }                
            }
        }
    }

    .sfeerbeelden {
        .slick {
            &-list {
                height: 560px;
                
                * {
                    height: inherit;
                }

                @include mq($until: $viewport--sm) {
                    height: 300px;
                }                
            }

            &-slide {                
                padding: 0 10px;
                max-width: 70vw;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;

                    @include mq($until: $viewport--sm) {
                        height: 300px !important;
                    }    
                }

                @include mq($until: 400px) {
                    max-width: 250px;
                }

                @include mq($from: 2200px) {
                    max-width: 1200px;
                }

                
            }

            &-arrow {
                position: absolute;
                top: -160px;
                cursor: pointer;
                transition: opacity .3s ease;

                &.prev {
                    left: 140px;
                    right: unset;
                }

                &.next {
                    left: unset;
                    right: 140px;
                }

                &:hover {
                    opacity: .6;
                }

                @include mq($until: 900px) {
                    &.prev {
                        left: 80px;
                    }
                    
                    &.next {
                        right: 80px;
                    }
                }
                
                @include mq($until: $viewport--sm) {
                    top: -100px;

                    svg {
                        min-width: 50px;
                        max-width: 50px;
                        min-height: 50px;
                        max-height: 50px;
                    }

                    &.prev {
                        left: 40px;
                    }
                    
                    &.next {
                        right: 40px;
                    }
                }

                @include mq($until: 450px) {
                    visibility: hidden;
                }
            }
        }


        &-container {
            margin-top: 120px;
            
            h1 {
                text-align: center;
                padding-bottom: 20px;
                margin-bottom: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
        
                .element {
                    letter-spacing: 10px;
                    color: var(--brandingColor);
                }
            }

            @include mq($until: $viewport--sm) {
                margin-top: 60px;

                h1 {
                    margin-bottom: 20px;
                }
            }
        }
    }

    .golden-ctas {
        margin-top: 120px;        
    }
    
    .slider {
        margin-top: 240px;   
        
        @include mq($until: 1060px) {
            margin-top: 140px;       
        }
        
        @include mq($until: $viewport--sm) {
            margin-top: 80px;      
        }
    }
}