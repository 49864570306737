/* Settings: Fonts */

// Default
$font-sans:                             "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-serif:                            Georgia, "Times New Roman", Times, serif !default;
$font-code:                             Menlo, Monaco, Consolas, monospace, serif !default;

// Custom
$font-base:                      "Gotham", sans-serif !default; //200, 300, 400, 500, 600, 700, 800, 900
$font-secondary:                      "Didonesque", sans-serif !default; //200, 300, 400, 500, 600, 700, 800, 900

@font-face {
    font-family: "Gotham";
    src: url('../fonts/Gotham-Light.woff2') format('woff2'),
        url('../fonts/Gotham-Light.woff') format('woff');
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: "Gotham";
    src: url('../fonts/Gotham-Lightitalic.woff2') format('woff2'),
        url('../fonts/Gotham-Lightitalic.woff') format('woff');
    font-style: italic;
    font-weight: 300;
}

@font-face {
    font-family: "Gotham";
    src: url('../fonts/Gotham-Book.woff2') format('woff2'),
        url('../fonts/Gotham-Book.woff') format('woff');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "Gotham";
    src: url('../fonts/Gotham-BookItalic.woff2') format('woff2'),
        url('../fonts/Gotham-BookItalic.woff') format('woff');
    font-style: italic;
    font-weight: 400;
}

@font-face {
    font-family: "Gotham";
    src: url('../fonts/Gotham-Medium.woff2') format('woff2'),
        url('../fonts/Gotham-Medium.woff') format('woff');
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: "Gotham";
    src: url('../fonts/Gotham-MediumItalic.woff2') format('woff2'),
        url('../fonts/Gotham-MediumItalic.woff') format('woff');
    font-style: italic;
    font-weight: 500;
}

@font-face {
    font-family: "Gotham";
    src: url('../fonts/Gotham-Bold.woff2') format('woff2'),
        url('../fonts/Gotham-Bold.woff') format('woff');
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: "Gotham";
    src: url('../fonts/Gotham-Bolditalic.woff2') format('woff2'),
        url('../fonts/Gotham-Bolditalic.woff') format('woff');
    font-style: italic;
    font-weight: 600;
}

@font-face {
    font-family: "Gotham";
    src: url('../fonts/Gotham-Black.woff2') format('woff2'),
        url('../fonts/Gotham-Black.woff') format('woff');
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: "Gotham";
    src: url('../fonts/Gotham-Blackitalic.woff2') format('woff2'),
        url('../fonts/Gotham-Blackitalic.woff') format('woff');
    font-style: italic;
    font-weight: 700;
}

@font-face {
    font-family: "Gotham";
    src: url('../fonts/Gotham-Ultra.woff2') format('woff2'),
        url('../fonts/Gotham-Ultra.woff') format('woff');
    font-style: normal;
    font-weight: 800;
}

@font-face {
    font-family: "Gotham";
    src: url('../fonts/Gotham-Ultraitalic.woff2') format('woff2'),
        url('../fonts/Gotham-Ultraitalic.woff') format('woff');
    font-style: italic;
    font-weight: 800;
}

@font-face {
    font-family: 'Didonesque';
    src: url('../fonts/DidonesqueLite-Black.woff2') format('woff2'),
        url('../fonts/DidonesqueLite-Black.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Didonesque';
    src: url('../fonts/DidonesqueLite-BlackItalic.woff2') format('woff2'),
        url('../fonts/DidonesqueLite-BlackItalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Didonesque';
    src: url('../fonts/DidonesqueLite-MediumItalic.woff2') format('woff2'),
        url('../fonts/DidonesqueLite-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Didonesque';
    src: url('../fonts/DidonesqueLite-BoldItalic.woff2') format('woff2'),
        url('../fonts/DidonesqueLite-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Didonesque Roman';
    src: url('../fonts/DidonesqueLite-Italic.woff2') format('woff2'),
        url('../fonts/DidonesqueLite-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}


@font-face {
    font-family: 'Didonesque';
    src: url('../fonts/DidonesqueLite-Medium.woff2') format('woff2'),
        url('../fonts/DidonesqueLite-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Didonesque';
    src: url('../fonts/DidonesqueLite-Bold.woff2') format('woff2'),
        url('../fonts/DidonesqueLite-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Didonesque';
    src: url('../fonts/DidonesqueLite-Roman.woff2') format('woff2'),
        url('../fonts/DidonesqueLite-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}



