.slider {
    position: relative;

    &.title-not-aligned {
        .slider-item {
            &--image {
                margin-top: 165px;
            }
            
            &--info {
                margin-top: 0;
            }
        }
    }

    &-item {
        display: grid;
        grid-template-columns: repeat(2, 50%);

        &--image {
            width: 100%;
            height: 550px;
            
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &--info {
            padding: 80px 100px;
            margin-left: -40px;
            background-color: $cc-white;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            height: 100%;

            h2 {
                width: 100%;
                border-bottom: 1px solid var(--brandingColor);
                padding-bottom: 20px;
                margin-bottom: 30px;
            }

            .text {
                margin-bottom: 30px;
            }

            .cta {
                z-index: 2;
            }
        }
    }

    &__counter {    
        position: relative;

        &-container {
            position: absolute;
            left: 50%;
            bottom: 2px;
            margin-left: 60px;
            z-index: -1;
        }

        span {
            font-weight: 500;
        }
    }

    @include mq($until: 1400px) {
        &.slick {
            &-slider {
                margin-left: 50px;
                margin-right: 50px;                
            }

            &-track {

            }
        }

        &-item {
            &--image {
                margin-top: 140px !important;
            }

            &--info {
                padding: 60px;
            }
        }
    }

    @include mq($until: 960px) {
        &-item {
            grid-template-columns: 100%;

            &--image {
                margin-top: 0px !important;
            }

            &--info {
                margin-left: 0;
            }
        }

        &__counter {                   
            &-container {
                left: 0;
                margin-left: 130px;
                z-index: 1;
                position: relative;
                margin-top: 20px;
            }
        }
    }
    
    @include mq($until: $viewport--md) {
        &.slick {
            &-slider {
                margin-left: 20px;
                margin-right: 20px;
            }
        }

        &-item {
            &--info {
                padding: 30px 20px;
                
                h2 {
                    margin-bottom: 20px;
                }

                .text {
                    * {
                        margin-bottom: 0;
                    }
                }
            }
        }

        &__counter {                   
            &-container {
                margin-left: 40px;
            }
        }
    }

    @include mq($until: $viewport--sm) {
        &-item {
            &--image {
                height: 280px !important;
            }
        }
    }
}