.agenda {
    padding: 220px 30px;
    background-color: var(--brandingShade);

    > .top {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;        
        padding: 0 30px;
        margin-bottom: 100px;
   
        h1 {
            text-align: left;
            padding-bottom: 20px;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
    
            .element {
                letter-spacing: 10px;
                color: var(--brandingColor);
            }
        }

        @include mq($until: 850px) {
            flex-direction: column;
            padding: 0;

            a {
                margin-top: 40px;
            }
        }
       
        @include mq($until: $viewport--sm) {
            padding: 0;
            margin: 0 10px 50px;

            a {
                margin-top: 10px;
            }
        }
    }    

    .js-carousel-agenda {
        display: flex;
        align-items: center;

        .slick-arrow {
            position: absolute;
            cursor: pointer;

            path {
                transition: fill .2s ease;
            }

            circle {
                transition: fill .2s ease;
            }

            &.slick-disabled {
                pointer-events: none;
                opacity: .5;
            }

            &:first-of-type {
                left: -180px;
            }
            
            &:last-of-type {
                right: -180px;
            }

            &:hover {
                path {
                    fill: $cc-white;
                }

                circle {
                    fill: var(--brandingColor);
                }
            }
        }
    }
    
    &-items {
        .slick {
            &-track {
                display: flex !important;
                height: 100%;
            }

            &-slide {
                margin: 0 10px;
                height: auto;

                > div {
                    height: 100%;
                }
            }
        }        

        .Show-tile {
            position: relative;
            display: flex !important;
            flex-direction: column;
            justify-content: flex-start;
            height: 100%;

            &--image {
                min-height: 620px;
                max-height: 620px;

                img {
                    height: inherit;
                    object-fit: cover;
                }
            }

            &--info {
                position: unset;
                padding: 40px 30px;
                background-color: $cc-white;
                height: 100%;
                justify-content: flex-start;

                .highlight {
                    left: 0;
                    right: unset;
                }

                .top {
                    min-height: 120px;
                }

                .intro {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    margin-top: 0;
                    
                    p {
                        margin-bottom: 0;
                    }
                }

                .bottom {
                    margin-top: auto;
                }
            }
        }
    }

    @include mq($until: 1900px) {        
        &-items {
            margin-left: 90px;
            margin-right: 90px;
        }

        .js-carousel-agenda {
            .slick {            
                &-arrow {
                    &:first-of-type {
                        left: -100px;
                    }
    
                    &:last-of-type {
                        right: -100px;
                    }
                }
            }
        }
    }

    @include mq($until: 1400px) {
        padding: 100px 30px 140px;

        &-items {
            .Show {
                &-tile {
                    &--image {
                        min-height: 520px;
                        max-height: 520px;
                    }

                    &--info {
                        .intro {
                            margin-top: 20px;
                        }
                    }
                }
            }

            svg {
                width: 60px;
                height: 60px;
            }
        }
    }

    @include mq($until: 960px) {
        padding: 80px 20px 140px;

        &-items {
            margin-left: 60px;
            margin-right: 60px;
        }

        .js-carousel-agenda {
            .slick {
                &-arrow {
                    &:first-of-type {
                        left: -80px;
                    }

                    &:last-of-type {
                        right: -80px;
                    }
                }
            }
        }
    }

    @include mq($until: $viewport--md) {
        padding: 80px 20px 200px;
        
        &-items {
            margin-left: 0;
            margin-right: 0;
        }

        .js-carousel-agenda {
            .slick {
                &-arrow {                    
                    bottom: -80px;

                    &:first-of-type {
                        left: calc(50% - 70px);
                    }

                    &:last-of-type {
                        right: calc(50% - 70px);
                    }
                }
            }
        }
    }

    @include mq($until: 700px) {
        &-items {
            .Show {
                &-tile {
                    &--info {
                        .top {
                            min-height: 0;
                        }
                    }
                }
            }
        }
    }

    @include mq($until: 400px) {
        &-items {
            .Show {
                &-tile {
                    &--image {
                        min-height: 400px;
                        max-height: 400px;
                    }
                }
            }
        }
    }
}