.container {
    margin-left: auto;
    margin-right: auto;
    padding: 0 20px;

    &--regular {
        margin-left: 260px;
        margin-right: 260px;
    }

    @include mq($from: $desktop-size) {
        &--regular {
            max-width: 1450px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }
    }

    @include mq($until: 1900px) {
        &--regular {
            margin-left: 160px;
            margin-right: 160px;
        }
    }

    @include mq($from: $resp-nav, $until: $viewport--laptop) {
        &--regular {
            margin-left: 60px;
            margin-right: 60px;
        }
    }
   
    @include mq($until: $resp-nav) {
        &--regular {
            margin-left: 30px;
            margin-right: 30px;
        }
    }
    
    @include mq($until: $viewport--sm) {
        &--regular {
            padding: 0;
        }
    }
}