.quote-block {
    display: flex;    
    align-items: flex-start;
    
    .quote {
        background-color: $cc-white;
        padding: 40px 120px 40px 30px;   
        margin-right: -80px;
        margin-top: 80px;
        z-index: 1;
    }

    .image {
        max-width: 820px;
        max-height: 480px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    @include mq($until: $viewport--md) {
        flex-direction: column-reverse;
    }
    
    @include mq($until: $viewport--sm) {
        .quote {
            margin-top: 40px;
        }
    }
}