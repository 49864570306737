.accordion {
    &-container {
        border-top: 1px solid var(--brandingColor);
        margin-top: 40px;
    }

    &-item {
        border: none !important;
        background-color: unset !important;
        border-bottom: u(1) solid var(--brandingColor) !important;        
        margin-top: 0 !important;

        .accordion-title {
            &:after {
                transform: rotate(90deg) !important; 
            }
        }
    
        &.is-active {
            .accordion-title {
                &:after {
                    transform: rotate(-90deg) !important; 
                    opacity: 1 !important;           
                }
            }
        }
    }

    &-title, &-text {
        font-family: $font-base !important;        
    }

    &-text {
        padding-left: 0 !important;
        padding-right: 0 !important;
        
        .ac-text {
            padding-top: 0 !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
        }

        > * {            
            font-family: $font-base !important;            
        }

        * {
            margin-bottom: 5px;
        }

        ul {
            padding-left: 17px;
            
            li {
                margin-top: 20px;                
                list-style-type: disc;
            }
        }

        a {
            color: $cc-black;
        }
    }
   
    &-title {
        padding: 0;
        padding-left: 0 !important;
        padding-right: u(40);
        padding-top: 15px !important;
        padding-bottom: 15px !important;
        font-size: 16px !important;
        color: var(--primaryDark);
        text-decoration: none;
        font-weight: 600 !important;

        &:focus {
            outline: none;
            color: var(--primaryDark) !important;
            opacity: 1 !important;
        }       

        &:after {
            content: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.09' height='15.939' viewBox='0 0 10.09 15.939'%3E%3Cpath id='Path_84' data-name='Path 84' d='M17988.873-1860.946l-2.121-2.121,5.848-5.848-5.848-5.848,2.121-2.121,7.969,7.969Z' transform='translate(-17986.752 1876.885)' fill='%23c0924a'/%3E%3C/svg%3E") !important;
            width: 13 !important;
            height: 7 !important;
            transition: all .2s ease;
            right: 2px !important;
            top: calc(50% - 12px) !important;
            transform: rotate(-90deg) !important;  
            opacity: 0.35 !important;
            transform-origin: center;      
        }
    }

    @include mq($from: 0, $until: $viewport--sm) {
       padding-left: 0;
       padding-right: 0;
    }
}