.HomePage {    
    .textblock {        
        padding-bottom: 600px;

        @include mq($until: $viewport--sm) {
            padding-bottom: 400px;
        }
    }

    .agenda {
        background-color: unset !important;

        .Show-tile {
            &--image {
                min-height: 470px;
                max-height: 470px;
            }
            
            &--info {
                background-color: var(--brandingShade);
            }
        }

        @include mq($from: $viewport--sm) {    
            .mobile {
                &-button, &-shows {
                    display: none;
                }
            }
        }
        
        @include mq($until: $viewport--sm) {  
            padding: 80px 20px;

            .top {
                align-items: center;

                h1 {
                    align-items: center;
                }

                > a {
                    display: none;
                }
            } 

            .mobile {
                &-button, &-shows {
                    display: flex;
                }

                &-button {
                    margin-top: 50px;
                    justify-content: center;
                    width: 100%;

                    a {
                        max-width: 360px;
                        width: 100%;
                    }
                }

                &-shows {
                    flex-direction: column;

                    .Show-tile {
                        &--image {
                            min-height: 400px;
                            max-height: 400px;
                        }
                    }
                }
            }

            .js-carousel-agenda {
                display: none;
            }
        }
    }

    @include mq($until: 1700px) {
        .textblock {
            padding-top: 0;
            padding-bottom: 200px;
        }
    }

    .golden-ctas {
        padding-top: 120px;
        position: relative;
        z-index: 1;

        @include mq($until: 1100px) {
            padding-top: 80px;
        }
    }

    .slider {        
        &-item {
            &--image {
                height: 460px;
            }
        }        
    }
}