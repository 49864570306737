form {    
    .hide {
        display: none;
    }   

    input  {
        border: 1px solid var(--primary);
        background-color: var(--primary);
        font-size: 12px;
        font-family: $font-base;
        color: $cc-white !important;
        appearance: none;
        resize: none;
        padding: 15px 10px;
        width: 100%;

        &::placeholder {
            color: $cc-white;
        }
    }
    
    button {
        font-family: $font-base !important;
        font-size: 12px;
        font-weight: 500;
        margin-top: 0;
        color: $cc-white;
        text-transform: uppercase;
        letter-spacing: 1px;
        padding: 15px !important;
        border-color: $cc-grey;

        cursor: pointer;
        
        &:after {
            opacity: 0;
        }

        &:hover {
            color: var(--brandingColor);
            border-color: var(--brandingColor);
        }
    }
}