/* Colors: Custom */
//Example: $cc-[NAME]: #......

// [PROJECTNAME] colors
$cc-white: white;
$cc-lightestGrey: #f0f0f0 !default;
$cc-lightGrey: #F4F4F4 !default;
$cc-grey: #B9B9B9 !default;
$cc-darkGrey: #6A6A6E  !default;
$cc-darkerGrey: #8B8B8B !default;
$cc-darkestGrey: #3B3B3B !default;
$cc-black:#293241 !default;
$cc-blackShade: #435572 !default;
$cc-red: #e20a0a !default;

:root {
    --brandingColor: #C0924A;
    --brandingShade: #FDF9F6;
    --brandingOpacity: #EDDABF;
    --primary: #041E42;
    --primaryShade: #041e4236;
    --primaryDark: #070023;
    --whiteShade: #FDFAF5;
    --red: #CE2419;
}

[data-theme="kusttheater"] {
    --brandingColor: #ae3030;
    --brandingShade: #f5f5f5;
    --brandingOpacity: #da5353;
    --primary: #171717;
    --primaryDark: #000000;
    --primaryShade: #17171736;
    --whiteShade: #fffffe;
    --red: #CE2419;
}
