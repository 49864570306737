.Popup {
    background-color: $cc-white;
    padding: 120px 90px;
    position: relative;
    width: 100%;
    max-width: 800px;
    margin-left: 20px;
    margin-right: 20px;

    &-container {
        position: fixed;
        z-index: 10;
        width: 100%;
        height: 100%;
        pointer-events: all;
        background-color: rgba(var(--primary), .5);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: opacity .2s ease;

        &.remove {
            opacity: 0;
            pointer-events: none;
        }

        &.kusttheater {
            .cta {
                color: var(--brandingColor) !important;
                border-color: var(--brandingColor) !important;

                &:after {
                    background-color: var(--brandingColor) !important;
                }
            }
        }
    }

    &-close {
        position: absolute;
        right: 30px;
        top: 30px;
        cursor: pointer;
        transition: transform .2s ease;

        &:hover {
            transform: rotate(90deg);
        }
    }

    .line {
        margin-top: 30px;
        margin-bottom: 30px;
        background-color: var(--brandingColor);
        width: 100%;
        height: 1px;
    }

    .text {        
        margin-right: 80px;

        > * {
            margin-bottom: 0;
        }
    }

    .cta {
        color: var(--primaryDark) !important;
        padding: 20px 25px !important;
        border-color: var(--primaryDark) !important;

        &:after {
            opacity: 0 !important;
            background-color: var(--primaryDark) !important;
        }

        &:hover {
            color: $cc-white !important;

            &:after {
                content: '' !important;
                opacity: 1 !important;
            }
        }

        &-container {
            position: relative;
            z-index: 11;
            margin-top: 50px;
            margin-right: 80px;
        }
    }

    .image {
        position: absolute;
        bottom: 0;
        right: -30px;
        width: 200px;
        height: 250px;

        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
            object-position: bottom;
        }
    }

    @include mq($until: $viewport--md) {
        padding: 70px 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        * {
            text-align: center;
        }

        .text, .cta-container {
            margin-right: 0;
        }

        &-container {
            padding-top: 20px;
            padding-bottom: 20px;
        }

        .image {
            display: none;
        }
    }

    @include mq($until: $viewport--sm) {
        padding: 70px 20px;

        .line {
            margin-top: 20px;
            margin-bottom: 20px;
        }

        .cta-container {
            margin-top: 20px;
        }

        &-close {
            right: 20px;
            top: 20px;
        }
    }
}