html {
    &.kusttheater {
        .slick-arrow {
            circle {
                stroke: var(--brandingColor);
            }
            
            path {
                fill: var(--brandingColor);
            }
        }

        .pagination {
            .page {
                svg {
                    circle {
                        stroke: var(--brandingColor);
                    }
                    
                    path {
                        fill: var(--brandingColor);
                    }
                }
            }
        }

        .cta {
            &.not-filled {
                border-color: var(--brandingColor) !important;
                color: var(--brandingColor) !important;
                
                * {
                    color: var(--brandingColor) !important;
                }

                &:after {
                    background-color: var(--brandingColor) !important;
                }

                &:hover {
                    color: $cc-white !important;
                    background-color: unset !important;

                    * {
                        color: $cc-white !important;
                    }
                }
            }
        }

        .golden-ctas {
            > span {
                &:hover {
                    a {
                        border-color: $cc-white !important;
                        color: $cc-white !important;

                        * {
                            color: $cc-white !important;
                        }
                    }
                }
            }
        }

        .accordion {
            &-title {
                &:after {
                    content: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.09' height='15.939' viewBox='0 0 10.09 15.939'%3E%3Cpath id='Path_84' data-name='Path 84' d='M17988.873-1860.946l-2.121-2.121,5.848-5.848-5.848-5.848,2.121-2.121,7.969,7.969Z' transform='translate(-17986.752 1876.885)' fill='%23ae3030'/%3E%3C/svg%3E") !important;
                }
            }
        }

        .PraktischeInfoPage {            
            .full-page-header-whiteblock {      
                .parameters {
                    li {
                        .text {                        
                            a {
                                color: var(--brandingColor);
        
                                &:before {
                                    background-color: var(--brandingColor);
                                }
                            }
                        }
                    }      
                }
            }
        }

        .Filter-items--options {
            &.icons {
                svg {
                    path, line {
                        stroke: var(--brandingColor);
                    }
                }
            }
        }

        .Show-list {
            .show {
                .categories {
                    svg {
                        path, line {
                            stroke: var(--brandingColor);
                        }
                    }
                }
            }
        }

        .header-slider {
            .right {
                &:before {
                    content: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='71.888' height='71.888' viewBox='0 0 71.888 71.888'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Cpath id='Path_146' data-name='Path 146' d='M0,60.776H71.888V-11.112H0Z' transform='translate(0 11.112)' fill='%23fcf9f6'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Group_112' data-name='Group 112' transform='translate(0 11.112)'%3E%3Cg id='Group_110' data-name='Group 110' transform='translate(0 -11.112)' clip-path='url(%23clip-path)'%3E%3Cg id='Group_109' data-name='Group 109' transform='translate(-0.001 0.001)'%3E%3Cpath id='Path_145' data-name='Path 145' d='M60.776,30.388A35.944,35.944,0,0,1,24.832-5.556,35.94,35.94,0,0,1-11.112,30.388,35.94,35.94,0,0,1,24.832,66.332,35.944,35.944,0,0,1,60.776,30.388' transform='translate(11.112 5.556)' fill='%23f5f5f5'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                }
            }
        }

        .custom-select {
            .select-selected {
                &:after {
                    content: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='7' viewBox='0 0 13 7'%3E%3Cpath id='Path_238' data-name='Path 238' d='M6.475,0h0L0-6.225.806-7,6.478-1.547,12.194-7,13-6.225,6.475,0Z' transform='translate(0 7)' fill='%23171717'/%3E%3C/svg%3E");
                }
            }
        }

        .calendar-container {
            svg {
                path {
                    stroke: #171717;
                }                
            }
        }
    }
}