.AgendaPage {
    .agenda {
        &-container {
            display: grid;
            grid-template-columns: repeat(2, 50%);
            grid-template-areas: 'filter productions';
            position: relative;
            margin-top: 120px;
            padding: 0 140px;

            @include mq($from: $desktop-size) {
                max-width: 1700px;
                margin-left: auto;
                margin-right: auto;
                padding: 0;
            }

            @include mq($until: 1700px) {
                padding: 0 40px;
            }

            @include mq($until: 1500px) {
                grid-template-columns: 40% 60%;
            }

            @include mq($until: $viewport--sm) {
                padding: 0 30px;
                margin-top: 40px;
            }
        }
    }    

    .filter {
        padding: 60px;
        background-color: var(--brandingShade);

        &-container {
            grid-area: filter;
            padding-right: 50px;
            height: fit-content;
            position: sticky;
            top: 160px;

            .full-experience {
                &-container {
                    border: 1px solid var(--primary);
                    width: 100%;
                    padding: 40px 60px;
                    margin-top: 30px;
                }

                &-text {
                    margin-top: 20px;

                    p {
                        margin-bottom: 0;
                    }
                }
            }              
        }

        &-title {
            margin-bottom: 40px;
        }
        
        &-dropdown {
            &--container {
                margin-top: 30px;

                .name {
                    letter-spacing: 1px;
                    font-size: 14px;
                    margin-bottom: 10px;
                    text-transform: uppercase;
                }

                .calendar {
                    border-radius: 0;
                    appearance: none;
                    border: 1px solid;
                    width: 100%;
                    height: 50px;
                    padding: 0 1.5rem;
                    background-color: var(--brandingShade);
                    outline: none;
                    font-size: 12px;
                    letter-spacing: 1px;

                    &:focus, &:active {
                        outline: none;
                    }

                    &::placeholder {
                        color: var(--primary);
                    }

                    &-container {
                        position: relative;
                        
                        svg {
                            position: absolute;
                            right: 17px;
                            top: 12px;
                            height: 25px;
                            pointer-events: none;
                        }
                    }
                }                
            }
        }

        @include mq($until: 1100px) {
            padding: 60px;

            .filter {
                padding: 40px;

                &-title {
                    margin-bottom: 30px;
                }

                &-dropdown {
                    &--container {
                        margin-top: 20px;
                    }
                }
            }
        }

        @include mq($until: $viewport--sm) {
            padding: 40px;
        }
    }

    .productions {
        display: flex;
        flex-direction: column;
        grid-area: productions;
        padding-left: 50px;

        .nothing-found {
            h2 {
                line-height: 40px;
                max-width: 560px;

                a {
                    color: var(--primary);
                    text-decoration: none;
                    border-bottom: 2px solid var(--primary);
                }
            }

            .cta {
                margin-top: 20px;
                cursor: pointer;
            }
        }

        .day + .day {
            margin-top: 80px;
        }

        .day {
            display: flex;
            flex-direction: column;
            
            > * {
                width: 100%;
            }

            > h2 {
                border-bottom: 1px solid var(--brandingOpacity);
                padding-bottom: 20px;
            }

            .production {
                display: flex;
                justify-content: space-between;
                position: relative;
                border-bottom: 1px solid var(--brandingOpacity);
                padding: 5px 10px;
                
                .url-bg {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    transition: background-color .4s ease;
                    z-index: 1;
                }

                .left, .right {
                    position: relative;
                    z-index: 2;
                }
    
                .left {
                    display: flex;
                    align-items: center;
                    margin-right: 40px;
                    pointer-events: none;

                    .info {
                        display: flex;
                        flex-direction: column;
                        margin-left: 20px;
                        margin-top: 10px;

                        .title {
                            font-weight: 500;
                            text-transform: uppercase; 
                            margin-bottom: 0;  
                            max-width: 250px;                                                         
                        }

                        .bottom {
                            display: flex;

                            > div {
                                margin-right: 20px;
                                margin-bottom: 10px;
                                display: flex;
                                align-items: center;

                                > svg {
                                    margin-right: 5px;
                                }
                            }
                        }
                    }
                }
    
                .right {
                    display: flex;
                    align-items: center;

                    .more-info-url {
                        color: var(--primary);
                        opacity: .5;
                        text-decoration: none;
                        border-bottom: 1px solid var(--primary);
                        line-height: 18px;
                        margin-right: 10px;
                    }
                }
    
                .cover {
                    width: 50px;
                    transition: width .4s ease;
                }

                &:hover {                
                    .url-bg {
                        background-color: var(--brandingShade);
                    }

                    .cover {
                        width: 100px;
                    }
                }
            }
        }

        @include mq($until: 1100px) {
            .day + .day {
                margin-top: 40px;
            }
        }

        @include mq($until: $viewport--md) {
            .production {
                flex-direction: column;

                .left {
                    margin-right: 0;
                    margin-bottom: 20px;
                }

                .right {
                    justify-content: flex-end;
                }
            }
        }

        @include mq($until: $viewport--sm) {
            .day {
                > h2 {
                    text-align: center;
                }
                
                .production {
                    .left {
                        .info {
                            margin-top: 0px;
    
                            .bottom {
                                flex-direction: column;
    
                                > div {
                                    margin-top: 5px;
                                    margin-bottom: 0 !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @include mq($until: 1700px) {
        .filter {
            &-container {
                padding-right: 30px;
            }
        }

        .productions {
            padding-left: 30px;
        }
    }

    .Shows {
        &-section {
            background-color: var(--brandingShade);
            margin-top: 120px;
            padding-bottom: 400px;

            > div {
                display: flex;
            }

            &-info {
                background-color: var(--brandingColor);
                padding: 20px 50px 100px;
                padding-top: 180px;
                max-width: 500px;
                margin-left: 120px;
                height: fit-content;

                h2 {
                    margin-bottom: 40px;
                }

                * {
                    color: $cc-white;
                }

                ul {
                    padding-left: 0;
                    margin-bottom: 0;

                    li {
                        margin-bottom: 0;
                        padding-top: 20px;
                        list-style: none;
                        display: flex;
                        align-items: center;

                        figure {
                            width: 60px;
                            height: 60px;
                            border-radius: 100%;
                            border: 1px solid $cc-white;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-right: 20px;

                            svg {
                                width: 25px;

                                path, rect {
                                    stroke: $cc-white;
                                }
                            }
                        }

                        &:not(:last-child) {
                            padding-bottom: 20px;
                            border-bottom: 1px solid $cc-white;
                        }

                        a {
                            text-decoration: none;
                            border-bottom: 1px solid $cc-white;
                            padding-bottom: 3px;
                        }

                        p {
                            margin-bottom: 0;
                            text-transform: uppercase;
                            font-weight: 500;
                        }
                    }
                }
            }

            &-shows {
                padding-top: 180px;
                max-width: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;

                a {
                    text-decoration: none;
                }

                &-list {
                }

                .nothing-available {
                    text-align: center;
                    padding: 0 20px;
                }

                .month {
                    &-navigation {                            
                        &--top {
                            margin-bottom: 60px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            user-select: none;

                            &_title {

                            }

                            &_arrows {

                            }

                            &_arrow {
                                cursor: pointer;

                                &.inactive {
                                    opacity: .5;
                                    pointer-events: none;
                                }

                                &:first-of-type {
                                    margin-right: 10px;
                                }
                            }
                        }

                        &--bottom {
                            margin-top: 50px;
                            
                            &_arrows {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                            }

                            &_arrow {
                                cursor: pointer;

                                &.inactive {
                                    opacity: 0;
                                    pointer-events: none;
                                }

                                .month {
                                    font-size: 12px;
                                    text-transform: uppercase;
                                    color: var(--brandingColor);
                                    padding: 0 10px;
                                }
                            }
                        }
                    }
                    
                    &-slider {
                        &--month {

                        }
                    }
                }

                .option {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    border-bottom: 1px solid var(--brandingColor);
                    padding-bottom: 5px;

                    * {
                        font-size: 16px;
                    }

                    .left {
                        p {
                            margin-bottom: 0;
                            text-transform: uppercase;
                        }

                        .date {
                            font-weight: 500;
                            color: var(--primaryDark);
                        }

                        .hour {
                            text-transform: uppercase;
                            color: $cc-darkGrey;
                        }
                    }

                    .right {
                        .book-btn {
                            color: var(--brandingColor);
                            text-transform: uppercase;
                            letter-spacing: 1px;
                            text-decoration: none;
                            transition: opacity .2s ease;

                            &.warning {
                                color: $cc-red;
                            }

                            &.sold {
                                opacity: .5;
                            }

                            &:not(.sold) {
                                &:hover {
                                    opacity: .5;
                                }
                            }
                        }
                    }

                    &:not(:last-of-type) {
                        margin-bottom: 40px;
                    }
                }  
                
                @include mq($until: 1060px) {
                    .option {
                        &:not(:last-of-type) {
                            margin-bottom: 30px;
                        }

                        * {
                            font-size: 15px;
                        }
                    }
                }
                
                @include mq($until: $viewport--md) {
                    .month-navigation {
                        &--top {
                            margin-bottom: 30px;
                        }

                        &--bottom {
                            margin-top: 30px;
                        }
                    }
                }

                @include mq($until: 500px) {
                    .option {
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;

                        &:not(:last-of-type) {
                            margin-bottom: 20px;
                            padding-bottom: 15px;
                        }
                    }
                }
            }

            @include mq($until: 1550px) {
                > div {
                    margin-left: 80px;
                    margin-right: 80px;
                }          
            }
            
            @include mq($until: 1350px) {
                &-info {
                    margin-left: 60px;
                }        
            }
            
            @include mq($until: 1440px) {
                padding-bottom: 320px;

                > div {
                    flex-direction: column;
    
                    .Shows-section-info {
                        margin-left: 0;
                        max-width: unset;
                        padding: 40px 30px;
                        margin-top: 60px;
                    }        
                }
            }

            @include mq($until: $viewport--md) {
                margin-top: 80px;
                padding-bottom: 260px;

                > div {
                    margin-left: 30px;
                    margin-right: 30px;
                    padding: 0;
                }
    
                &-shows {
                    padding-top: 60px;
                }               
            }
        }
    }

    .golden-ctas  {
        margin-top: -180px;
    }

    @include mq($until: 1100px) {
        .agenda {
            &-container {
                grid-template-columns: 100%;
                grid-template-areas:
                    'filter'
                    'productions';               
            }
        }

        .filter {
            &-container {
                padding-right: 0;    
                position: relative;
                top: unset;            
            }
        }

        .productions {
            padding-left: 0;
            padding-top: 30px;
        }
    }
}