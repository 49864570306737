.cast {
    &-container {
        background-color: var(--brandingShade);
        padding: 120px 20px;
        margin-top: 120px;

        h1 {
            text-align: left;
            padding-bottom: 20px;
            margin-bottom: 50px;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            width: fit-content;
            margin-left: auto;
            margin-right: auto;
    
            .element {
                letter-spacing: 10px;
                color: var(--brandingColor);
            }
        }

        &--content {
            display: flex;
            max-width: fit-content;
            margin-left: auto;
            margin-right: auto;

            &-image {
                max-width: 500px;
            }

            .cast {
                display: grid;
                grid-template-columns: repeat(2, auto);
                grid-row-gap: 50px;
                margin-left: 180px;
                margin-top: 60px;

                h2 {
                    margin-bottom: 30px;
                }

                &-item {
                    display: flex;
                    flex-direction: column;
                    min-width: 340px;

                    &:not(:nth-of-type(even)) {
                        margin-right: 180px;
                    }

                    &--list {
                        display: flex;
                        flex-direction: column;
                        padding-left: 0;
                        margin-bottom: 0;

                        &-item {
                            margin-top: 5px;
                            list-style: none;
                        }

                        .list-item {
                            display: flex;
                            justify-content: space-between;
                            border-bottom: 1px solid var(--brandingColor);
                            padding-bottom: 10px;
                            font-weight: 500;
                            text-transform: uppercase;
                            position: relative;                            
                        }

                        .members {
                            .member {
                                width: 100%;                              
                                display: grid;
                                grid-template-columns: repeat(2, auto);
                                grid-template-rows: repeat(2, auto);
                                grid-template-areas: 'name bio-label'
                                'bio bio';
                                padding: 5px 0;
                                justify-content: space-between;

                                &-name {
                                    font-weight: 500;
                                    font-size: 14px;
                                    color: var(--primary);
                                    max-width: 200px;
                                    grid-area: name;
                                }

                                &:first-of-type {
                                    padding-top: 15px;
                                }

                                &:last-of-type {
                                    padding-bottom: 15px;
                                }

                                a {
                                    font-size: 12px;
                                    color: var(--primary);
                                }

                                &-bio {
                                    display: none;
                                    grid-area: bio;
                                    flex-direction: column;
                                    margin-bottom: 10px;
                                    margin-top: 5px;
                                    max-width: 300px;

                                    p {
                                        margin-bottom: 0;
                                        font-size: 14px;
                                        color: var(--primary);
                                        word-break: break-word;
                                    }

                                    &--input {
                                        &:checked {
                                            & ~ .member-bio {
                                                display: flex;
                                            }
                                            
                                            & ~ .member-bio--label {
                                                opacity: .7;
                                            }
                                        }
                                    }
                                    
                                    &--label {                                        
                                        grid-area: bio-label;
                                        display: flex;
                                        justify-content: flex-end;
                                        font-size: 12px;
                                        text-decoration: underline;
                                        color: var(--primary);
                                    }
                                }
                            }
                        }

                        &.tabs {
                            .tab {
                                width: 100%;
                                color: var(--primary);
                                overflow: hidden;
                                
                                &-label {
                                    display: flex;
                                    justify-content: space-between;
                                    cursor: pointer;
                                    border-bottom: 1px solid var(--brandingColor);
                                    padding-bottom: 10px;
                                    font-weight: 500;
                                    text-transform: uppercase;
                                    position: relative;
                                    
                                    &::after {
                                        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.887' height='22.359' viewBox='0 0 11.887 22.359'%3E%3Cpath id='Path_133' data-name='Path 133' d='M17987.813-1875.824l10.826,10.826-10.826,10.826' transform='translate(-17987.459 1876.178)' fill='none' stroke='%23000000' stroke-width='1'/%3E%3C/svg%3E");
                                        width: 14px;
                                        height: 24px;
                                        transform-origin: center;
                                        text-align: center;
                                        transition: all .35s;
                                        transform: scale(.8) rotate(90deg);
                                        right: 5px;
                                        position: absolute;
                                    }
                                }

                                &-content {
                                    max-height: 0;
                                    color: var(--brandingColor);
                                    transition: all .35s;

                                    .item {
                                        display: flex;
                                        justify-content: space-between;

                                        span {
                                            padding-right: 20px;
                                        }
                                    }
                                }
                            }
                                
                            // :checked
                            input {
                                display: none;

                                &:checked {
                                    + .tab-label {
                                        
                                        &::after {
                                        transform: scale(.8) rotate(-90deg);
                                        }
                                    }
                                    ~ .tab-content {
                                        max-height: 100%;
                                    }
                                }
                            }
                        }                                                    
                    }

                    &:nth-of-type(n+3) {
                        margin-top: 30px;
                    }
                }
            }

            @include mq($until: 1600px) {
                .cast {
                    margin-left: 50px;
                    margin-top: 40px;

                    &-item {
                        &:not(:nth-of-type(even)) {
                            margin-right: 60px;
                        }

                        .members {
                            .member {
                                &-name {
                                    max-width: 180px;
                                }
                            }
                        }
                    }                    
                }
            }
            
            @include mq($until: 1200px) {
                .cast {
                    margin-left: 0;

                    &-container {
                        &--content {
                            &-image {
                                display: none;
                            }
                        }
                    }
                }
            }

            @include mq($until: 820px) {
                .cast {
                    &-item {
                        min-width: 280px;

                        .members {
                            .member {
                                &-name {
                                    max-width: 150px;
                                }
                            }
                        }
                    }
                }
            }
           
            @include mq($until: 400px) {
                .cast {
                    &-item {
                        min-width: 250px;
                    }
                }
            }
        }
        
        @include mq($until: $viewport--md) {
            padding: 60px 20px;
            margin-top: 60px;

            .cast {
                grid-template-columns: auto;

                &-item {
                    &:not(:nth-of-type(even)) {
                        margin-right: 0;
                    }
                }
            }
        }
        
        @include mq($until: $viewport--sm) {
            > h1 {
                padding-bottom: 10px;
                margin-bottom: 0px;
            }                
        }

    }
}