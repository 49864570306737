.PressPage {
    .press-items {
        margin-top: 180px;
        
        .press-item {
            position: relative;
            background-color: var(--brandingShade);
            display: flex;
            flex-direction: column;
            text-decoration: none;
            color: var(--primary);
            transition: background-color .2s ease;

            figure {
                width: 100%;
                min-height: 320px;
                max-height: 320px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    transition: transform .2s ease;
                }
            }
    
            .highlight {
                position: absolute;
                left: 0;
                top: 0;
                background-color: var(--primaryDark);
                color: $cc-white;
                padding: 10px 15px;
                font-size: 13px;
                letter-spacing: 1px;
                z-index: 1;
            }

            .text {
                padding: 55px 65px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                height: 100%;
                z-index: 1;

                h3 {
                    margin-bottom: 40px;
                    transition: color .2s ease;
                    line-height: 28px;
                }

                .cta {
                    transition: color .2s ease, border-color .2s ease;
                }

                @include mq($until: 1100px) {
                    padding: 40px 30px;
                }
            }

            &:hover {
                background-color: var(--brandingColor);

                figure {
                    img {
                        transform: scale(1.05);
                    }
                }

                .text {
                    h3 {
                        color: $cc-white !important;
                    }
                    
                    .cta {
                        color: $cc-white !important;
                        border-color: $cc-white !important;

                        &:before {
                            transform: scaleX(0);
                            background-color: $cc-white;
                        }
                    }
                }
            }
        }

        @include mq($until: $viewport--md) {
            margin-top: 80px;
        }
    }
}