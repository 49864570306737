.NewsletterPage {
    .newsletter-form {
        margin-top: 90px;
        margin-bottom: 140px;
        max-width: 760px;
        margin-left: auto;
        margin-right: auto;
        padding: 0 20px;

        form {
            display: flex;
            flex-direction: column;
            align-items: center;

            p {
                display: none !important;
            }

            input {
                text-align: center;
                font-size: 16px;
                background-color: unset;
                border: 1px solid var(--primaryDark);
                color: var(--primaryDark) !important;

                &::placeholder {
                    color: rgba(var(--primaryDark), .3);
                }
            }

            .error {
                color: $cc-red;
                margin-bottom: 10px;
            }
                
            .cta {
                margin-top: 20px;
                padding: 15px 25px !important;
                color: var(--brandingColor) !important;
                border-color: var(--brandingColor) !important;
                transition: border-color .2s ease, color .2s ease, background-color .2s ease;

                span {
                    position: relative;
                    z-index: 1;
                    color: $cc-white;
                }
                
                &:hover {
                    background-color: var(--primaryDark) !important;
                    border-color: var(--primaryDark) !important;
                    color: $cc-white !important;
                }
            }
        }
    }
}

@include mq($until: $viewport--sm) {
    .NewsletterThanksPage {
        .container {
            margin-left: 20px;
            margin-right: 20px;

            .page-header {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}