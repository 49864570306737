.PagesWithListPage {
    .page-header--image {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 30px;
        margin-top: 100px;
        margin-bottom: 100px;

        img {
            max-width: 1160px;
        }

        @include mq($until: $viewport--sm) {
            margin-top: 60px;
            margin-bottom: 60px;
        }
    }

    .listing {
        margin-top: 180px;

        &-item {
            display: grid;
            grid-template-columns: 540px auto;

            &--image {
                width: 100%;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;                    
                    transform-origin: center;
                    transition: transform .2s ease;
                }
            }

            &.hovering {
                .listing-item--image {
                    img {
                        transform: scale(1.05);
                    }
                }
            }

            &--info {
                background-color: var(--brandingShade);
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 100px 140px;

                .subtitle {
                    color: var(--brandingColor);
                    font-weight: 400;
                    text-transform: uppercase;
                    font-size: 14px;
                    margin-top: 10px;
                }

                > div {
                    p {
                        margin-bottom: 0;
                    }
                }

                .ctas {
                    display: flex;
                    align-items: flex-end;
                    margin-top: 30px;

                    .cta {
                        color: var(--brandingColor) !important;
                        z-index: 1;
                        position: relative;                        

                        &:after {
                            opacity: 0 !important;
                        }

                        &:hover {
                            color: $cc-white !important;

                            &:after {
                                opacity: 1 !important;
                            }
                        }

                        &:not(:last-of-type) {
                            margin-right: 20px;
                        }
                    }
                }
            }
        }

        .listing-item + .listing-item {
            margin-top: 40px;
        }  
        
        @include mq($until: 1500px) {
            margin-left: 80px;
            margin-right: 80px;
        }
        
        @include mq($until: 1350px) {
            &-item {
                &--info {
                    padding: 60px;
                }
            }
        }

        @include mq($until: 1250px) {
            &-item {
                grid-template-columns: 400px auto;
            }
        }
       
        @include mq($until: 1000px) {
            margin-left: 30px;
            margin-right: 30px;

            &-item {
                grid-template-columns: 320px auto;

                &--info {
                    padding: 40px;
                }
            }
        }

        @include mq($until: $viewport--md) {
            margin-top: 80px;

            &-item {
                grid-template-columns: auto;

                &--image {
                    max-height: 400px;
                }

                &--info {
                    padding: 40px 30px;

                    .text {
                        * {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        @include mq($until: $viewport--sm) {
            &-item {
                &--image {
                    max-height: 280px;
                }
            }
        }
    }

    .golden-ctas, .slider {
        margin-top: 300px;

        @include mq($until: 1400px) {
            margin-top: 160px;
        }

        @include mq($until: $viewport--md) {
            margin-top: 80px;
        }
    }

    .slider {
        &-item {
            &--info {
                height: fit-content;
            }
        }
    }
}